import { USER_LOCALE_STORE_PERSIST_NAME } from "@hotelspoint/store";
import { UserLocale } from "@hotelspoint/types";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import resources from "virtual:i18next-loader";

const parsedResources = Object.fromEntries(
  Object.keys(resources).map(key => [key, { translation: resources[key] }]),
);

// Experimental: Try to load language from local storage or fallback to navigator.language
let language;
try {
  const item = localStorage.getItem(USER_LOCALE_STORE_PERSIST_NAME);

  if (item) {
    const { state } = JSON.parse(item);
    language = state?.language;
  }

  if (!language) {
    language = navigator?.language;
  }
} catch (error: any) {
  language = navigator?.language;
}

i18n.use(initReactI18next).init({
  debug: import.meta.env.DEV,
  resources: parsedResources,
  lng: language,
  fallbackLng: UserLocale.English,
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
});

export default i18n;
