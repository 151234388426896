import { Button } from "@hotelspoint/components";
import { GlobalStyles, ThemeProvider } from "@hotelspoint/theme";
import { IconFaceIdError } from "@tabler/icons-react";

import * as S from "./ErrorOverlay.styled";

const ErrorOverlay = () => {
  const handleReloadPage = () => {
    window.location.reload();
  };

  return (
    <ThemeProvider>
      <GlobalStyles />
      <S.Wrapper>
        <S.InnerWrapper>
          <IconFaceIdError size={72} />
          <div>
            <S.Heading>Something went wrong...</S.Heading>
            <S.SubHeading>
              We keep track of these errors, but feel free to contact us if
              refreshing doesn't fix things.
            </S.SubHeading>
          </div>
        </S.InnerWrapper>
        <S.ActionWrapper>
          <Button variant="secondary" onClick={handleReloadPage}>
            Reload page
          </Button>
        </S.ActionWrapper>
      </S.Wrapper>
    </ThemeProvider>
  );
};

export default ErrorOverlay;
