import type { FieldValues, UseFormReturn } from "react-hook-form";
import { useFormContext } from "react-hook-form";

interface FormContextProps<T extends FieldValues = FieldValues> {
  render: (props: UseFormReturn<T>) => React.ReactNode;
}

const FormContext = <T extends FieldValues = FieldValues>({
  render,
}: FormContextProps<T>) => {
  const ret = useFormContext<T>();

  return <>{render(ret)}</>;
};

export default FormContext;
