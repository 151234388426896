import { borderRadius, pxToRem, spacing } from "@hotelspoint/theme";
import styled from "styled-components";
import { theme } from "styled-tools";

export const Wrapper = styled.div`
  max-width: 400px;
  color: ${theme("palette.white")};
  background: rgba(97, 97, 97, 0.92);
  border-radius: ${borderRadius.normal}px;
  padding: ${spacing(0.5)}px ${spacing(1)}px;
  font-size: ${pxToRem(14)};
  word-wrap: break-word;
`;
