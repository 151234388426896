import { ActivitySearchPayload } from "@hotelspoint/api";
import { PlaceType } from "@hotelspoint/types";
import { formatDateIso } from "@hotelspoint/utils";
import { array, date, InferType, mixed, number, object, string } from "yup";

import { PERSON_SELECTOR_CONFIG } from "../constants";

export const validationSchema = object({
  search: string()
    .required("searchActivities.search.validation.required")
    .test("place", "__ctx__", (_, ctx) => {
      const place = ctx.parent.place;

      if (!place || !place.id || !place.type) {
        return ctx.createError({
          message: "searchActivities.search.validation.place",
        });
      }

      return true;
    }),
  dateIn: date()
    .typeError("searchActivities.dateIn.validation.required")
    .required("searchActivities.dateIn.validation.required"),
  dateOut: date()
    .typeError("searchActivities.dateOut.validation.required")
    .required("searchActivities.dateOut.validation.required"),
  travelers: object({
    adults: number()
      .typeError("searchActivities.travelers.validation.adult.required")
      .min(1, "searchActivities.travelers.validation.adult.required")
      .max(
        PERSON_SELECTOR_CONFIG.maxAdults,
        "searchActivities.travelers.validation.adult.max",
      )
      .required(),
    children: array()
      .of(
        number()
          .typeError("searchActivities.travelers.validation.children.required")
          .min(1, "searchActivities.travelers.validation.children.min")
          .max(
            PERSON_SELECTOR_CONFIG.maxChildrenAge,
            "searchActivities.travelers.validation.children.max",
          )
          .required("searchActivities.travelers.validation.children.required"),
      )
      .required(),
  }).required(),

  // Read-only - set programmatically when selecting a place
  place: object({
    id: number(),
    type: mixed<PlaceType>().oneOf(Object.values(PlaceType)),
  }),
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: Partial<FormValues> = {
  search: "",
  dateIn: undefined,
  dateOut: undefined,
  travelers: { adults: 2, children: [] },
  place: undefined,
};

export const form2Entity = (formValues: FormValues): ActivitySearchPayload => ({
  placeId: formValues.place?.id as number,
  placeType: formValues.place?.type as PlaceType,
  dateIn: formatDateIso(formValues.dateIn),
  dateOut: formatDateIso(formValues.dateOut),
  adults: formValues.travelers.adults,
  children: formValues.travelers.children,
});
