import { useUserProfileStore } from "@hotelspoint/store";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const AuthenticatedRoute = () => {
  const { pathname } = useLocation();

  const loggedIn = useUserProfileStore(state => !!state.profile);

  return loggedIn ? (
    <Outlet />
  ) : (
    <Navigate to={`/login?redirectTo=${pathname}`} />
  );
};

export default AuthenticatedRoute;
