import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

Sentry.init({
  enabled: import.meta.env.PROD,
  dsn: import.meta.env.VITE_APP_SENTRY_DSN,
  tracesSampleRate: 1.0,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  beforeSend: event => {
    if (import.meta.env.PROD && event.exception) {
      Sentry.showReportDialog({ eventId: event.event_id as string });
    }

    return event;
  },
});
