import { type DayPickerSingleProps } from "react-day-picker";

import { type FormControlInjectedProps } from "../../FormControl";
import FormDatePicker from "../FormDatePicker";

interface FormDatePickerSingleProps
  extends FormControlInjectedProps,
    Omit<
      React.InputHTMLAttributes<HTMLInputElement>,
      "id" | "name" | "value" | "onChange"
    > {
  dayPickerProps?: Omit<DayPickerSingleProps, "mode">;
}

const FormDatePickerSingle = ({
  value,
  onChange,
  dayPickerProps,
  ...rest
}: FormDatePickerSingleProps) => {
  return (
    <FormDatePicker
      value={value}
      onChange={onChange}
      dayPickerProps={{
        mode: "single",
        fixedWeeks: true,
        showOutsideDays: true,
        ...dayPickerProps,
      }}
      {...rest}
    />
  );
};

export default FormDatePickerSingle;
