import { AdminActivityRequestListParams, AdminService } from "@hotelspoint/api";
import { useQuery } from "@tanstack/react-query";

const useAdminActivityRequests = (params?: AdminActivityRequestListParams) => {
  const { data, isLoading } = useQuery({
    queryKey: ["admin", "requests_activity", params],
    queryFn: () => AdminService.getActivityRequests(params),
  });

  const ret: [typeof data, boolean] = [data, isLoading];

  return ret;
};

export default useAdminActivityRequests;
