import { useAdminAgencyStatement } from "@hotelspoint/api";
import { Panel } from "@hotelspoint/components";
import { useDocumentTitle } from "@hotelspoint/utils";
import { type RowSelectionState } from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import AdminAgencyStatementActions from "./AdminAgencyStatementActions";
import AdminAgencyStatementForm from "./AdminAgencyStatementForm";
import AdminAgencyStatementItems from "./AdminAgencyStatementItems";
import AdminAgencyStatementItemsActions from "./AdminAgencyStatementItems/AdminAgencyStatementItemsActions";

interface AdminAgencyStatementParams extends Record<string, string> {
  id: string;
}

const AdminAgencyStatement = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("adminAgencyStatement.pageTitle"));

  const { id } = useParams<AdminAgencyStatementParams>();
  const agencyStatementId = Number(id);

  const [statement, isLoading] = useAdminAgencyStatement(agencyStatementId);

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const data = useMemo(() => statement?.items ?? [], [statement]);

  return (
    <Panel
      title={t("adminAgencyStatement.pageTitle")}
      actions={<AdminAgencyStatementActions id={agencyStatementId} />}
    >
      <AdminAgencyStatementForm id={agencyStatementId} />
      <AdminAgencyStatementItems
        data={data}
        isLoading={isLoading}
        useReactTableProps={{
          enableRowSelection: true,
          onRowSelectionChange: setRowSelection,
          state: {
            rowSelection,
          },
        }}
      />
      <AdminAgencyStatementItemsActions
        id={agencyStatementId}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
      />
    </Panel>
  );
};

export default AdminAgencyStatement;
