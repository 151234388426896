import {
  AdminActivityRequestType,
  AdminAgencyStatementStatus,
  AdminCommissionStatus,
  AdminHotelRequestType,
  AdminPaymentRequestType,
  AdminSupplierStatementStatus,
  AdminTransferRequestType,
  InvoiceStatus,
  MessageOrderBy,
  MessagePriority,
  MessageStatus,
  MessageType,
  PaymentCreditStatus,
  PaymentStatus,
  PaymentType,
  ReservationHotelConfirmationStatus,
  ReservationMessageFilter,
  ReservationOrderBy,
  ReservationStatus,
  ReservationSupplierConfirmationStatus,
  ReservationTransferConfirmationStatus,
  Titles,
} from "@hotelspoint/api";
import { AgencyStatus, ReservationType, UserRole } from "@hotelspoint/types";

import assignees from "./assignees.json";
import paymentProviders from "./paymentProviders.json";
import {
  activitySuppliers,
  hotelSuppliers,
  statementSuppliers,
  transferSuppliers,
} from "./suppliers";

export const BOOLEAN_OPTIONS = [
  { value: true, label: "booleanOptions.yes" },
  { value: false, label: "booleanOptions.no" },
];

export const USER_ROLE_OPTIONS = [
  {
    value: UserRole.SuperAdministrator,
    label: "userRoles.superAdministrator",
  },
  {
    value: UserRole.AgencyAdministrator,
    label: "userRoles.agencyAdministrator",
  },
  {
    value: UserRole.AgencyMember,
    label: "userRoles.agencyMember",
  },
  {
    value: UserRole.SalesManager,
    label: "userRoles.salesManager",
  },
];

export const USER_STATUS_OPTIONS = [
  {
    label: "userStatus.active",
    value: true,
  },
  {
    label: "userStatus.inactive",
    value: false,
  },
];

export const INVOICE_STATUS_OPTIONS = [
  {
    value: InvoiceStatus.Pending,
    label: "invoiceStatus.pending",
  },
  {
    value: InvoiceStatus.Completed,
    label: "invoiceStatus.completed",
  },
  {
    value: InvoiceStatus.Cancelled,
    label: "invoiceStatus.cancelled",
  },
];

export const PAYMENT_STATUS_OPTIONS = [
  {
    value: PaymentStatus.Progress,
    label: "paymentStatus.progress",
  },
  {
    value: PaymentStatus.Reserved,
    label: "paymentStatus.reserved",
  },
  {
    value: PaymentStatus.Confirmed,
    label: "paymentStatus.confirmed",
  },
  {
    value: PaymentStatus.Cancelled,
    label: "paymentStatus.cancelled",
  },
  {
    value: PaymentStatus.Refunded,
    label: "paymentStatus.refunded",
  },
  {
    value: PaymentStatus.Failed,
    label: "paymentStatus.failed",
  },
  {
    value: PaymentStatus.PendingRefund,
    label: "paymentStatus.pendingRefund",
  },
];

export const PAYMENT_CREDIT_STATUS_OPTIONS = [
  {
    value: PaymentCreditStatus.Pending,
    label: "paymentCreditStatus.pending",
  },
  {
    value: PaymentCreditStatus.Paid,
    label: "paymentCreditStatus.paid",
  },
];

export const PAYMENT_TYPE_OPTIONS = [
  {
    value: PaymentType.BankTransfer,
    label: "paymentType.bankTransfer",
  },
  {
    value: PaymentType.CreditCard,
    label: "paymentType.creditCard",
  },
  {
    value: PaymentType.CreditLine,
    label: "paymentType.creditLine",
  },
];

export const AGENCY_STATUS_OPTIONS = [
  {
    value: AgencyStatus.Created,
    label: "agencyStatus.created",
  },
  {
    value: AgencyStatus.Pending,
    label: "agencyStatus.pending",
  },
  {
    value: AgencyStatus.Verified,
    label: "agencyStatus.verified",
  },
  {
    value: AgencyStatus.Rejected,
    label: "agencyStatus.rejected",
  },
];

export const MESSAGE_STATUS_OPTIONS = [
  {
    value: MessageStatus.Pending,
    label: "messageStatus.pending",
  },
  {
    value: MessageStatus.InProgress,
    label: "messageStatus.inProgress",
  },
  {
    value: MessageStatus.Completed,
    label: "messageStatus.completed",
  },
];

export const RESERVATION_STATUS_OPTIONS = [
  {
    value: ReservationStatus.CreatedReservation,
    label: "reservationStatus.createdReservation",
  },
  {
    value: ReservationStatus.PendingConfirmation,
    label: "reservationStatus.pendingConfirmation",
  },
  {
    value: ReservationStatus.ConfirmedReservation,
    label: "reservationStatus.confirmedReservation",
  },
  {
    value: ReservationStatus.PendingCancellation,
    label: "reservationStatus.pendingCancellation",
  },
  {
    value: ReservationStatus.CancelledReservation,
    label: "reservationStatus.cancelledReservation",
  },
  {
    value: ReservationStatus.FailedConfirmation,
    label: "reservationStatus.failedConfirmation",
  },
  {
    value: ReservationStatus.CancelledWithFee,
    label: "reservationStatus.cancelledWithFee",
  },
];

export const MESSAGES_PRIORITY_OPTIONS = [
  {
    value: MessagePriority.Low,
    label: "messagePriority.low",
  },
  {
    value: MessagePriority.Medium,
    label: "messagePriority.medium",
  },
  {
    value: MessagePriority.High,
    label: "messagePriority.high",
  },
  {
    value: MessagePriority.Critical,
    label: "messagePriority.critical",
  },
];

export const MESSAGES_TYPE_OPTIONS = [
  {
    value: MessageType.Agency,
    label: "messageType.agency",
  },
  {
    value: MessageType.Hotel,
    label: "messageType.hotel",
  },
  {
    value: MessageType.Supplier,
    label: "messageType.supplier",
  },
];

export const MESSAGE_ORDER_BY_OPTIONS = [
  {
    value: MessageOrderBy.Date,
    label: "messageOrderBy.date",
  },
  {
    value: MessageOrderBy.Priority,
    label: "messageOrderBy.priority",
  },
];

export const ASSIGNEE_OPTIONS = Object.entries(assignees).map(
  ([key, value]) => ({
    value: Number(key),
    label: value,
  }),
);

export const COMMISSION_STATUS_OPTIONS = [
  {
    value: AdminCommissionStatus.Pending,
    label: "commissionStatus.pending",
  },
  {
    value: AdminCommissionStatus.Paid,
    label: "commissionStatus.paid",
  },
];

export const AGENCY_STATEMENTS_STATUS_OPTIONS = [
  {
    value: AdminAgencyStatementStatus.Pending,
    label: "agencyStatementStatus.pending",
  },
  {
    value: AdminAgencyStatementStatus.Paid,
    label: "agencyStatementStatus.paid",
  },
];

export const HOTEL_SUPPLIER_OPTIONS = Object.entries(hotelSuppliers).map(
  ([key, value]) => ({
    value: Number(key),
    label: value,
  }),
);

export const TRANSFER_SUPPLIER_OPTIONS = Object.entries(transferSuppliers).map(
  ([key, value]) => ({
    value: Number(key),
    label: value,
  }),
);

export const ACTIVITY_SUPPLIER_OPTIONS = Object.entries(activitySuppliers).map(
  ([key, value]) => ({
    value: Number(key),
    label: value,
  }),
);

export const STATEMENT_SUPPLIER_OPTIONS = statementSuppliers.map(supplier => ({
  value: supplier.id,
  label: supplier.name,
}));

export const SUPPLIER_STATUS_OPTIONS = [
  {
    value: AdminSupplierStatementStatus.PendingPayment,
    label: "supplierStatementStatus.pendingPayment",
  },
  {
    value: AdminSupplierStatementStatus.PartialPayment,
    label: "supplierStatementStatus.partialPayment",
  },
  {
    value: AdminSupplierStatementStatus.PaymentCompleted,
    label: "supplierStatementStatus.paymentCompleted",
  },
];

export const HOTEL_REQUEST_TYPE_OPTIONS = [
  {
    value: AdminHotelRequestType.Search,
    label: "hotelRequestType.search",
  },
  {
    value: AdminHotelRequestType.Fees,
    label: "hotelRequestType.fees",
  },
  {
    value: AdminHotelRequestType.Remarks,
    label: "hotelRequestType.remarks",
  },
  {
    value: AdminHotelRequestType.ReservePrepend,
    label: "hotelRequestType.reservePrepend",
  },
  {
    value: AdminHotelRequestType.ReserveConfirm,
    label: "hotelRequestType.reserveConfirm",
  },
  {
    value: AdminHotelRequestType.ReserveAbort,
    label: "hotelRequestType.reserveAbort",
  },
  {
    value: AdminHotelRequestType.ReserveCancel,
    label: "hotelRequestType.reserveCancel",
  },
  {
    value: AdminHotelRequestType.ReserveCheck,
    label: "hotelRequestType.reserveCheck",
  },
  {
    value: AdminHotelRequestType.ReserveFees,
    label: "hotelRequestType.reserveFees",
  },
];

export const RESERVATION_TYPE_OPTIONS = [
  {
    value: ReservationType.Hotel,
    label: "reservationType.hotel",
  },
  {
    value: ReservationType.Transfer,
    label: "reservationType.transfer",
  },
  {
    value: ReservationType.RentACar,
    label: "reservationType.rentACar",
  },
  {
    value: ReservationType.Activity,
    label: "reservationType.activity",
  },
];

export const HOTEL_CONFIRMATION_STATUS_OPTIONS = [
  {
    value: ReservationHotelConfirmationStatus.NotSend,
    label: "reservationHotelConfirmationStatus.notSend",
  },
  {
    value: ReservationHotelConfirmationStatus.EmailNotFound,
    label: "reservationHotelConfirmationStatus.emailNotFound",
  },
  {
    value: ReservationHotelConfirmationStatus.EmailSend,
    label: "reservationHotelConfirmationStatus.emailSend",
  },
  {
    value: ReservationHotelConfirmationStatus.EmailResend,
    label: "reservationHotelConfirmationStatus.emailResend",
  },
  {
    value: ReservationHotelConfirmationStatus.EmailReceived,
    label: "reservationHotelConfirmationStatus.emailReceived",
  },
  {
    value: ReservationHotelConfirmationStatus.EmailNotReceived,
    label: "reservationHotelConfirmationStatus.emailNotReceived",
  },
  {
    value: ReservationHotelConfirmationStatus.CallRequired,
    label: "reservationHotelConfirmationStatus.callRequired",
  },
  {
    value: ReservationHotelConfirmationStatus.Escalation1,
    label: "reservationHotelConfirmationStatus.escalation1",
  },
  {
    value: ReservationHotelConfirmationStatus.Escalation2,
    label: "reservationHotelConfirmationStatus.escalation2",
  },
  {
    value: ReservationHotelConfirmationStatus.Confirmed,
    label: "reservationHotelConfirmationStatus.confirmed",
  },
];

export const SUPPLIER_CONFIRMATION_STATUS_OPTIONS = [
  {
    value: ReservationSupplierConfirmationStatus.NotSend,
    label: "reservationSupplierConfirmationStatus.notSend",
  },
  {
    value: ReservationSupplierConfirmationStatus.EmailSend,
    label: "reservationSupplierConfirmationStatus.emailSend",
  },
  {
    value: ReservationSupplierConfirmationStatus.EmailReceived,
    label: "reservationSupplierConfirmationStatus.emailReceived",
  },
  {
    value: ReservationSupplierConfirmationStatus.EmailNotReceived,
    label: "reservationSupplierConfirmationStatus.emailNotReceived",
  },
  {
    value: ReservationSupplierConfirmationStatus.Escalation1,
    label: "reservationSupplierConfirmationStatus.escalation1",
  },
  {
    value: ReservationSupplierConfirmationStatus.Escalation2,
    label: "reservationSupplierConfirmationStatus.escalation2",
  },
  {
    value: ReservationSupplierConfirmationStatus.Confirmed,
    label: "reservationSupplierConfirmationStatus.confirmed",
  },
  {
    value: ReservationSupplierConfirmationStatus.Verified,
    label: "reservationSupplierConfirmationStatus.verified",
  },
  {
    value: ReservationSupplierConfirmationStatus.NotVerified,
    label: "reservationSupplierConfirmationStatus.notVerified",
  },
];

export const CONFIRMATION_TRANSFER_STATUS_OPTIONS = [
  {
    value: ReservationTransferConfirmationStatus.NotConfirmed,
    label: "reservationTransferConfirmationStatus.notConfirmed",
  },
  {
    value: ReservationTransferConfirmationStatus.ConfirmedWithNumber,
    label: "reservationTransferConfirmationStatus.confirmedWithNumber",
  },
  {
    value: ReservationTransferConfirmationStatus.ConfirmedWithoutNumber,
    label: "reservationTransferConfirmationStatus.confirmedWithoutNumber",
  },
  {
    value: ReservationTransferConfirmationStatus.NoFlightTracking,
    label: "reservationTransferConfirmationStatus.noFlightTracking",
  },
];

export const RESERVATION_MESSAGE_FILTER_OPTIONS = [
  {
    value: ReservationMessageFilter.All,
    label: "reservationMessageFilter.all",
  },
  {
    value: ReservationMessageFilter.New,
    label: "reservationMessageFilter.new",
  },
  {
    value: ReservationMessageFilter.Pending,
    label: "reservationMessageFilter.pending",
  },
  {
    value: ReservationMessageFilter.Progress,
    label: "reservationMessageFilter.progress",
  },
  {
    value: ReservationMessageFilter.Completed,
    label: "reservationMessageFilter.completed",
  },
  {
    value: ReservationMessageFilter.Alerts,
    label: "reservationMessageFilter.alerts",
  },
];

export const TRANSFER_REQUEST_TYPE_OPTIONS = [
  {
    value: AdminTransferRequestType.Search,
    label: "transferRequestType.search",
  },
  {
    value: AdminTransferRequestType.ReservePrepare,
    label: "transferRequestType.reservePrepare",
  },
  {
    value: AdminTransferRequestType.ReserveConfirm,
    label: "transferRequestType.reserveConfirm",
  },
  {
    value: AdminTransferRequestType.ReserveCancel,
    label: "transferRequestType.reserveCancel",
  },
  {
    value: AdminTransferRequestType.ReserveStatus,
    label: "transferRequestType.reserveStatus",
  },
];

export const ACTIVITY_REQUEST_TYPE_OPTIONS = [
  {
    value: AdminActivityRequestType.Search,
    label: "activityRequestType.search",
  },
  {
    value: AdminActivityRequestType.SearchDetails,
    label: "activityRequestType.searchDetails",
  },
  {
    value: AdminActivityRequestType.SearchOptions,
    label: "activityRequestType.searchOptions",
  },
  {
    value: AdminActivityRequestType.ReservePrepare,
    label: "activityRequestType.reservePrepare",
  },
  {
    value: AdminActivityRequestType.ReserveConfirm,
    label: "activityRequestType.reserveConfirm",
  },
];

export const PAYMENT_REQUEST_TYPE_OPTIONS = [
  {
    value: AdminPaymentRequestType.PaymentRegister,
    label: "paymentRequestType.paymentRegister",
  },
  {
    value: AdminPaymentRequestType.PaymentStatus,
    label: "paymentRequestType.paymentStatus",
  },
  {
    value: AdminPaymentRequestType.PaymentConfirm,
    label: "paymentRequestType.paymentConfirm",
  },
  {
    value: AdminPaymentRequestType.PaymentCancel,
    label: "paymentRequestType.paymentCancel",
  },
];

export const RESERVATION_ORDER_BY_OPTIONS = [
  {
    value: ReservationOrderBy.BookingDate,
    label: "reservationOrderBy.bookingDate",
  },
  {
    value: ReservationOrderBy.DeadlineDate,
    label: "reservationOrderBy.deadlineDate",
  },
  {
    value: ReservationOrderBy.CheckInDate,
    label: "reservationOrderBy.checkInDate",
  },
  {
    value: ReservationOrderBy.CancelledDate,
    label: "reservationOrderBy.cancelledDate",
  },
];

export const PAYMENT_PROVIDER_OPTIONS = Object.entries(paymentProviders).map(
  ([key, value]) => ({
    value: Number(key),
    label: value,
  }),
);

export const TITLE_OPTIONS = [
  {
    value: Titles.Mr,
    label: "titles.mr",
  },
  {
    value: Titles.Mrs,
    label: "titles.mrs",
  },
];
