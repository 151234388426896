import { handleError, useChangePassword } from "@hotelspoint/api";
import {
  Button,
  Form,
  FormAdapter,
  FormContext,
  FormInput,
  FormLayout,
  StatusBlock,
  StatusFlavour,
} from "@hotelspoint/components";
import { IconLock } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import * as S from "./ChangePassword.styled";
import {
  defaultValues,
  type FormValues,
  validationSchema,
} from "./ChangePasswordForm.util";

interface ChangePasswordFormProps {
  token?: string | null;
}

const ChangePasswordForm = ({ token }: ChangePasswordFormProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [changePassword, isChangingPassword] = useChangePassword();

  const onSubmit = async (formValues: FormValues) => {
    try {
      const payload = {
        token: token as string,
        password: formValues.password,
      };

      const response = await changePassword(payload);

      if (response.result) {
        navigate("/");
        toast.success(t("toast.changePassword"));
      } else {
        throw new Error(t("toast.unhandled"));
      }
    } catch (error: any) {
      handleError({ t, error });
    }
  };

  return (
    <Form<FormValues>
      defaultValues={defaultValues}
      validationSchema={validationSchema}
    >
      {!token && (
        <S.StatusWrapper>
          <StatusBlock status={StatusFlavour.Error}>
            {t("changePassword.errors.invalidToken")}
          </StatusBlock>
        </S.StatusWrapper>
      )}
      <FormLayout $small>
        <FormAdapter name="password" label={t("changePassword.password.label")}>
          {props => (
            <FormInput
              {...props}
              type="password"
              autoComplete="new-password"
              placeholder={t("changePassword.password.placeholder")}
              startAdornment={<IconLock size={18} />}
              disabled={!token || isChangingPassword}
            />
          )}
        </FormAdapter>
        <FormAdapter
          name="confirmPassword"
          label={t("changePassword.confirmPassword.label")}
        >
          {props => (
            <FormInput
              {...props}
              type="password"
              autoComplete="new-password"
              placeholder={t("changePassword.confirmPassword.placeholder")}
              startAdornment={<IconLock size={18} />}
              disabled={!token || isChangingPassword}
            />
          )}
        </FormAdapter>
        <FormContext<FormValues>
          render={({ handleSubmit }) => (
            <Button
              type="submit"
              variant="secondary"
              isLoading={isChangingPassword}
              isDisabled={!token}
              onClick={handleSubmit(onSubmit)}
            >
              {t("changePassword.submit")}
            </Button>
          )}
        />
      </FormLayout>
    </Form>
  );
};

export default ChangePasswordForm;
