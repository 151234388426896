import {
  BooleanParam,
  JsonParam,
  NumberParam,
  StringParam,
  useQueryParams,
} from "use-query-params";

const useHotelSearchParams = () => {
  const [query, setQuery] = useQueryParams({
    placeId: NumberParam,
    placeType: StringParam,
    checkIn: StringParam,
    checkOut: StringParam,
    nationality: StringParam,
    rooms: JsonParam,
    useCache: BooleanParam,
    // Misc
    view: StringParam,
  });

  const ret: [typeof query, typeof setQuery] = [query, setQuery];

  return ret;
};

export default useHotelSearchParams;
