import { useAgency, useFetchAgencies } from "@hotelspoint/api";
import {
  Box,
  Button,
  Chip,
  Flex,
  Form,
  FormAdapter,
  FormClearableInput,
  FormContext,
  FormDatePickerSingle,
  FormLayout,
  FormSelect,
  StatusChip,
} from "@hotelspoint/components";
import { FormAsyncSelect } from "@hotelspoint/components";
import { USER_ROLE_OPTIONS, USER_STATUS_OPTIONS } from "@hotelspoint/constants";
import { getUserRoleColor, getUserStatusColor } from "@hotelspoint/utils";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import useAdminAgentsParams from "../useAdminAgentsParams";
import {
  entity2Form,
  form2Entity,
  FormValues,
} from "./AdminAgentsFilters.form";

interface AdminAgentsFiltersProps {
  isLoading: boolean;
}

const AdminAgentsFilters = ({ isLoading }: AdminAgentsFiltersProps) => {
  const { t } = useTranslation();

  const [query, setQuery] = useAdminAgentsParams();

  const [fetchAgencies, isFetchingAgencies] = useFetchAgencies();
  const [agency, isLoadingAgency] = useAgency(Number(query?.agencyId));

  const formValues = useMemo(() => {
    return entity2Form(query);
  }, [query]);

  const defaultAgencyOptions = useMemo(() => {
    if (!agency) return [];

    return [
      {
        value: agency.id,
        label: agency.name,
      },
    ];
  }, [agency]);

  const roleOptions = useMemo(
    () =>
      USER_ROLE_OPTIONS.map(role => ({
        ...role,
        label: (
          <Chip $color={getUserRoleColor(role.value)}>{t(role.label)}</Chip>
        ),
      })),
    [t],
  );

  const statusOptions = useMemo(
    () =>
      USER_STATUS_OPTIONS.map(status => ({
        ...status,
        label: (
          <StatusChip $variant={getUserStatusColor(status.value)}>
            {t(status.label)}
          </StatusChip>
        ),
      })),
    [t],
  );

  const handleLoadOptions = useCallback(
    (searchQuery: string, callback: any) => {
      fetchAgencies({ name: searchQuery }).then(response => {
        if (response && Array.isArray(response.results)) {
          callback(
            response.results.map(agency => ({
              value: agency.id,
              label: agency.name,
            })),
          );
        }
      });
    },
    [fetchAgencies],
  );

  const onSubmit = (formValues: FormValues) => {
    const payload = form2Entity(formValues);

    setQuery(payload);
  };

  return (
    <Form defaultValues={formValues}>
      <FormLayout>
        <Flex mx={[0, 0, -1, -1]}>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter name="id" label={t("adminAgents.filters.id.label")}>
              {props => (
                <FormClearableInput
                  {...props}
                  placeholder={t("adminAgents.filters.id.placeholder")}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="name"
              label={t("adminAgents.filters.name.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  placeholder={t("adminAgents.filters.name.placeholder")}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="email"
              label={t("adminAgents.filters.email.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  placeholder={t("adminAgents.filters.email.placeholder")}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="roleId"
              label={t("adminAgents.filters.roleId.label")}
            >
              {props => (
                <FormSelect
                  {...props}
                  placeholder={t("adminAgents.filters.roleId.placeholder")}
                  options={roleOptions}
                  isClearable
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="agency"
              label={t("adminAgents.filters.agency.label")}
            >
              {props => (
                <FormAsyncSelect
                  {...props}
                  placeholder={t("adminAgents.filters.agency.placeholder")}
                  defaultOptions={defaultAgencyOptions}
                  loadOptions={handleLoadOptions}
                  isLoading={isLoadingAgency || isFetchingAgencies}
                  isClearable
                  noOptionsMessage={() =>
                    t("adminAgents.filters.agency.noOptions")
                  }
                  loadingMessage={() => t("adminAgent.agency.loading")}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="isActive"
              label={t("adminAgents.filters.isActive.label")}
            >
              {props => (
                <FormSelect
                  {...props}
                  placeholder={t("adminAgents.filters.isActive.placeholder")}
                  options={statusOptions}
                  isClearable
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="registerFrom"
              label={t("adminAgents.filters.registerFrom.label")}
            >
              {props => (
                <FormDatePickerSingle
                  {...props}
                  placeholder={t(
                    "adminAgents.filters.registerFrom.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="registerTo"
              label={t("adminAgents.filters.registerTo.label")}
            >
              {props => (
                <FormDatePickerSingle
                  {...props}
                  placeholder={t("adminAgents.filters.registerTo.placeholder")}
                />
              )}
            </FormAdapter>
          </Box>
        </Flex>
        <FormContext<FormValues>
          render={({ handleSubmit }) => (
            <Button
              type="submit"
              variant="secondary"
              isLoading={isLoading}
              onClick={handleSubmit(onSubmit)}
            >
              {t("adminAgents.filters.submit")}
            </Button>
          )}
        />
      </FormLayout>
    </Form>
  );
};

export default AdminAgentsFilters;
