import Breadcrumb, {
  BreadcrumbContainer,
  BreadcrumbItem,
} from "../../elements/Breadcrumb";
import Card from "../../elements/Card";
import PageSpacer from "../../scaffold/PageSpacer";
import * as S from "./PagePaper.styled";

interface PagePaperProps {
  title: string;
  children: React.ReactNode;
  breadcrumbs?: BreadcrumbItem[];
}

const PagePaper = ({ title, children, breadcrumbs }: PagePaperProps) => {
  return (
    <PageSpacer>
      <S.TitleWrapper>
        {breadcrumbs && (
          <BreadcrumbContainer>
            <Breadcrumb items={breadcrumbs} />
          </BreadcrumbContainer>
        )}
        <S.Title>{title}</S.Title>
      </S.TitleWrapper>
      <Card>{children}</Card>
    </PageSpacer>
  );
};

export default PagePaper;
