import {
  AdminSupplierStatementStatus,
  handleError,
  useAdminSupplierStatement,
  useDeleteAdminSupplierStatementPayment,
} from "@hotelspoint/api";
import {
  Button,
  Table,
  TableFooter,
  TableLayout,
} from "@hotelspoint/components";
import { IconPlus } from "@tabler/icons-react";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import AdminSupplierStatementAddPaymentDialog from "./AdminSupplierStatementAddPaymentDialog";
import getColumns from "./AdminSupplierStatementPayments.columns";

interface AdmiSupplierStatementsPaymentsProps {
  id: number;
}

const AdmiSupplierStatementsPayments = ({
  id,
}: AdmiSupplierStatementsPaymentsProps) => {
  const { t } = useTranslation();

  const [targetId, setTargetId] = useState<number | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [statement, isLoading] = useAdminSupplierStatement(id);

  const [deletePayment, isDeleting] =
    useDeleteAdminSupplierStatementPayment(id);

  const data = useMemo(() => statement?.payments ?? [], [statement]);

  const handleDelete = useCallback(
    async (id: number) => {
      try {
        setTargetId(id);
        await deletePayment(id);
      } catch (error: any) {
        handleError({ t, error });
      } finally {
        setTargetId(null);
      }
    },
    [t, deletePayment],
  );

  const columns = useMemo(() => {
    return getColumns({
      t,
      targetId,
      handleDelete,
      isDeleting,
    });
  }, [t, targetId, handleDelete, isDeleting]);

  return (
    <>
      <AdminSupplierStatementAddPaymentDialog
        id={id}
        isOpen={isDialogOpen}
        setIsOpen={setIsDialogOpen}
      />
      <TableLayout>
        <Table
          data={data}
          columns={columns}
          isLoading={isLoading}
          useReactTableProps={{
            state: {
              columnPinning: {
                left: ["id"],
                right: ["actions"],
              },
            },
          }}
        />
        <TableFooter>
          <Button
            small
            variant="outlined"
            onClick={() => setIsDialogOpen(true)}
            isDisabled={
              statement?.status ===
              AdminSupplierStatementStatus.PaymentCompleted
            }
          >
            <IconPlus size={12} />
            <span>
              {t("adminSupplierStatement.payments.columns.actions.add")}
            </span>
          </Button>
        </TableFooter>
      </TableLayout>
    </>
  );
};

export default AdmiSupplierStatementsPayments;
