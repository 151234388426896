import {
  useAgency,
  useFetchAgencies,
  useFetchUsers,
  useUser,
} from "@hotelspoint/api";
import {
  Box,
  Button,
  Chip,
  Flex,
  Form,
  FormAdapter,
  FormAsyncSelect,
  FormClearableInput,
  FormContext,
  FormDatePickerSingle,
  FormLayout,
  FormSelect,
} from "@hotelspoint/components";
import {
  ASSIGNEE_OPTIONS,
  BOOLEAN_OPTIONS,
  CHIP_COLORS,
  CONFIRMATION_TRANSFER_STATUS_OPTIONS,
  HOTEL_CONFIRMATION_STATUS_OPTIONS,
  HOTEL_SUPPLIER_OPTIONS,
  PAYMENT_STATUS_OPTIONS,
  RESERVATION_MESSAGE_FILTER_OPTIONS,
  RESERVATION_ORDER_BY_OPTIONS,
  RESERVATION_STATUS_OPTIONS,
  RESERVATION_TYPE_OPTIONS,
  SUPPLIER_CONFIRMATION_STATUS_OPTIONS,
} from "@hotelspoint/constants";
import {
  getPaymentStatusColor,
  getReservationStatusColor,
} from "@hotelspoint/utils";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import useAdminReservationsParams from "../useAdminReservationsParams";
import {
  entity2Form,
  form2Entity,
  FormValues,
} from "./AdminReservationsFilters.form";

interface AdminReservationsFiltersProps {
  isLoading: boolean;
}

const AdminReservationsFilters = ({
  isLoading,
}: AdminReservationsFiltersProps) => {
  const { t } = useTranslation();

  const [query, setQuery] = useAdminReservationsParams();

  const [fetchUsers, isFetchingUsers] = useFetchUsers();
  const [user, isLoadingUser] = useUser(Number(query?.userId));

  const [fetchAgencies, isFetchingAgencies] = useFetchAgencies();
  const [agency, isLoadingAgency] = useAgency(Number(query?.agencyId));

  const defaultUserOptions = useMemo(() => {
    if (!user) return [];

    return [
      {
        value: user.id,
        label: user.name,
      },
    ];
  }, [user]);

  const defaultAgencyOptions = useMemo(() => {
    if (!agency) return [];

    return [
      {
        value: agency.id,
        label: agency.name,
      },
    ];
  }, [agency]);

  const handleLoadUserOptions = useCallback(
    (searchQuery: string, callback: any) => {
      fetchUsers({ name: searchQuery }).then(response => {
        if (response && Array.isArray(response.results)) {
          callback(
            response.results.map(user => ({
              value: user.id,
              label: user.name,
            })),
          );
        }
      });
    },
    [fetchUsers],
  );

  const handleLoadOptions = useCallback(
    (searchQuery: string, callback: any) => {
      fetchAgencies({ name: searchQuery }).then(response => {
        if (response && Array.isArray(response.results)) {
          callback(
            response.results.map(agency => ({
              value: agency.id,
              label: agency.name,
            })),
          );
        }
      });
    },
    [fetchAgencies],
  );

  const typeOptions = useMemo(
    () =>
      RESERVATION_TYPE_OPTIONS.map(type => ({
        ...type,
        label: t(type.label),
      })),

    [t],
  );

  const statusOptions = useMemo(
    () =>
      RESERVATION_STATUS_OPTIONS.map(status => ({
        ...status,
        label: (
          <Chip $color={getReservationStatusColor(status.value)}>
            {t(status.label)}
          </Chip>
        ),
      })),
    [t],
  );

  const messageFilterOptions = useMemo(
    () =>
      RESERVATION_MESSAGE_FILTER_OPTIONS.map(option => ({
        ...option,
        label: <span>{t(option.label)}</span>,
      })),
    [t],
  );

  const paymentStatusOptions = useMemo(
    () =>
      PAYMENT_STATUS_OPTIONS.map(status => ({
        ...status,
        label: (
          <Chip $color={getPaymentStatusColor(status.value)}>
            {t(status.label)}
          </Chip>
        ),
      })),
    [t],
  );

  const supplierOptions = HOTEL_SUPPLIER_OPTIONS.map(option => ({
    ...option,
    label: <Chip $color={CHIP_COLORS.SUPPLIER}>{option.label}</Chip>,
  }));

  const booleanOptions = useMemo(
    () =>
      BOOLEAN_OPTIONS.map(option => ({
        ...option,
        label: t(option.label),
      })),
    [t],
  );

  const orderByOptions = useMemo(
    () =>
      RESERVATION_ORDER_BY_OPTIONS.map(order => ({
        ...order,
        label: t(order.label),
      })),
    [t],
  );

  const hotelConfirmationStatusOptions = useMemo(
    () =>
      HOTEL_CONFIRMATION_STATUS_OPTIONS.map(status => ({
        ...status,
        label: t(status.label),
      })),
    [t],
  );

  const supplierConfirmationStatusOptions = useMemo(
    () =>
      SUPPLIER_CONFIRMATION_STATUS_OPTIONS.map(status => ({
        ...status,
        label: t(status.label),
      })),
    [t],
  );

  const confirmationTransferStatusOptions = useMemo(
    () =>
      CONFIRMATION_TRANSFER_STATUS_OPTIONS.map(status => ({
        ...status,
        label: t(status.label),
      })),
    [t],
  );

  const formValues = useMemo(() => {
    return entity2Form(query);
  }, [query]);

  const onSubmit = (formValues: FormValues) => {
    const payload = form2Entity(formValues);

    setQuery(payload);
  };

  return (
    <Form defaultValues={formValues}>
      <FormLayout>
        <Flex mx={[0, 0, -1, -1]}>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="id"
              label={t("adminReservations.filters.id.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  placeholder={t("adminReservations.filters.id.placeholder")}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="dateFrom"
              label={t("adminReservations.filters.dateFrom.label")}
            >
              {props => (
                <FormDatePickerSingle
                  {...props}
                  placeholder={t(
                    "adminReservations.filters.dateFrom.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="dateTo"
              label={t("adminReservations.filters.dateTo.label")}
            >
              {props => (
                <FormDatePickerSingle
                  {...props}
                  placeholder={t(
                    "adminReservations.filters.dateTo.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="status"
              label={t("adminReservations.filters.status.label")}
            >
              {props => (
                <FormSelect
                  {...props}
                  placeholder={t(
                    "adminReservations.filters.status.placeholder",
                  )}
                  options={statusOptions}
                  isClearable
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="deadlineFrom"
              label={t("adminReservations.filters.deadlineFrom.label")}
            >
              {props => (
                <FormDatePickerSingle
                  {...props}
                  placeholder={t(
                    "adminReservations.filters.deadlineFrom.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="deadlineTo"
              label={t("adminReservations.filters.deadlineTo.label")}
            >
              {props => (
                <FormDatePickerSingle
                  {...props}
                  placeholder={t(
                    "adminReservations.filters.deadlineTo.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="agencyId"
              label={t("adminReservations.filters.agencyId.label")}
            >
              {props => (
                <FormAsyncSelect
                  {...props}
                  placeholder={t(
                    "adminReservations.filters.agencyId.placeholder",
                  )}
                  defaultOptions={defaultAgencyOptions}
                  loadOptions={handleLoadOptions}
                  isLoading={isLoadingAgency || isFetchingAgencies}
                  isClearable
                  noOptionsMessage={() =>
                    t("adminReservations.filters.agencyId.noOptions")
                  }
                  loadingMessage={() =>
                    t("adminReservations.filters.agencyId.loading")
                  }
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="userId"
              label={t("adminReservations.filters.userId.label")}
            >
              {props => (
                <FormAsyncSelect
                  {...props}
                  placeholder={t(
                    "adminReservations.filters.userId.placeholder",
                  )}
                  defaultOptions={defaultUserOptions}
                  loadOptions={handleLoadUserOptions}
                  isLoading={isLoadingUser || isFetchingUsers}
                  isClearable
                  noOptionsMessage={() =>
                    t("adminReservations.filters.userId.noOptions")
                  }
                  loadingMessage={() =>
                    t("adminReservations.filters.userId.loading")
                  }
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="supplierId"
              label={t("adminReservations.filters.supplierId.label")}
            >
              {props => (
                <FormSelect
                  {...props}
                  placeholder={t(
                    "adminReservations.filters.supplierId.placeholder",
                  )}
                  options={supplierOptions}
                  isClearable
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="cityName"
              label={t("adminReservations.filters.cityName.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  placeholder={t(
                    "adminReservations.filters.cityName.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="checkInFrom"
              label={t("adminReservations.filters.checkInFrom.label")}
            >
              {props => (
                <FormDatePickerSingle
                  {...props}
                  placeholder={t(
                    "adminReservations.filters.checkInFrom.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="checkInTo"
              label={t("adminReservations.filters.checkInTo.label")}
            >
              {props => (
                <FormDatePickerSingle
                  {...props}
                  placeholder={t(
                    "adminReservations.filters.checkInTo.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="name"
              label={t("adminReservations.filters.name.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  placeholder={t("adminReservations.filters.name.placeholder")}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="checkOutFrom"
              label={t("adminReservations.filters.checkOutFrom.label")}
            >
              {props => (
                <FormDatePickerSingle
                  {...props}
                  placeholder={t(
                    "adminReservations.filters.checkOutFrom.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="checkOutTo"
              label={t("adminReservations.filters.checkOutTo.label")}
            >
              {props => (
                <FormDatePickerSingle
                  {...props}
                  placeholder={t(
                    "adminReservations.filters.checkOutTo.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="priceFrom"
              label={t("adminReservations.filters.priceFrom.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  placeholder={t(
                    "adminReservations.filters.priceFrom.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="priceTo"
              label={t("adminReservations.filters.priceTo.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  placeholder={t(
                    "adminReservations.filters.priceTo.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="paymentStatus"
              label={t("adminReservations.filters.paymentStatus.label")}
            >
              {props => (
                <FormSelect
                  {...props}
                  placeholder={t(
                    "adminReservations.filters.paymentStatus.placeholder",
                  )}
                  options={paymentStatusOptions}
                  isClearable
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="messageFilter"
              label={t("adminReservations.filters.messageFilter.label")}
            >
              {props => (
                <FormSelect
                  {...props}
                  placeholder={t(
                    "adminReservations.filters.messageFilter.placeholder",
                  )}
                  options={messageFilterOptions}
                  isClearable
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="type"
              label={t("adminReservations.filters.type.label")}
            >
              {props => (
                <FormSelect
                  {...props}
                  placeholder={t("adminReservations.filters.type.placeholder")}
                  options={typeOptions}
                  isClearable
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="confirmed"
              label={t("adminReservations.filters.confirmed.label")}
            >
              {props => (
                <FormSelect
                  {...props}
                  placeholder={t(
                    "adminReservations.filters.confirmed.placeholder",
                  )}
                  options={booleanOptions}
                  isClearable
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="orderBy"
              label={t("adminReservations.filters.orderBy.label")}
            >
              {props => (
                <FormSelect
                  {...props}
                  placeholder={t(
                    "adminReservations.filters.orderBy.placeholder",
                  )}
                  options={orderByOptions}
                  isClearable
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="assignee"
              label={t("adminReservations.filters.assignee.label")}
            >
              {props => (
                <FormSelect
                  {...props}
                  placeholder={t(
                    "adminReservations.filters.assignee.placeholder",
                  )}
                  options={ASSIGNEE_OPTIONS}
                  isClearable
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="hotelConfirmationStatus"
              label={t(
                "adminReservations.filters.hotelConfirmationStatus.label",
              )}
            >
              {props => (
                <FormSelect
                  {...props}
                  placeholder={t(
                    "adminReservations.filters.hotelConfirmationStatus.placeholder",
                  )}
                  options={hotelConfirmationStatusOptions}
                  isClearable
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="supplierConfirmationStatus"
              label={t(
                "adminReservations.filters.supplierConfirmationStatus.label",
              )}
            >
              {props => (
                <FormSelect
                  {...props}
                  placeholder={t(
                    "adminReservations.filters.supplierConfirmationStatus.placeholder",
                  )}
                  options={supplierConfirmationStatusOptions}
                  isClearable
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="confirmationOutboundTransferStatus"
              label={t(
                "adminReservations.filters.confirmationOutboundTransferStatus.label",
              )}
            >
              {props => (
                <FormSelect
                  {...props}
                  placeholder={t(
                    "adminReservations.filters.confirmationOutboundTransferStatus.placeholder",
                  )}
                  options={confirmationTransferStatusOptions}
                  isClearable
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="confirmationReturnTransferStatus"
              label={t(
                "adminReservations.filters.confirmationReturnTransferStatus.label",
              )}
            >
              {props => (
                <FormSelect
                  {...props}
                  placeholder={t(
                    "adminReservations.filters.confirmationReturnTransferStatus.placeholder",
                  )}
                  options={confirmationTransferStatusOptions}
                  isClearable
                />
              )}
            </FormAdapter>
          </Box>
        </Flex>
        <FormContext<FormValues>
          render={({ handleSubmit }) => (
            <Button
              type="submit"
              variant="secondary"
              isLoading={isLoading}
              onClick={handleSubmit(onSubmit)}
            >
              {t("adminHotelSearches.filters.submit")}
            </Button>
          )}
        />
      </FormLayout>
    </Form>
  );
};

export default AdminReservationsFilters;
