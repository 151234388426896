import { IconStarFilled } from "@tabler/icons-react";
import { useTheme } from "styled-components";

import * as S from "./Rating.styled";

const MAX_COUNT_STARS = 5;
const DEFAULT_SIZE = 13;

interface RatingProps {
  value: number;
  size?: number;
}

const Rating = ({ value, size = DEFAULT_SIZE }: RatingProps) => {
  const theme = useTheme();
  const internalValue = value > MAX_COUNT_STARS ? MAX_COUNT_STARS : value;

  return (
    <S.Wrapper>
      {[...Array(MAX_COUNT_STARS)].map((_, index) => {
        const starValue = index + 1;

        return (
          <IconStarFilled
            key={index}
            size={size}
            style={{
              color:
                starValue <= internalValue
                  ? theme.palette.buttercup
                  : theme.palette.geyser,
            }}
          />
        );
      })}
    </S.Wrapper>
  );
};

export default Rating;
