import { InvoiceStatus, InvoiceType } from "@hotelspoint/api";
import { StatusFlavour } from "@hotelspoint/components";

export const getInvoiceTypeName = (type: InvoiceType) => {
  switch (type) {
    case InvoiceType.Proform:
      return "invoiceType.proform";

    case InvoiceType.Invoice:
      return "invoiceType.invoice";

    case InvoiceType.CreditNote:
      return "invoiceType.creditNote";

    case InvoiceType.DebitNote:
      return "invoiceType.debitNote";

    default:
      return "Unknown type";
  }
};

export const getInvoiceStatusName = (status: InvoiceStatus) => {
  switch (status) {
    case InvoiceStatus.Pending:
      return "invoiceStatus.pending";

    case InvoiceStatus.Completed:
      return "invoiceStatus.completed";

    case InvoiceStatus.Cancelled:
      return "invoiceStatus.cancelled";

    default:
      return "Unknown status";
  }
};

export const getInvoiceStatusColor = (status: InvoiceStatus) => {
  switch (status) {
    case InvoiceStatus.Pending:
      return StatusFlavour.Warning;

    case InvoiceStatus.Completed:
      return StatusFlavour.Success;

    case InvoiceStatus.Cancelled:
      return StatusFlavour.Error;

    default:
      return undefined;
  }
};
