import * as S from "./Avatar.styled";

interface AvatarProps {
  src?: string;
  alt: string;
}

const Avatar = ({ src, alt }: AvatarProps) => {
  return <S.Wrapper>{src && <img src={src} alt={alt} />}</S.Wrapper>;
};

export default Avatar;
