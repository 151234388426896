import { AdminSupplierStatementInList } from "@hotelspoint/api";
import {
  ButtonDelete,
  ButtonEdit,
  Chip,
  ConfirmDialog,
  TABLE_COLUMN_SIZE,
  TableCell,
} from "@hotelspoint/components";
import { CHIP_COLORS } from "@hotelspoint/constants";
import {
  formatDate,
  getStatementSupplierName,
  getSupplierStatementStatusColor,
  getSupplierStatementStatusName,
  withCurrency,
} from "@hotelspoint/utils";
import { createColumnHelper } from "@tanstack/react-table";
import { TFunction } from "i18next";
import { Link } from "react-router-dom";

const columnHelper = createColumnHelper<AdminSupplierStatementInList>();

interface GetColumnsParams {
  t: TFunction;
  targetId: number | null;
  handleDelete: (id: number) => void;
  isDeleting: boolean;
}

const getColumns = ({
  t,
  targetId,
  handleDelete,
  isDeleting,
}: GetColumnsParams) => [
  columnHelper.accessor("id", {
    header: t("adminSupplierStatements.columns.id"),
    size: TABLE_COLUMN_SIZE.ID,
  }),
  columnHelper.accessor("supplierId", {
    header: t("adminSupplierStatements.columns.supplierId"),
    size: TABLE_COLUMN_SIZE.SUPPLIER,
    cell: props => (
      <Chip $color={CHIP_COLORS.SUPPLIER}>
        {getStatementSupplierName(props.getValue())}
      </Chip>
    ),
  }),
  columnHelper.accessor("status", {
    header: t("adminSupplierStatements.columns.status"),
    size: TABLE_COLUMN_SIZE.STATUS,
    cell: props => {
      const status = props.getValue();

      return (
        <Chip $color={getSupplierStatementStatusColor(status)}>
          {t(getSupplierStatementStatusName(status))}
        </Chip>
      );
    },
  }),
  columnHelper.accessor("totalAmount", {
    header: t("adminSupplierStatements.columns.totalAmount"),
    size: TABLE_COLUMN_SIZE.AMOUNT,
    cell: props => withCurrency(props.getValue()),
  }),
  columnHelper.accessor("remainingAmount", {
    header: t("adminSupplierStatements.columns.remainingAmount"),
    size: TABLE_COLUMN_SIZE.AMOUNT,
    cell: props => withCurrency(props.getValue()),
  }),
  columnHelper.accessor("dateCreated", {
    header: t("adminSupplierStatements.columns.dateCreated"),
    size: TABLE_COLUMN_SIZE.DATE,
    cell: props => formatDate(props.getValue()),
  }),
  columnHelper.display({
    id: "actions",
    size: TABLE_COLUMN_SIZE.ACTION,
    cell: props => {
      const id = props.row.original.id;
      const isTargeted = id === targetId;

      return (
        <TableCell>
          <Link to={`/admin/statements/suppliers/${id}`}>
            <ButtonEdit />
          </Link>
          <ConfirmDialog
            title={t(
              "adminSupplierStatements.columns.actions.delete.modal.title",
            )}
            description={t(
              "adminSupplierStatements.columns.actions.delete.modal.description",
            )}
            onConfirm={() => handleDelete(id)}
            isLoading={isTargeted && isDeleting}
          >
            <ButtonDelete />
          </ConfirmDialog>
        </TableCell>
      );
    },
  }),
];

export default getColumns;
