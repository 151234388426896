import { useMutation, useQueryClient } from "@tanstack/react-query";

import { OfferService, OfferUpdatePayload } from "../../services";

const useUpdateOffer = (id: number) => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: ["offers", "update"],
    mutationFn: (payload: OfferUpdatePayload) => {
      return OfferService.update(id, payload);
    },
    onMutate: () => {
      queryClient.cancelQueries({
        queryKey: ["offers", id],
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["offers", id],
      });

      queryClient.invalidateQueries({
        queryKey: ["offers"],
      });
    },
  });

  const { mutateAsync, isPending } = mutation;
  const ret: [typeof mutateAsync, typeof isPending] = [mutateAsync, isPending];

  return ret;
};

export default useUpdateOffer;
