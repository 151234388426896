import { Rating } from "@hotelspoint/components";
import Skeleton from "react-loading-skeleton";

import * as S from "./HotelBaseInfo.styled";

interface HotelBaseInfoProps {
  isLoading?: boolean;
  name?: string;
  rating?: number;
  address?: string;
  cityName?: string;
}

const HotelBaseInfo = ({
  isLoading,
  name,
  rating,
  address,
  cityName,
}: HotelBaseInfoProps) => {
  return (
    <S.Header>
      <S.HeaderRow>
        <S.FlexLength $loading={isLoading}>
          <h3>{isLoading ? <Skeleton /> : name}</h3>
        </S.FlexLength>
        <S.FlexLength $loading={isLoading}>
          {rating && <Rating value={rating} />}
        </S.FlexLength>
      </S.HeaderRow>
      <p>
        {isLoading ? (
          <Skeleton />
        ) : (
          [address, cityName].filter(Boolean).join(", ")
        )}
      </p>
    </S.Header>
  );
};

export default HotelBaseInfo;
