import { PlaceType } from "@hotelspoint/types";

export const PLACE_TYPE_ORDER: PlaceType[] = [
  PlaceType.Area,
  PlaceType.Destination,
  PlaceType.City,
];

export const PERSON_SELECTOR_CONFIG = {
  maxAdults: 20,
  maxChildren: 20,
  maxChildrenAge: 17,
};
