import { Panel } from "@hotelspoint/components";
import { useDocumentTitle } from "@hotelspoint/utils";
import { useTranslation } from "react-i18next";
import { Navigate, useParams } from "react-router-dom";

import AdminAirportLocationForm from "./AdminAirportLocationForm";

interface AdminAirportLocationParams extends Record<string, string> {
  id: string;
}

const AdminAirportLocation = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("adminAirportLocation.pageTitle"));

  const { id } = useParams<AdminAirportLocationParams>();
  const airportId = Number(id);

  if (!airportId) {
    return <Navigate to="/404" replace={true} />;
  }

  return (
    <Panel title={t("adminAirportLocation.pageTitle")}>
      <AdminAirportLocationForm id={airportId} />
    </Panel>
  );
};

export default AdminAirportLocation;
