import { IconApi } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

import Button from "../Button/Button";

const ButtonApiHeaders = () => {
  const { t } = useTranslation();

  return (
    <Button small variant="tertiary">
      <IconApi size={24} />
      <span>{t("components.buttonApiHeaders.title")}</span>
    </Button>
  );
};

export default ButtonApiHeaders;
