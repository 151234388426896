import { useQuery } from "@tanstack/react-query";

import { AdminService } from "../../../services";

const useAdminCommission = (id: number) => {
  const { data, isLoading } = useQuery({
    queryKey: ["admin", "commissions", id],
    queryFn: () => AdminService.getCommission(id),
    enabled: !!id,
  });

  const ret: [typeof data, boolean] = [data, isLoading];

  return ret;
};

export default useAdminCommission;
