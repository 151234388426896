import { NumberParam, StringParam, useQueryParams } from "use-query-params";

const useAdminInvoicesParams = () => {
  const [query, setQuery] = useQueryParams({
    id: NumberParam,
    number: StringParam,
    reservationId: NumberParam,
    paymentId: NumberParam,
    agencyId: NumberParam,
    status: NumberParam,
    dateFrom: StringParam,
    dateTo: StringParam,
    amountFrom: StringParam,
    amountTo: StringParam,
  });

  const ret: [typeof query, typeof setQuery] = [query, setQuery];

  return ret;
};

export default useAdminInvoicesParams;
