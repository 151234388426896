import { Panel } from "@hotelspoint/components";
import { useDocumentTitle } from "@hotelspoint/utils";
import { useTranslation } from "react-i18next";
import { Navigate, useParams } from "react-router-dom";

import AdminCommissionForm from "./AdminCommissionForm";
import AdminCommissionItems from "./AdminCommissionItems";

interface AdminCommissionParams extends Record<string, string> {
  id: string;
}

const AdminCommission = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("adminCommission.pageTitle"));

  const { id } = useParams<AdminCommissionParams>();
  const commissionId = Number(id);

  if (!commissionId) {
    return <Navigate to="/404" replace={true} />;
  }

  return (
    <Panel title={t("adminCommission.pageTitle")}>
      <AdminCommissionForm id={commissionId} />
      <AdminCommissionItems id={commissionId} />
    </Panel>
  );
};

export default AdminCommission;
