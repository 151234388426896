import { OfferCurrent } from "@hotelspoint/types";
import { create } from "zustand";

interface UserCurrentOfferStore {
  offer: OfferCurrent | null;
  set: (offer: OfferCurrent) => void;
  reset: () => void;
}

const useUserCurrentOfferStore = create<UserCurrentOfferStore>(set => ({
  offer: null,
  set: offer => set({ offer }),
  reset: () => set({ offer: null }),
}));

export default useUserCurrentOfferStore;
