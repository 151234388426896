import countryCodes from "./countryCodes";

const featuredCountryCodes = ["bg", "ro", "mk", "rs"];
const nonFeaturedCountryCodes = countryCodes.filter(
  code => !featuredCountryCodes.includes(code),
);

const countryGroups = [
  { label: "common", options: featuredCountryCodes },
  { label: "other", options: nonFeaturedCountryCodes },
];

export default countryGroups;
