import {
  AdminSupplierStatement,
  AdminSupplierStatementCreatePaymentPayload,
  PaymentStatus,
} from "@hotelspoint/api";
import { formatDate } from "@hotelspoint/utils";
import { InferType, mixed, number, object, string } from "yup";

export const getValidationSchema = ({ maxAmount }: { maxAmount: number }) => {
  return object({
    date: string().required(
      "adminSupplierStatement.payments.form.date.validation.required",
    ),
    amount: number()
      .required(
        "adminSupplierStatement.payments.form.amount.validation.required",
      )
      .typeError(
        "adminSupplierStatement.payments.form.amount.validation.required",
      )
      .max(
        maxAmount,
        "adminSupplierStatement.payments.form.amount.validation.max",
      ),
    status: mixed<PaymentStatus>()
      .oneOf(Object.values(PaymentStatus).map(e => e as PaymentStatus))
      .required(
        "adminSupplierStatement.payments.form.status.validation.required",
      ),
    provider: number().required(
      "adminSupplierStatement.payments.form.provider.validation.required",
    ),
  }).required();
};

export type FormValues = InferType<ReturnType<typeof getValidationSchema>>;

export const defaultValues: Partial<FormValues> = {
  date: undefined,
  amount: undefined,
  status: undefined,
  provider: undefined,
};

export const entity2Form = (entity: AdminSupplierStatement) => ({
  date: formatDate(new Date()),
  amount: entity.remainingAmount,
  status: PaymentStatus.Confirmed,
  provider: undefined,
});

export const form2Entity = (
  formValues: FormValues,
): AdminSupplierStatementCreatePaymentPayload => ({
  date: formValues.date,
  amount: formValues.amount,
  status: formValues.status,
  provider: formValues.provider,
});
