import {
  AdminAgencyStatementCreatePayload,
  AdminAgencyStatementStatus,
} from "@hotelspoint/api";
import { array, InferType, number, object } from "yup";

export const validationSchema = object({
  agencyId: number().required(
    "adminAgencyStatementsCreate.form.agencyId.validation.required",
  ),
  paymentIds: array()
    .of(number().required())
    .required()
    .min(1, "adminAgencyStatementsCreate.form.paymentIds.validation.required"),
  // Read-only
  amount: number(),
  status: number(),
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: Partial<FormValues> = {
  agencyId: undefined,
  paymentIds: undefined,
  // Read-only
  amount: 0,
  status: AdminAgencyStatementStatus.Pending,
};

export const form2Entity = (
  formValues: FormValues,
): AdminAgencyStatementCreatePayload => ({
  agencyId: formValues.agencyId,
  paymentIds: formValues.paymentIds,
});
