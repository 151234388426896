import {
  handleError,
  useAdminAreaLocations,
  useDeleteAdminAreaLocation,
} from "@hotelspoint/api";
import {
  Button,
  Pagination,
  Panel,
  Table,
  TABLE_PAGE_SIZE_DEFAULT,
  TableFooter,
  TableLayout,
  TablePageSize,
  TableResultsCount,
} from "@hotelspoint/components";
import { pickFilterValues, useDocumentTitle } from "@hotelspoint/utils";
import omit from "lodash/omit";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import getColumns from "./AdminAreaLocations.columns";
import AdminAreaLocationsFilters from "./AdminAreaLocationsFilters";
import useAdminAreaLocationsParams from "./useAdminAreaLocationsParams";

const AdminAreaLocations = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("adminAreaLocations.pageTitle"));

  const [targetId, setTargetId] = useState<number | null>(null);
  const [pageSize, setPageSize] = useState(TABLE_PAGE_SIZE_DEFAULT);
  const [pageIndex, setPageIndex] = useState(1);

  const [query] = useAdminAreaLocationsParams();

  const filters = useMemo(
    () => omit(pickFilterValues(query), "agencyName"),
    [query],
  );

  const [locations, isLoading] = useAdminAreaLocations({
    pageIndex,
    pageSize,
    ...filters,
  });

  const [deleteArea, isDeleting] = useDeleteAdminAreaLocation();

  const handleDelete = useCallback(
    async (id: number) => {
      try {
        setTargetId(id);
        await deleteArea(id);
      } catch (error: any) {
        handleError({ t, error });
      } finally {
        setTargetId(null);
      }
    },
    [t, deleteArea],
  );
  const data = useMemo(() => locations?.results ?? [], [locations]);

  const columns = useMemo(() => {
    return getColumns({
      t,
      targetId,
      handleDelete,
      isDeleting,
    });
  }, [t, targetId, handleDelete, isDeleting]);

  const stringifiedFilters = useMemo(() => JSON.stringify(filters), [filters]);

  useEffect(() => {
    setPageIndex(1);
  }, [pageSize, stringifiedFilters]);

  return (
    <Panel
      title={t("adminAreaLocations.pageTitle")}
      actions={
        <Link to="/admin/locations/areas/create">
          <Button small variant="secondary">
            {t("adminAreaLocations.actions.create")}
          </Button>
        </Link>
      }
    >
      <AdminAreaLocationsFilters isLoading={isLoading} />
      <TableLayout>
        <Table
          data={data}
          columns={columns}
          isLoading={isLoading}
          useReactTableProps={{
            state: {
              pagination: {
                pageIndex,
                pageSize: TABLE_PAGE_SIZE_DEFAULT,
              },
              columnPinning: {
                left: ["id"],
                right: ["actions"],
              },
            },
            manualPagination: true,
          }}
        />
        <TableFooter>
          <div>
            <TableResultsCount value={locations?.count} />
          </div>
          <div>
            <Pagination
              pageIndex={pageIndex}
              pageSize={pageSize}
              setPageIndex={setPageIndex}
              totalCount={locations?.count ?? 0}
            />
          </div>
          <TablePageSize value={pageSize} onChange={setPageSize} />
        </TableFooter>
      </TableLayout>
    </Panel>
  );
};

export default AdminAreaLocations;
