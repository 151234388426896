import { array, InferType, object } from "yup";

const FILE_MAX_SIZE = 20971520; // 20MB

const FILE_ALLOWED_TYPES = ["image/jpeg", "image/png", "application/pdf"];

export const validationSchema = object({
  files: array()
    .min(1, "registerConfirm.files.validation.required")
    .max(5, "registerConfirm.files.validation.limit")
    .test("size", "registerConfirm.files.validation.size", (files?: File[]) => {
      if (!files) return;

      return files.every(file => file.size <= FILE_MAX_SIZE);
    })
    .test(
      "format",
      "registerConfirm.files.validation.format",
      (files?: File[]) => {
        if (!files) return;

        return files.every(file => {
          return FILE_ALLOWED_TYPES.includes(file.type);
        });
      },
    ),
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: FormValues = {
  files: [],
};
