export const contactEmail = "office@hotelspoint.com";

export const contactPhone = "+35970010027";
export const contactPhoneSpaced = "+359 700 10 027";

export const contactAddress = "56 Ruski blvd, Stara Zagora";
export const contactAddressGoogleMaps =
  "https://maps.app.goo.gl/n2rRHX3KKxq37a3u7";

export const contactAddressCoordinates = {
  longitude: 25.6242844,
  latitude: 42.4289524,
};
