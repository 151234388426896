import {
  AdminSupplierStatement,
  AdminSupplierStatementStatus,
  AdminSupplierStatementUpdatePayload,
} from "@hotelspoint/api";
import { InferType, mixed, number, object, string } from "yup";

export const validationSchema = object({
  supplierStatementNumber: string().required(
    "adminSupplierStatement.form.supplierStatementNumber.validation.required",
  ),
  // Read-only
  supplierId: string(),
  status: mixed<AdminSupplierStatementStatus>().oneOf(
    Object.values(AdminSupplierStatementStatus).map(
      e => e as AdminSupplierStatementStatus,
    ),
  ),
  totalAmount: number(),
  remainingAmount: number(),
  payedAmount: number(),
  reservationsAmount: number(),
  additionalCharges: number(),
  comments: string(),
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: FormValues = {
  supplierStatementNumber: "",
  // Read-only
  supplierId: "",
  status: undefined,
  totalAmount: undefined,
  remainingAmount: undefined,
  payedAmount: undefined,
  reservationsAmount: undefined,
  additionalCharges: undefined,
  comments: "",
};

export const entity2Form = (entity: AdminSupplierStatement): FormValues => ({
  supplierStatementNumber: entity.statementNumber,
  supplierId: entity.supplierId,
  status: entity.status,
  totalAmount: entity.totalAmount,
  remainingAmount: entity.remainingAmount,
  payedAmount: entity.payedAmount,
  reservationsAmount: entity.reservationsAmount,
  additionalCharges: entity.additionalCharges,
  comments: entity.comments,
});

export const form2Entity = (
  formValues: FormValues,
): AdminSupplierStatementUpdatePayload => ({
  supplierStatementNumber: formValues.supplierStatementNumber,
});
