import { pxToRem, spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${spacing(1)}px;
`;

export const Label = styled.label`
  font-size: ${pxToRem(15)};
  line-height: ${pxToRem(15)};
`;
