import { pxToRem, spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";

import Paper from "../../elements/Paper";

export const Wrapper = styled(Paper)`
  display: flex;
  flex-direction: column;
`;

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: ${spacing(2)}px;
  padding: ${spacing(1)}px ${spacing(2)}px;
  &:nth-child(odd) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }
`;

export const Label = styled.span`
  font-size: ${pxToRem(14)};
  line-height: 1;
`;

export const ItemPrice = styled.span`
  font-size: ${pxToRem(16)};
  line-height: 1;
  font-weight: 600;
`;
