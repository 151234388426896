import { Dialog, Modal } from "@hotelspoint/components";
import { useTranslation } from "react-i18next";

import { HotelRoomsTable } from "../../components";

interface HotelRoomsModalProps {
  isOpen: boolean;
  searchId: number;
  hotelId: number;
  onClose: () => void;
}

const HotelRoomsModal = ({
  isOpen,
  searchId,
  hotelId,
  onClose,
}: HotelRoomsModalProps) => {
  const { t } = useTranslation();

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <Dialog.Content>
        <Modal
          title={t("searchHotelResults.hotelRoomsModal.title")}
          onClose={onClose}
        >
          <HotelRoomsTable searchId={searchId} hotelId={hotelId} />
        </Modal>
      </Dialog.Content>
    </Dialog>
  );
};

export default HotelRoomsModal;
