import { HotelRateResponse } from "@hotelspoint/api";
import {
  AccordionSummary,
  AccordionTriggerBase,
} from "@hotelspoint/components";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

import * as S from "./ImportantInfo.styled";

interface ImportantInfoProps {
  hotelRate?: HotelRateResponse;
  isLoading: boolean;
}

const MAX_REMARKS = 6;

const ImportantInfo = ({ hotelRate, isLoading }: ImportantInfoProps) => {
  const { t } = useTranslation();
  return (
    <>
      <S.SectionTitle>
        {t("searchHotelsBook.content.importantInfo.title")}
      </S.SectionTitle>
      <AccordionSummary
        head={({ isOpen }) => {
          if (
            isLoading ||
            !hotelRate ||
            hotelRate.remarks.length <= MAX_REMARKS
          ) {
            return null;
          }

          return (
            <AccordionTriggerBase
              isOpen={isOpen}
              showText={t("searchHotelsBook.content.importantInfo.showMore")}
              hideText={t("searchHotelsBook.content.importantInfo.showLess")}
            />
          );
        }}
        summary={
          isLoading ? (
            <Skeleton count={4} />
          ) : (
            <>
              {hotelRate?.remarks
                .slice(0, MAX_REMARKS)
                .map((remark, remarkIndex) => (
                  <p key={remarkIndex}>{remark}</p>
                ))}
            </>
          )
        }
      >
        {hotelRate?.remarks
          .slice(MAX_REMARKS)
          .map((remark, remarkIndex) => (
            <p key={remarkIndex + MAX_REMARKS}>{remark}</p>
          ))}
      </AccordionSummary>
    </>
  );
};

export default ImportantInfo;
