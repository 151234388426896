import { IconTableColumn } from "@tabler/icons-react";
import { VisibilityState } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

import { Option } from "../../common";
import Popover from "../../composites/Popover";
import Button from "../../elements/Button";
import FormCheckbox from "../../form/FormCheckbox";
import FormLabel from "../../form/FormLabel";
import * as S from "./TableColumnVisibility.styled";

interface TableColumnVisibilityProps {
  value: VisibilityState;
  onChange: (value: VisibilityState) => void;
  columns: Option[];
}

const TableColumnVisibility = ({
  value = {},
  onChange,
  columns,
}: TableColumnVisibilityProps) => {
  const { t } = useTranslation();

  return (
    <Popover>
      <Popover.Trigger asChild>
        <Button small variant="primary">
          <IconTableColumn size={14} />
          <span>{t("components.tableColumnVisibility.label")}</span>
        </Button>
      </Popover.Trigger>
      <Popover.Content>
        <S.Modal>
          {columns.map(column => (
            <S.Wrapper key={column.value}>
              <FormCheckbox
                id={column.value}
                name={column.value}
                checked={value[column.value]}
                onChange={evt => {
                  onChange({
                    ...value,
                    [column.value]: evt.target.checked,
                  });
                }}
              />
              <FormLabel htmlFor={column.value}>{column.label}</FormLabel>
            </S.Wrapper>
          ))}
        </S.Modal>
      </Popover.Content>
    </Popover>
  );
};

export default TableColumnVisibility;
