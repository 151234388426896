import { pxToRem, spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";
import { theme } from "styled-tools";

import { NAV_LINK_ACTIVE_CLASSNAME } from "../../misc";

export const NavItem = styled.li`
  list-style: none;

  a {
    display: block;
  }

  a,
  button {
    width: 100%;
    padding: ${spacing(1.5)}px ${spacing(2)}px;
    font-size: ${pxToRem(15)};
    font-weight: 500;
    line-height: ${pxToRem(14)};
    color: ${theme("palette.ocean")};

    &:hover {
      color: ${theme("palette.ocean")};
      background-color: ${theme("palette.mercury")};
    }

    &.${NAV_LINK_ACTIVE_CLASSNAME} {
      color: ${theme("palette.ocean")};
      background-color: ${theme("palette.mercury")};
    }
  }
`;

export const NestedNavItem = styled(NavItem)`
  a {
    padding-left: ${spacing(3.5)}px;
  }
`;

export const DropdownItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
