import { usePayments } from "@hotelspoint/api";
import {
  Pagination,
  Panel,
  TABLE_PAGE_SIZE_DEFAULT,
  TableFooter,
  TableLayout,
  TablePageSize,
  TableResultsCount,
} from "@hotelspoint/components";
import { Table } from "@hotelspoint/components";
import { pickFilterValues, useDocumentTitle } from "@hotelspoint/utils";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import getColumns from "./AdminPayments.columns";
import AdminPaymentsFilters from "./AdminPaymentsFilters";
import useAdminPaymentsParams from "./useAdminPaymentsParams";

const AdminPayments = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("adminPayments.pageTitle"));

  const [pageSize, setPageSize] = useState(TABLE_PAGE_SIZE_DEFAULT);
  const [pageIndex, setPageIndex] = useState(1);

  const [query] = useAdminPaymentsParams();
  const filters = useMemo(() => pickFilterValues(query), [query]);

  const [payments, isLoading] = usePayments({
    pageIndex,
    pageSize,
    ...filters,
  });

  const data = useMemo(() => payments?.results ?? [], [payments]);

  const columns = useMemo(() => {
    return getColumns({ t });
  }, [t]);

  return (
    <Panel title={t("adminPayments.pageTitle")}>
      <AdminPaymentsFilters isLoading={isLoading} />
      <TableLayout>
        <Table
          data={data}
          columns={columns}
          isLoading={isLoading}
          useReactTableProps={{
            state: {
              pagination: {
                pageIndex,
                pageSize: TABLE_PAGE_SIZE_DEFAULT,
              },
              columnPinning: {
                left: ["id"],
                right: ["actions"],
              },
            },
            manualPagination: true,
          }}
        />
        <TableFooter>
          <div>
            <TableResultsCount value={payments?.count} />
          </div>
          <div>
            <Pagination
              pageIndex={pageIndex}
              pageSize={pageSize}
              setPageIndex={setPageIndex}
              totalCount={payments?.count ?? 0}
            />
          </div>
          <TablePageSize value={pageSize} onChange={setPageSize} />
        </TableFooter>
      </TableLayout>
    </Panel>
  );
};

export default AdminPayments;
