import { Panel } from "@hotelspoint/components";
import { useDocumentTitle } from "@hotelspoint/utils";
import { useTranslation } from "react-i18next";
import { Navigate, useParams } from "react-router-dom";

import AdminAgencyActions from "./AdminAgencyActions";
import AdminAgencyDocuments from "./AdminAgencyDocuments";
import AdminAgencyForm from "./AdminAgencyForm";

interface AdminAgencyParams extends Record<string, string> {
  id: string;
}

const AdminAgency = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("adminAgency.pageTitle"));

  const { id } = useParams<AdminAgencyParams>();
  const agencyId = Number(id);

  if (!agencyId) {
    return <Navigate to="/404" replace={true} />;
  }

  return (
    <Panel
      title={t("adminAgency.pageTitle")}
      actions={<AdminAgencyActions id={agencyId} />}
    >
      <AdminAgencyForm id={agencyId} />
      <AdminAgencyDocuments id={agencyId} />
    </Panel>
  );
};

export default AdminAgency;
