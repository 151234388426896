import { useMutation } from "@tanstack/react-query";

import { AuthService, type ChangePasswordPayload } from "../../services";

const useChangePassword = () => {
  const mutation = useMutation({
    mutationKey: ["auth", "change_password"],
    mutationFn: (payload: ChangePasswordPayload) => {
      return AuthService.changePassword(payload);
    },
  });

  const { mutateAsync, isPending } = mutation;
  const ret: [typeof mutateAsync, typeof isPending] = [mutateAsync, isPending];

  return ret;
};

export default useChangePassword;
