import { useUserProfileStore } from "@hotelspoint/store";
import { Navigate, Outlet } from "react-router-dom";

const NonAuthenticatedRoute = () => {
  const loggedIn = useUserProfileStore(state => !!state.profile);

  return loggedIn ? <Navigate to="/404" replace={true} /> : <Outlet />;
};

export default NonAuthenticatedRoute;
