import {
  Box,
  Button,
  Flex,
  Form,
  FormAdapter,
  FormClearableInput,
  FormContext,
  FormLayout,
} from "@hotelspoint/components";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import useAdminAreaLocationsParams from "../useAdminAreaLocationsParams";
import {
  entity2Form,
  form2Entity,
  FormValues,
} from "./AdminAreaLocationsFilters.form";

interface AdminAreaLocationsFiltersProps {
  isLoading: boolean;
}

const AdminAreaLocationsFilters = ({
  isLoading,
}: AdminAreaLocationsFiltersProps) => {
  const { t } = useTranslation();

  const [query, setQuery] = useAdminAreaLocationsParams();

  const formValues = useMemo(() => {
    return entity2Form(query);
  }, [query]);

  const onSubmit = (formValues: FormValues) => {
    const payload = form2Entity(formValues);

    setQuery(payload);
  };

  return (
    <Form defaultValues={formValues}>
      <FormLayout>
        <Flex mx={[0, 0, -1, -1]}>
          <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="id"
              label={t("adminAreaLocations.filters.id.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  placeholder={t("adminAreaLocations.filters.id.placeholder")}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="name"
              label={t("adminAreaLocations.filters.name.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  placeholder={t("adminAreaLocations.filters.name.placeholder")}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="cityName"
              label={t("adminAreaLocations.filters.cityName.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  placeholder={t(
                    "adminAreaLocations.filters.cityName.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="destinationName"
              label={t("adminAreaLocations.filters.destinationName.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  placeholder={t(
                    "adminAreaLocations.filters.destinationName.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
        </Flex>
        <FormContext<FormValues>
          render={({ handleSubmit }) => (
            <Button
              type="submit"
              variant="secondary"
              isLoading={isLoading}
              onClick={handleSubmit(onSubmit)}
            >
              {t("adminAreaLocations.filters.submit")}
            </Button>
          )}
        />
      </FormLayout>
    </Form>
  );
};

export default AdminAreaLocationsFilters;
