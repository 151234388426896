import { IconBrandTripadvisor } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";

import * as S from "./TripAdvisorRating.styled";

export interface TripAdvisorRatingProps {
  score: number;
  reviewsCount: number;
}

const MAX_RATING_SCORE = 5;

const TripAdvisorRating = ({ score, reviewsCount }: TripAdvisorRatingProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <S.Wrapper>
      <S.Head>
        <IconBrandTripadvisor color={theme.palette.black} size={20} />
        <S.CircleWrapper>
          {[...Array(MAX_RATING_SCORE)].map((_, index) => {
            const circleValue = index + 1;
            const isFullCircle = circleValue <= score;
            const isHalfCircle = circleValue - 0.5 === score;

            return (
              <S.Circle
                key={index}
                $variant={
                  isFullCircle ? "full" : isHalfCircle ? "half" : undefined
                }
              />
            );
          })}
        </S.CircleWrapper>
      </S.Head>
      <S.Reviews>
        {[
          reviewsCount,
          t("components.tripAdvisorRating.review", { count: reviewsCount }),
        ].join(" ")}
      </S.Reviews>
    </S.Wrapper>
  );
};

export default TripAdvisorRating;
