import {
  AdminDestinationLocationListParams,
  AdminService,
} from "@hotelspoint/api";
import { useQuery } from "@tanstack/react-query";

const useAdminDestinationLocations = (
  params?: AdminDestinationLocationListParams,
) => {
  const { data, isLoading } = useQuery({
    queryKey: ["admin", "locations_destination", params],
    queryFn: () => AdminService.getDestinationLocations(params),
  });

  const ret: [typeof data, boolean] = [data, isLoading];

  return ret;
};

export default useAdminDestinationLocations;
