const timing = {
  veryFast: 100,
  fast: 150,
  semiFast: 180,
  normal: 250,
  medium: 350,
  slow: 500,
};

export default timing;
