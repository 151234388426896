import { IconChevronDown } from "@tabler/icons-react";
import { matchPath, useLocation } from "react-router-dom";

import { NavItemBase } from "../../common";
import Accordion from "../../elements/Accordion";
import NavLink from "../../misc/NavLink";
import * as S from "./SidebarNav.styled";

export interface SidebarNavItem extends NavItemBase {
  children?: NavItemBase[];
}

interface SidebarNavProps {
  items: SidebarNavItem[];
}

const SidebarNav = ({ items }: SidebarNavProps) => {
  const { pathname } = useLocation();

  const navItems = items.map((navItem, index) => {
    // Open the accordion if any of the children routes matches the current path
    const isInitiallyOpen = navItem.children
      ? navItem.children.some(child =>
          matchPath(
            {
              path: child.href,
              end: false,
            },
            pathname,
          ),
        )
      : false;

    return (
      <S.NavItem key={index}>
        {navItem.children ? (
          <Accordion
            head={({ isOpen }) => (
              <S.DropdownItem>
                <span>{navItem.title}</span>
                <IconChevronDown
                  size={14}
                  style={{
                    transform: isOpen ? "rotate(180deg)" : undefined,
                    opacity: 0.5,
                  }}
                />
              </S.DropdownItem>
            )}
            isInitiallyOpen={isInitiallyOpen}
          >
            <ul>
              {navItem.children.map((child, childIndex) => {
                return (
                  <S.NestedNavItem key={childIndex}>
                    <NavLink to={child.href}>{child.title}</NavLink>
                  </S.NestedNavItem>
                );
              })}
            </ul>
          </Accordion>
        ) : (
          <NavLink to={navItem.href}>{navItem.title}</NavLink>
        )}
      </S.NavItem>
    );
  });

  return <ul>{navItems}</ul>;
};

export default SidebarNav;
