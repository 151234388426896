import { IconMapPinFilled } from "@tabler/icons-react";
import { useTheme } from "styled-components";

import minimapBg from "./assets/minimap-bg.png";
import * as S from "./Minimap.styled";

interface MinimapProps {
  children: React.ReactNode;
}

const Minimap = ({ children }: MinimapProps) => {
  const theme = useTheme();

  return (
    <S.Wrapper>
      <S.InnerWrapper $src={minimapBg}>
        <IconMapPinFilled size={48} style={{ color: theme.primary.bg }} />
        <S.Content>{children}</S.Content>
      </S.InnerWrapper>
    </S.Wrapper>
  );
};

export default Minimap;
