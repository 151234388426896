import {
  BooleanParam,
  NumberParam,
  StringParam,
  useQueryParams,
} from "use-query-params";

const useAdminReservationsParams = () => {
  const [query, setQuery] = useQueryParams({
    id: NumberParam,
    dateFrom: StringParam,
    dateTo: StringParam,
    status: NumberParam,
    deadlineFrom: StringParam,
    deadlineTo: StringParam,
    agencyId: NumberParam,
    userId: NumberParam,
    supplierId: NumberParam,
    cityName: StringParam,
    checkInFrom: StringParam,
    checkInTo: StringParam,
    name: StringParam,
    checkOutFrom: StringParam,
    checkOutTo: StringParam,
    priceFrom: NumberParam,
    priceTo: NumberParam,
    paymentStatus: NumberParam,
    messageFilter: NumberParam,
    type: NumberParam,
    confirmed: BooleanParam,
    orderBy: NumberParam,
    assignee: NumberParam,
    hotelConfirmationStatus: NumberParam,
    supplierConfirmationStatus: NumberParam,
    confirmationOutboundTransferStatus: NumberParam,
    confirmationReturnTransferStatus: NumberParam,
    // Misc
    agencyName: StringParam,
    userName: StringParam,
  });

  const ret: [typeof query, typeof setQuery] = [query, setQuery];

  return ret;
};

export default useAdminReservationsParams;
