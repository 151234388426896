import { type FormControlInjectedProps } from "../FormControl";
import * as S from "./FormTextArea.styled";

export interface FormTextAreaProps
  extends Partial<FormControlInjectedProps>,
    Pick<
      React.TextareaHTMLAttributes<HTMLTextAreaElement>,
      "rows" | "cols" | "placeholder" | "disabled" | "readOnly" | "onFocus"
    > {}

const FormTextArea = ({ error, ...inputProps }: FormTextAreaProps) => {
  return <S.Textarea $hasError={!!error} {...inputProps} />;
};

export default FormTextArea;
