import { usePayments } from "@hotelspoint/api";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import Panel from "../../elements/Panel";
import { TABLE_PAGE_SIZE_DEFAULT } from "../../table/constants";
import Table from "../../table/Table";
import TableFooter from "../../table/TableFooter";
import TableLayout from "../../table/TableLayout";
import TableResultsCount from "../../table/TableResultsCount";
import PaymentDialog from "../PaymentDialog";
import getColumns from "./ReservationPaymentsTable.columns";

interface ReservationPaymentsTableProps {
  id: number;
}

const ReservationPaymentsTable = ({ id }: ReservationPaymentsTableProps) => {
  const { t } = useTranslation();

  const [targetId, setTargetId] = useState<number | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [pageSize] = useState(TABLE_PAGE_SIZE_DEFAULT);
  const [pageIndex] = useState(1);

  const [payments, isLoading] = usePayments({
    pageIndex,
    pageSize,
    reservationId: id,
  });

  const data = useMemo(() => payments?.results ?? [], [payments]);

  const handleOpenDialog = (id: number) => {
    setTargetId(id);
    setIsDialogOpen(true);
  };

  const columns = useMemo(() => {
    return getColumns({ t, handleOpenDialog });
  }, [t]);

  useEffect(() => {
    if (!isDialogOpen && targetId) {
      setTargetId(null);
    }
  }, [isDialogOpen, targetId]);

  return (
    <>
      <PaymentDialog
        isOpen={isDialogOpen}
        setIsOpen={setIsDialogOpen}
        id={targetId as number}
      />
      <Panel title={t("components.reservationPaymentsTable.title")}>
        <TableLayout>
          <Table data={data} columns={columns} isLoading={isLoading} />
          <TableFooter>
            <div>
              <TableResultsCount value={payments?.count} />
            </div>
            <div />
          </TableFooter>
        </TableLayout>
      </Panel>
    </>
  );
};

export default ReservationPaymentsTable;
