import { borderRadius, spacing } from "@hotelspoint/theme";
import styled from "styled-components";
import { theme } from "styled-tools";

import Paper from "../Paper";

export const Wrapper = styled(Paper)`
  border-radius: ${borderRadius.small}px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 45px;
  background: ${theme("palette.bahamaBlue")};
  padding: 0 ${spacing(1.5)}px;
  border-radius: ${borderRadius.small}px ${borderRadius.small}px 0 0;
`;

export const Title = styled.div`
  color: ${theme("palette.white")};
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${spacing(1)}px;
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing(4)}px;
  padding: ${spacing(2)}px ${spacing(1.5)}px;
`;
