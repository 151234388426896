import { ReservationFlights } from "@hotelspoint/api";
import { Panel } from "@hotelspoint/components";
import { formatDate } from "@hotelspoint/utils";
import { IconPlaneArrival, IconPlaneDeparture } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

import * as S from "./ReservationFlightsInfo.styled";

interface ReservationFlightsInfoProps {
  flights: ReservationFlights;
}

const ReservationFlightsInfo = ({ flights }: ReservationFlightsInfoProps) => {
  const { t } = useTranslation();

  return (
    <Panel title={t("profileReservation.flightInformation.title")}>
      <S.Wrapper>
        <S.IconItem>
          <IconPlaneArrival size={18} />
          <div>
            <p>{t("profileReservation.flightInformation.arrivalNumber")}</p>
            <h6>{flights.arrival.number}</h6>
          </div>
        </S.IconItem>
        <div>
          <p>{t("profileReservation.flightInformation.arrivalDate")}</p>
          <h6>{formatDate(flights.arrival.date)}</h6>
        </div>
        <S.IconItem>
          <IconPlaneDeparture size={18} />
          <div>
            <p>{t("profileReservation.flightInformation.returnNumber")}</p>
            <h6>{flights.return.number}</h6>
          </div>
        </S.IconItem>
        <div>
          <p>{t("profileReservation.flightInformation.returnDate")}</p>
          <h6>{formatDate(flights.return.date)}</h6>
        </div>
      </S.Wrapper>
    </Panel>
  );
};

export default ReservationFlightsInfo;
