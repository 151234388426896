import { type NavLinkProps } from "react-router-dom";

import NavLink from "../../../misc/NavLink";
import * as S from "./NavTab.styled";

const NavTab = ({ to, children }: NavLinkProps) => {
  return (
    <S.Wrapper role="tab">
      <NavLink to={to}>{children}</NavLink>
    </S.Wrapper>
  );
};

export default NavTab;
