import { useQuery } from "@tanstack/react-query";

import { OfferService } from "../../services";

const useOffer = (id: number) => {
  const { data, isLoading } = useQuery({
    queryKey: ["offers", id],
    queryFn: () => OfferService.get(id),
    enabled: !!id,
  });

  const ret: [typeof data, boolean] = [data, isLoading];

  return ret;
};

export default useOffer;
