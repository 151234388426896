import { spacing } from "@hotelspoint/theme";
import styled from "styled-components";

import Paper from "../../elements/Paper";

export const Wrapper = styled(Paper)`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing(2)}px;
  padding: ${spacing(2)}px ${spacing(2)}px ${spacing(1)}px;
  width: 350px;
  max-height: 520px;
  overflow-y: auto;
`;

export const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${spacing(2)}px;
`;
