import { IconApi } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

import Button from "../Button/Button";

const ButtonApiResponse = () => {
  const { t } = useTranslation();

  return (
    <Button small variant="secondary">
      <IconApi size={24} />
      <span>{t("components.buttonApiResponse.title")}</span>
    </Button>
  );
};

export default ButtonApiResponse;
