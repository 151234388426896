import { borderRadius, spacing } from "@hotelspoint/theme";
import styled from "styled-components";

// Only working for 2x "outlined" variant buttons
export default styled.div`
  display: flex;
  flex-direction: row;

  button {
    border-radius: 0;
    padding: 0 ${spacing(1)}px;

    &:first-child {
      border-radius: ${borderRadius.medium}px 0 0 ${borderRadius.medium}px;
    }

    &:last-child {
      border-radius: 0 ${borderRadius.medium}px ${borderRadius.medium}px 0;
      border-left: none;
    }
  }
`;
