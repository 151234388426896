import {
  AdminService,
  AdminSupplierStatementListParams,
} from "@hotelspoint/api";
import { useQuery } from "@tanstack/react-query";

const useAdminSupplierStatements = (
  params?: AdminSupplierStatementListParams,
) => {
  const { data, isLoading } = useQuery({
    queryKey: ["admin", "statements_supplier", params],
    queryFn: () => AdminService.getSupplierStatements(params),
  });

  const ret: [typeof data, boolean] = [data, isLoading];

  return ret;
};

export default useAdminSupplierStatements;
