import { useQuery } from "@tanstack/react-query";

import { MessageListParams, MessageService } from "../../services";

const useMessages = (params?: MessageListParams) => {
  const { data, isLoading } = useQuery({
    queryKey: ["messages", params],
    queryFn: () => MessageService.getList(params),
  });

  const ret: [typeof data, boolean] = [data, isLoading];

  return ret;
};

export default useMessages;
