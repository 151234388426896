import { useReservations } from "@hotelspoint/api";
import {
  Button,
  DateRangeDialog,
  Pagination,
  Panel,
  Table,
  TABLE_PAGE_SIZE_DEFAULT,
  TableFooter,
  TableLayout,
  TablePageSize,
  TableResultsCount,
} from "@hotelspoint/components";
import { useUserTokenStore } from "@hotelspoint/store";
import {
  getApiUrl,
  openInNewTab,
  pickFilterValues,
  useDocumentTitle,
} from "@hotelspoint/utils";
import { IconFileExport } from "@tabler/icons-react";
import omit from "lodash/omit";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import getColumns from "./AdminReservations.columns";
import AdminReservationsAssignDialog from "./AdminReservationsAssignDialog";
import AdminReservationsFilters from "./AdminReservationsFilters";
import useAdminReservationsParams from "./useAdminReservationsParams";

const AdminReservations = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("adminReservations.pageTitle"));

  const navigate = useNavigate();

  const token = useUserTokenStore(state => state.token);

  const [isReportDialogOpen, setIsReportDialogOpen] = useState(false);
  const [isAssignDialogOpen, setIsAssignDialogOpen] = useState(false);
  const [targetId, setTargetId] = useState<number | null>(null);

  const [pageSize, setPageSize] = useState(TABLE_PAGE_SIZE_DEFAULT);
  const [pageIndex, setPageIndex] = useState(1);

  const [query] = useAdminReservationsParams();
  const filters = useMemo(
    () => omit(pickFilterValues(query), ["agencyName", "userName"]),
    [query],
  );

  const [reservations, isLoading] = useReservations({
    pageIndex,
    pageSize,
    ...filters,
  });

  const data = useMemo(() => reservations?.results ?? [], [reservations]);

  const handleAssignTo = (id: number) => {
    setTargetId(id);
    setIsAssignDialogOpen(true);
  };

  const columns = useMemo(() => {
    return getColumns({
      t,
      token: token as string,
      navigate,
      handleAssignTo,
    });
  }, [t, token, navigate]);

  const onConfirm = (dateFrom: string, dateTo: string) => {
    const url = getApiUrl("reservations/report", { token, dateFrom, dateTo });

    openInNewTab(url);
  };

  const stringifiedFilters = useMemo(() => JSON.stringify(filters), [filters]);

  useEffect(() => {
    setPageIndex(1);
  }, [pageSize, stringifiedFilters]);

  return (
    <>
      <DateRangeDialog
        isOpen={isReportDialogOpen}
        setIsOpen={setIsReportDialogOpen}
        title={t("adminReservations.actions.report.modal.title")}
        onConfirm={onConfirm}
      />
      <AdminReservationsAssignDialog
        id={targetId as number}
        isOpen={isAssignDialogOpen}
        setIsOpen={setIsAssignDialogOpen}
      />
      <Panel
        title={t("adminReservations.pageTitle")}
        actions={
          <Button
            variant="secondary"
            small
            onClick={() => setIsReportDialogOpen(true)}
          >
            <IconFileExport size={14} />
            <span>{t("adminReservations.actions.report.button")}</span>
          </Button>
        }
      >
        <AdminReservationsFilters isLoading={isLoading} />
        <TableLayout>
          <Table
            data={data}
            columns={columns}
            isLoading={isLoading}
            useReactTableProps={{
              state: {
                pagination: { pageIndex, pageSize: TABLE_PAGE_SIZE_DEFAULT },
                columnPinning: { left: ["id"], right: ["actions"] },
              },
              manualPagination: true,
            }}
          />
          <TableFooter>
            <div>
              <TableResultsCount value={reservations?.count} />
            </div>
            <div>
              <Pagination
                pageIndex={pageIndex}
                pageSize={pageSize}
                setPageIndex={setPageIndex}
                totalCount={reservations?.count ?? 0}
              />
            </div>
            <TablePageSize value={pageSize} onChange={setPageSize} />
          </TableFooter>
        </TableLayout>
      </Panel>
    </>
  );
};

export default AdminReservations;
