import { Container, PagePaper, PageWrapper } from "@hotelspoint/components";
import { useDocumentTitle } from "@hotelspoint/utils";
import { useTranslation } from "react-i18next";

import RegisterConfirmForm from "./RegisterConfirmForm";
import useRegisterConfirmParams from "./useRegisterConfirmParams";

const RegisterConfirm = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("registerConfirm.pageTitle"));

  const [{ token }] = useRegisterConfirmParams();

  return (
    <PageWrapper>
      <Container>
        <PagePaper title={t("registerConfirm.pageTitle")}>
          <RegisterConfirmForm token={token} />
        </PagePaper>
      </Container>
    </PageWrapper>
  );
};

export default RegisterConfirm;
