import { Panel } from "@hotelspoint/components";
import { useDocumentTitle } from "@hotelspoint/utils";
import { useTranslation } from "react-i18next";
import { Navigate, useParams } from "react-router-dom";

import AdminPaymentActions from "./AdminPaymentActions";
import AdminPaymentForm from "./AdminPaymentForm";

interface AdminPaymentParams extends Record<string, string> {
  id: string;
}

const AdminPayment = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("adminPayment.pageTitle"));

  const { id } = useParams<AdminPaymentParams>();
  const paymentId = Number(id);

  if (!paymentId) {
    return <Navigate to="/404" replace={true} />;
  }

  return (
    <Panel
      title={t("adminPayment.pageTitle")}
      actions={<AdminPaymentActions id={paymentId} />}
    >
      <AdminPaymentForm id={paymentId} />
    </Panel>
  );
};

export default AdminPayment;
