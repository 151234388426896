import { borderRadius, pxToRem, spacing } from "@hotelspoint/theme";
import styled from "styled-components";
import { switchProp, theme } from "styled-tools";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: fit-content;
  min-width: 88px;
`;

export const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: ${spacing(1)}px;
`;

export const CircleWrapper = styled.div`
  display: flex;
  column-gap: ${spacing(0.5)}px;
`;

export const Circle = styled.div<{ $variant?: "full" | "half" }>`
  width: 10px;
  height: 10px;
  border-radius: ${borderRadius.circle};
  border: 1px solid ${theme("palette.eucalyptus")};
  background: ${switchProp(
    "$variant",
    {
      full: theme("palette.eucalyptus"),
      half: `linear-gradient(-90deg, #fff 50%, transparent 50%), linear-gradient(90deg, #1E9A51 50%, transparent 50%)`,
    },
    "white",
  )};
`;

export const Reviews = styled.span`
  align-self: flex-end;
  font-size: ${pxToRem(12)};
  line-height: ${pxToRem(12)};
  color: ${theme("palette.black")};
`;
