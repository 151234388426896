import { HotelRoomGuests } from "@hotelspoint/types";
import { IconChevronDown, IconUsers } from "@tabler/icons-react";
import range from "lodash/range";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import Popover from "../../composites/Popover";
import Button from "../../elements/Button";
import { Box, Flex } from "../../grid";
import { type FormControlInjectedProps } from "../FormControl";
import FormCounter from "../FormCounter";
import FormInput from "../FormInput";
import FormLabel from "../FormLabel";
import FormSelect from "../FormSelect";
import * as S from "./FormPersonSelector.styled";

// !!! Config limits for new modules:
// Transfers: max adults - 60, max children - 15, max children age - 13
// Activities: max adults - 20, max children - 20, max children age - 17
interface FormPersonSelectorConfig {
  maxAdults: number;
  maxChildren: number;
  maxChildrenAge: number;
}

interface FormPersonSelectorProps
  extends FormControlInjectedProps,
    Pick<React.InputHTMLAttributes<HTMLInputElement>, "placeholder"> {
  value: HotelRoomGuests;
  onChange: (rooms: HotelRoomGuests) => void;
  config: FormPersonSelectorConfig;
}

const FormPersonSelector = ({
  id,
  name,
  value,
  onChange,
  placeholder,
  config,
}: FormPersonSelectorProps) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [localValue, setLocalValue] = useState<HotelRoomGuests>(value);

  const handleAdultsChange = useCallback(
    (adults: number) => {
      setLocalValue({
        ...localValue,
        adults,
      });
    },
    [localValue],
  );

  const handleChildrenChange = useCallback(
    (children: number) => {
      setLocalValue({
        ...localValue,
        children:
          children >= localValue.children.length
            ? [...localValue.children, 0]
            : localValue.children.slice(0, children),
      });
    },
    [localValue],
  );

  const handleChildrenAgeChange = useCallback(
    (childrenIndex: number, childrenAge: number) => {
      setLocalValue({
        ...localValue,
        children: localValue.children.map((currValue, index) => {
          if (index === childrenIndex) {
            return childrenAge;
          }

          return currValue;
        }),
      });
    },
    [localValue],
  );

  const displayValue = useMemo(() => {
    if (!localValue) return undefined;

    const totalAdults = localValue.adults;
    const totalChildren = localValue.children.length;

    const adultsText =
      totalAdults !== 0
        ? t("components.common.adultCount", { count: totalAdults })
        : "";

    const childrenText =
      totalChildren !== 0
        ? t("components.common.childCount", { count: totalChildren })
        : "";

    return [adultsText, childrenText].filter(str => str).join(", ");
  }, [t, localValue]);

  const childAgeOptions = useMemo(() => {
    // Range is not including the end, so +1 is needed
    return range(1, config.maxChildrenAge + 1).map(value => ({
      label: value.toString(),
      value,
    }));
  }, [config.maxChildrenAge]);

  const handleConfirm = () => {
    setIsOpen(false);
    onChange(localValue);
  };

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen} placement="bottom-start">
      <Popover.Trigger onClick={() => setIsOpen(true)}>
        <FormInput
          id={id}
          name={name}
          value={displayValue}
          placeholder={placeholder}
          onChange={() => {}}
          startAdornment={<IconUsers size={18} />}
          endAdornment={
            <IconChevronDown
              size={18}
              style={{
                transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
              }}
            />
          }
          style={{ pointerEvents: "none" }}
        />
      </Popover.Trigger>
      <Popover.Content>
        <S.Wrapper>
          <S.InnerWrapper>
            <FormLabel id="adults" htmlFor="adults">
              {t("components.formPersonSelector.adults")}
            </FormLabel>
            <FormCounter
              id="adults"
              name="adults"
              value={localValue.adults}
              onChange={handleAdultsChange}
              disabled={{
                increment: localValue.adults >= config.maxAdults,
              }}
            />
          </S.InnerWrapper>
          <S.InnerWrapper>
            <FormLabel id="children" htmlFor="children">
              {t("components.formPersonSelector.children")}
            </FormLabel>
            <FormCounter
              id="children"
              name="children"
              value={localValue.children.length}
              onChange={handleChildrenChange}
              disabled={{
                increment: localValue.children.length >= config.maxChildren,
              }}
            />
          </S.InnerWrapper>
          {localValue.children.length !== 0 && (
            <Flex marginX={-1} style={{ alignItems: "baseline" }}>
              <Box width={[1]} paddingX={1} paddingY={1}>
                <FormLabel id="children-age-label" htmlFor="children-age-label">
                  {t("components.formPersonSelector.childAges.label")}
                </FormLabel>
              </Box>
              {localValue.children.map((child, index) => {
                return (
                  <Box
                    key={index}
                    width={[1 / 2]}
                    paddingX={1}
                    paddingTop={index > 1 ? 2 : 0}
                  >
                    <FormSelect
                      id={`children-age-${index}`}
                      name={`children-age-${index}`}
                      value={child}
                      placeholder={t(
                        "components.formPersonSelector.childAges.placeholder",
                      )}
                      options={childAgeOptions}
                      onChange={localValue =>
                        handleChildrenAgeChange(index, localValue)
                      }
                    />
                  </Box>
                );
              })}
            </Flex>
          )}
          <S.ActionsWrapper>
            <Button variant="tertiary" onClick={handleConfirm}>
              {t("components.formPersonSelector.apply")}
            </Button>
          </S.ActionsWrapper>
        </S.Wrapper>
      </Popover.Content>
    </Popover>
  );
};

export default FormPersonSelector;
