import { borderRadius, pxToRem, spacing } from "@hotelspoint/theme";
import styled from "styled-components";

export default styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  min-width: max-content;
  height: 20px;
  border-radius: ${borderRadius.medium}px;
  line-height: ${pxToRem(14)};
  font-size: ${pxToRem(14)};
  padding: 0 ${spacing(0.5)}px;
  border-style: solid;
  border-width: 1px;
`;
