import { OfferInList } from "@hotelspoint/api";
import {
  ButtonDelete,
  ButtonView,
  ConfirmDialog,
  StatusFlavour,
  TABLE_COLUMN_SIZE,
  TableCell,
} from "@hotelspoint/components";
import { formatDateTime } from "@hotelspoint/utils";
import { createColumnHelper } from "@tanstack/react-table";
import { TFunction } from "i18next";
import { Link } from "react-router-dom";

const columnHelper = createColumnHelper<OfferInList>();

interface GetColumnsParams {
  t: TFunction;
  targetId: number | null;
  handleDelete: (id: number) => void;
  isDeleting: boolean;
}

const getColumns = ({
  t,
  targetId,
  handleDelete,
  isDeleting,
}: GetColumnsParams) => [
  columnHelper.accessor("id", {
    header: t("profileOffers.columns.id"),
    size: TABLE_COLUMN_SIZE.ID,
  }),
  columnHelper.accessor("agencyName", {
    header: t("profileOffers.columns.agencyName"),
    size: TABLE_COLUMN_SIZE.NAME,
  }),
  columnHelper.accessor("name", {
    header: t("profileOffers.columns.name"),
    size: TABLE_COLUMN_SIZE.NAME,
  }),
  columnHelper.accessor("clientName", {
    header: t("profileOffers.columns.clientName"),
    size: TABLE_COLUMN_SIZE.NAME,
  }),
  columnHelper.accessor("dateCreated", {
    header: t("profileOffers.columns.dateCreated"),
    size: TABLE_COLUMN_SIZE.DATE_TIME,
    cell: props => formatDateTime(props.getValue()),
  }),
  columnHelper.display({
    id: "actions",
    size: TABLE_COLUMN_SIZE.ACTION,
    cell: props => {
      const id = props.row.original.id;
      const isTarget = id === targetId;

      return (
        <TableCell>
          <Link to={`/profile/offers/${id}`}>
            <ButtonView />
          </Link>
          <ConfirmDialog
            title={t("profileOffers.columns.actions.delete.modal.title")}
            description={t(
              "profileOffers.columns.actions.delete.modal.description",
            )}
            onConfirm={() => handleDelete(id)}
            isLoading={isTarget && isDeleting}
            status={StatusFlavour.Error}
          >
            <ButtonDelete />
          </ConfirmDialog>
        </TableCell>
      );
    },
  }),
];

export default getColumns;
