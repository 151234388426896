import { useQuery } from "@tanstack/react-query";

import { InvoiceListParams, InvoiceService } from "../../services";

const useInvoices = (params?: InvoiceListParams) => {
  const { data, isLoading } = useQuery({
    queryKey: ["invoices", params],
    queryFn: () => InvoiceService.getList(params),
  });

  const ret: [typeof data, boolean] = [data, isLoading];

  return ret;
};

export default useInvoices;
