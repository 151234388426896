import {
  ApiResponseSuccessResult,
  ApiResponseSuccessResultList,
  PaginationOptions,
  UserProfile,
  UserRole,
} from "@hotelspoint/types";

import FetchService from "./base/FetchService";

const USER_API_PATH = "users";

export interface User extends UserProfile {
  agencyName: string;
  isActive: boolean;
}

export type UserUpdatePayload = Partial<User>;

export interface UserInList extends User {
  dateCreated: string;
}

export interface UserListParams extends PaginationOptions {
  id?: number;
  name?: string;
  email?: string;
  roleId?: UserRole;
  agencyId?: number;
  registerFrom?: string;
  registerTo?: string;
  isActive?: boolean;
}

export type UserListResponse = ApiResponseSuccessResultList<UserInList>;

const UserService = {
  get: (id: number) => {
    return FetchService.get<User>(`${USER_API_PATH}/${id}`);
  },
  update: (id: number, payload: UserUpdatePayload) => {
    return FetchService.patch<ApiResponseSuccessResult>(
      `${USER_API_PATH}/${id}`,
      payload,
    );
  },
  getList: (params?: Partial<UserListParams>) => {
    return FetchService.get<UserListResponse>(USER_API_PATH, params);
  },
};

export default UserService;
