import { PlaceType } from "@hotelspoint/types";

export const PLACE_TYPES: PlaceType[] = [
  PlaceType.Airport,
  PlaceType.Hotel,
  PlaceType.Address,
];

export const PLACE_TYPE_ORDER: PlaceType[] = [
  PlaceType.Airport,
  PlaceType.Hotel,
  PlaceType.Address,
];

export const PERSON_SELECTOR_CONFIG = {
  maxAdults: 60,
  maxChildren: 15,
  maxChildrenAge: 13,
};
