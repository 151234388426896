import { Paper } from "@hotelspoint/components";
import { borderRadius, pxToRem, spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";
import { theme } from "styled-tools";

export const SidebarCard = styled(Paper)`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing(1.25)}px;
  padding-bottom: ${spacing(1.5)}px;
`;

export const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 45px;
  background-color: ${theme("palette.bahamaBlue")};
  padding: 0 ${spacing(1.625)}px;
  border-radius: ${borderRadius.small}px ${borderRadius.small}px 0 0;
`;

export const Heading = styled.h6`
  color: ${theme("palette.white")};
  font-size: ${pxToRem(16)};
`;
