import {
  AccordionSummary,
  AccordionTriggerBase,
} from "@hotelspoint/components";
import { HotelInformation } from "@hotelspoint/types";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

import * as S from "./HotelSummary.styled";

interface HotelSummaryProps {
  isLoading?: boolean;
  information?: HotelInformation[];
}

const HotelSummary = ({ isLoading, information }: HotelSummaryProps) => {
  const { t } = useTranslation();
  const [summaryInformation] = information ?? [];

  return (
    <AccordionSummary
      head={({ isOpen }) => {
        if (isLoading) return null;

        return (
          <AccordionTriggerBase
            isOpen={isOpen}
            showText={t(
              "searchHotelResults.hotelDetailsModal.description.showMore",
            )}
            hideText={t(
              "searchHotelResults.hotelDetailsModal.description.showLess",
            )}
          />
        );
      }}
      summary={
        <>
          <S.Headline>
            {isLoading ? <Skeleton /> : summaryInformation?.label}
          </S.Headline>
          <S.Paragraph>
            {isLoading ? <Skeleton /> : summaryInformation?.text}
          </S.Paragraph>
        </>
      }
    >
      {information?.slice(1).map(info => (
        <div key={info.label}>
          <S.Headline>{info.label}</S.Headline>
          <S.Paragraph>{info.text}</S.Paragraph>
        </div>
      ))}
    </AccordionSummary>
  );
};

export default HotelSummary;
