import {
  handleError,
  useAdminSupplierStatement,
  useCreateAdminSupplierStatementPayment,
} from "@hotelspoint/api";
import {
  Button,
  Chip,
  Dialog,
  Form,
  FormAdapter,
  FormContext,
  FormDatePickerSingle,
  FormInput,
  FormLayout,
  FormSelect,
  Modal,
} from "@hotelspoint/components";
import {
  PAYMENT_PROVIDER_OPTIONS,
  PAYMENT_STATUS_OPTIONS,
} from "@hotelspoint/constants";
import { UserCurrency } from "@hotelspoint/types";
import { getPaymentStatusColor } from "@hotelspoint/utils";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import {
  defaultValues,
  entity2Form,
  form2Entity,
  FormValues,
  getValidationSchema,
} from "./AdminSupplierStatementAddPaymentDialog.util";

interface AdminSupplierStatementAddPaymentDialogProps {
  id: number;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}

const AdminSupplierStatementAddPaymentDialog = ({
  id,
  isOpen,
  setIsOpen,
}: AdminSupplierStatementAddPaymentDialogProps) => {
  const { t } = useTranslation();

  const [statement, isLoading] = useAdminSupplierStatement(id);

  const [createPayment, isCreating] =
    useCreateAdminSupplierStatementPayment(id);

  const initialValues = useMemo(() => {
    if (!statement) return defaultValues;

    return entity2Form(statement);
  }, [statement]);

  const validationSchema = useMemo(
    () => getValidationSchema({ maxAmount: statement?.remainingAmount ?? 0 }),
    [statement],
  );

  const statusOptions = useMemo(
    () =>
      PAYMENT_STATUS_OPTIONS.map(status => ({
        ...status,
        label: (
          <Chip $color={getPaymentStatusColor(status.value)}>
            {t(status.label)}
          </Chip>
        ),
      })),
    [t],
  );

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        const payload = form2Entity(formValues);

        await createPayment(payload);

        setIsOpen(false);
        toast.success(t("toast.adminSupplierStatement.createPayment"));
      } catch (error: any) {
        handleError({ t, error });
      }
    },
    [t, createPayment, setIsOpen],
  );

  const handleCancel = () => {
    setIsOpen(false);
  };

  if (!statement || isLoading) return null;

  return (
    <Form defaultValues={initialValues} validationSchema={validationSchema}>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <Dialog.Content>
          <Modal
            title={t("adminSupplierStatement.payments.form.title")}
            onClose={() => setIsOpen(false)}
          >
            <FormLayout>
              <FormAdapter
                name="date"
                label={t("adminSupplierStatement.payments.form.date.label")}
              >
                {props => <FormDatePickerSingle {...props} />}
              </FormAdapter>
              <FormAdapter
                name="amount"
                label={t("adminSupplierStatement.payments.form.amount.label")}
              >
                {props => (
                  <FormInput
                    {...props}
                    type="number"
                    endAdornment={UserCurrency.EUR}
                    adornmentStyle="filled"
                  />
                )}
              </FormAdapter>
              <FormAdapter
                name="provider"
                label={t("adminSupplierStatement.payments.form.provider.label")}
              >
                {props => (
                  <FormSelect {...props} options={PAYMENT_PROVIDER_OPTIONS} />
                )}
              </FormAdapter>
              <FormAdapter
                name="status"
                label={t("adminSupplierStatement.payments.form.status.label")}
              >
                {props => <FormSelect {...props} options={statusOptions} />}
              </FormAdapter>
            </FormLayout>
            <Modal.Actions>
              <Button variant="outlined" onClick={handleCancel}>
                {t("adminSupplierStatement.payments.form.cancel")}
              </Button>
              <FormContext<FormValues>
                render={({ handleSubmit }) => (
                  <Button
                    onClick={handleSubmit(onSubmit)}
                    isLoading={isCreating}
                  >
                    {t("adminSupplierStatement.payments.form.confirm")}
                  </Button>
                )}
              />
            </Modal.Actions>
          </Modal>
        </Dialog.Content>
      </Dialog>
    </Form>
  );
};

export default AdminSupplierStatementAddPaymentDialog;
