import styled from "styled-components";
import { theme } from "styled-tools";

export default styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px dashed ${theme("palette.subMarine")};
`;
