import { NumberParam, useQueryParams } from "use-query-params";

const useInvoiceQueryParams = () => {
  const [query, setQuery] = useQueryParams({
    amount: NumberParam,
  });

  const ret: [typeof query, typeof setQuery] = [query, setQuery];

  return ret;
};

export default useInvoiceQueryParams;
