import { DevTool } from "@hookform/devtools";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import {
  type FieldValues,
  FormProvider,
  useForm,
  UseFormProps,
  UseFormReturn,
} from "react-hook-form";
import { type ObjectSchema } from "yup";

type FormChildrenProps<T extends FieldValues, K = any> = (
  props: UseFormReturn<T, K>,
) => React.ReactNode;

export type FormChildrenMethods<T extends FieldValues> = Parameters<
  FormChildrenProps<T>
>[0];

interface FormProps<T extends FieldValues, K = any> {
  children: React.ReactNode | FormChildrenProps<T, K>;
  defaultValues: UseFormProps<T, K>["defaultValues"];
  validationSchema?: ObjectSchema<any>;
  useFormProps?: UseFormProps<T, K>;
  // Enable the form to reinitialize when default values change
  enableReinitialize?: boolean;
  submitOnEnter?: boolean;
  showDevTool?: boolean;
}

const Form = <T extends FieldValues>({
  children,
  defaultValues,
  validationSchema,
  useFormProps,
  enableReinitialize = false,
  submitOnEnter = true,
  showDevTool = false,
}: FormProps<T>) => {
  const methods = useForm({
    defaultValues,
    mode: "onSubmit",
    reValidateMode: "onChange",
    criteriaMode: "all",
    resolver: validationSchema ? yupResolver(validationSchema) : undefined,
    ...useFormProps,
  });

  const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (!submitOnEnter && event.code === "Enter") {
      event.preventDefault();
    }
  };

  // Reinitialize form when default values change
  useEffect(() => {
    if (enableReinitialize) {
      methods.reset(defaultValues);
    }
  }, [methods, defaultValues, enableReinitialize]);

  return (
    <FormProvider {...methods}>
      <form onKeyDown={handleKeyDown}>
        {typeof children === "function" ? children(methods) : children}

        {/* Dev tools */}
        {import.meta.env.DEV && showDevTool && (
          <DevTool
            control={methods.control}
            placement={
              import.meta.env.STORYBOOK ? "bottom-right" : "bottom-left"
            }
          />
        )}
      </form>
    </FormProvider>
  );
};

export default Form;
