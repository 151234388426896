import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const Search = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  // Navigate to the main route
  useEffect(() => {
    if (pathname === "/search") {
      navigate("/search/hotels", { replace: true });
    }
  }, [pathname, navigate]);

  return <Outlet />;
};

export default Search;
