import {
  handleError,
  useReservation,
  useUpdateReservationAssignee,
} from "@hotelspoint/api";
import {
  Button,
  Dialog,
  Form,
  FormAdapter,
  FormContext,
  FormInput,
  FormLayout,
  FormSelect,
  Modal,
} from "@hotelspoint/components";
import { ASSIGNEE_OPTIONS } from "@hotelspoint/constants";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import {
  entity2Form,
  form2Entity,
  FormValues,
  validationSchema,
} from "./AdminReservationsAssignDialog.util";

interface AdminReservationsAssignDialogProps {
  id: number;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}

const AdminReservationsAssignDialog = ({
  id,
  isOpen,
  setIsOpen,
}: AdminReservationsAssignDialogProps) => {
  const { t } = useTranslation();

  const [reservation, isLoading] = useReservation(id);
  const [updateAssignee, isUpdatingAssignee] = useUpdateReservationAssignee(id);

  const formValues = useMemo(() => {
    if (!reservation) return {};

    return entity2Form(reservation);
  }, [reservation]);

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        const payload = form2Entity(formValues);

        await updateAssignee(payload);
        setIsOpen(false);

        toast.success(t("toast.adminReservations.updateAssignee"));
      } catch (error: any) {
        handleError({ t, error });
      }
    },
    [t, updateAssignee, setIsOpen],
  );

  const handleCancel = () => {
    setIsOpen(false);
  };

  if (isLoading) {
    return null;
  }

  return (
    <Form defaultValues={formValues} validationSchema={validationSchema}>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <Dialog.Content>
          <Modal
            title={t("adminReservations.columns.actions.assign.modal.title")}
            onClose={() => setIsOpen(false)}
          >
            <FormLayout>
              <FormAdapter
                name="id"
                label={t(
                  "adminReservations.columns.actions.assign.modal.id.label",
                )}
              >
                {props => <FormInput {...props} readOnly />}
              </FormAdapter>
              <FormAdapter
                name="assigneeId"
                label={t(
                  "adminReservations.columns.actions.assign.modal.assigneeId.label",
                )}
              >
                {props => (
                  <FormSelect
                    {...props}
                    placeholder={t(
                      "adminReservations.columns.actions.assign.modal.assigneeId.placeholder",
                    )}
                    options={ASSIGNEE_OPTIONS}
                    isClearable
                  />
                )}
              </FormAdapter>
            </FormLayout>
            <Modal.Actions>
              <Button variant="outlined" onClick={handleCancel}>
                {t("adminReservations.columns.actions.assign.modal.cancel")}
              </Button>
              <FormContext<FormValues>
                render={({ handleSubmit }) => (
                  <Button
                    onClick={handleSubmit(onSubmit)}
                    isLoading={isUpdatingAssignee}
                  >
                    {t(
                      "adminReservations.columns.actions.assign.modal.confirm",
                    )}
                  </Button>
                )}
              />
            </Modal.Actions>
          </Modal>
        </Dialog.Content>
      </Dialog>
    </Form>
  );
};

export default AdminReservationsAssignDialog;
