import { Container, PageSpacer, PageWrapper } from "@hotelspoint/components";
import { useDocumentTitle } from "@hotelspoint/utils";
import { useTranslation } from "react-i18next";

const SearchTransferBook = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("searchTransferBook.pageTitle"));

  return (
    <PageWrapper>
      <Container>
        <PageSpacer>Transfer book</PageSpacer>
      </Container>
    </PageWrapper>
  );
};

export default SearchTransferBook;
