import { UserLocale } from "@hotelspoint/types";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

export const USER_LOCALE_STORE_PERSIST_NAME = "@hp::user_locale";

interface UserLocaleStore {
  language: UserLocale | null;
  set: (language: UserLocale) => void;
}

const useUserLocaleStore = create<UserLocaleStore>()(
  persist(
    set => ({
      language: UserLocale.English,
      set: language => set({ language }),
    }),
    {
      name: USER_LOCALE_STORE_PERSIST_NAME,
      storage: createJSONStorage(() => localStorage),
      partialize: state => ({
        language: state.language,
      }),
    },
  ),
);

export default useUserLocaleStore;
