import { NumberParam, StringParam, useQueryParams } from "use-query-params";

const useAdminActivityRequestsParams = () => {
  const [query, setQuery] = useQueryParams({
    id: NumberParam,
    userId: NumberParam,
    userName: StringParam,
    dateFrom: StringParam,
    dateTo: StringParam,
    type: NumberParam,
    supplierId: NumberParam,
    recordId: StringParam,
  });

  const ret: [typeof query, typeof setQuery] = [query, setQuery];

  return ret;
};

export default useAdminActivityRequestsParams;
