import { useMutation } from "@tanstack/react-query";

import { AuthService, type UploadDocumentsPayload } from "../../services";

const useUploadDocuments = () => {
  const mutation = useMutation({
    mutationKey: ["auth", "upload_documents"],
    mutationFn: (payload: UploadDocumentsPayload) => {
      return AuthService.uploadDocuments(payload);
    },
  });

  const { mutateAsync, isPending } = mutation;
  const ret: [typeof mutateAsync, typeof isPending] = [mutateAsync, isPending];

  return ret;
};

export default useUploadDocuments;
