import { type RowSelectionState } from "@tanstack/react-table";

export const parseRowSelectionToValue = (state?: RowSelectionState) => {
  if (!state) return [];

  return Object.keys(state).map(Number);
};

export const parseValueToRowSelection = (value?: string[]) => {
  if (!value) return {};

  return Object.fromEntries(value.map((key: string) => [key, true]));
};
