import { borderRadius, spacing } from "@hotelspoint/theme";
import styled from "styled-components";
import { theme } from "styled-tools";

export const Wrapper = styled.div`
  display: flex;
  column-gap: ${spacing(1)}px;
`;

export const Initials = styled.div`
  width: 33px;
  height: 33px;
  border-radius: ${borderRadius.circle};
  background: ${theme("palette.sunrise")};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme("palette.black")};
  text-transform: uppercase;
`;

export const UserEmail = styled.p`
  color: rgba(255, 255, 255, 0.5);
  line-height: 1;
`;
