import { useUserCurrentOfferStore } from "@hotelspoint/store";
import { useMutation } from "@tanstack/react-query";

import { OfferItemShort, OfferService } from "../../services";
import useFetchCurrentOffer from "./useFetchCurrentOffer";

const useAddOfferRate = () => {
  const [fetchCurrentOffer] = useFetchCurrentOffer();
  const setCurrentOffer = useUserCurrentOfferStore(state => state.set);

  const mutation = useMutation({
    mutationKey: ["offers", "add_rate"],
    mutationFn: (payload: OfferItemShort) => {
      return OfferService.addItem(payload);
    },
    onSuccess: async () => {
      const offer = await fetchCurrentOffer();
      setCurrentOffer(offer);
    },
  });

  const { mutateAsync, isPending } = mutation;
  const ret: [typeof mutateAsync, typeof isPending] = [mutateAsync, isPending];

  return ret;
};

export default useAddOfferRate;
