import { mediaQuery } from "@hotelspoint/theme";
import styled from "styled-components";
import { theme } from "styled-tools";

// Component to enforce margin from header/footer elements
export default styled.div`
  margin-top: ${theme("custom.header.heightS")}px;
  margin-bottom: 120px;
  min-height: calc(100vh - 120px - 50px);

  @media ${mediaQuery.tablet} {
    margin-top: ${theme("custom.header.heightL")}px;
    margin-bottom: 160px;
    min-height: calc(100vh - 160px - 65px);
  }
`;
