import { Panel } from "@hotelspoint/components";
import { useDocumentTitle } from "@hotelspoint/utils";
import { useTranslation } from "react-i18next";
import { Navigate, useParams } from "react-router-dom";

interface AdminReservationParams extends Record<string, string> {
  id: string;
}

const AdminReservation = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("adminReservation.pageTitle"));

  const { id } = useParams<AdminReservationParams>();
  const reservationId = Number(id);

  if (!reservationId) {
    return <Navigate to="/404" replace={true} />;
  }

  return (
    <Panel title={t("adminReservation.pageTitle")}>
      <div />
    </Panel>
  );
};

export default AdminReservation;
