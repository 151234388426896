import { useMutation } from "@tanstack/react-query";

import { OfferService } from "../../services";

const useFetchCurrentOffer = () => {
  const mutation = useMutation({
    mutationKey: ["fetch_current_offer"],
    mutationFn: () => {
      return OfferService.getCurrent();
    },
  });

  const { mutateAsync, isPending } = mutation;
  const ret: [typeof mutateAsync, typeof isPending] = [mutateAsync, isPending];

  return ret;
};

export default useFetchCurrentOffer;
