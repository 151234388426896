import {
  MessageType,
  PaymentStatus,
  PaymentType,
  ReservationOrderBy,
  ReservationStatus,
  useAdminMetrics,
} from "@hotelspoint/api";
import { Box, DashboardCard, Flex } from "@hotelspoint/components";
import { useUserProfileStore } from "@hotelspoint/store";
import { AgencyStatus } from "@hotelspoint/types";
import {
  addDays,
  formatDateIso,
  getFirstDayOfMonth,
  getLastDayOfMonth,
  getPreviousMonthDate,
  subDays,
  useDocumentTitle,
  withCurrency,
} from "@hotelspoint/utils";
import qs from "query-string";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

const AdminDashboard = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("adminDashboard.pageTitle"));

  const [data, isLoading] = useAdminMetrics();

  const assigneeId = useUserProfileStore(state => state.profile?.id);

  const dateFactory = useMemo(() => new Date(), []);
  const dateToday = useMemo(() => formatDateIso(dateFactory), [dateFactory]);

  const dateTomorrow = useMemo(
    () => formatDateIso(addDays(dateFactory, 1)),
    [dateFactory],
  );

  const dateInSevenDays = useMemo(
    () => formatDateIso(addDays(dateFactory, 7)),
    [dateFactory],
  );

  const dateInThirdyDays = useMemo(
    () => formatDateIso(addDays(dateFactory, 30)),
    [dateFactory],
  );

  const firstDayOfMonth = useMemo(
    () => formatDateIso(getFirstDayOfMonth(dateFactory)),
    [dateFactory],
  );

  const lastDayOfMonth = useMemo(
    () => formatDateIso(getLastDayOfMonth(dateFactory)),
    [dateFactory],
  );

  const previousMonthDay = useMemo(
    () => getPreviousMonthDate(dateFactory),
    [dateFactory],
  );

  const firstDayOfPreviousMonth = useMemo(
    () => formatDateIso(getFirstDayOfMonth(previousMonthDay)),
    [previousMonthDay],
  );

  const lastDayOfPreviousMonth = useMemo(
    () => formatDateIso(getLastDayOfMonth(previousMonthDay)),
    [previousMonthDay],
  );

  return (
    <Flex mx={-1} my={-1}>
      {isLoading
        ? Array.from({ length: 24 }, (_, index) => (
            <Box key={index} width={[1, 1, 1 / 2, 1 / 3]} px={1} py={1}>
              <Skeleton width="100%" height={80} />
            </Box>
          ))
        : [
            // Bookings last minute
            <DashboardCard
              title={t("adminDashboard.cards.bookingsLastMinute")}
              data={[
                {
                  value: data?.bookingsLastMinute?.assigned,
                  href: `/admin/bookings?${qs.stringify({
                    status: ReservationStatus.ConfirmedReservation,
                    confirmed: false,
                    dateFrom: formatDateIso(subDays(dateFactory, 3)),
                    checkInFrom: dateToday,
                    checkInTo: formatDateIso(addDays(dateFactory, 3)),
                    assigneeId,
                  })}`,
                },
                {
                  value: data?.bookingsLastMinute?.total,
                  href: `/admin/bookings?${qs.stringify({
                    status: ReservationStatus.ConfirmedReservation,
                    confirmed: false,
                    dateFrom: formatDateIso(subDays(dateFactory, 3)),
                    checkInFrom: dateToday,
                    checkInTo: formatDateIso(addDays(dateFactory, 3)),
                  })}`,
                },
              ]}
            />,
            // Messages alerts
            <DashboardCard
              title={t("adminDashboard.cards.alertsNear")}
              data={[
                {
                  value: data?.alertsNear?.assigned,
                  href: `/admin/messages?${qs.stringify({
                    alert: true,
                    checkInTo: dateInThirdyDays,
                    assigneeId,
                  })}`,
                },
                {
                  value: data?.alertsNear?.total,
                  href: `/admin/messages?${qs.stringify({
                    alert: true,
                    checkInTo: dateInThirdyDays,
                  })}`,
                },
              ]}
            />,
            <DashboardCard
              title={t("adminDashboard.cards.alertsAll")}
              data={[
                {
                  value: data?.alertsAll?.assigned,
                  href: `/admin/messages?${qs.stringify({
                    alert: true,
                    assigneeId,
                  })}`,
                },
                {
                  value: data?.alertsAll?.total,
                  href: `/admin/messages?${qs.stringify({
                    alert: true,
                  })}`,
                },
              ]}
            />,
            // Hotel messages
            <DashboardCard
              title={t("adminDashboard.cards.messagesHotelSoon")}
              data={[
                {
                  value: data?.messagesHotelSoon?.assigned,
                  href: `/admin/messages?${qs.stringify({
                    type: MessageType.Hotel,
                    seen: false,
                    checkInTo: dateInSevenDays,
                    assigneeId,
                  })}`,
                },
                {
                  value: data?.messagesHotelSoon?.total,
                  href: `/admin/messages?${qs.stringify({
                    type: MessageType.Hotel,
                    seen: false,
                    checkInTo: dateInSevenDays,
                  })}`,
                },
              ]}
            />,
            <DashboardCard
              title={t("adminDashboard.cards.messagesHotelNear")}
              data={[
                {
                  value: data?.messagesHotelNear?.assigned,
                  href: `/admin/messages?${qs.stringify({
                    type: MessageType.Hotel,
                    seen: false,
                    checkInTo: dateInThirdyDays,
                    assigneeId,
                  })}`,
                },
                {
                  value: data?.messagesHotelNear?.total,
                  href: `/admin/messages?${qs.stringify({
                    type: MessageType.Hotel,
                    seen: false,
                    checkInTo: dateInThirdyDays,
                  })}`,
                },
              ]}
            />,
            <DashboardCard
              title={t("adminDashboard.cards.messagesHotelAll")}
              data={[
                {
                  value: data?.messagesHotelAll?.assigned,
                  href: `/admin/messages?${qs.stringify({
                    type: MessageType.Hotel,
                    seen: false,
                    assigneeId,
                  })}`,
                },
                {
                  value: data?.messagesHotelAll?.total,
                  href: `/admin/messages?${qs.stringify({
                    type: MessageType.Hotel,
                    seen: false,
                  })}`,
                },
              ]}
            />,
            // Agency messages
            <DashboardCard
              title={t("adminDashboard.cards.messagesAgencySoon")}
              data={[
                {
                  value: data?.messagesAgencySoon?.assigned,
                  href: `/admin/messages?${qs.stringify({
                    type: MessageType.Agency,
                    seen: false,
                    checkInTo: dateInSevenDays,
                    assigneeId,
                  })}`,
                },
                {
                  value: data?.messagesAgencySoon?.total,
                  href: `/admin/messages?${qs.stringify({
                    type: MessageType.Agency,
                    seen: false,
                    checkInTo: dateInSevenDays,
                  })}`,
                },
              ]}
            />,
            <DashboardCard
              title={t("adminDashboard.cards.messagesAgencyNear")}
              data={[
                {
                  value: data?.messagesAgencyNear?.assigned,
                  href: `/admin/messages?${qs.stringify({
                    type: MessageType.Agency,
                    seen: false,
                    checkInTo: dateInThirdyDays,
                    assigneeId,
                  })}`,
                },
                {
                  value: data?.messagesAgencyNear?.total,
                  href: `/admin/messages?${qs.stringify({
                    type: MessageType.Agency,
                    seen: false,
                    checkInTo: dateInThirdyDays,
                  })}`,
                },
              ]}
            />,
            <DashboardCard
              title={t("adminDashboard.cards.messagesAgencyAll")}
              data={[
                {
                  value: data?.messagesAgencyAll?.assigned,
                  href: `/admin/messages?${qs.stringify({
                    type: MessageType.Agency,
                    seen: false,
                    assigneeId,
                  })}`,
                },
                {
                  value: data?.messagesAgencyAll?.total,
                  href: `/admin/messages?${qs.stringify({
                    type: MessageType.Agency,
                    seen: false,
                  })}`,
                },
              ]}
            />,
            // Supplier messages
            <DashboardCard
              title={t("adminDashboard.cards.messagesSupplierSoon")}
              data={[
                {
                  value: data?.messagesSupplierSoon?.assigned,
                  href: `/admin/messages?${qs.stringify({
                    type: MessageType.Supplier,
                    seen: false,
                    checkInTo: dateInSevenDays,
                    assigneeId,
                  })}`,
                },
                {
                  value: data?.messagesSupplierSoon?.total,
                  href: `/admin/messages?${qs.stringify({
                    type: MessageType.Supplier,
                    seen: false,
                    checkInTo: dateInSevenDays,
                  })}`,
                },
              ]}
            />,
            <DashboardCard
              title={t("adminDashboard.cards.messagesSupplierNear")}
              data={[
                {
                  value: data?.messagesSupplierNear?.assigned,
                  href: `/admin/messages?${qs.stringify({
                    type: MessageType.Supplier,
                    seen: false,
                    checkInTo: dateInThirdyDays,
                    assigneeId,
                  })}`,
                },
                {
                  value: data?.messagesSupplierNear?.total,
                  href: `/admin/messages?${qs.stringify({
                    type: MessageType.Supplier,
                    seen: false,
                    checkInTo: dateInThirdyDays,
                  })}`,
                },
              ]}
            />,
            <DashboardCard
              title={t("adminDashboard.cards.messagesSupplierAll")}
              data={[
                {
                  value: data?.messagesSupplierAll?.assigned,
                  href: `/admin/messages?${qs.stringify({
                    type: MessageType.Supplier,
                    seen: false,
                    assigneeId,
                  })}`,
                },
                {
                  value: data?.messagesSupplierAll?.total,
                  href: `/admin/messages?${qs.stringify({
                    type: MessageType.Supplier,
                    seen: false,
                  })}`,
                },
              ]}
            />,
            // Agencies
            <DashboardCard
              title={t("adminDashboard.cards.agenciesTotal")}
              data={{
                value: data?.agenciesTotal,
                href: "/admin/agencies",
              }}
            />,
            <DashboardCard
              title={t("adminDashboard.cards.agenciesToday")}
              data={{
                value: data?.agenciesToday,
                href: `/admin/agencies?${qs.stringify({
                  dateFrom: dateToday,
                })}`,
              }}
            />,
            <DashboardCard
              title={t("adminDashboard.cards.agenciesPending")}
              data={{
                value: data?.agenciesPending,
                href: `/admin/agencies?${qs.stringify({
                  status: AgencyStatus.Pending,
                })}`,
              }}
            />,
            // Payments
            <DashboardCard
              title={t("adminDashboard.cards.paymentsToday")}
              data={{
                value: data?.paymentsToday,
                href: `/admin/payments?${qs.stringify({
                  dateFrom: dateToday,
                })}`,
              }}
            />,
            <DashboardCard
              title={t("adminDashboard.cards.paymentsPending")}
              data={{
                value: data?.paymentsPending,
                href: `/admin/payments?${qs.stringify({
                  type: PaymentType.BankTransfer,
                  status: PaymentStatus.Progress,
                })}`,
              }}
            />,
            <DashboardCard
              title={t("adminDashboard.cards.paymentsRefund")}
              data={{
                value: data?.paymentsRefund,
                href: `/admin/payments?${qs.stringify({
                  status: PaymentStatus.PendingRefund,
                })}`,
              }}
            />,
            // Bookings
            <DashboardCard
              title={t("adminDashboard.cards.bookingsToday")}
              data={{
                value: data?.bookingsToday,
                href: `/admin/bookings?${qs.stringify({
                  dateFrom: dateToday,
                  dateTo: dateToday,
                })}`,
              }}
            />,
            <DashboardCard
              title={t("adminDashboard.cards.bookingsTodayConfirmed")}
              data={{
                value: data?.bookingsTodayConfirmed,
                href: `/admin/bookings?${qs.stringify({
                  dateFrom: dateToday,
                  dateTo: dateToday,
                  status: ReservationStatus.ConfirmedReservation,
                })}`,
              }}
            />,
            <DashboardCard
              title={t("adminDashboard.cards.bookingsTodayFailed")}
              data={{
                value: data?.bookingsTodayFailed,
                href: `/admin/bookings?${qs.stringify({
                  dateFrom: dateToday,
                  dateTo: dateToday,
                  status: ReservationStatus.FailedConfirmation,
                })}`,
              }}
            />,
            <DashboardCard
              title={t("adminDashboard.cards.bookingsPendingConfirmation")}
              data={{
                value: data?.bookingsPendingConfirmation,
                href: `/admin/bookings?${qs.stringify({
                  status: ReservationStatus.PendingConfirmation,
                  orderBy: ReservationOrderBy.CancelledDate,
                })}`,
              }}
            />,
            <DashboardCard
              title={t("adminDashboard.cards.bookingsPendingCancellation")}
              data={{
                value: data?.bookingsPendingCancellation,
                href: `/admin/bookings?${qs.stringify({
                  status: ReservationStatus.PendingCancellation,
                  orderBy: ReservationOrderBy.CancelledDate,
                })}`,
              }}
            />,
            <DashboardCard
              title={t("adminDashboard.cards.bookingsOnDeadline")}
              data={{
                value: data?.bookingsOnDeadline,
                href: `/admin/bookings?${qs.stringify({
                  deadlineFrom: dateToday,
                  deadlineTo: dateToday,
                  status: ReservationStatus.ConfirmedReservation,
                  paymentStatus: PaymentStatus.Progress,
                })}`,
              }}
            />,
            <DashboardCard
              title={t("adminDashboard.cards.commissionLastMonth")}
              data={{
                value: withCurrency(data?.commissionLastMonth),
                href: `/admin/bookings?${qs.stringify({
                  status: ReservationStatus.ConfirmedReservation,
                  checkInFrom: firstDayOfPreviousMonth,
                  checkInTo: lastDayOfPreviousMonth,
                })}`,
              }}
            />,
            <DashboardCard
              title={t("adminDashboard.cards.commissionThisMonth")}
              data={{
                value: withCurrency(data?.commissionThisMonth),
                href: `/admin/bookings?${qs.stringify({
                  status: ReservationStatus.ConfirmedReservation,
                  checkInFrom: firstDayOfMonth,
                  checkInTo: lastDayOfMonth,
                })}`,
              }}
            />,
            <DashboardCard
              title={t("adminDashboard.cards.commissionFuture")}
              data={{
                value: withCurrency(data?.commissionFuture),
                href: `/admin/bookings?${qs.stringify({
                  status: ReservationStatus.ConfirmedReservation,
                  checkInFrom: dateTomorrow,
                })}`,
              }}
            />,
          ].map((card, index) => (
            <Box key={index} width={[1, 1, 1 / 2, 1 / 3]} px={1} py={1}>
              {card}
            </Box>
          ))}
    </Flex>
  );
};

export default AdminDashboard;
