import { Message, MessageStatus, ReservationStatus } from "@hotelspoint/api";
import {
  Chip,
  TABLE_COLUMN_SIZE,
  TableActions,
  TableCell,
} from "@hotelspoint/components";
import { CHIP_COLORS } from "@hotelspoint/constants";
import {
  formatDate,
  formatDateTime,
  getAssigneeById,
  getMessagesPriorityColor,
  getMessagesPriorityName,
  getMessagesStatusColor,
  getMessagesStatusName,
  getMessageTypeName,
  getReservationStatusColor,
  getReservationStatusName,
} from "@hotelspoint/utils";
import { IconBell, IconCheckbox } from "@tabler/icons-react";
import { createColumnHelper } from "@tanstack/react-table";
import { TFunction } from "i18next";
import dropRight from "lodash/dropRight";
import last from "lodash/last";
import { type NavigateFunction } from "react-router-dom";

import * as S from "./AdminMessages.styled";

const columnHelper = createColumnHelper<Message>();

interface GetColumnsParams {
  t: TFunction;
  targetId: number | null;
  isUpdating: boolean;
  navigate: NavigateFunction;
  handleOpenReadModal: (id: number) => void;
  handleOpenPriorityModal: (id: number) => void;
  handleUpdateStatus: (id: number, status: MessageStatus | null) => void;
  handleUpdateAlert: (id: number, state: boolean) => void;
}

const getColumns = ({
  t,
  targetId,
  isUpdating,
  navigate,
  handleOpenReadModal,
  handleOpenPriorityModal,
  handleUpdateStatus,
  handleUpdateAlert,
}: GetColumnsParams) => [
  columnHelper.accessor("id", {
    header: t("adminMessages.columns.id"),
    size: TABLE_COLUMN_SIZE.ID_LONG,
    cell: props => {
      return (
        <S.CellWrapper $active={props.row.original.isNew}>
          {props.getValue()}
        </S.CellWrapper>
      );
    },
  }),
  columnHelper.accessor("priority", {
    header: t("adminMessages.columns.priority"),
    size: TABLE_COLUMN_SIZE.STATUS,
    cell: props => {
      const priority = props.getValue();

      return (
        <S.CellWrapper $active={props.row.original.isNew}>
          <Chip $color={getMessagesPriorityColor(priority)}>
            {t(getMessagesPriorityName(priority))}
          </Chip>
        </S.CellWrapper>
      );
    },
  }),
  columnHelper.display({
    id: "from",
    header: t("adminMessages.columns.from"),
    size: TABLE_COLUMN_SIZE.NAME,
    cell: props => {
      const { senderAgencyName, senderEmail, isNew } = props.row.original;

      return (
        <S.CellWrapper $active={isNew}>
          {senderAgencyName || senderEmail}
        </S.CellWrapper>
      );
    },
  }),
  columnHelper.display({
    id: "subject",
    header: t("adminMessages.columns.subject"),
    size: TABLE_COLUMN_SIZE.NAME,
    cell: props => {
      const { status, subject, isNew, isAlert } = props.row.original;

      return (
        <S.CellWrapper $active={isNew}>
          <div style={{ display: "flex", columnGap: 8 }}>
            {isAlert && (
              <IconBell
                size={18}
                style={{ color: CHIP_COLORS.RED, minWidth: 18 }}
              />
            )}
            {status && (
              <Chip $color={getMessagesStatusColor(status)}>
                {t(getMessagesStatusName(status))}
              </Chip>
            )}
            <span>{subject}</span>
          </div>
        </S.CellWrapper>
      );
    },
  }),
  columnHelper.accessor("type", {
    header: t("adminMessages.columns.type"),
    size: TABLE_COLUMN_SIZE.TYPE,
    cell: props => {
      const messageType = props.getValue();

      return (
        <S.CellWrapper $active={props.row.original.isNew}>
          {t(getMessageTypeName(messageType))}
        </S.CellWrapper>
      );
    },
  }),
  columnHelper.accessor("reservationId", {
    header: t("adminMessages.columns.reservationId"),
    size: TABLE_COLUMN_SIZE.ID_LONG,
    cell: props => {
      const reservationId = props.getValue();
      const { reservationStatus, isNew } = props.row.original;

      return (
        <S.CellWrapper $active={isNew}>
          <TableCell>
            <IconCheckbox
              size={18}
              style={{
                color:
                  reservationStatus == ReservationStatus.ConfirmedReservation
                    ? CHIP_COLORS.GREEN
                    : CHIP_COLORS.GREY,
              }}
            />
            <span>{reservationId}</span>
          </TableCell>
        </S.CellWrapper>
      );
    },
  }),
  columnHelper.accessor("assigneeId", {
    header: t("adminMessages.columns.assigneeId"),
    size: TABLE_COLUMN_SIZE.ASSIGNEE,
    cell: props => {
      const id = props.getValue();

      return (
        <S.CellWrapper $active={props.row.original.isNew}>
          {getAssigneeById(id)}
        </S.CellWrapper>
      );
    },
  }),
  columnHelper.accessor("reservationDateFrom", {
    header: t("adminMessages.columns.reservationDateFrom"),
    size: TABLE_COLUMN_SIZE.DATE,
    cell: props => {
      return (
        <S.CellWrapper $active={props.row.original.isNew}>
          {formatDate(props.getValue())}
        </S.CellWrapper>
      );
    },
  }),
  columnHelper.accessor("reservationStatus", {
    header: t("adminMessages.columns.reservationStatus"),
    size: TABLE_COLUMN_SIZE.STATUS,
    cell: props => {
      const status = props.getValue();

      return (
        <S.CellWrapper $active={props.row.original.isNew}>
          <Chip $color={getReservationStatusColor(status)}>
            {t(getReservationStatusName(status))}
          </Chip>
        </S.CellWrapper>
      );
    },
  }),
  columnHelper.accessor("dateCreated", {
    header: t("adminMessages.columns.dateCreated"),
    size: TABLE_COLUMN_SIZE.DATE_TIME,
    cell: props => {
      return (
        <S.CellWrapper $active={props.row.original.isNew}>
          {formatDateTime(props.getValue())}
        </S.CellWrapper>
      );
    },
  }),
  columnHelper.display({
    id: "actions",
    size: TABLE_COLUMN_SIZE.ACTION,
    cell: props => {
      const { id, status, reservationId, isAlert } = props.row.original;
      const isTarget = id === targetId;

      const statusOptions = [
        ...(status !== null
          ? [
              {
                id: "update_status_to_none",
                title: t("adminMessages.columns.actions.status.noStatus"),
                onClick: () => handleUpdateStatus(id, null),
              },
            ]
          : []),
        ...(status !== MessageStatus.InProgress
          ? [
              {
                id: "update_status_to_in_progress",
                title: t("adminMessages.columns.actions.status.inProgress"),
                onClick: () => handleUpdateStatus(id, MessageStatus.InProgress),
              },
            ]
          : []),
        ...(status !== MessageStatus.Pending
          ? [
              {
                id: "update_status_to_pending",
                title: t("adminMessages.columns.actions.status.pending"),
                onClick: () => handleUpdateStatus(id, MessageStatus.Pending),
              },
            ]
          : []),
        ...(status !== MessageStatus.Completed
          ? [
              {
                id: "update_status_to_completed",
                title: t("adminMessages.columns.actions.status.completed"),
                onClick: () => handleUpdateStatus(id, MessageStatus.Completed),
              },
            ]
          : []),
      ];

      return (
        <TableActions
          options={[
            {
              id: "read",
              title: t("adminMessages.columns.actions.read.button"),
              onClick: () => handleOpenReadModal(id),
            },
            {
              id: "view_booking",
              title: t("adminMessages.columns.actions.viewBooking"),
              isSeparated: true,
              onClick: () => navigate(`/admin/bookings/${reservationId}`),
            },
            {
              id: "update_priority",
              title: t("adminMessages.columns.actions.priority.button"),
              isSeparated: true,
              onClick: () => handleOpenPriorityModal(id),
            },
            // Always keep the status options separated from the other action items
            ...[
              ...dropRight(statusOptions),
              { ...(last(statusOptions) as any), isSeparated: true },
            ],
            {
              id: "update_alert",
              title: isAlert
                ? t("adminMessages.columns.actions.alert.unset")
                : t("adminMessages.columns.actions.alert.set"),
              onClick: () => handleUpdateAlert(id, !isAlert),
            },
          ]}
          isLoading={isTarget && isUpdating}
        />
      );
    },
  }),
];

export default getColumns;
