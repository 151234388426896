import { pxToRem, spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";

export const Title = styled.h6`
  margin-bottom: ${spacing(0.5)}px;
  color: rgba(18, 50, 102, 0.6);
  font-weight: 700;
  font-size: ${pxToRem(14)};
  text-transform: uppercase;
`;

export const RoomName = styled.h5`
  margin-bottom: ${spacing(2)}px;
  font-weight: 600;
`;

export const ListWrapper = styled.div`
  display: flex;
  column-gap: ${spacing(8)}px;
`;

export const NameList = styled.ul`
  list-style: none;
`;

export const NameListItem = styled.li`
  font-weight: 600;
  font-size: ${pxToRem(16)};
`;
