import { AdminService, AdminTransferRequestListParams } from "@hotelspoint/api";
import { useQuery } from "@tanstack/react-query";

const useAdminTransferRequests = (params?: AdminTransferRequestListParams) => {
  const { data, isLoading } = useQuery({
    queryKey: ["admin", "requests_transfer", params],
    queryFn: () => AdminService.getTransferRequests(params),
  });

  const ret: [typeof data, boolean] = [data, isLoading];

  return ret;
};

export default useAdminTransferRequests;
