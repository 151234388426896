import { createContext, useContext } from "react";

import usePopover from "./usePopover";

type ContextType = ReturnType<typeof usePopover> | null;

const PopoverContext = createContext<ContextType>(null);

export const usePopoverContext = () => {
  const context = useContext(PopoverContext);

  if (context == null) {
    throw new Error("Popover components must be wrapped in <Popover />");
  }

  return context;
};

export default PopoverContext;
