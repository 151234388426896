import { handleError, useDeleteOffer, useOffers } from "@hotelspoint/api";
import {
  Breadcrumb,
  BreadcrumbContainer,
  Pagination,
  Panel,
  Table,
  TABLE_PAGE_SIZE_DEFAULT,
  TableFooter,
  TableLayout,
  TablePageSize,
  TableResultsCount,
} from "@hotelspoint/components";
import { pickFilterValues, useDocumentTitle } from "@hotelspoint/utils";
import { IconFilePercent, IconUser } from "@tabler/icons-react";
import omit from "lodash/omit";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import getColumns from "./ProfileOffers.columns";
import ProfileOffersFilters from "./ProfileOffersFilters";
import useProfileOffersParams from "./useProfileOffersParams";

const ProfileOffers = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("profileOffers.pageTitle"));

  const [targetId, setTargetId] = useState<number | null>(null);
  const [pageSize, setPageSize] = useState(TABLE_PAGE_SIZE_DEFAULT);
  const [pageIndex, setPageIndex] = useState(1);

  const [query] = useProfileOffersParams();
  const filters = useMemo(
    () => omit(pickFilterValues(query), "agencyName"),
    [query],
  );

  const breadcrumbs = useMemo(
    () => [
      {
        title: t("profile.pageTitle"),
        href: "/profile",
        icon: IconUser,
      },
      {
        title: t("profileOffers.pageTitle"),
        icon: IconFilePercent,
      },
    ],
    [t],
  );

  const [offers, isLoading] = useOffers({
    pageIndex,
    pageSize,
    ...filters,
  });

  const [deleteOffer, isDeleting] = useDeleteOffer();

  const handleDelete = useCallback(
    async (id: number) => {
      try {
        setTargetId(id);
        await deleteOffer(id);
      } catch (error: any) {
        handleError({ t, error });
      } finally {
        setTargetId(null);
      }
    },
    [t, deleteOffer],
  );

  const data = useMemo(() => offers?.results ?? [], [offers]);

  const columns = useMemo(() => {
    return getColumns({
      t,
      targetId,
      handleDelete,
      isDeleting,
    });
  }, [t, targetId, handleDelete, isDeleting]);

  return (
    <>
      <BreadcrumbContainer>
        <Breadcrumb items={breadcrumbs} />
      </BreadcrumbContainer>
      <Panel title={t("profileOffers.pageTitle")}>
        <ProfileOffersFilters isLoading={isLoading} />
        <TableLayout>
          <Table
            data={data}
            columns={columns}
            isLoading={isLoading}
            useReactTableProps={{
              state: {
                pagination: {
                  pageIndex,
                  pageSize: TABLE_PAGE_SIZE_DEFAULT,
                },
                columnPinning: {
                  left: ["id"],
                  right: ["actions"],
                },
              },
              manualPagination: true,
            }}
          />
          <TableFooter>
            <div>
              <TableResultsCount value={offers?.count} />
            </div>
            <div>
              <Pagination
                pageIndex={pageIndex}
                pageSize={pageSize}
                setPageIndex={setPageIndex}
                totalCount={offers?.count ?? 0}
              />
            </div>
            <TablePageSize value={pageSize} onChange={setPageSize} />
          </TableFooter>
        </TableLayout>
      </Panel>
    </>
  );
};

export default ProfileOffers;
