import { CookieConsent, ScrollToTop } from "@hotelspoint/components";
import { useCookieConsentStore, useUserLocaleStore } from "@hotelspoint/store";
import { GlobalStyles, ThemeProvider } from "@hotelspoint/theme";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import qs from "query-string";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

import AuthProvider from "./auth/AuthProvider";
import Router from "./Router";

const App = () => {
  const { i18n } = useTranslation();
  const language = useUserLocaleStore(state => state.language);
  const hasConcentForCookies = useCookieConsentStore(state => state.consent);

  // Sync store value for language with i18n instance
  useEffect(() => {
    if (language && i18n.language !== language) {
      i18n.changeLanguage(language);

      // Dismiss & clear queue for toasts when changing language
      toast.dismiss();
      toast.clearWaitingQueue();
    }
  }, [language, i18n]);

  return (
    <ThemeProvider>
      <AuthProvider>
        <QueryParamProvider
          adapter={ReactRouter6Adapter}
          options={{
            searchStringToObject: qs.parse,
            objectToSearchString: qs.stringify,
          }}
        >
          <GlobalStyles />
          <ScrollToTop />
          <ToastContainer
            position="top-center"
            autoClose={3_000}
            closeOnClick
            pauseOnFocusLoss
            pauseOnHover
            newestOnTop
            theme="light"
          />
          {!hasConcentForCookies && <CookieConsent />}
          <Router />

          {/* Dev tools */}
          {import.meta.env.DEV && (
            <ReactQueryDevtools
              initialIsOpen={false}
              position="right"
              buttonPosition="bottom-right"
            />
          )}
        </QueryParamProvider>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
