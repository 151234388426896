import createRecentSearchesStore from "./utils/createRecentSearchesStore";

const PERSIST_NAME = "@hp::recent_transfer_searches";

// @todo: add missing type
const useRecentHotelSearchesStore = createRecentSearchesStore<any>({
  persistName: PERSIST_NAME,
});

export default useRecentHotelSearchesStore;
