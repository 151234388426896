import { pxToRem } from "@hotelspoint/theme";
import styled from "styled-components";
import { theme } from "styled-tools";

export default styled.h6`
  color: ${theme("palette.doveGrey")};
  font-weight: 500;
  font-size: ${pxToRem(15)};
  text-transform: uppercase;
`;
