import {
  AdminCommission,
  AdminCommissionStatus,
  AdminCommissionUpdatePayload,
} from "@hotelspoint/api";
import { formatDateTime } from "@hotelspoint/utils";
import { InferType, mixed, number, object, string } from "yup";

export const validationSchema = object({
  userName: string(),
  amount: number(),
  status: mixed<AdminCommissionStatus>()
    .oneOf(
      Object.values(AdminCommissionStatus).map(e => e as AdminCommissionStatus),
    )
    .required(),
  dateCreated: string(),
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: Partial<FormValues> = {
  userName: "",
  amount: undefined,
  dateCreated: "",
};

export const entity2Form = (entity: AdminCommission): FormValues => ({
  userName: entity.userName,
  amount: entity.amount,
  status: entity.status,
  dateCreated: formatDateTime(entity.dateCreated),
});

export const form2Entity = (
  formValues: FormValues,
): AdminCommissionUpdatePayload => ({
  status: formValues.status,
});
