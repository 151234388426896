import { useQuery } from "@tanstack/react-query";

import { ReservationService } from "../../services";

const useReservation = (id: number) => {
  const { data, isLoading } = useQuery({
    queryKey: ["reservations", id],
    queryFn: () => ReservationService.get(id),
    enabled: !!id,
  });

  const ret: [typeof data, boolean] = [data, isLoading];

  return ret;
};

export default useReservation;
