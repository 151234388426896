import {
  activitySuppliers,
  hotelSuppliers,
  statementSuppliers,
  transferSuppliers,
} from "@hotelspoint/constants";

export const getHotelSupplierName = (id: number) => {
  const supplierName = hotelSuppliers[`${id}` as keyof typeof hotelSuppliers];

  if (!supplierName) {
    return "Unknown hotel supplier";
  }

  return supplierName;
};

export const getTransferSupplierName = (id: number) => {
  const supplierName =
    transferSuppliers[`${id}` as keyof typeof transferSuppliers];

  if (!supplierName) {
    return "Unknown transfer supplier";
  }

  return supplierName;
};

export const getActivitySupplierName = (id: number) => {
  const supplierName =
    activitySuppliers[`${id}` as keyof typeof activitySuppliers];

  if (!supplierName) {
    return "Unknown activity supplier";
  }

  return supplierName;
};
export const getStatementSupplierName = (id: string) => {
  const supplierName = statementSuppliers.find(
    supplier => supplier.id === id,
  )?.name;

  if (!supplierName) {
    return "Unknown statement supplier";
  }

  return supplierName;
};
