import { pxToRem, spacing } from "@hotelspoint/theme";
import styled from "styled-components";
import { theme } from "styled-tools";

import { NAV_LINK_ACTIVE_CLASSNAME } from "../../../misc/NavLink";

export const Wrapper = styled.div`
  a {
    position: relative;
    display: block;
    padding: ${spacing(1)}px ${spacing(1.5)}px;
    color: ${theme("palette.black")};
    font-size: ${pxToRem(17)};
    line-height: 1;

    &:hover {
      color: ${theme("palette.black")};

      &::before {
        display: block;
      }
    }

    &::before {
      content: "";
      position: absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      width: 100%;
      height: 3px;
      background: ${theme("secondary.bg")};
      display: none;
    }

    &.${NAV_LINK_ACTIVE_CLASSNAME} {
      font-weight: 600;

      &::before {
        display: block;
      }
    }
  }
`;
