import { useQuery } from "@tanstack/react-query";

import { AgencyService } from "../../services";

const useAgency = (id: number) => {
  const { data, isLoading } = useQuery({
    queryKey: ["agencies", id],
    queryFn: () => AgencyService.get(id),
    enabled: !!id,
  });

  const ret: [typeof data, boolean] = [data, isLoading];

  return ret;
};

export default useAgency;
