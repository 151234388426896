import { zIndex } from "@hotelspoint/theme";
import { useClick } from "@szhsin/react-menu";
import { IconChevronDown } from "@tabler/icons-react";
import { Fragment, useRef, useState } from "react";

import { IconType } from "../../common";
import { ControlledMenu, MenuDivider, MenuItem } from "../../composites/Menu";
import Button from "../../elements/Button";
import ButtonGroup from "../../elements/ButtonGroup";
import * as S from "./TableActions.styled";

interface TableActionOption {
  id: string;
  icon?: IconType;
  title: string;
  onClick?: () => void;
  isSeparated?: boolean;
}

interface TableActionsProps {
  options: TableActionOption[];
  isLoading?: boolean;
}

const TableActions = ({ options, isLoading = false }: TableActionsProps) => {
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const anchorProps = useClick(isOpen, setOpen);

  const [primaryOption, ...restOptions] = options;

  return (
    <>
      <ButtonGroup>
        <Button
          small
          variant="outlined"
          onClick={primaryOption.onClick}
          isLoading={isLoading}
        >
          {primaryOption.title}
        </Button>
        <Button ref={ref} small variant="outlined" {...anchorProps}>
          <IconChevronDown size={14} />
        </Button>
      </ButtonGroup>
      <ControlledMenu
        anchorRef={ref}
        state={isOpen ? "open" : "closed"}
        onClose={() => setOpen(false)}
        transition
        arrow
        direction="bottom"
        align="center"
        viewScroll="close"
        portal={true}
        menuStyle={{ zIndex: zIndex.tableActions }}
      >
        {restOptions.map(option => {
          return (
            <Fragment key={option.id}>
              <MenuItem onClick={option.onClick}>
                <S.MenuItem>
                  {option.icon && <option.icon size={16} />}
                  <span>{option.title}</span>
                </S.MenuItem>
              </MenuItem>
              {option.isSeparated && <MenuDivider />}
            </Fragment>
          );
        })}
      </ControlledMenu>
    </>
  );
};

export default TableActions;
