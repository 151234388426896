import { Agency } from "@hotelspoint/types";
import { create } from "zustand";

interface UserAgencyStore {
  agency: Agency | null;
  set: (agency: Agency) => void;
  reset: () => void;
}

const useUserAgencyStore = create<UserAgencyStore>(set => ({
  agency: null,
  set: agency => set({ agency }),
  reset: () => set({ agency: null }),
}));

export default useUserAgencyStore;
