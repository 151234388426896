import { spacing } from "@hotelspoint/theme";
import styled from "styled-components";

export const Title = styled.h4`
  text-align: center;
`;

export const ResetPassword = styled.div`
  text-align: right;
`;

export const Register = styled.div`
  text-align: center;
  margin-top: ${spacing(2)}px;
`;
