import {
  handleError,
  useAdminAgencyStatementItems,
  useAdminAgencyStatementOptions,
  useCreateAdminAgencyStatement,
} from "@hotelspoint/api";
import {
  Box,
  Button,
  Chip,
  Flex,
  Form,
  FormAdapter,
  FormChildrenMethods,
  FormContext,
  FormErrorMessage,
  FormInput,
  FormLayout,
  FormSelect,
  LoaderBlock,
  Panel,
  parseRowSelectionToValue,
  parseValueToRowSelection,
} from "@hotelspoint/components";
import { AGENCY_STATEMENTS_STATUS_OPTIONS } from "@hotelspoint/constants";
import { UserCurrency } from "@hotelspoint/types";
import {
  getAgencyStatementStatusColor,
  parseMarkup,
  useDocumentTitle,
} from "@hotelspoint/utils";
import { type RowSelectionState } from "@tanstack/react-table";
import isEmpty from "lodash/isEmpty";
import sumBy from "lodash/sumBy";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import AdminAgencyStatementItems from "../AdminAgencyStatement/AdminAgencyStatementItems";
import {
  defaultValues,
  form2Entity,
  FormValues,
  validationSchema,
} from "./AdminAgencyStatementsCreate.util";

const AdminAgencyStatementCreateInner = ({
  setValue,
}: FormChildrenMethods<FormValues>) => {
  const { t } = useTranslation();
  useDocumentTitle(t("adminAgencyStatementsCreate.pageTitle"));

  const navigate = useNavigate();

  const [agencyId] = useWatch({ name: ["agencyId"] });

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const [createStatement, isCreating] = useCreateAdminAgencyStatement();
  const [agencies, isLoadingAgencies] = useAdminAgencyStatementOptions();
  const [items, isLoadingItems] = useAdminAgencyStatementItems(agencyId);

  const agencyOptions = useMemo(() => {
    if (!agencies || isLoadingAgencies) return [];

    return agencies.map(agency => ({
      value: agency.id,
      label: agency.name,
    }));
  }, [agencies, isLoadingAgencies]);

  const statusOptions = useMemo(
    () =>
      AGENCY_STATEMENTS_STATUS_OPTIONS.map(status => ({
        ...status,
        label: (
          <Chip $color={getAgencyStatementStatusColor(status.value)}>
            {t(status.label)}
          </Chip>
        ),
      })),
    [t],
  );

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        const payload = form2Entity(formValues);

        const { id } = await createStatement(payload);
        toast.success(t("toast.adminAgencyStatement.createStatement"));

        navigate(`/admin/statements/agencies/${id}`);
      } catch (error: any) {
        handleError({ t, error });
      }
    },
    [t, createStatement, navigate],
  );

  // Based on the row selection, update the form values
  useEffect(() => {
    if (isEmpty(rowSelection)) {
      setValue("paymentIds", []);
      setValue("amount", 0);
    } else {
      const ids = parseRowSelectionToValue(rowSelection);
      setValue("paymentIds", ids);

      const totalSum = sumBy(
        items?.filter(item => ids.includes(item.id)),
        "amount",
      );

      setValue("amount", parseMarkup(totalSum));
    }
  }, [rowSelection, setValue, items]);

  useEffect(() => {
    if (items) {
      // All items are selected by default
      setRowSelection(
        parseValueToRowSelection(items?.map(item => `${item.id}`)),
      );
    } else {
      setValue("paymentIds", []);
    }
  }, [items, setValue]);

  if (isLoadingAgencies) {
    return <LoaderBlock />;
  }

  return (
    <Panel title={t("adminAgencyStatementsCreate.pageTitle")}>
      <FormLayout>
        <Flex mx={[0, 0, -1, -1]}>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="agencyId"
              label={t("adminAgencyStatementsCreate.form.agencyId.label")}
            >
              {props => (
                <FormSelect
                  {...props}
                  placeholder={t(
                    "adminAgencyStatementsCreate.form.agencyId.placeholder",
                  )}
                  options={agencyOptions}
                  isClearable
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="amount"
              label={t("adminAgencyStatementsCreate.form.amount.label")}
            >
              {props => (
                <FormInput
                  {...props}
                  readOnly
                  adornmentStyle="filled"
                  endAdornment={UserCurrency.EUR}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="status"
              label={t("adminAgencyStatementsCreate.form.status.label")}
            >
              {props => (
                <FormSelect {...props} options={statusOptions} readOnly />
              )}
            </FormAdapter>
          </Box>
        </Flex>
        <FormContext<FormValues>
          render={({ handleSubmit }) => (
            <Button
              type="submit"
              variant="secondary"
              isLoading={isCreating}
              onClick={handleSubmit(onSubmit)}
            >
              {t("adminAgencyStatementsCreate.submit")}
            </Button>
          )}
        />
        <FormErrorMessage name="paymentIds" />
        <AdminAgencyStatementItems
          data={items ?? []}
          isLoading={isLoadingItems}
          useReactTableProps={{
            enableRowSelection: true,
            onRowSelectionChange: setRowSelection,
            state: {
              rowSelection,
            },
          }}
        />
      </FormLayout>
    </Panel>
  );
};

const AdminAgencyStatementCreate = () => {
  return (
    <Form<FormValues>
      defaultValues={defaultValues}
      validationSchema={validationSchema}
    >
      {formMethods => <AdminAgencyStatementCreateInner {...formMethods} />}
    </Form>
  );
};

export default AdminAgencyStatementCreate;
