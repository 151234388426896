import iconCompany from "./assets/icon-company.svg";
import iconMission from "./assets/icon-mission.svg";
import iconSystem from "./assets/icon-system.svg";
import iconTechnologies from "./assets/icon-technologies.svg";
import iconValues from "./assets/icon-values.svg";
import iconVision from "./assets/icon-vision.svg";
const sections = [
  {
    title: "about.sections.company.title",
    description: "about.sections.company.description",
    icon: iconCompany,
  },
  {
    title: "about.sections.system.title",
    description: "about.sections.system.description",
    icon: iconSystem,
  },
  {
    title: "about.sections.vision.title",
    description: "about.sections.vision.description",
    icon: iconVision,
  },
  {
    title: "about.sections.mission.title",
    description: "about.sections.mission.description",
    icon: iconMission,
  },
  {
    title: "about.sections.values.title",
    description: "about.sections.values.description",
    icon: iconValues,
  },
  {
    title: "about.sections.technologies.title",
    description: "about.sections.technologies.description",
    icon: iconTechnologies,
  },
];

export default sections;
