import { Coordinates, PlaceType } from "@hotelspoint/types";

import FetchService from "./base/FetchService";

const PLACES_API_PATH = "places";

export interface PlaceParams {
  id: number;
  type: PlaceType;
}

export interface Place {
  id: number;
  name: string;
  type: PlaceType;
  position: Coordinates;
  parents: Pick<Place, "id" | "name" | "type">[];
}

export interface PlacesListParams {
  search: string;
  types: PlaceType[];
}

export type PlaceInList = Pick<Place, "id" | "name">;

export type PlacesListResponse = Partial<{
  [key in PlaceType]: PlaceInList[];
}>;

const PlaceService = {
  get: (params: Partial<PlaceParams>) => {
    return FetchService.get<Place>(
      `${PLACES_API_PATH}/${params.type}/${params.id}`,
    );
  },
  getList: (params: Partial<PlacesListParams>) => {
    return FetchService.get<PlacesListResponse>(PLACES_API_PATH, params);
  },
};

export default PlaceService;
