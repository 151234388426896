import { CHIP_COLORS } from "@hotelspoint/constants";
import { AgencyStatus } from "@hotelspoint/types";

export const getAgencyStatusName = (status: AgencyStatus) => {
  switch (status) {
    case AgencyStatus.Created:
      return "agencyStatus.created";

    case AgencyStatus.Pending:
      return "agencyStatus.pending";

    case AgencyStatus.Verified:
      return "agencyStatus.verified";

    case AgencyStatus.Rejected:
      return "agencyStatus.rejected";

    default:
      return "Unknown status";
  }
};

export const getAgencyStatusColor = (status: AgencyStatus) => {
  switch (status) {
    case AgencyStatus.Created:
      return CHIP_COLORS.DARK_GREY;

    case AgencyStatus.Pending:
      return CHIP_COLORS.YELLOW;

    case AgencyStatus.Verified:
      return CHIP_COLORS.GREEN;

    case AgencyStatus.Rejected:
      return CHIP_COLORS.RED;

    default:
      return CHIP_COLORS.GREY;
  }
};
