import { UserLocale } from "@hotelspoint/types";

import flagBg from "./assets/flag-bg.svg";
import flagGb from "./assets/flag-gb.svg";

export default [
  {
    value: UserLocale.English,
    title: "English",
    icon: flagGb,
  },
  {
    value: UserLocale.Bulgarian,
    title: "Български",
    icon: flagBg,
  },
];
