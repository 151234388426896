class ApiError extends Error {
  code: number | null = null;

  constructor(code: number) {
    super(`API error with code: ${code}`);
    this.code = code;
  }
}

export default ApiError;
