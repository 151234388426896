import {
  borderRadius,
  pxToRem,
  spacing,
  timing,
  zIndex,
} from "@hotelspoint/theme";
import styled from "styled-components";
import { ifProp, theme } from "styled-tools";

import Paper from "../../elements/Paper";
import { VISIBLE_OPTIONS } from "./constants";

export const Wrapper = styled.div`
  position: relative;
`;

export const Options = styled(Paper)<{ $isVisible: boolean }>`
  z-index: ${zIndex.formAutoComplete};
  position: absolute;
  top: calc(100% + 1px);
  left: 0;
  right: 0;
  width: 100%;
  padding: ${spacing(0.5)}px;
  max-height: calc(32px * ${VISIBLE_OPTIONS}); /* 8 items */
  overflow-y: auto;
  display: ${ifProp("$isVisible", "block", "none")};
`;

export const OptionGroup = styled.div`
  padding: ${spacing(0.5)}px 0;
`;

export const OptionGroupTitle = styled.h6`
  text-transform: uppercase;
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(14)};
  color: ${theme("palette.silverChalice")};
  margin: ${spacing(0.5)}px;
`;

export const BaseOption = styled.li`
  border-radius: ${borderRadius.normal}px;
  padding: ${spacing(0.5)}px;
`;

export const Option = styled(BaseOption)<{ $isActive: boolean }>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  row-gap: ${spacing(1)}px;
  background-color: ${ifProp("$isActive", theme("palette.concrete"))};
  transition: background ${timing.semiFast}ms;

  &:hover {
    background-color: ${theme("palette.concrete")};
  }
`;

export const EmptyState = styled.li`
  padding: ${spacing(1)}px ${spacing(0.5)}px;
`;
