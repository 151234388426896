import { AdminService } from "@hotelspoint/api";
import { useQuery } from "@tanstack/react-query";

const useAdminMetrics = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["admin", "metrics"],
    queryFn: () => AdminService.getMetrics(),
  });

  const ret: [typeof data, boolean] = [data, isLoading];

  return ret;
};

export default useAdminMetrics;
