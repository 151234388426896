import {
  Box,
  Button,
  Chip,
  Flex,
  Form,
  FormAdapter,
  FormClearableInput,
  FormContext,
  FormDatePickerSingle,
  FormLayout,
  FormSelect,
} from "@hotelspoint/components";
import {
  ASSIGNEE_OPTIONS,
  BOOLEAN_OPTIONS,
  MESSAGE_ORDER_BY_OPTIONS,
  MESSAGE_STATUS_OPTIONS,
  MESSAGES_PRIORITY_OPTIONS,
  MESSAGES_TYPE_OPTIONS,
  RESERVATION_STATUS_OPTIONS,
} from "@hotelspoint/constants";
import {
  getMessagesPriorityColor,
  getMessagesStatusColor,
  getReservationStatusColor,
} from "@hotelspoint/utils";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import useAdminMessagesParams from "../useAdminMessagesParams";
import {
  entity2Form,
  form2Entity,
  FormValues,
} from "./AdminMessagesFilters.form";

interface AdminMessagesFiltersProps {
  isLoading: boolean;
}

const AdminMessagesFilters = ({ isLoading }: AdminMessagesFiltersProps) => {
  const { t } = useTranslation();

  const [query, setQuery] = useAdminMessagesParams();

  const formValues = useMemo(() => {
    return entity2Form(query);
  }, [query]);

  const orderByOptions = useMemo(
    () =>
      MESSAGE_ORDER_BY_OPTIONS.map(order => ({
        ...order,
        label: t(order.label),
      })),
    [t],
  );

  const statusOptions = useMemo(
    () =>
      MESSAGE_STATUS_OPTIONS.map(status => ({
        ...status,
        label: (
          <Chip $color={getMessagesStatusColor(status.value)}>
            {t(status.label)}
          </Chip>
        ),
      })),
    [t],
  );

  const reservationStatusOptions = useMemo(
    () =>
      RESERVATION_STATUS_OPTIONS.map(status => ({
        ...status,
        label: (
          <Chip $color={getReservationStatusColor(status.value)}>
            {t(status.label)}
          </Chip>
        ),
      })),
    [t],
  );

  const priorityOptions = useMemo(
    () =>
      MESSAGES_PRIORITY_OPTIONS.map(status => ({
        ...status,
        label: (
          <Chip $color={getMessagesPriorityColor(status.value)}>
            {t(status.label)}
          </Chip>
        ),
      })),
    [t],
  );

  const typeOptions = useMemo(
    () =>
      MESSAGES_TYPE_OPTIONS.map(type => ({
        ...type,
        label: t(type.label),
      })),
    [t],
  );

  const booleanOptions = useMemo(
    () =>
      BOOLEAN_OPTIONS.map(option => ({
        ...option,
        label: t(option.label),
      })),
    [t],
  );

  const onSubmit = (formValues: FormValues) => {
    const payload = form2Entity(formValues);

    setQuery(payload);
  };

  return (
    <Form defaultValues={formValues}>
      <FormLayout>
        <Flex mx={[0, 0, -1, -1]}>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter name="id" label={t("adminMessages.filters.id.label")}>
              {props => (
                <FormClearableInput
                  {...props}
                  placeholder={t("adminMessages.filters.id.placeholder")}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="reservationId"
              label={t("adminMessages.filters.reservationId.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  placeholder={t(
                    "adminMessages.filters.reservationId.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="type"
              label={t("adminMessages.filters.type.label")}
            >
              {props => (
                <FormSelect
                  {...props}
                  placeholder={t("adminMessages.filters.type.placeholder")}
                  options={typeOptions}
                  isClearable
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="priority"
              label={t("adminMessages.filters.priority.label")}
            >
              {props => (
                <FormSelect
                  {...props}
                  placeholder={t("adminMessages.filters.priority.placeholder")}
                  options={priorityOptions}
                  isClearable
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="status"
              label={t("adminMessages.filters.status.label")}
            >
              {props => (
                <FormSelect
                  {...props}
                  placeholder={t("adminMessages.filters.status.placeholder")}
                  options={statusOptions}
                  isClearable
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="assigneeId"
              label={t("adminMessages.filters.assigneeId.label")}
            >
              {props => (
                <FormSelect
                  {...props}
                  placeholder={t(
                    "adminMessages.filters.assigneeId.placeholder",
                  )}
                  options={ASSIGNEE_OPTIONS}
                  isClearable
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="dateFrom"
              label={t("adminMessages.filters.dateFrom.label")}
            >
              {props => (
                <FormDatePickerSingle
                  {...props}
                  placeholder={t("adminMessages.filters.dateFrom.placeholder")}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="dateTo"
              label={t("adminMessages.filters.dateTo.label")}
            >
              {props => (
                <FormDatePickerSingle
                  {...props}
                  placeholder={t("adminMessages.filters.dateTo.placeholder")}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="alert"
              label={t("adminMessages.filters.alert.label")}
            >
              {props => (
                <FormSelect
                  {...props}
                  placeholder={t("adminMessages.filters.alert.placeholder")}
                  options={booleanOptions}
                  isClearable
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="checkInFrom"
              label={t("adminMessages.filters.checkInFrom.label")}
            >
              {props => (
                <FormDatePickerSingle
                  {...props}
                  placeholder={t(
                    "adminMessages.filters.checkInFrom.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="checkInTo"
              label={t("adminMessages.filters.checkInTo.label")}
            >
              {props => (
                <FormDatePickerSingle
                  {...props}
                  placeholder={t("adminMessages.filters.checkInTo.placeholder")}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="seen"
              label={t("adminMessages.filters.seen.label")}
            >
              {props => (
                <FormSelect
                  {...props}
                  placeholder={t("adminMessages.filters.seen.placeholder")}
                  options={booleanOptions}
                  isClearable
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="reservationStatus"
              label={t("adminMessages.filters.reservationStatus.label")}
            >
              {props => (
                <FormSelect
                  {...props}
                  placeholder={t(
                    "adminMessages.filters.reservationStatus.placeholder",
                  )}
                  options={reservationStatusOptions}
                  isClearable
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="reservationConfirmed"
              label={t("adminMessages.filters.reservationConfirmed.label")}
            >
              {props => (
                <FormSelect
                  {...props}
                  placeholder={t(
                    "adminMessages.filters.reservationConfirmed.placeholder",
                  )}
                  options={booleanOptions}
                  isClearable
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="orderBy"
              label={t("adminMessages.filters.orderBy.label")}
            >
              {props => (
                <FormSelect
                  {...props}
                  placeholder={t("adminMessages.filters.orderBy.placeholder")}
                  options={orderByOptions}
                  isClearable
                />
              )}
            </FormAdapter>
          </Box>
        </Flex>
        <FormContext<FormValues>
          render={({ handleSubmit }) => (
            <Button
              type="submit"
              variant="secondary"
              isLoading={isLoading}
              onClick={handleSubmit(onSubmit)}
            >
              {t("adminMessages.filters.submit")}
            </Button>
          )}
        />
      </FormLayout>
    </Form>
  );
};

export default AdminMessagesFilters;
