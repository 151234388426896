import { NumberParam, StringParam, useQueryParams } from "use-query-params";

const useAdminAreaLocationsParams = () => {
  const [query, setQuery] = useQueryParams({
    id: NumberParam,
    name: StringParam,
    cityName: StringParam,
    destinationName: StringParam,
  });

  const ret: [typeof query, typeof setQuery] = [query, setQuery];

  return ret;
};

export default useAdminAreaLocationsParams;
