import { useUserCurrencyStore } from "@hotelspoint/store";
import { IconCash } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

import { MenuHeader, MenuItem } from "../../../composites/Menu";
import currencies from "../currencies";
import HeaderMenu from "../HeaderMenu";
import * as S from "./UserCurrency.styled";

const UserCurrency = () => {
  const { t } = useTranslation();

  const { currency, set: setCurrency } = useUserCurrencyStore(state => state);

  return (
    <HeaderMenu menuButton={<IconCash size={22} />}>
      <MenuHeader>{t("header.userCurrency.title")}</MenuHeader>
      {currencies.map(curr => (
        <MenuItem
          key={curr.value}
          disabled={curr.value === currency}
          onClick={() => setCurrency(curr.value)}
        >
          <S.MenuItem>
            <span>{curr.value}</span>
            <span>{t(curr.title)}</span>
          </S.MenuItem>
        </MenuItem>
      ))}
    </HeaderMenu>
  );
};

export default UserCurrency;
