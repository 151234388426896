import { borderRadius, spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";
import { theme } from "styled-tools";

export const Wrapper = styled.div`
  display: flex;
  column-gap: ${spacing(0.5)}px;
`;

export const RadioInput = styled.input`
  box-sizing: border-box;
  width: 14px;
  height: 14px;
  border: 2px solid ${theme("palette.blueBayoux")};
  border-radius: ${borderRadius.circle};
  appearance: none;
  background-color: transparent;
  outline: none;

  &:not(:disabled):checked {
    border-color: ${theme("palette.ocean")};
    background-color: ${theme("palette.ocean")};
    background-clip: content-box;
    padding: 2px;
    background-image: radial-gradient(
      circle,
      ${theme("palette.ocean")} 0%,
      ${theme("palette.ocean")} 50%,
      transparent 60%,
      transparent 100%
    );

    & + span {
      color: ${theme("palette.black")};
    }
  }

  &:disabled {
    background-color: ${theme("disabled.bg")};
  }

  &:disabled:checked {
    background-image: radial-gradient(
      circle,
      ${theme("disabled.text")} 0%,
      ${theme("disabled.text")} 50%,
      transparent 50%,
      transparent 100%
    );
  }

  &:focus-visible {
    outline: 1px solid ${theme("palette.ocean")};
  }
`;
