import {
  handleError,
  PaymentType,
  usePayment,
  useUpdatePayment,
} from "@hotelspoint/api";
import {
  Box,
  Button,
  Chip,
  Flex,
  Form,
  FormAdapter,
  FormContext,
  FormInput,
  FormLayout,
  FormSelect,
} from "@hotelspoint/components";
import {
  PAYMENT_CREDIT_STATUS_OPTIONS,
  PAYMENT_STATUS_OPTIONS,
  PAYMENT_TYPE_OPTIONS,
} from "@hotelspoint/constants";
import {
  getPaymentCreditStatusColor,
  getPaymentStatusColor,
} from "@hotelspoint/utils";
import omit from "lodash/omit";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  defaultValues,
  entity2Form,
  form2Entity,
  FormValues,
} from "./AdminPaymentForm.util";

interface AdminPaymentFormProps {
  id: number;
}

const AdminPaymentForm = ({ id }: AdminPaymentFormProps) => {
  const { t } = useTranslation();

  const [payment, isLoadingPayment] = usePayment(id);
  const [updatePayment, isUpdatingPayment] = useUpdatePayment(id);

  const isCreditLinePayment = payment?.type === PaymentType.CreditLine;

  const formValues = useMemo(() => {
    if (!payment) return defaultValues;

    return entity2Form(payment);
  }, [payment]);

  const typeOptions = useMemo(
    () =>
      PAYMENT_TYPE_OPTIONS.map(type => ({
        ...type,
        label: t(type.label),
      })),
    [t],
  );

  const statusOptions = useMemo(
    () =>
      PAYMENT_STATUS_OPTIONS.map(status => ({
        ...status,
        label: (
          <Chip $color={getPaymentStatusColor(status.value)}>
            {t(status.label)}
          </Chip>
        ),
      })),
    [t],
  );

  const creditStatusOptions = useMemo(
    () =>
      PAYMENT_CREDIT_STATUS_OPTIONS.map(status => ({
        ...status,
        label: (
          <Chip $color={getPaymentCreditStatusColor(status.value)}>
            {t(status.label)}
          </Chip>
        ),
      })),
    [t],
  );

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        const payload = form2Entity(formValues);

        if (isCreditLinePayment) {
          await updatePayment(payload);
        } else {
          await updatePayment(omit(payload, "creditStatus"));
        }

        await updatePayment(payload);
      } catch (error: any) {
        handleError({ t, error });
      }
    },
    [t, isCreditLinePayment, updatePayment],
  );

  if (isLoadingPayment) {
    return null;
  }

  return (
    <Form defaultValues={formValues}>
      <FormLayout>
        <Flex mx={[0, 0, -1, -1]}>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter name="id" label={t("adminPayment.form.id.label")}>
              {props => <FormInput {...props} readOnly />}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="reservationId"
              label={t("adminPayment.form.reservationId.label")}
            >
              {props => <FormInput {...props} readOnly />}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="amount"
              label={t("adminPayment.form.amount.label")}
            >
              {props => (
                <FormInput
                  {...props}
                  readOnly
                  adornmentStyle="filled"
                  endAdornment={payment?.currency}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter name="type" label={t("adminPayment.form.type.label")}>
              {props => (
                <FormSelect {...props} options={typeOptions} readOnly />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="agencyName"
              label={t("adminPayment.form.agencyName.label")}
            >
              {props => <FormInput {...props} readOnly />}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="status"
              label={t("adminPayment.form.status.label")}
            >
              {props => <FormSelect {...props} options={statusOptions} />}
            </FormAdapter>
          </Box>
          {isCreditLinePayment && (
            <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
              <FormAdapter
                name="creditStatus"
                label={t("adminPayment.form.creditStatus.label")}
              >
                {props => (
                  <FormSelect {...props} options={creditStatusOptions} />
                )}
              </FormAdapter>
            </Box>
          )}
        </Flex>
        <FormContext<FormValues>
          render={({ handleSubmit }) => (
            <Button
              type="submit"
              variant="secondary"
              isLoading={isUpdatingPayment}
              onClick={handleSubmit(onSubmit)}
            >
              {t("adminPayment.form.submit")}
            </Button>
          )}
        />
      </FormLayout>
    </Form>
  );
};

export default AdminPaymentForm;
