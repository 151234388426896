import "@szhsin/react-menu/dist/core.css";

import {
  ControlledMenu as ControlledMenuComponent,
  Menu as MenuComponent,
  MenuDivider as MenuDividerComponent,
  MenuHeader as MenuHeaderComponent,
  MenuItem as MenuItemComponent,
  SubMenu as SubMenuComponent,
} from "@szhsin/react-menu";
import styled from "styled-components";

import baseStyles from "./Menu.styled";

export const Menu = styled(MenuComponent)`
  ${baseStyles};
`;

export const ControlledMenu = styled(ControlledMenuComponent)`
  ${baseStyles};
`;

export const SubMenu = SubMenuComponent;
export const MenuHeader = MenuHeaderComponent;
export const MenuItem = MenuItemComponent;
export const MenuDivider = MenuDividerComponent;

export default Menu;
