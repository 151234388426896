import { formatDateIso, isNumeric } from "@hotelspoint/utils";
import { boolean, date, InferType, number, object, string } from "yup";

export const validationSchema = object({
  id: string(),
  dateFrom: date(),
  dateTo: date(),
  status: number(),
  deadlineFrom: date(),
  deadlineTo: date(),
  agencyId: object({
    value: number(),
    label: string(),
  }).nullable(),
  userId: object({
    value: number(),
    label: string(),
  }).nullable(),
  supplierId: number(),
  cityName: string(),
  checkInFrom: date(),
  checkInTo: date(),
  name: string(),
  checkOutFrom: date(),
  checkOutTo: date(),
  priceFrom: number(),
  priceTo: number(),
  paymentStatus: number(),
  messageFilter: number(),
  type: number(),
  confirmed: boolean(),
  orderBy: number(),
  assignee: number(),
  hotelConfirmationStatus: number(),
  supplierConfirmationStatus: number(),
  confirmationOutboundTransferStatus: number(),
  confirmationReturnTransferStatus: number(),
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: FormValues = {
  id: "",
  dateFrom: undefined,
  dateTo: undefined,
  status: undefined,
  deadlineFrom: undefined,
  deadlineTo: undefined,
  agencyId: {},
  userId: {},
  supplierId: undefined,
  cityName: "",
  checkInFrom: undefined,
  checkInTo: undefined,
  name: "",
  checkOutFrom: undefined,
  checkOutTo: undefined,
  priceFrom: undefined,
  priceTo: undefined,
  paymentStatus: undefined,
  messageFilter: undefined,
  type: undefined,
  confirmed: undefined,
  orderBy: undefined,
  assignee: undefined,
  hotelConfirmationStatus: undefined,
  supplierConfirmationStatus: undefined,
  confirmationOutboundTransferStatus: undefined,
  confirmationReturnTransferStatus: undefined,
};

export const entity2Form = (query: any): FormValues => {
  return {
    id: query.id,
    dateFrom: query.dateFrom ? new Date(query.dateFrom) : undefined,
    dateTo: query.dateTo ? new Date(query.dateTo) : undefined,
    status: query.status,
    deadlineFrom: query.deadlineFrom ? new Date(query.deadlineFrom) : undefined,
    deadlineTo: query.deadlineTo ? new Date(query.deadlineTo) : undefined,
    agencyId: query.agencyId
      ? {
          value: query.agencyId,
          label: query.agencyName,
        }
      : null,
    userId: query.userId
      ? {
          value: query.userId,
          label: query.userName,
        }
      : null,
    supplierId: query.supplierId,
    cityName: query.cityName,
    checkInFrom: query.checkInFrom ? new Date(query.checkInFrom) : undefined,
    checkInTo: query.checkInTo ? new Date(query.checkInTo) : undefined,
    name: query.name,
    checkOutFrom: query.checkOutFrom ? new Date(query.checkOutFrom) : undefined,
    checkOutTo: query.checkOutTo ? new Date(query.checkOutTo) : undefined,
    priceFrom: query.priceFrom,
    priceTo: query.priceTo,
    paymentStatus: query.paymentStatus,
    messageFilter: query.messageFilter,
    type: query.type,
    confirmed: query.confirmed,
    orderBy: query.orderBy,
    assignee: query.assignee,
    hotelConfirmationStatus: query.hotelConfirmationStatus,
    supplierConfirmationStatus: query.supplierConfirmationStatus,
    confirmationOutboundTransferStatus:
      query.confirmationOutboundTransferStatus,
    confirmationReturnTransferStatus: query.confirmationReturnTransferStatus,
  };
};

export const form2Entity = (formValues: FormValues) => {
  return {
    id: isNumeric(formValues.id) ? Number(formValues.id) : undefined,
    dateFrom: formValues.dateFrom
      ? formatDateIso(formValues.dateFrom)
      : undefined,
    dateTo: formValues.dateTo ? formatDateIso(formValues.dateTo) : undefined,
    status: formValues.status,
    deadlineFrom: formValues.deadlineFrom
      ? formatDateIso(formValues.deadlineFrom)
      : undefined,
    deadlineTo: formValues.deadlineTo
      ? formatDateIso(formValues.deadlineTo)
      : undefined,
    agencyId: formValues.agencyId?.value,
    agencyName: formValues.agencyId?.label,
    userId: formValues.userId?.value,
    userName: formValues.userId?.label,
    supplierId: formValues.supplierId,
    cityName: formValues.cityName,
    checkInFrom: formValues.checkInFrom
      ? formatDateIso(formValues.checkInFrom)
      : undefined,
    checkInTo: formValues.checkInTo
      ? formatDateIso(formValues.checkInTo)
      : undefined,
    name: formValues.name,
    checkOutFrom: formValues.checkOutFrom
      ? formatDateIso(formValues.checkOutFrom)
      : undefined,
    checkOutTo: formValues.checkOutTo
      ? formatDateIso(formValues.checkOutTo)
      : undefined,
    priceFrom: formValues.priceFrom,
    priceTo: formValues.priceTo,
    paymentStatus: formValues.paymentStatus,
    messageFilter: formValues.messageFilter,
    type: formValues.type,
    confirmed: formValues.confirmed,
    orderBy: formValues.orderBy,
    assignee: formValues.assignee,
    hotelConfirmationStatus: formValues.hotelConfirmationStatus,
    supplierConfirmationStatus: formValues.supplierConfirmationStatus,
    confirmationOutboundTransferStatus:
      formValues.confirmationOutboundTransferStatus,
    confirmationReturnTransferStatus:
      formValues.confirmationReturnTransferStatus,
  };
};
