import { borderRadius, spacing } from "@hotelspoint/theme";
import styled from "styled-components";
import { prop, theme } from "styled-tools";

import Paper from "../../elements/Paper";

export const Wrapper = styled(Paper)`
  display: flex;
  width: 300px;
  column-gap: ${spacing(1.5)}px;
  border-radius: ${borderRadius.medium}px;
  padding: ${spacing(1)}px;
`;

export const ThumbnailWrapper = styled.div`
  min-width: 65px;
  width: 65px;
  height: 65px;
  border-radius: ${borderRadius.medium}px;
  background: ${theme("primary.bg")};
  overflow: hidden;
`;

export const Thumbnail = styled.div<{ $src: string }>`
  width: 100%;
  height: 65px;
  background: url(${prop("$src")}) no-repeat;
  background-size: cover;
  background-position: center center;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const HeaderTitle = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing(0.5)}px;
`;

export const Footer = styled.div`
  align-self: flex-end;
`;
