import { formatDateIso, isNumeric } from "@hotelspoint/utils";
import { boolean, date, InferType, number, object, string } from "yup";

export const validationSchema = object({
  id: string(),
  reservationId: string(),
  priority: number(),
  type: string(),
  status: number(),
  assigneeId: string(),
  dateFrom: date(),
  dateTo: date(),
  alert: boolean(),
  checkInFrom: date(),
  checkInTo: date(),
  seen: boolean(),
  reservationStatus: number(),
  reservationConfirmed: boolean(),
  orderBy: number(),
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: FormValues = {
  id: "",
  reservationId: "",
  priority: undefined,
  type: "",
  status: undefined,
  assigneeId: "",
  dateFrom: undefined,
  dateTo: undefined,
  alert: undefined,
  checkInFrom: undefined,
  checkInTo: undefined,
  seen: undefined,
  reservationStatus: undefined,
  reservationConfirmed: undefined,
  orderBy: undefined,
};

export const entity2Form = (query: any): FormValues => {
  return {
    id: query.id,
    reservationId: query.reservationId,
    priority: query.priority,
    type: query.type,
    status: query.status,
    assigneeId: query.assigneeId,
    dateFrom: query.dateFrom ? new Date(query.dateFrom) : undefined,
    dateTo: query.dateTo ? new Date(query.dateTo) : undefined,
    alert: query.alert,
    checkInFrom: query.checkInFrom ? new Date(query.checkInFrom) : undefined,
    checkInTo: query.checkInTo ? new Date(query.checkInTo) : undefined,
    seen: query.seen,
    reservationStatus: query.reservationStatus,
    reservationConfirmed: query.reservationConfirmed,
    orderBy: query.orderBy,
  };
};

export const form2Entity = (formValues: FormValues) => {
  return {
    id: isNumeric(formValues.id) ? Number(formValues.id) : undefined,
    reservationId: isNumeric(formValues.reservationId)
      ? Number(formValues.reservationId)
      : undefined,
    priority: formValues.priority,
    type: formValues.type,
    status: formValues.status,
    assigneeId: formValues.assigneeId,
    dateFrom: formValues.dateFrom
      ? formatDateIso(formValues.dateFrom)
      : undefined,
    dateTo: formValues.dateTo ? formatDateIso(formValues.dateTo) : undefined,
    alert: formValues.alert,
    checkInFrom: formValues.checkInFrom
      ? formatDateIso(formValues.checkInFrom)
      : undefined,
    checkInTo: formValues.checkInTo
      ? formatDateIso(formValues.checkInTo)
      : undefined,
    seen: formValues.seen,
    reservationStatus: formValues.reservationStatus,
    reservationConfirmed: formValues.reservationConfirmed,
    orderBy: formValues.orderBy,
  };
};
