import { HotelRateResponse } from "@hotelspoint/api";
import sumBy from "lodash/sumBy";

export const totalPrice = (hotelRateData?: HotelRateResponse) => {
  return sumBy(hotelRateData?.rooms, "price");
};

export const totalPriceNet = (hotelRateData?: HotelRateResponse) => {
  return sumBy(hotelRateData?.rooms, "priceNet");
};
