import { formatDateIso, isNumeric } from "@hotelspoint/utils";
import { date, InferType, number, object, string } from "yup";

export const validationSchema = object({
  id: string(),
  userId: object({
    value: number(),
    label: string(),
  }).nullable(),
  dateFrom: date(),
  dateTo: date(),
  type: number(),
  paymentId: string(),
  reservationId: number(),
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: FormValues = {
  id: "",
  userId: {},
  dateFrom: undefined,
  dateTo: undefined,
  type: undefined,
  paymentId: "",
  reservationId: undefined,
};

export const entity2Form = (query: any): FormValues => {
  return {
    id: query.id,
    userId: query.userId
      ? {
          value: query.userId,
          label: query.userName,
        }
      : null,
    dateFrom: query.dateFrom ? new Date(query.dateFrom) : undefined,
    dateTo: query.dateTo ? new Date(query.dateTo) : undefined,
    type: query.type,
    paymentId: query.paymentId,
    reservationId: query.reservationId,
  };
};

export const form2Entity = (formValues: FormValues) => {
  return {
    id: isNumeric(formValues.id) ? Number(formValues.id) : undefined,
    userId: formValues.userId?.value,
    userName: formValues.userId?.label,
    dateFrom: formValues.dateFrom
      ? formatDateIso(formValues.dateFrom)
      : undefined,
    dateTo: formValues.dateTo ? formatDateIso(formValues.dateTo) : undefined,
    type: formValues.type,
    paymentId: isNumeric(formValues.paymentId)
      ? Number(formValues.paymentId)
      : undefined,
    reservationId: isNumeric(formValues.reservationId)
      ? Number(formValues.reservationId)
      : undefined,
  };
};
