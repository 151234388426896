import { AdminSupplierStatementPayment } from "@hotelspoint/api";
import {
  ButtonDelete,
  Chip,
  ConfirmDialog,
  StatusFlavour,
  TABLE_COLUMN_SIZE,
  TableCell,
} from "@hotelspoint/components";
import {
  formatDate,
  getPaymentProviderNameById,
  getPaymentStatusColor,
  getPaymentStatusName,
  withCurrency,
} from "@hotelspoint/utils";
import { createColumnHelper } from "@tanstack/react-table";
import { TFunction } from "i18next";

const columnHelper = createColumnHelper<AdminSupplierStatementPayment>();

interface GetColumnsParams {
  t: TFunction;
  targetId: number | null;
  handleDelete: (id: number) => void;
  isDeleting: boolean;
}

const getColumns = ({
  t,
  targetId,
  handleDelete,
  isDeleting,
}: GetColumnsParams) => [
  columnHelper.accessor("dateCreated", {
    header: t("adminSupplierStatement.payments.columns.dateCreated"),
    size: TABLE_COLUMN_SIZE.DATE,
    cell: props => formatDate(props.getValue()),
  }),
  columnHelper.accessor("amount", {
    header: t("adminSupplierStatement.payments.columns.amount"),
    size: TABLE_COLUMN_SIZE.AMOUNT,
    cell: props => withCurrency(props.getValue()),
  }),
  columnHelper.accessor("status", {
    header: t("adminSupplierStatement.payments.columns.status"),
    size: TABLE_COLUMN_SIZE.STATUS,
    cell: props => {
      const status = props.getValue();

      return (
        <Chip $color={getPaymentStatusColor(status)}>
          {t(getPaymentStatusName(status))}
        </Chip>
      );
    },
  }),
  columnHelper.accessor("provider", {
    header: t("adminSupplierStatement.payments.columns.provider"),
    size: TABLE_COLUMN_SIZE.NAME,
    cell: props => {
      const providerId = props.getValue();

      return getPaymentProviderNameById(providerId);
    },
  }),
  columnHelper.accessor("userName", {
    header: t("adminSupplierStatement.payments.columns.userName"),
    size: TABLE_COLUMN_SIZE.NAME,
  }),
  columnHelper.display({
    id: "actions",
    size: TABLE_COLUMN_SIZE.ACTION,
    cell: props => {
      const id = props.row.original.statementSupplierPaymentId;
      const isTarget = id === targetId;

      return (
        <TableCell>
          <ConfirmDialog
            title={t(
              "adminSupplierStatement.payments.columns.actions.delete.modal.title",
            )}
            description={t(
              "adminSupplierStatement.payments.columns.actions.delete.modal.description",
            )}
            onConfirm={() => handleDelete(id)}
            isLoading={isTarget && isDeleting}
            status={StatusFlavour.Error}
          >
            <ButtonDelete />
          </ConfirmDialog>
        </TableCell>
      );
    },
  }),
];

export default getColumns;
