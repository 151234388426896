import { useMutation } from "@tanstack/react-query";

import {
  ReservationService,
  ReservationUpdateAssigneePayload,
} from "../../services";

const useUpdateReservationAssignee = (id: number) => {
  const mutation = useMutation({
    mutationKey: ["reservations", "update_assignee"],
    mutationFn: (payload: ReservationUpdateAssigneePayload) => {
      return ReservationService.updateAssignee(id, payload);
    },
  });

  const { mutateAsync, isPending } = mutation;
  const ret: [typeof mutateAsync, typeof isPending] = [mutateAsync, isPending];

  return ret;
};

export default useUpdateReservationAssignee;
