import { formatDateIso, isNumeric } from "@hotelspoint/utils";
import { date, InferType, object, string } from "yup";

export const validationSchema = object({
  reservationId: string(),
  dateFrom: date(),
  dateTo: date(),
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: FormValues = {
  reservationId: "",
  dateFrom: undefined,
  dateTo: undefined,
};

export const entity2Form = (query: any): FormValues => {
  return {
    reservationId: query.reservationId,
    dateFrom: query.dateFrom ? new Date(query.dateFrom) : undefined,
    dateTo: query.registerTo ? new Date(query.dateTo) : undefined,
  };
};

export const form2Entity = (formValues: FormValues) => {
  return {
    reservationId: isNumeric(formValues.reservationId)
      ? Number(formValues.reservationId)
      : undefined,
    dateFrom: formValues.dateFrom
      ? formatDateIso(formValues.dateFrom)
      : undefined,
    dateTo: formValues.dateTo ? formatDateIso(formValues.dateTo) : undefined,
  };
};
