import { Controller, useFormContext } from "react-hook-form";

import FormControl, {
  type FormControlInjectedProps,
  FormControlProps,
} from "./FormControl";

interface FormAdapterProps {
  name: string;
  label?: string | React.ReactNode;
  children: (props: FormControlInjectedProps) => React.ReactNode;
  formControlProps?: Partial<FormControlProps>;
}

const FormAdapter = ({
  name,
  label,
  children,
  formControlProps,
}: FormAdapterProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { name, value, onChange }, fieldState: { error } }) => (
        <FormControl
          name={name}
          value={value}
          error={error?.message}
          onChange={onChange}
          label={label}
          {...formControlProps}
        >
          {children}
        </FormControl>
      )}
    />
  );
};

export default FormAdapter;
