import { borderRadius, mediaQuery, spacing, zIndex } from "@hotelspoint/theme";
import styled from "styled-components";
import { theme } from "styled-tools";

export const Wrapper = styled.div`
  z-index: ${zIndex.cookieConsent};
  position: fixed;
  bottom: ${spacing(1)}px;
  left: ${spacing(1)}px;
  right: ${spacing(1)}px;
  padding: ${spacing(1)}px ${spacing(3.5)}px ${spacing(1)}px ${spacing(1)}px;
  color: white;
  font-size: 0.9rem;
  background: ${theme("palette.royalBlue")};
  border-radius: ${borderRadius.normal}px;

  a {
    color: white;
    font-weight: 600;
    text-decoration: underline;

    &:hover {
      color: white;
    }

    &::after {
      display: none;
    }

    @media ${mediaQuery.tablet} {
      text-decoration: none;

      &::after {
        display: block;
        background: white;
      }
    }
  }

  ${({ theme }) => `
    @media only screen and (min-width: ${theme.custom.container.maxWidth}px) {
      width: 100%;
      max-width: calc(${theme.custom.container.maxWidth}px - 80px);
      bottom: ${spacing(1.5)}px;
      left: 50%;
      transform: translateX(-50%);
      padding-right: ${spacing(4.5)}px;
    }
  `};
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: ${spacing(1)}px;
  color: white;
`;
