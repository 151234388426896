export const CHIP_COLORS = {
  SUPPLIER: "#47B7A3",
  // Base colors
  RED: "#FF9999",
  ORANGE: "#FFA500",
  YELLOW: "#FFCC66",
  GREEN: "#99CC99",
  BLUE: "#6699CC",
  PURPLE: "#9967FE",
  GREY: "#D3D3D3",
  DARK_GREY: "#A9A9A9",
};
