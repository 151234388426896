import { formatDateIso, isNumeric } from "@hotelspoint/utils";
import { boolean, date, InferType, number, object, string } from "yup";

export const validationSchema = object({
  id: string(),
  name: string(),
  email: string(),
  agency: object({
    value: number(),
    label: string(),
  }).nullable(),
  roleId: number(),
  registerFrom: date(),
  registerTo: date(),
  isActive: boolean(),
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: FormValues = {
  id: "",
  name: "",
  email: "",
  agency: {},
  roleId: undefined,
  registerFrom: undefined,
  registerTo: undefined,
  isActive: undefined,
};

export const entity2Form = (query: any): FormValues => {
  return {
    id: query.id,
    name: query.name,
    email: query.email,
    agency: query.agencyId
      ? {
          value: query.agencyId,
          label: query.agencyName,
        }
      : null,
    roleId: query.roleId,
    registerFrom: query.registerFrom ? new Date(query.registerFrom) : undefined,
    registerTo: query.registerTo ? new Date(query.registerTo) : undefined,
    isActive: query.isActive,
  };
};

export const form2Entity = (formValues: FormValues) => {
  return {
    id: isNumeric(formValues.id) ? Number(formValues.id) : undefined,
    name: formValues.name,
    email: formValues.email,
    agencyId: formValues.agency?.value,
    agencyName: formValues.agency?.label,
    roleId: formValues.roleId,
    registerFrom: formValues.registerFrom
      ? formatDateIso(formValues.registerFrom)
      : undefined,
    registerTo: formValues.registerTo
      ? formatDateIso(formValues.registerTo)
      : undefined,
    isActive: formValues.isActive,
  };
};
