import isPropValid from "@emotion/is-prop-valid";

// Extend the default v5 styled-components functionality
function shouldForwardProp(propName: string, target: any) {
  if (typeof target === "string") {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }

  // Omit reflexbox props
  if (["m", "y", "mx", "my", "p", "px", "py", "width"].includes(propName)) {
    return false;
  }

  // For other elements, forward all props
  return true;
}

export default shouldForwardProp;
