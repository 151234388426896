import { Card } from "@hotelspoint/components";
import { mediaQuery } from "@hotelspoint/theme";
import styled from "styled-components";

export const HeaderWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const HeaderCard = styled(Card)`
  @media ${mediaQuery.tablet} {
    width: 600px;
  }
`;
