import {
  Invoice,
  InvoiceApprovePayload,
  InvoiceRegeneratePayload,
} from "@hotelspoint/api";
import { InferType, mixed, number, object, string } from "yup";

const FILE_MAX_SIZE = 20971520; // 20MB

const FILE_ALLOWED_TYPES = ["application/pdf"];

export const getValidationSchema = ({
  dateRequired,
}: {
  dateRequired: boolean;
}) => {
  return object({
    id: number(),
    fullNumber: string().nullable(),
    reservationId: number(),
    reservationStatus: number(),
    paymentId: number().nullable(),
    paymentType: number().nullable(),
    status: number(),
    agencyName: string(),
    transactionId: number(),
    amount: number().required("adminInvoice.form.amount.validation.required"),
    dateIssued: dateRequired
      ? string()
          .nullable()
          .required("adminInvoice.form.dateIssued.validation.required")
      : string().nullable(),
    mainInvoiceId: number().nullable(),
    file: mixed<File>()
      .test("size", "adminInvoice.form.file.validation.size", (file?: File) => {
        if (!file) return true;

        return file.size <= FILE_MAX_SIZE;
      })
      .test(
        "format",
        "adminInvoice.form.file.validation.format",
        (file?: File) => {
          if (!file) return true;

          return FILE_ALLOWED_TYPES.includes(file.type);
        },
      )
      .nullable(),
  });
};

export type FormValues = InferType<ReturnType<typeof getValidationSchema>>;

export const defaultValues: Partial<FormValues> = {
  id: undefined,
  fullNumber: undefined,
  reservationId: undefined,
  reservationStatus: undefined,
  paymentId: undefined,
  paymentType: undefined,
  status: undefined,
  agencyName: "",
  amount: undefined,
  dateIssued: "",
  mainInvoiceId: undefined,
  file: null,
};

export const entity2Form = (entity: Invoice): FormValues => ({
  id: entity.id,
  fullNumber: entity.fullNumber,
  reservationId: entity.reservationId,
  reservationStatus: entity.reservationStatus,
  paymentId: entity.paymentId,
  paymentType: entity.paymentType,
  status: entity.status,
  agencyName: entity.agencyName,
  amount: entity.amount,
  dateIssued: entity.dateIssued ?? "",
  mainInvoiceId: entity.mainInvoiceId,
});

export const form2EntityRegenerate = (
  formValues: FormValues,
): InvoiceRegeneratePayload => ({
  amount: formValues.amount,
  dateIssued: formValues.dateIssued as string,
});

export const form2EntityApprove = (
  formValues: FormValues,
): InvoiceApprovePayload => ({
  amount: formValues.amount,
});
