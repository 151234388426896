import {
  Agency,
  ApiResponseSuccessResult,
  ApiResponseSuccessResultList,
  Coordinates,
  HotelRate,
  PaginationOptions,
  PlaceType,
  ReservationType,
} from "@hotelspoint/types";

import { UPLOAD_CONFIG } from "../constants";
import FetchService from "./base/FetchService";
import { PaymentStatus } from "./PaymentService";
import { ReservationStatus } from "./ReservationService";

const ADMIN_API_PATH = "admin";

interface AdminMetricAggregated {
  assigned: number;
  total: number;
}

export interface AdminMetricsResponse extends ApiResponseSuccessResult {
  alertsAll: AdminMetricAggregated;
  alertsSoon: AdminMetricAggregated;
  alertsNear: AdminMetricAggregated;
  messagesAgencyAll: AdminMetricAggregated;
  messagesAgencySoon: AdminMetricAggregated;
  messagesAgencyNear: AdminMetricAggregated;
  messagesHotelAll: AdminMetricAggregated;
  messagesHotelSoon: AdminMetricAggregated;
  messagesHotelNear: AdminMetricAggregated;
  messagesSupplierAll: AdminMetricAggregated;
  messagesSupplierSoon: AdminMetricAggregated;
  messagesSupplierNear: AdminMetricAggregated;
  bookingsLastMinute: AdminMetricAggregated;
  agenciesToday: number;
  agenciesTotal: number;
  agenciesPending: number;
  paymentsToday: number;
  paymentsRefund: number;
  paymentsPending: number;
  bookingsToday: number;
  bookingsTodayConfirmed: number;
  bookingsTodayFailed: number;
  bookingsOnDeadline: number;
  bookingsPendingConfirmation: number;
  bookingsPendingCancellation: number;
  commissionLastMonth: number;
  commissionThisMonth: number;
  commissionFuture: number;
}

export enum AdminCommissionStatus {
  Pending = 1,
  Paid = 2,
}

export interface AdminCommissionListParams extends PaginationOptions {
  id?: number;
  name?: string;
  status?: AdminCommissionStatus;
  dateFrom?: string;
  dateTo?: string;
}

export interface AdminCommission {
  id: number;
  userId: number;
  userName: string;
  amount: number;
  status: AdminCommissionStatus;
  reservationsCount: number;
  dateCreated: string;
}

export interface AdminCommissionUpdatePayload
  extends Pick<AdminCommission, "status"> {}

export interface AdminCommissionItem {
  id: number;
  reservationId: number;
  agencyName: string;
  cityName: string;
  clientName: string;
  price: number;
  priceNet: number;
  commissionAmount: number;
  dateFrom: string;
}

export interface AdminCommissionItemsListParams extends PaginationOptions {}

export type AdminCommissionItemsListResponse =
  ApiResponseSuccessResultList<AdminCommissionItem>;

export type AdminCommissionListResponse =
  ApiResponseSuccessResultList<AdminCommission>;

export enum AdminAgencyStatementStatus {
  Pending = 1,
  Paid = 2,
}

export interface AdminAgencyStatementCreatePayload {
  agencyId: number;
  paymentIds: number[];
}

export interface AdminAgencyStatementUpdatePayload {
  paidIds?: number[];
  notPaidIds?: number[];
}

export interface AdminAgencyStatement {
  id: number;
  agencyId: number;
  agencyName: string;
  amount: number;
  status: AdminAgencyStatementStatus;
  paymentsCount: number;
  dateCreated: string;
  items: AdminAgencyStatementItem[];
}

export interface AdminAgencyStatementListParams extends PaginationOptions {
  id?: number;
  agencyId?: number;
  status?: AdminAgencyStatementStatus;
  dateFrom?: string;
  dateTo?: string;
}

export type AdminAgencyStatementListResponse =
  ApiResponseSuccessResultList<AdminAgencyStatement>;

export type AdminAgencyStatementOptionsResponse = Pick<Agency, "id" | "name">[];

export interface AdminAgencyStatementItem {
  id: number;
  reservationId: number;
  reservationStatus: ReservationStatus;
  status: AdminAgencyStatementStatus;
  agencyId: number;
  agencyName: string;
  amount: number;
  clientName: string;
  voucherCode: string;
  invoiceNumber: number;
  invoiceDate: string;
  dateFrom: string;
  dateTo: string;
  dateDeadline: string;
  itemType: ReservationType;
  itemId: number;
  itemData: HotelRate;
}

export type AdminAgencyStatementItemsResponse = AdminAgencyStatementItem[];

export enum AdminSupplierStatementStatus {
  PendingPayment = 1,
  PartialPayment = 2,
  PaymentCompleted = 3,
}

export interface AdminSupplierStatementLoadReservationsPayload {
  supplierId: string;
  prices: string;
  reservationIds: string;
  voucherCodes: string;
}

export interface AdminSupplierStatementLoadReservationsResponse {
  errors: {
    missing: string[];
    supplier: string[];
    duplicate: string[];
  };
  warnings: {
    status: string[];
    prices: string[];
  };
  reservations: AdminSupplierStatementItem[];
}

export interface AdminSupplierStatementCreatePayload {
  supplierId: string;
  supplierStatementNumber: string;
  reservationIds: string[];
  additionalCharges: number;
  comments: string;
}

export type AdminSupplierStatementUpdatePayload = Pick<
  AdminSupplierStatementCreatePayload,
  "supplierStatementNumber"
>;

export interface AdminSupplierStatementListParams extends PaginationOptions {
  id?: number;
  agencyId?: number;
  status?: AdminSupplierStatementStatus;
  dateFrom?: string;
  dateTo?: string;
  supplierId?: string;
}

export interface AdminSupplierStatementItem {
  itemId: number;
  itemType: ReservationType;
  reservationId: number;
  reservationStatus: ReservationStatus;
  priceBase: number;
  paymentStatus: PaymentStatus;
  clientName: string;
  voucherCode: string;
  dateFrom: string;
  dateTo: string;
  dateDeadline: string;
}

export interface AdminSupplierStatementPayment {
  statementSupplierId: number;
  statementSupplierPaymentId: number;
  provider: number;
  status: PaymentStatus;
  amount: number;
  userId: number;
  userName: string;
  dateSent: string;
  dateCreated: string;
}

export interface AdminSupplierStatement {
  id: number;
  supplierId: string;
  statementNumber: string;
  status: AdminSupplierStatementStatus;
  totalAmount: number;
  payedAmount: number;
  reservationsAmount: number;
  remainingAmount: number;
  additionalCharges: number;
  comments: string;
  dateCreated: string;
  items: AdminSupplierStatementItem[];
  payments: AdminSupplierStatementPayment[];
}

export type AdminSupplierStatementInList = Omit<
  AdminSupplierStatement,
  "items" | "payments"
>;

export type AdminSupplierStatementListResponse =
  ApiResponseSuccessResultList<AdminSupplierStatementInList>;

export interface AdminSupplierStatementCreatePaymentPayload {
  date: string;
  amount: number;
  // Payment status and provider ID
  status: PaymentStatus;
  provider: number;
}

export type AdminSupplierStatementFilesResponse =
  ApiResponseSuccessResultList<string>;

export interface AdminSearchListParams extends PaginationOptions {
  id?: number;
  userId?: number;
  dateFrom?: string;
  dateTo?: string;
  agencyId?: number;
}

export interface AdminSearch {
  id: number;
  userId: number;
  userName: string;
  agencyId: number;
  agencyName: string;
  dateCreated: string;
}

export type AdminSearchListResponse = ApiResponseSuccessResultList<AdminSearch>;

export interface AdminRequest {
  id: number;
  userId: string;
  userName: string;
  recordId: string;
  supplierId: number;
  dateCreated: string;
}

export enum AdminHotelRequestType {
  Search = 1,
  Fees = 2,
  Remarks = 3,
  ReservePrepend = 4,
  ReserveConfirm = 5,
  ReserveAbort = 6,
  ReserveCancel = 7,
  ReserveCheck = 8,
  ReserveFees = 9,
}

export interface AdminHotelRequest extends AdminRequest {
  type: AdminHotelRequestType;
}

export interface AdminHotelRequestListParams extends PaginationOptions {
  id?: number;
  userId?: number;
  userName?: string;
  dateFrom?: string;
  dateTo?: string;
  type?: AdminHotelRequestType;
  supplierId?: number;
  recordId?: string;
}

export type AdminHotelRequestListResponse =
  ApiResponseSuccessResultList<AdminHotelRequest>;

export enum AdminTransferRequestType {
  Search = 1,
  ReservePrepare = 2,
  ReserveConfirm = 3,
  ReserveCancel = 4,
  ReserveStatus = 5,
}

export interface AdminTransferRequest extends AdminRequest {
  type: AdminTransferRequestType;
}

export interface AdminTransferRequestListParams extends PaginationOptions {
  id?: number;
  userId?: number;
  userName?: string;
  dateFrom?: string;
  dateTo?: string;
  type?: AdminTransferRequestType;
  supplierId?: number;
  recordId?: string;
}

export type AdminTransferRequestListResponse =
  ApiResponseSuccessResultList<AdminTransferRequest>;

export enum AdminActivityRequestType {
  Search = 1,
  SearchDetails = 2,
  SearchOptions = 3,
  ReservePrepare = 4,
  ReserveConfirm = 5,
  ReserveCancel = 6,
  ReserveStatus = 7,
}

export interface AdminActivityRequest extends AdminRequest {
  type: AdminActivityRequestType;
}

export interface AdminActivityRequestListParams extends PaginationOptions {
  id?: number;
  userId?: number;
  userName?: string;
  dateFrom?: string;
  dateTo?: string;
  type?: AdminActivityRequestType;
  supplierId?: number;
  recordId?: string;
}

export type AdminActivityRequestListResponse =
  ApiResponseSuccessResultList<AdminActivityRequest>;

export enum AdminPaymentRequestType {
  CardList = 1,
  PaymentRegister = 2,
  PaymentStatus = 3,
  PaymentConfirm = 4,
  PaymentCancel = 5,
  PaymentRefund = 6,
}

export interface AdminPaymentRequest
  extends Omit<AdminRequest, "recordId" | "supplierId"> {
  type: AdminPaymentRequestType;
  reservationId: number;
  paymentId: number;
  providerId: number;
}

export interface AdminPaymentRequestListParams extends PaginationOptions {
  id?: number;
  userId?: number;
  userName?: string;
  dateFrom?: string;
  dateTo?: string;
  type?: AdminPaymentRequestType;
  paymentId?: number;
  reservationId?: number;
}

export type AdminPaymentRequestListResponse =
  ApiResponseSuccessResultList<AdminPaymentRequest>;

export interface AdminLocationUpdatePayload {
  name: string;
}

export interface AdminAreaLocationCreatePayload extends Coordinates {
  name: string;
  isActive: boolean;
}

export type AdminAreaLocationUpdatePayload = Pick<
  AdminAreaLocation,
  "name" | "isActive" | "latitude" | "longitude"
>;

export interface AdminAreaLocationAddItemPayload {
  placeId: number;
  placeType: PlaceType;
}

export interface AdminAreaLocationListParams extends PaginationOptions {
  id?: number;
  name?: string;
  cityName?: string;
  destinationName?: string;
}

interface AdminAreaLocationBase {
  id: number;
  name: string;
  dateCreated: string;
}

export interface AdminAreaLocationItem {
  id: number;
  itemId: number;
  itemName: string;
  type: PlaceType;
}

export interface AdminAreaLocation extends AdminAreaLocationBase, Coordinates {
  items: AdminAreaLocationItem[];
  isActive: boolean;
}

export interface AdminAreaLocationInList extends AdminAreaLocationBase {
  cities: string | null;
  destinations: string | null;
}

export type AdminAreaLocationListResponse =
  ApiResponseSuccessResultList<AdminAreaLocationInList>;

export interface AdminCityLocationUpdatePayload
  extends Pick<AdminCityLocation, "name"> {}

export interface AdminCityLocationListParams extends PaginationOptions {
  id?: number;
  name?: string;
  countryName?: string;
}

export interface AdminCityLocation {
  id: number;
  name: string;
  countryName: string;
  dateCreated: string;
}

export type AdminCityLocationListResponse =
  ApiResponseSuccessResultList<AdminCityLocation>;

export interface AdminAirportLocationListParams extends PaginationOptions {
  id?: number;
  name?: string;
  countryName?: string;
}

export interface AdminAirportLocationUpdatePayload
  extends Pick<AdminAirportLocation, "name"> {}

export interface AdminAirportLocation {
  id: number;
  name: string;
  code: string;
  countryName: string;
  dateCreated: string;
}

export type AdminAirportLocationListResponse =
  ApiResponseSuccessResultList<AdminAirportLocation>;

export interface AdminDestinationLocationListParams extends PaginationOptions {
  id?: number;
  name?: string;
  countryName?: string;
}

export interface AdminDestinationLocationUpdatePayload
  extends Pick<AdminDestinationLocation, "name"> {}

export interface AdminDestinationLocation {
  id: number;
  name: string;
  countryName: string;
  dateCreated: string;
}

export type AdminDestinationLocationListResponse =
  ApiResponseSuccessResultList<AdminDestinationLocation>;

export interface AdminMealListParams extends Partial<PaginationOptions> {
  id?: number;
  name?: string;
  mainName?: string;
  isMapped?: boolean;
  // Used for the list of options when mapping a meal
  isMain?: boolean;
}

export interface AdminMeal {
  id: number;
  name: string;
  mainId: number;
  mainName: string;
  isMain: boolean;
  dateCreated: string;
}

export type AdminMealListResponse = ApiResponseSuccessResultList<AdminMeal>;

export type AdminMapMealToMainPayload = Pick<AdminMeal, "mainId">;

export interface AdminCurrencyListParams extends PaginationOptions {}

export interface AdminCurrency {
  id: number;
  code: string;
  name: string;
  rate: number;
}

export type AdminCurrencyListResponse =
  ApiResponseSuccessResultList<AdminCurrency>;

export type AdminCurrencyUpdatePayload = Pick<AdminCurrency, "rate">;

const AdminService = {
  getMetrics: () => {
    return FetchService.get<AdminMetricsResponse>(`${ADMIN_API_PATH}/overview`);
  },
  // Commissions
  getCommission: (id: number) => {
    return FetchService.get<AdminCommission>(
      `${ADMIN_API_PATH}/commissions/${id}`,
    );
  },
  updateCommission: (id: number, payload: AdminCommissionUpdatePayload) => {
    return FetchService.patch<ApiResponseSuccessResult>(
      `${ADMIN_API_PATH}/commissions/${id}`,
      payload,
    );
  },
  getCommissionItems: (id: number, params: AdminCommissionItemsListParams) => {
    return FetchService.get<AdminCommissionItemsListResponse>(
      `${ADMIN_API_PATH}/commissions/${id}/items`,
      params,
    );
  },
  getCommissions: (params?: AdminCommissionListParams) => {
    return FetchService.get<AdminCommissionListResponse>(
      `${ADMIN_API_PATH}/commissions`,
      params,
    );
  },
  // Agency statements
  createAgencyStatement: (payload: AdminAgencyStatementCreatePayload) => {
    return FetchService.post<AdminAgencyStatement>(
      `${ADMIN_API_PATH}/agencyStatements`,
      payload,
    );
  },
  updateAgencyStatement: (
    id: number,
    payload: AdminAgencyStatementUpdatePayload,
  ) => {
    return FetchService.patch<ApiResponseSuccessResult>(
      `${ADMIN_API_PATH}/agencyStatements/${id}`,
      payload,
    );
  },
  getAgencyStatement: (id: number) => {
    return FetchService.get<AdminAgencyStatement>(
      `${ADMIN_API_PATH}/agencyStatements/${id}`,
    );
  },
  getAgencyStatements: (params?: AdminAgencyStatementListParams) => {
    return FetchService.get<AdminAgencyStatementListResponse>(
      `${ADMIN_API_PATH}/agencyStatements`,
      params,
    );
  },
  getAgencyStatementOptions: () => {
    return FetchService.get<AdminAgencyStatementOptionsResponse>(
      `${ADMIN_API_PATH}/agencyStatements/agencies`,
    );
  },
  getAgencyStatementItems: (agencyId: number) => {
    return FetchService.get<AdminAgencyStatementItemsResponse>(
      `${ADMIN_API_PATH}/agencyStatements/agencies/${agencyId}/items`,
    );
  },
  // Supplier statements
  loadSupplierStatementReservations: (
    payload: AdminSupplierStatementLoadReservationsPayload,
  ) => {
    return FetchService.post<AdminSupplierStatementLoadReservationsResponse>(
      `${ADMIN_API_PATH}/supplierStatements/reservations`,
      payload,
    );
  },
  createSupplierStatement: (payload: AdminSupplierStatementCreatePayload) => {
    return FetchService.post<AdminSupplierStatement>(
      `${ADMIN_API_PATH}/supplierStatements`,
      payload,
    );
  },
  updateSupplierStatement: (
    id: number,
    payload: AdminSupplierStatementUpdatePayload,
  ) => {
    return FetchService.patch<ApiResponseSuccessResult>(
      `${ADMIN_API_PATH}/supplierStatements/${id}`,
      payload,
    );
  },
  deleteSupplierStatement: (id: number) => {
    return FetchService.delete<ApiResponseSuccessResult>(
      `${ADMIN_API_PATH}/supplierStatements/${id}`,
    );
  },
  getSupplierStatement: (id: number) => {
    return FetchService.get<AdminSupplierStatement>(
      `${ADMIN_API_PATH}/supplierStatements/${id}`,
    );
  },

  createSupplierStatementPayment: (
    id: number,
    payload: AdminSupplierStatementCreatePaymentPayload,
  ) => {
    return FetchService.post<ApiResponseSuccessResult>(
      `${ADMIN_API_PATH}/supplierStatements/${id}/payments`,
      payload,
    );
  },
  deleteSupplierStatementPayment: (id: number, paymentId: number) => {
    return FetchService.delete<ApiResponseSuccessResult>(
      `${ADMIN_API_PATH}/supplierStatements/${id}/payments/${paymentId}`,
    );
  },
  uploadSupplierStatementFile: (id: number, payload: FormData) => {
    return FetchService.post<ApiResponseSuccessResult>(
      `${ADMIN_API_PATH}/supplierStatements/${id}/files/upload`,
      payload,
      undefined,
      UPLOAD_CONFIG,
    );
  },
  deleteSupplierStatementFile: (id: number, name: string) => {
    return FetchService.delete<ApiResponseSuccessResult>(
      `${ADMIN_API_PATH}/supplierStatements/${id}/files/${name}/delete`,
    );
  },
  getSupplierStatementFiles: (id: number) => {
    return FetchService.get<AdminSupplierStatementFilesResponse>(
      `${ADMIN_API_PATH}/supplierStatements/${id}/files`,
    );
  },
  getSupplierStatements: (params?: AdminSupplierStatementListParams) => {
    return FetchService.get<AdminSupplierStatementListResponse>(
      `${ADMIN_API_PATH}/supplierStatements`,
      params,
    );
  },
  // Searches
  getHotelSearches: (params?: AdminSearchListParams) => {
    return FetchService.get<AdminSearchListResponse>(
      `${ADMIN_API_PATH}/searches/hotels`,
      params,
    );
  },
  getTransferSearches: (params?: AdminSearchListParams) => {
    return FetchService.get<AdminSearchListResponse>(
      `${ADMIN_API_PATH}/searches/transfers`,
      params,
    );
  },
  getActivitySearches: (params?: AdminSearchListParams) => {
    return FetchService.get<AdminSearchListResponse>(
      `${ADMIN_API_PATH}/searches/activities`,
      params,
    );
  },
  // Requests
  getHotelRequests: (params?: AdminHotelRequestListParams) => {
    return FetchService.get<AdminHotelRequestListResponse>(
      `${ADMIN_API_PATH}/requests/hotels`,
      params,
    );
  },
  getTransferRequests: (params?: AdminTransferRequestListParams) => {
    return FetchService.get<AdminTransferRequestListResponse>(
      `${ADMIN_API_PATH}/requests/transfers`,
      params,
    );
  },
  getActivityRequests: (params?: AdminActivityRequestListParams) => {
    return FetchService.get<AdminActivityRequestListResponse>(
      `${ADMIN_API_PATH}/requests/activities`,
      params,
    );
  },
  getPaymentRequests: (params?: AdminPaymentRequestListParams) => {
    return FetchService.get<AdminPaymentRequestListResponse>(
      `${ADMIN_API_PATH}/requests/payments`,
      params,
    );
  },
  // Locations
  createAreaLocation: (payload: AdminAreaLocationCreatePayload) => {
    return FetchService.post<ApiResponseSuccessResult>(
      `${ADMIN_API_PATH}/areas`,
      payload,
    );
  },
  getAreaLocation: (id: number) => {
    return FetchService.get<AdminAreaLocation>(`${ADMIN_API_PATH}/areas/${id}`);
  },
  updateAreaLocation: (id: number, payload: AdminAreaLocationUpdatePayload) => {
    return FetchService.patch<ApiResponseSuccessResult>(
      `${ADMIN_API_PATH}/areas/${id}`,
      payload,
    );
  },
  deleteAreaLocation: (id: number) => {
    return FetchService.delete<ApiResponseSuccessResult>(
      `${ADMIN_API_PATH}/areas/${id}`,
    );
  },
  getAreaLocations: (params?: AdminAreaLocationListParams) => {
    return FetchService.get<AdminAreaLocationListResponse>(
      `${ADMIN_API_PATH}/areas`,
      params,
    );
  },
  addAreaLocationItem: (
    id: number,
    payload: AdminAreaLocationAddItemPayload,
  ) => {
    return FetchService.post<ApiResponseSuccessResult>(
      `${ADMIN_API_PATH}/areas/${id}/items`,
      payload,
    );
  },
  deleteAreaLocationItem: (id: number, itemId: number) => {
    return FetchService.delete<ApiResponseSuccessResult>(
      `${ADMIN_API_PATH}/areas/${id}/items/${itemId}`,
    );
  },
  getCityLocation: (id: number) => {
    return FetchService.get<AdminCityLocation>(
      `${ADMIN_API_PATH}/cities/${id}`,
    );
  },
  updateCityLocation: (id: number, payload: AdminLocationUpdatePayload) => {
    return FetchService.patch<ApiResponseSuccessResult>(
      `${ADMIN_API_PATH}/cities/${id}`,
      payload,
    );
  },
  getCityLocations: (params?: AdminCityLocationListParams) => {
    return FetchService.get<AdminCityLocationListResponse>(
      `${ADMIN_API_PATH}/cities`,
      params,
    );
  },
  getAirportLocation: (id: number) => {
    return FetchService.get<AdminAirportLocation>(
      `${ADMIN_API_PATH}/airports/${id}`,
    );
  },
  updateAirportLocation: (id: number, payload: AdminLocationUpdatePayload) => {
    return FetchService.patch<ApiResponseSuccessResult>(
      `${ADMIN_API_PATH}/airports/${id}`,
      payload,
    );
  },
  getAirportLocations: (params?: AdminAirportLocationListParams) => {
    return FetchService.get<AdminAirportLocationListResponse>(
      `${ADMIN_API_PATH}/airports`,
      params,
    );
  },
  getDestinationLocation: (id: number) => {
    return FetchService.get<AdminDestinationLocation>(
      `${ADMIN_API_PATH}/destinations/${id}`,
    );
  },
  updateDestinationLocation: (
    id: number,
    payload: AdminLocationUpdatePayload,
  ) => {
    return FetchService.patch<ApiResponseSuccessResult>(
      `${ADMIN_API_PATH}/destinations/${id}`,
      payload,
    );
  },
  getDestinationLocations: (params?: AdminDestinationLocationListParams) => {
    return FetchService.get<AdminDestinationLocationListResponse>(
      `${ADMIN_API_PATH}/destinations`,
      params,
    );
  },
  // Configuration
  getMeals: (params?: AdminMealListParams) => {
    return FetchService.get<AdminMealListResponse>(
      `${ADMIN_API_PATH}/meals`,
      params,
    );
  },
  mapMealToMain: (id: number, payload: AdminMapMealToMainPayload) => {
    return FetchService.post<ApiResponseSuccessResult>(
      `${ADMIN_API_PATH}/meals/${id}/map`,
      payload,
    );
  },
  getCurrencies: (params?: AdminCurrencyListParams) => {
    return FetchService.get<AdminCurrencyListResponse>(
      `${ADMIN_API_PATH}/currencies`,
      params,
    );
  },
  updateCurrencyRate: (id: number, payload: AdminCurrencyUpdatePayload) => {
    return FetchService.post<ApiResponseSuccessResult>(
      `${ADMIN_API_PATH}/currencies/${id}`,
      payload,
    );
  },
};

export default AdminService;
