import { borderRadius, pxToRem, spacing } from "@hotelspoint/theme";
import styled from "styled-components";
import { prop, theme } from "styled-tools";

export const Wrapper = styled.div<{ $size: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  min-width: ${prop("$size")}px;
  height: ${prop("$size")}px;
  padding: 0 ${spacing(0.5)}px;
  font-weight: 600;
  font-size: ${pxToRem(15)};
  border-radius: ${borderRadius.medium}px;
  background: ${theme("primary.bg")};
  outline: 1px solid rgba(0, 0, 0, 0.15);
  outline-offset: -1px;
  color: ${theme("palette.white")};
`;
