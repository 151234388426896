import { spacing } from "@hotelspoint/theme";
import { IconReload } from "@tabler/icons-react";
import { keyframes, styled } from "styled-components";

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
`;

export const LoadingIcon = styled(IconReload)`
  animation: ${spin} 1s infinite;
  margin-right: ${spacing(0.5)}px;
`;
