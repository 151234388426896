import { useQuery } from "@tanstack/react-query";

import { ReservationListParams, ReservationService } from "../../services";

const useReservations = (params?: ReservationListParams) => {
  const { data, isLoading } = useQuery({
    queryKey: ["reservations", params],
    queryFn: () => ReservationService.getList(params),
  });

  const ret: [typeof data, boolean] = [data, isLoading];

  return ret;
};

export default useReservations;
