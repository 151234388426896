import {
  handleError,
  PaymentStatus,
  PaymentType,
  useApprovePayment,
  useCardDepositPayment,
  useCardRefundPayment,
  useCardReturnPayment,
  usePayment,
  useRejectPayment,
} from "@hotelspoint/api";
import {
  AmountDialog,
  Button,
  ConfirmDialog,
  LinkExternal,
  StatusFlavour,
} from "@hotelspoint/components";
import { useUserTokenStore } from "@hotelspoint/store";
import { getApiUrl } from "@hotelspoint/utils";
import { IconPdf } from "@tabler/icons-react";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { getRefundValidationSchema } from "./AdminPaymentActions.util";

interface AdminPaymentActionsProps {
  id: number;
}

const AdminPaymentActions = ({ id }: AdminPaymentActionsProps) => {
  const { t } = useTranslation();

  const [isRefundDialogOpen, setIsRefundDialogOpen] = useState(false);

  const token = useUserTokenStore(state => state.token);
  const [payment, isLoadingPayment] = usePayment(id);

  // Bank transfer actions
  const [approvePayment, isApprovingPayment] = useApprovePayment(id);
  const [rejectPayment, isRejectingPayment] = useRejectPayment(id);

  // Credit card actions
  const [depositPayment, isDepositingPayment] = useCardDepositPayment(id);
  const [returnPayment, isReturningPayment] = useCardReturnPayment(id);
  const [refundPayment, isRefundingPayment] = useCardRefundPayment(id);

  const handleApprove = useCallback(async () => {
    try {
      await approvePayment();
      toast.success(t("toast.adminPayment.approve"));
    } catch (error: any) {
      handleError({ t, error });
    }
  }, [t, approvePayment]);

  const handleReject = useCallback(async () => {
    try {
      await rejectPayment();
      toast.success(t("toast.adminPayment.reject"));
    } catch (error: any) {
      handleError({ t, error });
    }
  }, [t, rejectPayment]);

  const handleRefund = useCallback(
    async (amount: number) => {
      try {
        await refundPayment({ amount });

        toast.success(t("toast.adminPayment.refund"));
      } catch (error: any) {
        handleError({ t, error });
      }
    },
    [t, refundPayment],
  );

  const handleDeposit = useCallback(async () => {
    try {
      await depositPayment();
      toast.success(t("toast.adminPayment.deposit"));
    } catch (error: any) {
      handleError({ t, error });
    }
  }, [t, depositPayment]);

  const handleReturn = useCallback(async () => {
    try {
      await returnPayment();
      toast.success(t("toast.adminPayment.return"));
    } catch (error: any) {
      handleError({ t, error });
    }
  }, [t, returnPayment]);

  if (!payment || isLoadingPayment) return null;

  if (payment.type === PaymentType.BankTransfer) {
    if (payment.status === PaymentStatus.Progress) {
      return (
        <>
          <ConfirmDialog
            title={t("adminPayment.actions.approve.modal.title")}
            description={t("adminPayment.actions.approve.modal.description")}
            onConfirm={handleApprove}
            isLoading={isApprovingPayment}
            status={StatusFlavour.Success}
          >
            <Button small variant="success">
              {t("adminPayment.actions.approve.button")}
            </Button>
          </ConfirmDialog>
          <ConfirmDialog
            title={t("adminPayment.actions.reject.modal.title")}
            description={t("adminPayment.actions.reject.modal.description")}
            onConfirm={handleReject}
            isLoading={isRejectingPayment}
            status={StatusFlavour.Error}
          >
            <Button small variant="error">
              {t("adminPayment.actions.reject.button")}
            </Button>
          </ConfirmDialog>
        </>
      );
    }

    if (payment.fileId) {
      const url = getApiUrl(`payments/uploads/${payment.fileId}`, { token });

      return (
        <LinkExternal to={url}>
          <Button small variant="secondary">
            <IconPdf size={18} />
            <span>{t("adminPayment.actions.preview.button")}</span>
          </Button>
        </LinkExternal>
      );
    }
  }

  if (payment.type === PaymentType.CreditCard) {
    if (payment.status === PaymentStatus.Reserved) {
      return (
        <>
          <ConfirmDialog
            title={t("adminPayment.actions.deposit.modal.title")}
            description={t("adminPayment.actions.deposit.modal.description")}
            onConfirm={handleDeposit}
            isLoading={isDepositingPayment}
            status={StatusFlavour.Success}
          >
            <Button small variant="success">
              {t("adminPayment.actions.deposit.button")}
            </Button>
          </ConfirmDialog>
          <ConfirmDialog
            title={t("adminPayment.actions.return.modal.title")}
            description={t("adminPayment.actions.return.modal.description")}
            onConfirm={handleReturn}
            isLoading={isReturningPayment}
            status={StatusFlavour.Warning}
          >
            <Button small variant="warning">
              {t("adminPayment.actions.return.button")}
            </Button>
          </ConfirmDialog>
        </>
      );
    }

    if (payment.status === PaymentStatus.Confirmed) {
      return (
        <>
          <AmountDialog
            isOpen={isRefundDialogOpen}
            setIsOpen={setIsRefundDialogOpen}
            title={t("adminPayment.actions.refund.modal.title")}
            onConfirm={handleRefund}
            isLoading={isRefundingPayment}
            validationSchema={getRefundValidationSchema({
              maxAmount: payment.amount,
            })}
          />
          <Button
            small
            variant="error"
            onClick={() => setIsRefundDialogOpen(true)}
          >
            {t("adminPayment.actions.refund.button")}
          </Button>
        </>
      );
    }
  }

  return null;
};

export default AdminPaymentActions;
