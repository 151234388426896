import { spacing } from "@hotelspoint/theme";
import styled from "styled-components";
import { theme } from "styled-tools";

export const Wrapper = styled.button`
  display: flex;
  align-items: center;
  column-gap: ${spacing(0.5)}px;

  svg {
    color: ${theme("palette.silverChalice")};
  }
`;
