import useUserAgencyStore from "../useUserAgencyStore";
import useUserCurrentOfferStore from "../useUserCurrentOfferStore";
import useUserProfileStore from "../useUserProfileStore";
import useUserTokenStore from "../useUserTokenStore";
import useUserVerifyTokenStore from "../useUserVerifyTokenStore";

const useResetUserStores = () => {
  const resetToken = useUserTokenStore(state => state.reset);
  const resetTempToken = useUserVerifyTokenStore(state => state.reset);

  const resetProfile = useUserProfileStore(state => state.reset);
  const resetAgency = useUserAgencyStore(state => state.reset);
  const resetCurrentOffer = useUserCurrentOfferStore(state => state.reset);

  return () => {
    // Reset tokens
    resetToken();
    resetTempToken();

    // Reset profile state
    resetProfile();
    resetAgency();
    resetCurrentOffer();
  };
};

export default useResetUserStores;
