import { useOffer } from "@hotelspoint/api";
import {
  Breadcrumb,
  BreadcrumbContainer,
  Form,
  LoaderBlock,
  Panel,
} from "@hotelspoint/components";
import { IconFilePercent, IconPencil, IconUser } from "@tabler/icons-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import ProfileOfferForm from "../ProfileOfferForm";
import {
  defaultValues,
  entity2Form,
  FormValues,
  validationSchema,
} from "../ProfileOfferForm/ProfileOfferForm.util";
import ProfileOfferFormActions from "../ProfileOfferForm/ProfileOfferFormActions";
import { buildPriceObject } from "../ProfileOffers.util";
import ProfileOfferPanel from "./ProfileOfferPanel";

interface ProfileOfferParams extends Record<string, any> {
  id: number;
}

const ProfileOffer = () => {
  const { t } = useTranslation();

  const { id: offerId } = useParams<ProfileOfferParams>();
  const [offer, isLoadingOffer] = useOffer(offerId as number);

  const breadcrumbs = useMemo(
    () => [
      {
        title: t("profile.pageTitle"),
        href: "/profile",
        icon: IconUser,
      },
      {
        title: t("profileOffers.pageTitle"),
        href: "/profile/offers",
        icon: IconFilePercent,
      },
      {
        title: t("profileOffer.pageTitle"),
        icon: IconPencil,
      },
    ],
    [t],
  );

  const formData = useMemo(() => {
    if (!offer) return defaultValues;

    return {
      name: offer.name,
      client: offer.clientName,
      prices: buildPriceObject(offer?.items),
    };
  }, [offer]);

  const formValues = useMemo(() => {
    return entity2Form(formData);
  }, [formData]);

  return (
    <>
      <BreadcrumbContainer>
        <Breadcrumb items={breadcrumbs} />
      </BreadcrumbContainer>
      {isLoadingOffer ? (
        <LoaderBlock />
      ) : (
        <Form<FormValues>
          defaultValues={formValues}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {formMethods => (
            <>
              <div style={{ marginBottom: 32 }}>
                <Panel
                  title={t("profileOffer.pageTitle")}
                  actions={
                    <ProfileOfferFormActions offerId={offerId as number} />
                  }
                >
                  <ProfileOfferForm
                    offerId={offer?.id}
                    currentOffer={false}
                    {...formMethods}
                  />
                </Panel>
              </div>
              {offer?.items && (
                <ProfileOfferPanel
                  offerId={offerId as number}
                  offerItems={offer.items}
                  {...formMethods}
                />
              )}
            </>
          )}
        </Form>
      )}
    </>
  );
};

export default ProfileOffer;
