import { Panel } from "@hotelspoint/components";
import { useDocumentTitle } from "@hotelspoint/utils";
import { useTranslation } from "react-i18next";
import { Navigate, useParams } from "react-router-dom";

import AdminCityLocationForm from "./AdminCityLocationForm";

interface AdminCityLocationParams extends Record<string, string> {
  id: string;
}

const AdminCityLocation = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("adminCityLocation.pageTitle"));

  const { id } = useParams<AdminCityLocationParams>();
  const cityId = Number(id);

  if (!cityId) {
    return <Navigate to="/404" replace={true} />;
  }

  return (
    <Panel title={t("adminCityLocation.pageTitle")}>
      <AdminCityLocationForm id={cityId} />
    </Panel>
  );
};

export default AdminCityLocation;
