const zIndex = {
  tablePinned: 850,
  tableActions: 860,
  popover: 900,
  formAutoComplete: 902,
  header: 1000,
  headerMenu: 1001,
  navigationOverlay: 2000,
  burger: 2001,
  cookieConsent: 3000,
  overlay: 5000,
  // @todo: investigate overflow issues with react-select and the dialog overlay
  formSelect: 5001,
};

export default zIndex;
