import * as S from "./BurgerMenu.styled";

interface BurgerMenuProps {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
}

const BurgerMenu = ({ isOpen, setIsOpen }: BurgerMenuProps) => {
  return (
    <S.Wrapper $isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
      <div />
      <div />
      <div />
    </S.Wrapper>
  );
};

export default BurgerMenu;
