import { pxToRem, spacing } from "@hotelspoint/theme";
import styled from "styled-components";
import { theme } from "styled-tools";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing(0.5)}px;
`;

export const Title = styled.div`
  color: ${theme("palette.silverChalice")};
  font-weight: 500;
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(14)};
  text-transform: uppercase;
`;

export const Value = styled.div`
  font-size: ${pxToRem(17)};
`;
