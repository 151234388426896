import { User, UserUpdatePayload } from "@hotelspoint/api";
import { SCHEMA_EMAIL, SCHEMA_NAMES } from "@hotelspoint/utils";
import { boolean, InferType, number, object, string } from "yup";

export const validationSchema = object({
  name: SCHEMA_NAMES,
  email: SCHEMA_EMAIL,
  roleId: number(),
  agency: object({
    value: number(),
    label: string(),
  }),
  isActive: boolean(),
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: FormValues = {
  name: "",
  email: "",
  roleId: undefined,
  agency: {},
  isActive: false,
};

export const entity2Form = (user: User): FormValues => ({
  name: user.name,
  email: user.email,
  roleId: user.roleId,
  agency: {
    value: user.agencyId,
    label: user.agencyName,
  },
  isActive: user.isActive,
});

export const form2Entity = (formValues: FormValues): UserUpdatePayload => ({
  name: formValues.name,
  email: formValues.email,
  roleId: formValues.roleId,
  agencyId: formValues.agency.value,
  isActive: formValues.isActive,
});
