export const parseColumnsToOptions = (columns: any[]) => {
  return (
    columns
      // Remove ID and actions columns from the option list
      ?.filter(column => column.accessorKey !== "id" && column.id !== "actions")
      ?.map(column => ({
        label: column.header,
        value: column.accessorKey,
      }))
  );
};

export const parseColumnsToVisibility = (columns: any[]) => {
  return Object.fromEntries(columns?.map(column => [column.accessorKey, true]));
};
