import { IconX } from "@tabler/icons-react";
import { useMemo } from "react";

import { type FormInputProps } from "../FormInput";
import FormInput from "../FormInput";

const FormClearableInput = ({
  value,
  onChange,
  disabled,
  readOnly,
  ...rest
}: FormInputProps) => {
  const canClear = useMemo(() => {
    return !!value && !disabled && !readOnly;
  }, [value, disabled, readOnly]);

  return (
    <FormInput
      value={value}
      onChange={onChange}
      endAdornment={
        canClear ? (
          <button
            type="button"
            onClick={event => {
              event.stopPropagation();
              onChange("");
            }}
          >
            <IconX size={18} />
          </button>
        ) : null
      }
      disabled={disabled}
      readOnly={readOnly}
      {...rest}
    />
  );
};

export default FormClearableInput;
