import { spacing } from "@hotelspoint/theme";
import styled from "styled-components";

export const FlagIcon = styled.img`
  width: 16px;
`;

export const MenuItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: ${spacing(1)}px;
`;
