import { Invoice } from "@hotelspoint/api";
import {
  ButtonEdit,
  Chip,
  CopyToClipboard,
  StatusChip,
  TABLE_COLUMN_SIZE,
  TableCell,
  TableCellEmpty,
} from "@hotelspoint/components";
import { CHIP_COLORS } from "@hotelspoint/constants";
import {
  formatDate,
  getInvoiceStatusColor,
  getInvoiceStatusName,
  getPaymentTypeName,
  getReservationStatusColor,
  getReservationStatusName,
  getReservationSupplierNameByType,
  getReservationTypeName,
  withCurrency,
} from "@hotelspoint/utils";
import { createColumnHelper } from "@tanstack/react-table";
import { TFunction } from "i18next";
import { Link } from "react-router-dom";

const columnHelper = createColumnHelper<Invoice>();

interface GetColumnsParams {
  t: TFunction;
}

const getColumns = ({ t }: GetColumnsParams) => [
  columnHelper.accessor("id", {
    header: t("adminInvoices.columns.id"),
    size: TABLE_COLUMN_SIZE.ID_LONG,
  }),
  columnHelper.accessor("reservationId", {
    header: t("adminInvoices.columns.reservationId"),
    size: TABLE_COLUMN_SIZE.ID_LONG,
  }),
  columnHelper.accessor("reservationStatus", {
    header: t("adminInvoices.columns.reservationStatus"),
    size: TABLE_COLUMN_SIZE.STATUS,
    cell: props => {
      const reservationStatus = props.getValue();

      return (
        <Chip $color={getReservationStatusColor(reservationStatus)}>
          {t(getReservationStatusName(reservationStatus))}
        </Chip>
      );
    },
  }),
  columnHelper.accessor("paymentId", {
    header: t("adminInvoices.columns.paymentId"),
    size: TABLE_COLUMN_SIZE.ID,
    cell: props => props.getValue() || <TableCellEmpty />,
  }),
  columnHelper.accessor("paymentType", {
    header: t("adminInvoices.columns.paymentType"),
    size: TABLE_COLUMN_SIZE.TYPE,
    cell: props => {
      const paymentType = props.getValue();

      if (!paymentType) return <TableCellEmpty />;

      return t(getPaymentTypeName(paymentType));
    },
  }),
  columnHelper.accessor("status", {
    header: t("adminInvoices.columns.status"),
    size: TABLE_COLUMN_SIZE.STATUS,
    cell: props => {
      const status = props.getValue();

      return (
        <StatusChip $variant={getInvoiceStatusColor(status)}>
          {t(getInvoiceStatusName(status))}
        </StatusChip>
      );
    },
  }),
  columnHelper.accessor("itemType", {
    header: t("adminInvoices.columns.itemType"),
    size: TABLE_COLUMN_SIZE.TYPE,
    cell: props => t(getReservationTypeName(props.getValue())),
  }),
  columnHelper.accessor("agencyName", {
    header: t("adminInvoices.columns.agencyName"),
    size: TABLE_COLUMN_SIZE.NAME,
  }),
  columnHelper.accessor("amount", {
    header: t("adminInvoices.columns.amount"),
    size: TABLE_COLUMN_SIZE.AMOUNT,
    cell: props => withCurrency(props.getValue(), props.row.original.currency),
  }),
  columnHelper.accessor("number", {
    header: t("adminInvoices.columns.number"),
    size: TABLE_COLUMN_SIZE.ID_LONG,
    cell: props => props.getValue() || <TableCellEmpty />,
  }),
  columnHelper.accessor("dateCreated", {
    header: t("adminInvoices.columns.dateCreated"),
    size: TABLE_COLUMN_SIZE.DATE,
    cell: props => formatDate(props.getValue()),
  }),
  columnHelper.accessor("supplierId", {
    header: t("adminInvoices.columns.supplierId"),
    size: TABLE_COLUMN_SIZE.SUPPLIER,
    cell: props => {
      const type = props.row.original.itemType;
      const supplierId = props.getValue();
      const supplierName = getReservationSupplierNameByType(type, supplierId);

      return <Chip $color={CHIP_COLORS.SUPPLIER}>{supplierName}</Chip>;
    },
  }),
  columnHelper.accessor("voucherCode", {
    header: t("adminInvoices.columns.voucherCode"),
    cell: props => {
      return <CopyToClipboard value={props.getValue()} />;
    },
  }),
  columnHelper.display({
    id: "actions",
    size: TABLE_COLUMN_SIZE.ACTION,
    cell: props => {
      const id = props.row.original.id;

      return (
        <TableCell>
          <Link to={`/admin/invoices/${id}`}>
            <ButtonEdit />
          </Link>
        </TableCell>
      );
    },
  }),
];

export default getColumns;
