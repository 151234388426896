import { pxToRem, spacing } from "@hotelspoint/theme";
import styled from "styled-components";
import { ifProp, switchProp } from "styled-tools";

export const Wrapper = styled.ul<{ $orientation: "vertical" | "horizontal" }>`
  display: flex;
  flex-direction: ${switchProp("$orientation", {
    vertical: "column",
    horizontal: "row",
  })};
  flex-wrap: wrap;
  row-gap: ${spacing(1)}px;
  column-gap: ${ifProp({ $orientation: "horizontal" }, `${spacing(1)}px`)};
  list-style: none;
`;

export const Item = styled.li`
  display: flex;
  align-items: center;
  column-gap: ${spacing(0.5)}px;
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(14)};
  text-transform: capitalize;
`;
