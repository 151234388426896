import * as S from "./HeaderImage.styled";

interface HeaderImageProps {
  src: string;
  children: React.ReactNode;
}

const HeaderImage = ({ src, children }: HeaderImageProps) => {
  return <S.Wrapper $src={src}>{children}</S.Wrapper>;
};

export default HeaderImage;
