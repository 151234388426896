import { Container, PagePaper, PageWrapper } from "@hotelspoint/components";
import { useDocumentTitle } from "@hotelspoint/utils";
import { useTranslation } from "react-i18next";

import RegisterForm from "./RegisterForm";

const Register = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("register.pageTitle"));

  return (
    <PageWrapper>
      <Container>
        <PagePaper title={t("register.pageTitle")}>
          <RegisterForm />
        </PagePaper>
      </Container>
    </PageWrapper>
  );
};

export default Register;
