import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

const PERSIST_NAME = "@hp::cookie_consent";

interface CookieConsentStore {
  consent: boolean;
  setConsent: (value: boolean) => void;
}

const useCookieConsentStore = create<CookieConsentStore>()(
  persist(
    set => ({
      consent: false,
      setConsent: value => set({ consent: value }),
    }),
    {
      name: PERSIST_NAME,
      storage: createJSONStorage(() => localStorage),
      partialize: state => ({
        consent: state.consent,
      }),
    },
  ),
);

export default useCookieConsentStore;
