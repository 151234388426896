import { useUsers } from "@hotelspoint/api";
import {
  Pagination,
  Panel,
  parseColumnsToOptions,
  parseColumnsToVisibility,
  Table,
  TABLE_PAGE_SIZE_DEFAULT,
  TableColumnVisibility,
  TableFooter,
  TableHeader,
  TableLayout,
  TablePageSize,
  TableResultsCount,
} from "@hotelspoint/components";
import {
  pickFilterValues,
  useDocumentTitle,
  useLocalStorage,
} from "@hotelspoint/utils";
import omit from "lodash/omit";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import AdminAgentsFilters from "./AdminAgentFilters";
import getColumns from "./AdminAgents.columns";
import { PERSIST_NAME } from "./constants";
import useAdminAgentsParams from "./useAdminAgentsParams";

const AdminAgents = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("adminAgents.pageTitle"));

  const [pageIndex, setPageIndex] = useState(1);
  const [query] = useAdminAgentsParams();

  const [pageSize, setPageSize] = useLocalStorage(
    `${PERSIST_NAME}::pageSize`,
    TABLE_PAGE_SIZE_DEFAULT,
  );

  const filters = useMemo(
    () => omit(pickFilterValues(query), "agencyName"),
    [query],
  );

  const [users, isLoadingUsers] = useUsers({
    pageIndex,
    pageSize,
    ...filters,
  });

  const data = useMemo(() => users?.results ?? [], [users]);

  const columns = useMemo(() => {
    return getColumns({ t });
  }, [t]);

  const columnOptions = useMemo(() => {
    return parseColumnsToOptions(columns);
  }, [columns]);

  const [columnVisibility, setColumnVisibility] = useLocalStorage(
    `${PERSIST_NAME}::columns`,
    parseColumnsToVisibility(columns),
  );

  const stringifiedFilters = useMemo(() => JSON.stringify(filters), [filters]);

  useEffect(() => {
    setPageIndex(1);
  }, [pageSize, stringifiedFilters]);

  return (
    <Panel title={t("adminAgents.pageTitle")}>
      <AdminAgentsFilters isLoading={isLoadingUsers} />
      <TableLayout>
        <TableHeader>
          <div />
          <div>
            <TableColumnVisibility
              value={columnVisibility}
              onChange={setColumnVisibility}
              columns={columnOptions}
            />
          </div>
        </TableHeader>
        <Table
          data={data}
          columns={columns}
          isLoading={isLoadingUsers}
          useReactTableProps={{
            state: {
              pagination: {
                pageIndex,
                pageSize: TABLE_PAGE_SIZE_DEFAULT,
              },
              columnPinning: {
                left: ["id"],
                right: ["actions"],
              },
              columnVisibility,
            },
            manualPagination: true,
          }}
        />
        <TableFooter>
          <div>
            <TableResultsCount value={users?.count} />
          </div>
          <div>
            <Pagination
              pageIndex={pageIndex}
              pageSize={pageSize}
              setPageIndex={setPageIndex}
              totalCount={users?.count ?? 0}
            />
          </div>
          <TablePageSize value={pageSize} onChange={setPageSize} />
        </TableFooter>
      </TableLayout>
    </Panel>
  );
};

export default AdminAgents;
