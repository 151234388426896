import tail from "lodash/fp/tail";
import isEqual from "lodash/isEqual";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

const RECENT_SEARCHES_STORE_MAX_QUERIES = 6;

export interface RecentSearchesStore<T> {
  queries: T[];
  add: (query: T) => void;
  reset: () => void;
}

interface CreateRecentSearchesStoreParams {
  persistName: string;
}

const createRecentSearchesStore = <T>({
  persistName,
}: CreateRecentSearchesStoreParams) => {
  return create<RecentSearchesStore<T>>()(
    persist(
      set => ({
        queries: [],
        add: query =>
          set(state => {
            const queryAlreadyExists = state.queries.some(q =>
              isEqual(q, query),
            );

            // Do not add the query if it already exists
            if (queryAlreadyExists) return state;

            // Drop the oldest query if the store is full
            const pastQueries =
              state.queries.length < RECENT_SEARCHES_STORE_MAX_QUERIES
                ? state.queries
                : tail(state.queries);

            return {
              queries: [query, ...pastQueries],
            };
          }),
        reset: () => set({ queries: [] }),
      }),
      {
        name: persistName,
        storage: createJSONStorage(() => localStorage),
        partialize: state => ({
          queries: state.queries,
        }),
      },
    ),
  );
};

export default createRecentSearchesStore;
