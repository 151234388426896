import { useMutation, useQueryClient } from "@tanstack/react-query";

import { MessageService, MessageUpdatePayload } from "../../services";

const useUpdateMessage = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: ["messages", "update"],
    mutationFn: ({
      id,
      payload,
    }: {
      id: number;
      payload: MessageUpdatePayload;
    }) => {
      return MessageService.update(id, payload);
    },
    onMutate: ({ id }) => {
      queryClient.cancelQueries({
        queryKey: ["messages", id],
      });
    },
    onSuccess: (_, { id }) => {
      queryClient.invalidateQueries({
        queryKey: ["messages", id],
      });

      queryClient.invalidateQueries({
        queryKey: ["messages"],
      });
    },
  });

  const { mutateAsync, isPending } = mutation;
  const ret: [typeof mutateAsync, typeof isPending] = [mutateAsync, isPending];

  return ret;
};

export default useUpdateMessage;
