import { type FormControlInjectedProps } from "../FormControl";
import * as S from "./FormCheckbox.styled";

export interface FormCheckboxProps
  extends Omit<FormControlInjectedProps, "value">,
    Pick<React.InputHTMLAttributes<HTMLInputElement>, "disabled" | "checked"> {}

const FormCheckbox = ({ ...inputProps }: FormCheckboxProps) => {
  return <S.Checkbox type="checkbox" {...inputProps} />;
};

export default FormCheckbox;
