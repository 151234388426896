import { HotelService } from "@hotelspoint/api";
import { useQuery } from "@tanstack/react-query";

const useHotelRateFees = (
  searchId?: number,
  hotelId?: number,
  rateId?: number,
) => {
  const { data, isLoading } = useQuery({
    queryKey: ["hotels", searchId, hotelId, rateId, "rate_fees"],
    queryFn: () =>
      HotelService.getRateFees(
        searchId as number,
        hotelId as number,
        rateId as number,
      ),
    enabled: !!searchId && !!hotelId && !!rateId,
  });

  const ret: [typeof data, boolean] = [data, isLoading];

  return ret;
};

export default useHotelRateFees;
