import { InferType, mixed, object } from "yup";

const FILE_MAX_SIZE = 20971520; // 20MB

const FILE_ALLOWED_TYPES = ["application/pdf"];

export const validationSchema = object({
  file: mixed<File>()
    .test(
      "size",
      "adminSupplierStatement.documents.form.file.validation.size",
      (file?: File) => {
        if (!file) return true;

        return file.size <= FILE_MAX_SIZE;
      },
    )
    .test(
      "format",
      "adminSupplierStatement.documents.form.file.validation.format",
      (file?: File) => {
        if (!file) return true;

        return FILE_ALLOWED_TYPES.includes(file.type);
      },
    )
    .nullable(),
});

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: FormValues = {
  file: null,
};
