import {
  ReservationHotelConfirmationStatus,
  ReservationStatus,
  ReservationSupplierConfirmationStatus,
  ReservationTransferConfirmationStatus,
} from "@hotelspoint/api";
import { CHIP_COLORS } from "@hotelspoint/constants";
import { ReservationType } from "@hotelspoint/types";

import {
  getActivitySupplierName,
  getHotelSupplierName,
  getTransferSupplierName,
} from "./suppliers";

export const getReservationTypeName = (type: ReservationType) => {
  switch (type) {
    case ReservationType.Hotel:
      return "reservationType.hotel";

    case ReservationType.Transfer:
      return "reservationType.transfer";

    case ReservationType.RentACar:
      return "reservationType.rentACar";

    case ReservationType.Activity:
      return "reservationType.activity";

    default:
      return "Unknown reservation type";
  }
};

export const getReservationStatusName = (status: ReservationStatus) => {
  switch (status) {
    case ReservationStatus.CreatedReservation:
      return "reservationStatus.createdReservation";

    case ReservationStatus.PendingConfirmation:
      return "reservationStatus.pendingConfirmation";

    case ReservationStatus.ConfirmedReservation:
      return "reservationStatus.confirmedReservation";

    case ReservationStatus.PendingCancellation:
      return "reservationStatus.pendingCancellation";

    case ReservationStatus.CancelledReservation:
      return "reservationStatus.cancelledReservation";

    case ReservationStatus.FailedConfirmation:
      return "reservationStatus.failedConfirmation";

    case ReservationStatus.CancelledWithFee:
      return "reservationStatus.cancelledWithFee";

    default:
      return "Unknown reservation status";
  }
};

export const getReservationStatusColor = (status: ReservationStatus) => {
  switch (status) {
    case ReservationStatus.CreatedReservation:
      return CHIP_COLORS.DARK_GREY;

    case ReservationStatus.PendingConfirmation:
      return CHIP_COLORS.YELLOW;

    case ReservationStatus.ConfirmedReservation:
      return CHIP_COLORS.GREEN;

    case ReservationStatus.PendingCancellation:
      return CHIP_COLORS.ORANGE;

    case ReservationStatus.CancelledReservation:
    case ReservationStatus.CancelledWithFee:
      return CHIP_COLORS.RED;

    case ReservationStatus.FailedConfirmation:
      return CHIP_COLORS.PURPLE;

    default:
      return CHIP_COLORS.GREY;
  }
};

export const getReservationSupplierNameByType = (
  type: ReservationType,
  supplierId: number,
) => {
  switch (type) {
    case ReservationType.Hotel:
      return getHotelSupplierName(supplierId);

    case ReservationType.Transfer:
      return getTransferSupplierName(supplierId);

    case ReservationType.Activity:
      return getActivitySupplierName(supplierId);

    // @todo: handle the suppliers
    case ReservationType.RentACar:
      return "Unknown rent-a-car supplier";

    default:
      return "Unknown supplier";
  }
};

export const getReservationHotelConfirmationStatusName = (
  status: ReservationHotelConfirmationStatus,
) => {
  switch (status) {
    case ReservationHotelConfirmationStatus.NotSend:
      return "reservationHotelConfirmationStatus.notSend";

    case ReservationHotelConfirmationStatus.EmailNotFound:
      return "reservationHotelConfirmationStatus.emailNotFound";

    case ReservationHotelConfirmationStatus.EmailSend:
      return "reservationHotelConfirmationStatus.emailSend";

    case ReservationHotelConfirmationStatus.EmailResend:
      return "reservationHotelConfirmationStatus.emailResend";

    case ReservationHotelConfirmationStatus.EmailReceived:
      return "reservationHotelConfirmationStatus.emailReceived";

    case ReservationHotelConfirmationStatus.EmailNotReceived:
      return "reservationHotelConfirmationStatus.emailNotReceived";

    case ReservationHotelConfirmationStatus.CallRequired:
      return "reservationHotelConfirmationStatus.callRequired";

    case ReservationHotelConfirmationStatus.Escalation1:
      return "reservationHotelConfirmationStatus.escalation1";

    case ReservationHotelConfirmationStatus.Escalation2:
      return "reservationHotelConfirmationStatus.escalation2";

    case ReservationHotelConfirmationStatus.Confirmed:
      return "reservationHotelConfirmationStatus.confirmed";

    default:
      return "Unknown reservation hotel confirmation status";
  }
};

export const getReservationSupplierConfirmationStatusName = (
  status: ReservationSupplierConfirmationStatus,
) => {
  switch (status) {
    case ReservationSupplierConfirmationStatus.NotSend:
      return "reservationSupplierConfirmationStatus.notSend";

    case ReservationSupplierConfirmationStatus.EmailSend:
      return "reservationSupplierConfirmationStatus.emailSend";

    case ReservationSupplierConfirmationStatus.EmailReceived:
      return "reservationSupplierConfirmationStatus.emailReceived";

    case ReservationSupplierConfirmationStatus.EmailNotReceived:
      return "reservationSupplierConfirmationStatus.emailNotReceived";

    case ReservationSupplierConfirmationStatus.Escalation1:
      return "reservationSupplierConfirmationStatus.escalation1";

    case ReservationSupplierConfirmationStatus.Escalation2:
      return "reservationSupplierConfirmationStatus.escalation2";

    case ReservationSupplierConfirmationStatus.Confirmed:
      return "reservationSupplierConfirmationStatus.confirmed";

    case ReservationSupplierConfirmationStatus.Verified:
      return "reservationSupplierConfirmationStatus.verified";

    case ReservationSupplierConfirmationStatus.NotVerified:
      return "reservationSupplierConfirmationStatus.notVerified";

    default:
      return "Unknown reservation supplier confirmation status";
  }
};

export const getReservationTransferConfirmationStatusName = (
  status: ReservationTransferConfirmationStatus,
) => {
  switch (status) {
    case ReservationTransferConfirmationStatus.NotConfirmed:
      return "reservationTransferConfirmationStatus.notConfirmed";

    case ReservationTransferConfirmationStatus.ConfirmedWithNumber:
      return "reservationTransferConfirmationStatus.confirmedWithNumber";

    case ReservationTransferConfirmationStatus.ConfirmedWithoutNumber:
      return "reservationTransferConfirmationStatus.confirmedWithoutNumber";

    case ReservationTransferConfirmationStatus.NoFlightTracking:
      return "reservationTransferConfirmationStatus.noFlightTracking";

    default:
      return "Unknown reservation transfer confirmation status";
  }
};
