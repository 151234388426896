import { AdminAreaLocationItem } from "@hotelspoint/api";
import {
  ButtonDelete,
  ConfirmDialog,
  StatusFlavour,
  TABLE_COLUMN_SIZE,
  TableCell,
} from "@hotelspoint/components";
import { getPlaceTypeName } from "@hotelspoint/utils";
import { createColumnHelper } from "@tanstack/react-table";
import { TFunction } from "i18next";

const columnHelper = createColumnHelper<AdminAreaLocationItem>();

interface GetColumnsParams {
  t: TFunction;
  targetId: number | null;
  handleDelete: (id: number) => void;
  isDeleting: boolean;
}

const getColumns = ({
  t,
  targetId,
  handleDelete,
  isDeleting,
}: GetColumnsParams) => [
  columnHelper.accessor("id", {
    header: t("adminAreaLocation.items.columns.id"),
    size: TABLE_COLUMN_SIZE.ID_LONG,
  }),
  columnHelper.accessor("type", {
    header: t("adminAreaLocation.items.columns.type"),
    size: TABLE_COLUMN_SIZE.TYPE_LONG,
    cell: props => {
      return t(getPlaceTypeName(props.getValue()));
    },
  }),
  columnHelper.accessor("itemName", {
    header: t("adminAreaLocation.items.columns.itemName"),
    size: TABLE_COLUMN_SIZE.NAME,
  }),
  columnHelper.display({
    id: "actions",
    size: TABLE_COLUMN_SIZE.ACTION,
    cell: props => {
      const id = props.row.original.id;
      const isTarget = id === targetId;

      return (
        <TableCell>
          <ConfirmDialog
            title={t(
              "adminAreaLocation.items.columns.actions.delete.modal.title",
            )}
            description={t(
              "adminAreaLocation.items.columns.actions.delete.modal.description",
            )}
            onConfirm={() => handleDelete(id)}
            isLoading={isTarget && isDeleting}
            status={StatusFlavour.Error}
          >
            <ButtonDelete />
          </ConfirmDialog>
        </TableCell>
      );
    },
  }),
];

export default getColumns;
