import { AdminService } from "@hotelspoint/api";
import { useQuery } from "@tanstack/react-query";

const useAdminAreaLocation = (id: number) => {
  const { data, isLoading } = useQuery({
    queryKey: ["admin", "locations_area", id],
    queryFn: () => AdminService.getAreaLocation(id),
    enabled: !!id,
  });

  const ret: [typeof data, boolean] = [data, isLoading];

  return ret;
};

export default useAdminAreaLocation;
