import { spacing } from "@hotelspoint/theme";
import styled from "styled-components";

export default styled.div`
  ul li,
  ol li {
    margin: ${spacing(1)}px;
  }

  ul {
    padding-inline-start: ${spacing(3)}px;
  }

  ol {
    padding-inline-start: ${spacing(2)}px;
  }

  p {
    margin: ${spacing(1.5)}px 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: ${spacing(2)}px 0;
  }

  hr {
    margin: ${spacing(3)}px 0;
  }
`;
