import { useMutation } from "@tanstack/react-query";

import {
  AdminService,
  AdminSupplierStatementLoadReservationsPayload,
} from "../../../../services";

const useLoadAdminSupplierStatementReservations = () => {
  const mutation = useMutation({
    mutationKey: ["admin", "statements_supplier", "load_reservations"],
    mutationFn: (payload: AdminSupplierStatementLoadReservationsPayload) => {
      return AdminService.loadSupplierStatementReservations(payload);
    },
  });

  const { mutateAsync, isPending } = mutation;
  const ret: [typeof mutateAsync, typeof isPending] = [mutateAsync, isPending];

  return ret;
};

export default useLoadAdminSupplierStatementReservations;
