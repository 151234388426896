import { pxToRem, spacing } from "@hotelspoint/theme";
import styled from "styled-components";
import { ifProp, theme } from "styled-tools";

export const Wrapper = styled.button.attrs({ type: "button" })<{
  $active: boolean;
}>`
  position: relative;
  padding: ${spacing(1)}px ${spacing(1.5)}px;
  color: ${theme("palette.black")};
  font-size: ${pxToRem(17)};
  line-height: 1;
  font-weight: ${ifProp("$active", 600)};

  &:hover {
    color: ${theme("palette.black")};

    &::before {
      display: block;
    }
  }

  &::before {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    width: 100%;
    height: 3px;
    background: ${theme("secondary.bg")};
    display: ${ifProp("$active", "block", "none")};
  }
`;
