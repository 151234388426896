const mergeHeaders = (...sources: HeadersInit[]) => {
  const result: Record<string, string> = {};

  for (const source of sources) {
    const headers = new Headers(source) as any;

    for (const [name, value] of headers.entries()) {
      if (value === undefined || value === "undefined") {
        delete result[name];
      } else {
        result[name] = value;
      }
    }
  }

  return new Headers(result);
};

export default mergeHeaders;
