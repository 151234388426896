import { useAgency } from "@hotelspoint/api";
import { Table } from "@hotelspoint/components";
import { useUserTokenStore } from "@hotelspoint/store";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import getColumns from "./AdminAgencyDocuments.columns";

interface AdminAgencyDocumentsProps {
  id: number;
}

const AdminAgencyDocuments = ({ id }: AdminAgencyDocumentsProps) => {
  const { t } = useTranslation();

  const token = useUserTokenStore(state => state.token);

  const [agency, isLoading] = useAgency(id);

  const data = useMemo(() => agency?.documents ?? [], [agency]);

  const columns = useMemo(() => {
    return getColumns({
      t,
      token: token as string,
    });
  }, [t, token]);

  return <Table data={data} columns={columns} isLoading={isLoading} />;
};

export default AdminAgencyDocuments;
