import { borderRadius, spacing } from "@hotelspoint/theme";
import {
  menuArrowSelector,
  menuDividerSelector,
  menuHeaderSelector,
  menuItemSelector,
  menuSelector,
} from "@szhsin/react-menu/style-utils";
import { css } from "styled-components";
import { theme } from "styled-tools";

import { NAV_LINK_ACTIVE_CLASSNAME } from "../../misc/NavLink";
import { menuHide, menuShow } from "./keyframes";

export default css`
  ${menuSelector.name} {
    /* min-width: 12rem; */
    box-sizing: border-box;
    border: none;
    list-style: none;
    user-select: none;
    font-size: 1rem;
    padding: ${spacing(0.8)}px;
    color: ${theme("body.text")};
    background-color: white;
    border-radius: ${borderRadius.normal}px;
    box-shadow:
      0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14),
      0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }

  ${menuSelector.name}:focus, ${menuItemSelector.name}:focus {
    outline: none;
  }

  ${menuSelector.stateOpening} {
    animation: ${menuShow} 0.15s ease-out;
  }

  // @note: animation-fill-mode: forwards is required to prevent flickering with React 18 createRoot()
  ${menuSelector.stateClosing} {
    animation-fill-mode: forwards;
    animation: ${menuHide} 0.2s ease-out forwards;
  }

  ${menuArrowSelector.name} {
    width: 0.5rem;
    height: 0.5rem;
  }

  ${menuArrowSelector.dirTop} {
    bottom: -0.25rem;
  }

  ${menuArrowSelector.dirBottom} {
    top: -0.25rem;
  }

  ${menuArrowSelector.dirLeft} {
    right: -0.25rem;
  }

  ${menuArrowSelector.dirRight} {
    left: -0.25rem;
  }

  ${menuHeaderSelector.name} {
    color: ${theme("palette.dustyGrey")};
    font-size: 0.8rem;
    padding: ${spacing(0.5)}px ${spacing(1.25)}px;
    text-transform: uppercase;
  }

  ${menuItemSelector.name} {
    cursor: pointer;
    padding: ${spacing(0.8)}px ${spacing(1.25)}px;
  }

  /* Nav active style in menu */
  a.${NAV_LINK_ACTIVE_CLASSNAME} {
    cursor: default;
    pointer-events: none;
  }

  a.${NAV_LINK_ACTIVE_CLASSNAME} > ${menuItemSelector.name} {
    background: rgba(0, 0, 0, 0.08);
    border-radius: ${borderRadius.normal}px;
  }

  ${menuItemSelector.hover} {
    color: ${theme("body.text")};
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: ${borderRadius.normal}px;
  }

  ${menuItemSelector.disabled} {
    cursor: default;
    pointer-events: none;
    color: ${theme("body.text")};
    background-color: rgba(0, 0, 0, 0.08);
    border-radius: ${borderRadius.normal}px;
  }

  ${menuDividerSelector.name} {
    height: 1px;
    margin: ${spacing(1)}px ${spacing(1.25)}px;
    background-color: rgba(0, 0, 0, 0.12);
  }
`;
