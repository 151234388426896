import { UserCurrency } from "@hotelspoint/types";

export default [
  {
    value: UserCurrency.EUR,
    title: "header.userCurrency.eur",
  },
  {
    value: UserCurrency.BGN,
    title: "header.userCurrency.bgn",
  },
];
