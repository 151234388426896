import { IconEdit } from "@tabler/icons-react";

import Button from "../Button/Button";
import { ButtonProps } from "../Button/types";

const ButtonEdit = (props: Omit<ButtonProps, "children">) => (
  <Button small variant="tertiary" {...props}>
    <IconEdit size={14} />
  </Button>
);

export default ButtonEdit;
