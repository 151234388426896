import { Container, PagePaper, PageWrapper } from "@hotelspoint/components";
import { useDocumentTitle } from "@hotelspoint/utils";
import { useTranslation } from "react-i18next";

import ChangePasswordForm from "./ChangePasswordForm";
import useChangePasswordParams from "./useChangePasswordParams";

const ChangePassword = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("changePassword.pageTitle"));

  const [{ token }] = useChangePasswordParams();

  return (
    <PageWrapper>
      <Container>
        <PagePaper title={t("changePassword.pageTitle")}>
          <ChangePasswordForm token={token} />
        </PagePaper>
      </Container>
    </PageWrapper>
  );
};

export default ChangePassword;
