import { styled } from "styled-components";
import { ifProp } from "styled-tools";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Button = styled.button.attrs({ type: "button" })<{
  $position: "top" | "bottom";
}>`
  order: ${ifProp({ $position: "bottom" }, 2, "unset")};
`;
