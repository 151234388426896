import { useAgency, useFetchAgencies } from "@hotelspoint/api";
import {
  Box,
  Button,
  Chip,
  Flex,
  Form,
  FormAdapter,
  FormAsyncSelect,
  FormClearableInput,
  FormContext,
  FormDatePickerSingle,
  FormLayout,
  FormSelect,
} from "@hotelspoint/components";
import {
  PAYMENT_STATUS_OPTIONS,
  PAYMENT_TYPE_OPTIONS,
} from "@hotelspoint/constants";
import { getPaymentStatusColor } from "@hotelspoint/utils";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import useProfilePaymentsParams from "../useProfilePaymentsParams";
import {
  entity2Form,
  form2Entity,
  FormValues,
} from "./ProfilePaymentsFilters.form";

interface ProfilePaymentsFiltersProps {
  isLoading: boolean;
}

const ProfilePaymentsFilters = ({ isLoading }: ProfilePaymentsFiltersProps) => {
  const { t } = useTranslation();

  const [query, setQuery] = useProfilePaymentsParams();

  const [fetchAgencies, isFetchingAgencies] = useFetchAgencies();
  const [agency, isLoadingAgency] = useAgency(Number(query?.agencyId));

  const defaultAgencyOptions = useMemo(() => {
    if (!agency) return [];

    return [
      {
        value: agency.id,
        label: agency.name,
      },
    ];
  }, [agency]);

  const formValues = useMemo(() => {
    return entity2Form(query);
  }, [query]);

  const statusOptions = useMemo(
    () =>
      PAYMENT_STATUS_OPTIONS.map(status => ({
        ...status,
        label: (
          <Chip $color={getPaymentStatusColor(status.value)}>
            {t(status.label)}
          </Chip>
        ),
      })),
    [t],
  );

  const typeOptions = useMemo(
    () =>
      PAYMENT_TYPE_OPTIONS.map(type => ({
        ...type,
        label: t(type.label),
      })),
    [t],
  );

  const handleLoadOptions = useCallback(
    (searchQuery: string, callback: any) => {
      fetchAgencies({ name: searchQuery }).then(response => {
        if (response && Array.isArray(response.results)) {
          callback(
            response.results.map(agency => ({
              value: agency.id,
              label: agency.name,
            })),
          );
        }
      });
    },
    [fetchAgencies],
  );

  const onSubmit = (formValues: FormValues) => {
    const payload = form2Entity(formValues);

    setQuery(payload);
  };

  return (
    <Form defaultValues={formValues}>
      <FormLayout>
        <Flex mx={[0, 0, -1, -1]}>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="id"
              label={t("profilePayments.filters.id.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  placeholder={t("profilePayments.filters.id.placeholder")}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="reservationId"
              label={t("profilePayments.filters.reservationId.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  placeholder={t(
                    "profilePayments.filters.reservationId.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="agencyId"
              label={t("profilePayments.filters.agencyId.label")}
            >
              {props => (
                <FormAsyncSelect
                  {...props}
                  placeholder={t(
                    "profilePayments.filters.agencyId.placeholder",
                  )}
                  defaultOptions={defaultAgencyOptions}
                  loadOptions={handleLoadOptions}
                  isLoading={isLoadingAgency || isFetchingAgencies}
                  isClearable
                  noOptionsMessage={() =>
                    t("profilePayments.filters.agencyId.noOptions")
                  }
                  loadingMessage={() =>
                    t("profilePayments.filters.agencyId.loading")
                  }
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="type"
              label={t("profilePayments.filters.type.label")}
            >
              {props => (
                <FormSelect
                  {...props}
                  placeholder={t("profilePayments.filters.type.placeholder")}
                  options={typeOptions}
                  isClearable
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="status"
              label={t("profilePayments.filters.status.label")}
            >
              {props => (
                <FormSelect
                  {...props}
                  placeholder={t("profilePayments.filters.status.placeholder")}
                  options={statusOptions}
                  isClearable
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="amountFrom"
              label={t("profilePayments.filters.amountFrom.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  placeholder={t(
                    "profilePayments.filters.amountFrom.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="amountTo"
              label={t("profilePayments.filters.amountTo.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  placeholder={t(
                    "profilePayments.filters.amountTo.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="dateFrom"
              label={t("profilePayments.filters.dateFrom.label")}
            >
              {props => (
                <FormDatePickerSingle
                  {...props}
                  placeholder={t(
                    "profilePayments.filters.dateFrom.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="dateTo"
              label={t("profilePayments.filters.dateTo.label")}
            >
              {props => (
                <FormDatePickerSingle
                  {...props}
                  placeholder={t("profilePayments.filters.dateTo.placeholder")}
                />
              )}
            </FormAdapter>
          </Box>
        </Flex>
        <FormContext<FormValues>
          render={({ handleSubmit }) => (
            <Button
              type="submit"
              variant="secondary"
              isLoading={isLoading}
              onClick={handleSubmit(onSubmit)}
            >
              {t("profilePayments.filters.submit")}
            </Button>
          )}
        />
      </FormLayout>
    </Form>
  );
};

export default ProfilePaymentsFilters;
