import { Paper } from "@hotelspoint/components";
import { spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";
import { theme } from "styled-tools";

export const EmptyState = styled(Paper)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: ${spacing(1)}px;
  min-height: 240px;
  margin-top: ${spacing(0.5)}px;
  text-align: center;
`;

export const EmptyStateTitle = styled.h6`
  color: ${theme("palette.blueBayoux")};
`;

export const EmptyStateDescription = styled.p`
  color: ${theme("palette.doveGrey")};
`;
