import { Button, Container } from "@hotelspoint/components";
import { useDocumentTitle } from "@hotelspoint/utils";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import * as S from "./NotFound.styled";

const NotFound = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("notFound.pageTitle"));

  return (
    <Container>
      <S.Wrapper>
        <S.Title>{t("notFound.title")}</S.Title>
        <h5>{t("notFound.subTitle")}</h5>
        <S.BackHome>
          <Link to="/">
            <Button variant="secondary">{t("notFound.action")}</Button>
          </Link>
        </S.BackHome>
      </S.Wrapper>
    </Container>
  );
};

export default NotFound;
