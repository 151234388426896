import { Invoice } from "@hotelspoint/api";
import {
  formatDate,
  getApiUrl,
  getPaymentTypeName,
  withCurrency,
} from "@hotelspoint/utils";
import { createColumnHelper } from "@tanstack/react-table";
import { TFunction } from "i18next";

import ButtonView from "../../elements/ButtonView";
import LinkExternal from "../../elements/LinkExternal";
import { TABLE_COLUMN_SIZE } from "../../table/constants";
import TableCell from "../../table/TableCell";
import TableCellEmpty from "../../table/TableCellEmpty";

const columnHelper = createColumnHelper<Invoice>();

interface GetColumnsParams {
  t: TFunction;
  token: string;
}

const getColumns = ({ t, token }: GetColumnsParams) => [
  columnHelper.accessor("dateCreated", {
    header: t("components.reservationInvoicesTable.columns.dateCreated"),
    size: TABLE_COLUMN_SIZE.DATE,
    cell: props => formatDate(props.getValue()),
  }),
  columnHelper.accessor("amount", {
    header: t("components.reservationInvoicesTable.columns.amount"),
    size: TABLE_COLUMN_SIZE.AMOUNT,
    cell: props => withCurrency(props.getValue(), props.row.original.currency),
  }),
  columnHelper.accessor("fullNumber", {
    header: t("components.reservationInvoicesTable.columns.fullNumber"),
    size: TABLE_COLUMN_SIZE.INVOICE_NUMBER,
  }),
  columnHelper.accessor("paymentType", {
    header: t("components.reservationInvoicesTable.columns.paymentType"),
    size: TABLE_COLUMN_SIZE.TYPE,
    cell: props => {
      const paymentType = props.getValue();

      if (!paymentType) return <TableCellEmpty />;

      return t(getPaymentTypeName(paymentType));
    },
  }),
  columnHelper.display({
    id: "actions",
    size: TABLE_COLUMN_SIZE.ACTION,
    cell: props => {
      const { id, reservationId } = props.row.original;
      const url = getApiUrl(`reservations/${reservationId}/${id}/proform`, {
        token,
      });

      return (
        <TableCell>
          <LinkExternal to={url}>
            <ButtonView />
          </LinkExternal>
        </TableCell>
      );
    },
  }),
];

export default getColumns;
