import { Option } from "../../common";

export const getSelectedOption = (
  options: Option[],
  selectedOption: Pick<Option, "value">,
) => {
  if (!selectedOption) return undefined;

  return options?.find(option => option.value === selectedOption.value);
};

export const getSelectedOptions = (
  options: Option[],
  selectedOptions: Pick<Option, "value">[],
) => {
  if (!selectedOptions || !Array.isArray(selectedOptions)) return [];

  return options.filter(({ value: optionValue }) => {
    return selectedOptions.some(({ value }) => optionValue === value);
  });
};
