import { useLogout } from "@hotelspoint/api";
import { useResetUserStores, useUserProfileStore } from "@hotelspoint/store";
import { UserRole } from "@hotelspoint/types";
import { IconLogout, IconSettings } from "@tabler/icons-react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import BurgerMenu from "../../composites/BurgerMenu";
import Button from "../../elements/Button";
import NavLink from "../../misc/NavLink";
import VisibleMobile from "../../misc/VisibleMobile";
import VisibleTablet from "../../misc/VisibleTablet";
import Container from "../Container";
import logoIcon from "./assets/logo-72x72.png";
import logoFull from "./assets/logo-180x32.png";
import * as S from "./Header.styled";
import navItems, { type NavItem } from "./navItems";
import navItemsGuest from "./navItemsGuest";
import profileNavItems, { type ProfileNavItem } from "./profileNavItems";
import UserCurrency from "./UserCurrency";
import UserCurrentOffer from "./UserCurrentOffer";
import UserLocale from "./UserLocale";
import UserProfile from "./UserProfile";
import UserWelcome from "./UserWelcome";

const Header = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [logoutUser] = useLogout();

  const profile = useUserProfileStore(state => state.profile);
  const loggedIn = !!profile;

  const resetUserStores = useResetUserStores();

  const [isOpen, setIsOpen] = useState(false);
  const navItemsUser = loggedIn ? navItems : navItemsGuest;

  const renderNavItem = (item: NavItem) => (
    <S.NavItem key={item.href} $isDisabled={item.isDisabled}>
      <NavLink to={item.href}>{t(item.title)}</NavLink>
    </S.NavItem>
  );

  const renderMobileNavItem = (item: ProfileNavItem) => (
    <S.NavItem key={item.href} $isDisabled={item.isDisabled}>
      <NavLink to={item.href}>
        <S.NavItemHeader>
          {item.icon && <item.icon size={22} />}
          <span>{t(item.title)}</span>
        </S.NavItemHeader>
        {typeof item.renderCounter === "function" && (
          <S.Stats>{item.renderCounter()}</S.Stats>
        )}
      </NavLink>
    </S.NavItem>
  );

  const handleLogout = useCallback(async () => {
    setIsOpen(false);

    try {
      await logoutUser();
    } catch (error) {
      resetUserStores();
    } finally {
      navigate("/");
      toast.success(t("toast.logout"));
    }
  }, [t, logoutUser, resetUserStores, navigate]);

  const handleLogin = () => {
    setIsOpen(false);
  };

  // Close navigation on route change
  useEffect(() => {
    setIsOpen(false);
  }, [pathname]);

  return (
    <S.Wrapper>
      <Container>
        <S.InnerWrapper>
          <S.SideContent>
            <Link to={loggedIn ? "/search/hotels" : "/"}>
              <S.LogoSmall>
                <img src={logoIcon} alt="HotelsPoint logo" />
              </S.LogoSmall>
              <S.LogoLarge>
                <img src={logoFull} alt="HotelsPoint logo" />
              </S.LogoLarge>
            </Link>
            <VisibleTablet style={{ height: "100%" }}>
              <S.Nav>
                <S.NavItems>{navItemsUser.map(renderNavItem)}</S.NavItems>
              </S.Nav>
            </VisibleTablet>
          </S.SideContent>
          <S.SideContent>
            <VisibleMobile>
              <S.NavSide>
                {loggedIn && <UserCurrentOffer />}
                <BurgerMenu isOpen={isOpen} setIsOpen={setIsOpen} />
              </S.NavSide>
              <S.NavOverlay $isOpen={isOpen}>
                <S.NavOverlayHeader>
                  {loggedIn ? (
                    <UserWelcome />
                  ) : pathname === "/" ? (
                    <Button variant="secondary" onClick={handleLogin}>
                      Login
                    </Button>
                  ) : (
                    <Link to="/">
                      <Button variant="secondary">Login</Button>
                    </Link>
                  )}
                  <S.SideMenus>
                    <UserLocale />
                    {loggedIn && <UserCurrency />}
                  </S.SideMenus>
                </S.NavOverlayHeader>
                <S.NavOverlayMenu>
                  <S.NavHeading>{t("header.nav.heading")}</S.NavHeading>
                  <S.NavItems>
                    {navItemsUser.map(renderMobileNavItem)}
                  </S.NavItems>
                  {loggedIn && (
                    <>
                      <S.NavSeparator />
                      <S.NavHeading>
                        {t("header.userProfile.heading")}
                      </S.NavHeading>
                      <S.NavItems>
                        {profileNavItems.map(renderMobileNavItem)}
                      </S.NavItems>
                      {profile?.roleId === UserRole.SuperAdministrator && (
                        <>
                          <S.NavSeparator />
                          <S.NavItems>
                            <S.NavItem>
                              <NavLink to="/admin">
                                <S.NavItemHeader>
                                  <IconSettings size={22} />
                                  <span>{t("admin.pageTitle")}</span>
                                </S.NavItemHeader>
                              </NavLink>
                            </S.NavItem>
                          </S.NavItems>
                        </>
                      )}
                      <S.NavSeparator />
                      <S.NavItems>
                        <S.NavItem>
                          <button type="button" onClick={handleLogout}>
                            <S.NavItemHeader>
                              <IconLogout size={22} />
                              <span>{t("header.userProfile.logout")}</span>
                            </S.NavItemHeader>
                          </button>
                        </S.NavItem>
                      </S.NavItems>
                    </>
                  )}
                </S.NavOverlayMenu>
              </S.NavOverlay>
            </VisibleMobile>
            <VisibleTablet style={{ height: "100%" }}>
              <S.UserMenus>
                {loggedIn ? <UserProfile /> : <UserLocale />}
              </S.UserMenus>
            </VisibleTablet>
          </S.SideContent>
        </S.InnerWrapper>
      </Container>
    </S.Wrapper>
  );
};

export default Header;
