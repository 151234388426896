import { Payment } from "@hotelspoint/api";
import {
  ButtonView,
  Chip,
  TABLE_COLUMN_SIZE,
  TableCell,
  TableCellEmpty,
} from "@hotelspoint/components";
import {
  formatDateTime,
  getPaymentCreditStatusColor,
  getPaymentCreditStatusName,
  getPaymentStatusColor,
  getPaymentStatusName,
  getPaymentTypeName,
  withCurrency,
} from "@hotelspoint/utils";
import { createColumnHelper } from "@tanstack/react-table";
import { TFunction } from "i18next";

const columnHelper = createColumnHelper<Payment>();

interface GetColumnsParams {
  t: TFunction;
  handleOpenDialog: (id: number) => void;
}

const getColumns = ({ t, handleOpenDialog }: GetColumnsParams) => [
  columnHelper.accessor("id", {
    header: t("profilePayments.columns.id"),
    size: TABLE_COLUMN_SIZE.ID_LONG,
  }),
  columnHelper.accessor("reservationId", {
    header: t("profilePayments.columns.reservationId"),
    size: TABLE_COLUMN_SIZE.ID_LONG,
  }),
  columnHelper.accessor("type", {
    header: t("profilePayments.columns.type"),
    size: TABLE_COLUMN_SIZE.TYPE,
    cell: props => {
      const paymentType = props.getValue();

      return t(getPaymentTypeName(paymentType));
    },
  }),
  columnHelper.accessor("status", {
    header: t("profilePayments.columns.status"),
    size: TABLE_COLUMN_SIZE.STATUS,
    cell: props => {
      const status = props.getValue();

      return (
        <Chip $color={getPaymentStatusColor(status)}>
          {t(getPaymentStatusName(status))}
        </Chip>
      );
    },
  }),
  columnHelper.accessor("agencyName", {
    header: t("profilePayments.columns.agencyName"),
    size: TABLE_COLUMN_SIZE.NAME,
  }),
  columnHelper.accessor("amount", {
    header: t("profilePayments.columns.amount"),
    size: TABLE_COLUMN_SIZE.AMOUNT,
    cell: props => withCurrency(props.getValue(), props.row.original.currency),
  }),
  columnHelper.accessor("creditStatus", {
    header: t("profilePayments.columns.creditStatus"),
    size: TABLE_COLUMN_SIZE.STATUS,
    cell: props => {
      const creditStatus = props.getValue();

      if (!creditStatus) return <TableCellEmpty />;

      return (
        <Chip $color={getPaymentCreditStatusColor(creditStatus)}>
          {t(getPaymentCreditStatusName(creditStatus))}
        </Chip>
      );
    },
  }),
  columnHelper.accessor("dateCreated", {
    header: t("profilePayments.columns.dateCreated"),
    size: TABLE_COLUMN_SIZE.DATE_TIME,
    cell: props => formatDateTime(props.getValue()),
  }),
  columnHelper.display({
    id: "actions",
    size: TABLE_COLUMN_SIZE.ACTION,
    cell: props => {
      const id = props.row.original.id;

      return (
        <TableCell>
          <ButtonView onClick={() => handleOpenDialog(id)} />
        </TableCell>
      );
    },
  }),
];

export default getColumns;
