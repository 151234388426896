import { AdminPaymentRequestListParams, AdminService } from "@hotelspoint/api";
import { useQuery } from "@tanstack/react-query";

const useAdminPaymentRequests = (params?: AdminPaymentRequestListParams) => {
  const { data, isLoading } = useQuery({
    queryKey: ["admin", "requests_payment", params],
    queryFn: () => AdminService.getPaymentRequests(params),
  });

  const ret: [typeof data, boolean] = [data, isLoading];

  return ret;
};

export default useAdminPaymentRequests;
