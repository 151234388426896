import { AdminService } from "@hotelspoint/api";
import { useQuery } from "@tanstack/react-query";

const useAdminDestinationLocation = (id: number) => {
  const { data, isLoading } = useQuery({
    queryKey: ["admin", "locations_destination", id],
    queryFn: () => AdminService.getDestinationLocation(id),
    enabled: !!id,
  });

  const ret: [typeof data, boolean] = [data, isLoading];

  return ret;
};

export default useAdminDestinationLocation;
