export enum ImageType {
  Agency = "agency",
  Hotel = "hotel",
  Activity = "activity",
  Transfer = "transfer",
}

const getImagePathFromCDN = (relativePath: string, type: ImageType) => {
  // Detect if running in Storybook, return the relative path
  if (import.meta.env.STORYBOOK) {
    return relativePath;
  }

  switch (type) {
    case ImageType.Agency:
      return `${import.meta.env.VITE_CDN_URL}/agencies/logo/${relativePath}`;

    case ImageType.Hotel:
      return `${import.meta.env.VITE_CDN_URL}/hotels/image/${relativePath}`;

    case ImageType.Activity:
      return `${import.meta.env.VITE_CDN_URL}/activities/image/${relativePath}`;

    case ImageType.Transfer:
      return `${import.meta.env.VITE_CDN_URL}/transfers/image/${relativePath}`;
    default:
      return relativePath;
  }
};

export default getImagePathFromCDN;
