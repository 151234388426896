import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import { Children, useEffect, useRef, useState } from "react";
import { type CustomArrowProps, type Settings } from "react-slick";
import Slider from "react-slick";

import * as S from "./Carousel.styled";

interface CarouselProps extends Settings {
  children: React.ReactNode | React.ReactNode[];
}

const NextArrow = ({ className, style, onClick }: CustomArrowProps) => {
  return (
    <S.Arrow className={className} style={style} onClick={onClick}>
      <IconChevronRight size={48} />
    </S.Arrow>
  );
};

const PrevArrow = ({ className, style, onClick }: CustomArrowProps) => {
  return (
    <S.Arrow className={className} style={style} onClick={onClick}>
      <IconChevronLeft size={48} />
    </S.Arrow>
  );
};

const defaultSettings = {
  dots: false,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

const Carousel = ({ children, ...rest }: CarouselProps) => {
  const [slider, setSlider] = useState<Slider | undefined>(undefined);
  const [dotsSlider, setDotsSlider] = useState<Slider | undefined>(undefined);
  const sliderRef1 = useRef<Slider | null>(null);
  const sliderRef2 = useRef<Slider | null>(null);

  useEffect(() => {
    setSlider(sliderRef1.current!);
    setDotsSlider(sliderRef2.current!);
  }, []);
  return (
    <S.Wrapper>
      <Slider
        {...defaultSettings}
        {...rest}
        asNavFor={dotsSlider}
        ref={slider => (sliderRef1.current = slider)}
        infinite={Children.count(children) > 1}
      >
        {children}
      </Slider>
      <S.DotsWrapper>
        <Slider
          asNavFor={slider}
          ref={slider => (sliderRef2.current = slider)}
          slidesToShow={4}
          focusOnSelect={true}
          infinite={Children.count(children) > 1}
        >
          {Children.map(children, (_child, index) => (
            <S.Dot key={index} />
          ))}
        </Slider>
      </S.DotsWrapper>
    </S.Wrapper>
  );
};

export default Carousel;
