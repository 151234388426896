import {
  ButtonView,
  LinkExternal,
  TABLE_COLUMN_SIZE,
  TableCell,
} from "@hotelspoint/components";
import { AgencyDocument } from "@hotelspoint/types";
import { formatDate, getApiUrl } from "@hotelspoint/utils";
import { createColumnHelper } from "@tanstack/react-table";
import { TFunction } from "i18next";

const columnHelper = createColumnHelper<AgencyDocument>();

interface GetColumnsParams {
  t: TFunction;
  token: string;
}

const getColumns = ({ t, token }: GetColumnsParams) => [
  columnHelper.accessor("name", {
    header: t("adminAgency.documents.columns.name"),
    size: TABLE_COLUMN_SIZE.NAME,
  }),
  columnHelper.accessor("date", {
    header: t("adminAgency.documents.columns.date"),
    size: TABLE_COLUMN_SIZE.DATE,
    cell: props => formatDate(props.getValue()),
  }),
  columnHelper.display({
    id: "actions",
    size: TABLE_COLUMN_SIZE.ACTION,
    cell: props => {
      const id = props.row.id;

      const url = getApiUrl(`agencies/uploads/${id}`, {
        token,
      });

      return (
        <TableCell>
          <LinkExternal to={url}>
            <ButtonView />
          </LinkExternal>
        </TableCell>
      );
    },
  }),
];

export default getColumns;
