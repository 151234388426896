import { UserCurrency } from "@hotelspoint/types";

import { toFixedPrecise } from "../number";

export const parseMarkup = (value: number) => {
  return toFixedPrecise(value, 2);
};

export const parseRate = (value: number) => {
  return toFixedPrecise(value, 4);
};

export const withCurrency = (
  value?: number,
  currency: UserCurrency = UserCurrency.EUR,
) => {
  return [parseMarkup(value || 0), currency].join(" ");
};
