import { Reservation } from "@hotelspoint/api";
import { InferType, number, object, string } from "yup";

export const validationSchema = object({
  id: string(),
  assigneeId: number().required(
    "adminReservations.columns.actions.assign.modal.assigneeId.validation.required",
  ),
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const entity2Form = (entity: Reservation) => ({
  id: entity.id,
  assigneeId: entity.confirmationAssigneeId,
});

export const form2Entity = (formValues: FormValues) => ({
  id: Number(formValues.id),
  assigneeId: formValues.assigneeId,
});
