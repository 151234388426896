import theme from "@hotelspoint/theme";
import { IconHeartFilled } from "@tabler/icons-react";

import * as S from "./ButtonFavorite.styled";

interface ButtonFavoriteProps {
  active: boolean;
  onClick: () => void;
}

const ButtonFavorite = ({ active, onClick }: ButtonFavoriteProps) => {
  return (
    <S.Wrapper variant="outlined" onClick={onClick}>
      <IconHeartFilled
        size={13}
        style={{ color: active ? theme.palette.cinnabar : theme.disabled.bg }}
      />
    </S.Wrapper>
  );
};

export default ButtonFavorite;
