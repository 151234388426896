import {
  ButtonDelete,
  ButtonDownload,
  ConfirmDialog,
  LinkExternal,
  StatusFlavour,
  TABLE_COLUMN_SIZE,
  TableCell,
} from "@hotelspoint/components";
import { getApiUrl } from "@hotelspoint/utils";
import { createColumnHelper } from "@tanstack/react-table";
import { TFunction } from "i18next";

const columnHelper = createColumnHelper<any>();

interface GetColumnsParams {
  t: TFunction;
  token: string;
  id: number;
  targetName: string | null;
  handleDelete: (name: string) => void;
  isDeleting: boolean;
}

const getColumns = ({
  t,
  token,
  id,
  targetName,
  handleDelete,
  isDeleting,
}: GetColumnsParams) => [
  columnHelper.display({
    id: "name",
    header: t("adminSupplierStatement.documents.columns.name"),
    size: TABLE_COLUMN_SIZE.NAME,
    cell: props => props.row.original,
  }),
  columnHelper.display({
    id: "actions",
    size: TABLE_COLUMN_SIZE.ACTION,
    cell: props => {
      const name = props.row.original;
      const isTarget = name === targetName;

      const downloadUrl = getApiUrl(
        `admin/supplierStatements/${id}/${name}/download`,
        {
          token,
        },
      );

      return (
        <TableCell>
          <LinkExternal to={downloadUrl}>
            <ButtonDownload />
          </LinkExternal>
          <ConfirmDialog
            title={t(
              "adminSupplierStatement.documents.columns.actions.delete.modal.title",
            )}
            description={t(
              "adminSupplierStatement.documents.columns.actions.delete.modal.description",
            )}
            onConfirm={() => handleDelete(name)}
            isLoading={isTarget && isDeleting}
            status={StatusFlavour.Error}
          >
            <ButtonDelete />
          </ConfirmDialog>
        </TableCell>
      );
    },
  }),
];

export default getColumns;
