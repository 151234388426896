import { FloatingPortal, useMergeRefs } from "@floating-ui/react";
import { forwardRef } from "react";

import * as S from "./TooltipContent.styled";
import { useTooltipContext } from "./TooltipContext";

const TooltipContent = forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement>
>(function TooltipContent({ style, ...props }, propRef) {
  const context = useTooltipContext();
  const ref = useMergeRefs([context.refs.setFloating, propRef]);

  if (!context.open) return null;

  return (
    <FloatingPortal>
      <S.Wrapper
        ref={ref}
        style={{
          ...context.floatingStyles,
          ...style,
        }}
        {...context.getFloatingProps(props)}
      />
    </FloatingPortal>
  );
});

export default TooltipContent;
