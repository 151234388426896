import { Agency, AgencyStatus } from "@hotelspoint/types";
import {
  SCHEMA_COMPANY_ADDRESS,
  SCHEMA_COMPANY_LICENSE,
  SCHEMA_COMPANY_UIC_NUMBER,
  SCHEMA_EMAIL,
  SCHEMA_NAMES,
  SCHEMA_PHONE,
} from "@hotelspoint/utils";
import { array, InferType, mixed, number, object } from "yup";

export const validationSchema = object({
  name: SCHEMA_NAMES,
  email: SCHEMA_EMAIL,
  phone: SCHEMA_PHONE,
  companyLicense: SCHEMA_COMPANY_LICENSE,
  uicNumber: SCHEMA_COMPANY_UIC_NUMBER,
  companyManager: SCHEMA_NAMES,
  country: mixed().nullable(),
  companyAddress: SCHEMA_COMPANY_ADDRESS,
  status: mixed<AgencyStatus>().oneOf(
    Object.values(AgencyStatus).map(e => e as AgencyStatus),
  ),
  suppliersHotels: array().of(number().required()),
  suppliersTransfers: array().of(number().required()),
  suppliersActivities: array().of(number().required()),
  markupHotels: number()
    .typeError("adminAgency.form.markupHotels.validation.required")
    .required("adminAgency.form.markupHotels.validation.required"),
  markupHotelsBase: number()
    .typeError("adminAgency.form.markupHotelsBase.validation.required")
    .required("adminAgency.form.markupHotelsBase.validation.required"),
  markupHotelsOperator: number()
    .typeError("adminAgency.form.markupHotelsOperator.validation.required")
    .required("adminAgency.form.markupHotelsOperator.validation.required"),
  markupTransfers: number()
    .typeError("adminAgency.form.markupTransfers.validation.required")
    .required("adminAgency.form.markupTransfers.validation.required"),
  markupTransfersBase: number()
    .typeError("adminAgency.form.markupTransfersBase.validation.required")
    .required("adminAgency.form.markupTransfersBase.validation.required"),
  markupTransfersOperator: number()
    .typeError("adminAgency.form.markupTransfersOperator.validation.required")
    .required("adminAgency.form.markupTransfersOperator.validation.required"),
  markupActivities: number()
    .typeError("adminAgency.form.markupActivities.validation.required")
    .required("adminAgency.form.markupActivities.validation.required"),
  markupActivitiesBase: number()
    .typeError("adminAgency.form.markupActivitiesBase.validation.required")
    .required("adminAgency.form.markupActivitiesBase.validation.required"),
  markupActivitiesOperator: number()
    .typeError("adminAgency.form.markupActivitiesOperator.validation.required")
    .required("adminAgency.form.markupActivitiesOperator.validation.required"),
  creditUsed: number().required(),
  creditBalance: number().required(),
  creditLimit: number().required(
    "adminAgency.form.creditLimit.validation.required",
  ),
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: FormValues = {
  name: "",
  email: "",
  phone: "",
  companyLicense: "",
  uicNumber: "",
  companyManager: "",
  country: "",
  companyAddress: "",
  status: undefined,
  suppliersHotels: [],
  suppliersTransfers: [],
  suppliersActivities: [],
  markupHotels: 0,
  markupHotelsBase: 0,
  markupHotelsOperator: 0,
  markupTransfers: 0,
  markupTransfersBase: 0,
  markupActivities: 0,
  markupActivitiesBase: 0,
  markupTransfersOperator: 0,
  markupActivitiesOperator: 0,
  creditUsed: 0,
  creditBalance: 0,
  creditLimit: 0,
};

export const entity2Form = (entity: Agency): FormValues => {
  return {
    name: entity.name,
    email: entity.email,
    phone: entity.companyPhone,
    companyLicense: entity.companyLicense,
    uicNumber: entity.uicNumber,
    companyManager: entity.companyManager,
    country: `${entity.countryCode}`.toLowerCase(),
    companyAddress: entity.companyAddress,
    status: entity.status,
    suppliersHotels: entity.suppliersHotels,
    suppliersTransfers: entity.suppliersTransfers,
    suppliersActivities: entity.suppliersActivities,
    markupHotels: entity.markupHotels ?? 0,
    markupHotelsBase: entity.markupHotelsBase ?? 0,
    markupHotelsOperator: entity.markupHotelsOperator ?? 0,
    markupTransfers: entity.markupTransfers ?? 0,
    markupTransfersBase: entity.markupTransfersBase ?? 0,
    markupTransfersOperator: entity.markupTransfersOperator ?? 0,
    markupActivities: entity.markupActivities ?? 0,
    markupActivitiesBase: entity.markupActivitiesBase ?? 0,
    markupActivitiesOperator: entity.markupActivitiesOperator ?? 0,
    creditUsed: entity.credit.used ?? 0,
    creditBalance: entity.credit.balance ?? 0,
    creditLimit: entity.credit.limit ?? 0,
  };
};

export const form2Entity = (formValues: FormValues) => {
  return {
    name: formValues.name,
    email: formValues.email,
    phone: formValues.phone,
    companyLicense: formValues.companyLicense,
    uicNumber: formValues.uicNumber,
    companyManager: formValues.companyManager,
    country: `${formValues.country}`.toUpperCase(),
    companyAddress: formValues.companyAddress,
    suppliersHotels: formValues.suppliersHotels ?? [],
    suppliersTransfers: formValues.suppliersTransfers ?? [],
    suppliersActivities: formValues.suppliersActivities ?? [],
    status: formValues.status,
    markupHotels: formValues.markupHotels,
    markupHotelsBase: formValues.markupHotelsBase,
    // markupHotelsOperator: formValues.markupHotelsOperator,
    markupTransfers: formValues.markupTransfers,
    markupTransfersBase: formValues.markupTransfersBase,
    // markupTransfersOperator: formValues.markupTransfersOperator,
    markupActivities: formValues.markupActivities,
    markupActivitiesBase: formValues.markupActivitiesBase,
    // markupActivitiesOperator: formValues.markupActivitiesOperator,
    credit: {
      used: formValues.creditUsed,
      balance: formValues.creditBalance,
      limit: formValues.creditLimit,
    },
  };
};
