import { spacing } from "@hotelspoint/theme";
import styled from "styled-components";
import { theme } from "styled-tools";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${spacing(2)}px;
`;

export const MenuItems = styled.div`
  width: 280px;
`;

export const MenuItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const MenuItemHeader = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${spacing(1.25)}px;
`;

export const IconWrapper = styled.div`
  svg {
    color: ${theme("palette.royalBlue")};
  }
`;

export const Title = styled.span`
  color: ${theme("body.text")};
  line-height: 1;
`;

export const MenuItemStats = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${spacing(0.5)}px;
`;

export const FlagIcon = styled.img`
  width: 16px;
  margin-right: ${spacing(1)}px;
`;
