import {
  IconCalendarEvent,
  IconCreditCardPay,
  IconFileDollar,
  IconFilePercent,
  IconUserCog,
} from "@tabler/icons-react";

import { StatusFlavour } from "../../common";
import StatusChip from "../../elements/StatusChip";
import { NavItem } from "./navItems";

export interface ProfileNavItem extends NavItem {
  renderCounter?: () => React.ReactNode;
}

const profileNavItems: ProfileNavItem[] = [
  {
    icon: IconFilePercent,
    href: "/profile/offers",
    title: "profileOffers.pageTitle",
  },
  {
    icon: IconCalendarEvent,
    href: "/profile/bookings",
    title: "profileReservations.pageTitle",
    renderCounter: () => {
      return (
        <>
          <StatusChip $variant={StatusFlavour.Success}>2</StatusChip>
          <StatusChip $variant={StatusFlavour.Error}>13</StatusChip>
          <StatusChip $variant={StatusFlavour.Info}>22</StatusChip>
        </>
      );
    },
  },
  {
    icon: IconFileDollar,
    href: "/profile/invoices",
    title: "profileInvoices.pageTitle",
  },
  {
    icon: IconCreditCardPay,
    href: "/profile/payments",
    title: "profilePayments.pageTitle",
  },
  {
    icon: IconUserCog,
    href: "/profile/settings",
    title: "profileSettings.pageTitle",
  },
];

export default profileNavItems;
