import { spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";
import { ifProp } from "styled-tools";

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing(1)}px;
`;

export const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${spacing(2)}px;
  justify-content: flex-start;
`;

export const FlexLength = styled.div<{ $loading?: boolean }>`
  flex: ${ifProp("$loading", 1)};
`;
