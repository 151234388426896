import Loader from "../../elements/Loader";
import * as S from "./LoaderBlock.styled";

interface LoaderBlockProps {
  children?: React.ReactNode;
}

const LoaderBlock = ({ children }: LoaderBlockProps) => (
  <S.Wrapper>
    <Loader />
    {children && <S.Content>{children}</S.Content>}
  </S.Wrapper>
);

export default LoaderBlock;
