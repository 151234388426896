import { mediaQuery, spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";
import { theme } from "styled-tools";

export const InformationWrapper = styled.div`
  @media ${mediaQuery.tablet} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const InformationActionWrapper = styled.div`
  display: flex;
  column-gap: ${spacing(2)}px;
`;

export const InformationBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing(2)}px;
  margin-bottom: ${spacing(2)}px;
  @media ${mediaQuery.tablet} {
    flex-direction: row;
    row-gap: 0;
    column-gap: ${spacing(2)}px;
  }
`;

export const InformationBlock = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing(1)}px;
`;

export const InformationBlockRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: ${spacing(2)}px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${theme("palette.silver")};
  @media ${mediaQuery.tablet} {
    display: none;
  }
`;
