import { spacing, timing } from "@hotelspoint/theme";
import styled from "styled-components";
import { theme } from "styled-tools";

export const ButtonWrapper = styled.button`
  color: ${theme("palette.subMarine")};
  transition: color ${timing.fast}ms linear;

  &:hover {
    color: ${theme("palette.blueBayoux")};
  }
`;

export const MenuItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: ${spacing(0.5)}px;
`;
