import {
  Box as BoxComponent,
  Flex as FlexComponent,
} from "reflexbox/styled-components";
import styled from "styled-components";

export const Flex = styled(FlexComponent)`
  flex-wrap: wrap;
`;

export const Box = BoxComponent;
