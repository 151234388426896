import { useEffect, useRef } from "react";

import { Checkbox } from "../form/FormCheckbox/FormCheckbox.styled";

interface TableCheckboxProps extends React.HTMLProps<HTMLInputElement> {
  indeterminate?: boolean;
}

const TableCheckbox = ({
  indeterminate,
  checked,
  ...rest
}: TableCheckboxProps) => {
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !checked && indeterminate;
    }
  }, [ref, checked, indeterminate]);

  return (
    <Checkbox
      ref={ref}
      type="checkbox"
      checked={checked}
      readOnly={false}
      {...rest}
    />
  );
};

export default TableCheckbox;
