const borderRadius = {
  circle: "50%",
  smallest: 1,
  small: 2,
  normal: 3,
  medium: 5,
  large: 15,
};

export default borderRadius;
