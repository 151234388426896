import { UserProfile } from "@hotelspoint/types";
import { SCHEMA_EMAIL, SCHEMA_NAMES } from "@hotelspoint/utils";
import { InferType, object } from "yup";

export const validationSchema = object({
  name: SCHEMA_NAMES,
  email: SCHEMA_EMAIL,
}).required();

export type FormValues = InferType<typeof validationSchema>;

const defaultValues: FormValues = {
  name: "",
  email: "",
};

export const entity2Form = (entity: UserProfile): FormValues => ({
  ...defaultValues,
  name: entity?.name,
  email: entity?.email,
});
