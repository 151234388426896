import * as Sentry from "@sentry/react";
import { TFunction } from "i18next";
import { toast } from "react-toastify";

import { ApiError } from "../services";

interface HandleErrorParams {
  t: TFunction;
  error: Error | ApiError;
}

const handleError = ({ t, error }: HandleErrorParams) => {
  if (error instanceof ApiError) {
    toast.error(t(`serverError.${error?.code}`));
  } else {
    toast.error(error?.message);
    Sentry.captureException(error);
  }
};

export default handleError;
