import { Payment } from "@hotelspoint/api";
import {
  formatDateTime,
  getPaymentCreditStatusColor,
  getPaymentCreditStatusName,
  getPaymentStatusColor,
  getPaymentStatusName,
  getPaymentTypeName,
  withCurrency,
} from "@hotelspoint/utils";
import { createColumnHelper } from "@tanstack/react-table";
import { TFunction } from "i18next";

import ButtonView from "../../elements/ButtonView";
import Chip from "../../elements/Chip";
import { TABLE_COLUMN_SIZE } from "../../table/constants";
import TableCell from "../../table/TableCell";

const columnHelper = createColumnHelper<Payment>();

interface GetColumnsParams {
  t: TFunction;
  handleOpenDialog: (id: number) => void;
}

const getColumns = ({ t, handleOpenDialog }: GetColumnsParams) => [
  columnHelper.accessor("dateCreated", {
    header: t("components.reservationPaymentsTable.columns.dateCreated"),
    size: TABLE_COLUMN_SIZE.DATE,
    cell: props => formatDateTime(props.getValue()),
  }),
  columnHelper.accessor("amount", {
    header: t("components.reservationPaymentsTable.columns.amount"),
    size: TABLE_COLUMN_SIZE.AMOUNT,
    cell: props => withCurrency(props.getValue(), props.row.original.currency),
  }),
  columnHelper.display({
    id: "type",
    header: t("components.reservationPaymentsTable.columns.type"),
    size: TABLE_COLUMN_SIZE.TYPE,
    cell: props => {
      const { type, creditStatus } = props.row.original;

      if (!creditStatus) {
        return t(getPaymentTypeName(type));
      }

      return (
        <TableCell style={{ justifyContent: "flex-start" }}>
          <span>{t(getPaymentTypeName(type))}</span>
          <Chip $color={getPaymentCreditStatusColor(creditStatus)}>
            {t(getPaymentCreditStatusName(creditStatus))}
          </Chip>
        </TableCell>
      );
    },
  }),
  columnHelper.accessor("status", {
    header: t("components.reservationPaymentsTable.columns.status"),
    size: TABLE_COLUMN_SIZE.STATUS,
    cell: props => {
      const status = props.getValue();

      return (
        <Chip $color={getPaymentStatusColor(status)}>
          {t(getPaymentStatusName(status))}
        </Chip>
      );
    },
  }),
  columnHelper.display({
    id: "actions",
    size: TABLE_COLUMN_SIZE.ACTION,
    cell: props => {
      const id = props.row.original.id;

      return (
        <TableCell>
          <ButtonView onClick={() => handleOpenDialog(id)} />
        </TableCell>
      );
    },
  }),
];

export default getColumns;
