import { IconReload } from "@tabler/icons-react";
import styled, { keyframes } from "styled-components";

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
`;

export default styled(IconReload)`
  animation: ${spin} 1s infinite;
`;
