import { useMutation } from "@tanstack/react-query";

import { AuthService, type RegisterPayload } from "../../services";

const useRegister = () => {
  const mutation = useMutation({
    mutationKey: ["auth", "register"],
    mutationFn: (payload: RegisterPayload) => {
      return AuthService.register(payload);
    },
  });

  const { mutateAsync, isPending } = mutation;
  const ret: [typeof mutateAsync, typeof isPending] = [mutateAsync, isPending];

  return ret;
};

export default useRegister;
