import { isNumeric } from "@hotelspoint/utils";
import { InferType, object, string } from "yup";

export const validationSchema = object({
  id: string(),
  name: string(),
  countryName: string(),
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: FormValues = {
  id: "",
  name: "",
  countryName: "",
};

export const entity2Form = (query: any): FormValues => {
  return {
    id: query.id,
    name: query.name,
    countryName: query.countryName,
  };
};

export const form2Entity = (formValues: FormValues) => {
  return {
    id: isNumeric(formValues.id) ? Number(formValues.id) : undefined,
    name: formValues.name,
    countryName: formValues.countryName,
  };
};
