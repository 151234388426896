import { mediaQuery, pxToRem, spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";
import { theme } from "styled-tools";

export const Title = styled.h6`
  margin-bottom: ${spacing(1)}px;
  color: rgba(18, 50, 102, 0.6);
  font-weight: 700;
  font-size: ${pxToRem(14)};
  text-transform: uppercase;
`;

export const TableRow = styled.div`
  margin-bottom: ${spacing(2)}px;
  border: 1px solid ${theme("palette.subMarine")};
  @media ${mediaQuery.desktop} {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;

    &:not(:first-child) {
      border-top: none;
      ${Title} {
        display: none;
      }
    }

    &:not(:last-child) {
      border-bottom: none;
    }
  }
`;

export const TableCell = styled.div`
  padding: ${spacing(2)}px;
  &:not(:last-child) {
    border-bottom: 1px solid ${theme("palette.subMarine")};
  }
  @media ${mediaQuery.desktop} {
    &:not(:last-child) {
      border-bottom: none;
    }
  }
`;

export const RoomNameCell = styled(TableCell)`
  @media ${mediaQuery.desktop} {
    width: 45%;
  }
`;

export const RoomPersonsCell = styled(TableCell)`
  @media ${mediaQuery.desktop} {
    width: 10%;
    text-align: center;
  }
`;

export const RoomPromotionsCell = styled(TableCell)`
  @media ${mediaQuery.desktop} {
    width: 33%;
  }
`;

export const RoomPriceCell = styled(TableCell)`
  @media ${mediaQuery.desktop} {
    width: 12%;
    text-align: right;
  }
`;

export const RoomName = styled.h6`
  font-weight: 600;
`;

export const Price = styled.p`
  font-weight: 600;
  font-size: ${pxToRem(16)};
`;

export const StatusBlockHeader = styled.h6`
  font-size: ${pxToRem(14)};
  font-weight: 600;
`;

export const StatusBlockCopy = styled.p`
  font-size: ${pxToRem(13)};
`;

export const StatusBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing(2)}px;
`;
