import { useQuery } from "@tanstack/react-query";

import { OfferListParams, OfferService } from "../../services";

const useOffers = (params?: OfferListParams) => {
  const { data, isLoading } = useQuery({
    queryKey: ["offers", params],
    queryFn: () => OfferService.getList(params),
  });

  const ret: [typeof data, boolean] = [data, isLoading];

  return ret;
};

export default useOffers;
