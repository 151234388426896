import { formatDateIso, isNumeric } from "@hotelspoint/utils";
import { date, InferType, number, object, string } from "yup";

export const validationSchema = object({
  id: string(),
  name: string(),
  dateFrom: date(),
  dateTo: date(),
  clientName: string(),
  agencyId: object({
    value: number(),
    label: string(),
  }).nullable(),
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: FormValues = {
  id: "",
  name: "",
  dateFrom: undefined,
  dateTo: undefined,
  clientName: "",
  agencyId: {},
};

export const entity2Form = (query: any): FormValues => {
  return {
    id: query.id,
    name: query.name,
    dateFrom: query.dateFrom ? new Date(query.dateFrom) : undefined,
    dateTo: query.dateTo ? new Date(query.dateTo) : undefined,
    clientName: query.clientName,
    agencyId: query.agencyId
      ? {
          value: query.agencyId,
          label: query.agencyName,
        }
      : null,
  };
};

export const form2Entity = (formValues: FormValues) => {
  return {
    id: isNumeric(formValues.id) ? Number(formValues.id) : undefined,
    name: formValues.name,
    dateFrom: formValues.dateFrom
      ? formatDateIso(formValues.dateFrom)
      : undefined,
    dateTo: formValues.dateTo ? formatDateIso(formValues.dateTo) : undefined,
    clientName: formValues.clientName,
    agencyId: formValues.agencyId?.value,
    agencyName: formValues.agencyId?.label,
  };
};
