import { pxToRem, spacing } from "@hotelspoint/theme";
import { IconQuote } from "@tabler/icons-react";
import styled from "styled-components";
import { theme } from "styled-tools";

import Card from "../../elements/Card";

export const Wrapper = styled(Card)`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Icon = styled(IconQuote)`
  color: ${theme("secondary.bg")};
  transform: rotate(180deg);
`;

export const Content = styled.p`
  font-size: ${pxToRem(18)};
  margin: ${spacing(2)}px 0 ${spacing(3)}px;
`;

export const Author = styled.div``;

export const AuthorNames = styled.div`
  font-weight: 600;
  margin-top: ${spacing(1.5)}px;
`;

export const AuthorCompany = styled.div`
  font-style: italic;
  font-size: 0.9rem;
`;
