import { StatusFlavour } from "@hotelspoint/components";
import { CHIP_COLORS } from "@hotelspoint/constants";
import { UserRole } from "@hotelspoint/types";

export const getUserRoleDisplayName = (roleId: UserRole) => {
  switch (roleId) {
    case UserRole.SuperAdministrator:
      return "userRoles.superAdministrator";

    case UserRole.AgencyAdministrator:
      return "userRoles.agencyAdministrator";

    case UserRole.AgencyMember:
      return "userRoles.agencyMember";

    case UserRole.SalesManager:
      return "userRoles.salesManager";

    default:
      return "Unknown user role";
  }
};

export const getUserRoleColor = (roleId: UserRole) => {
  switch (roleId) {
    case UserRole.SuperAdministrator:
      return CHIP_COLORS.RED;

    case UserRole.AgencyAdministrator:
      return CHIP_COLORS.GREEN;

    case UserRole.AgencyMember:
      return CHIP_COLORS.YELLOW;

    case UserRole.SalesManager:
      return CHIP_COLORS.BLUE;

    default:
      return CHIP_COLORS.GREY;
  }
};

export const getUserStatusColor = (isActive: boolean) => {
  return isActive ? StatusFlavour.Success : StatusFlavour.Error;
};
