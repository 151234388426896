import { mediaQuery, spacing } from "@hotelspoint/theme";
import styled from "styled-components";
import { ifProp, theme } from "styled-tools";

export const Wrapper = styled.div<{ $wide: boolean }>`
  display: flex;

  @media ${mediaQuery.tablet} {
    column-gap: ${spacing(1.5)}px;

    aside {
      max-width: ${ifProp(
        "$wide",
        theme("custom.sidebar.widthWide"),
        theme("custom.sidebar.width"),
      )}px;
    }

    section {
      width: calc(
        100% -
          ${ifProp(
            "$wide",
            theme("custom.sidebar.widthWide"),
            theme("custom.sidebar.width"),
          )}px
      );
    }
  }
`;

export const Sidebar = styled.aside`
  display: none;

  @media ${mediaQuery.tablet} {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: ${spacing(2.5)}px;
  }
`;

export const Content = styled.section`
  width: 100%;
`;
