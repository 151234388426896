import * as React from "react";

import TooltipContent from "./TooltipContent";
import TooltipContext from "./TooltipContext";
import TooltipTrigger from "./TooltipTrigger";
import useTooltip, { TooltipOptions } from "./useTooltip";

interface TooltipProps extends TooltipOptions {
  children: React.ReactNode;
}

const Tooltip = ({ children, ...options }: TooltipProps) => {
  // This can accept any props as options, e.g. `placement`,
  // or other positioning options.
  const tooltip = useTooltip(options);

  return (
    <TooltipContext.Provider value={tooltip}>
      {children}
    </TooltipContext.Provider>
  );
};

Tooltip.Content = TooltipContent;
Tooltip.Trigger = TooltipTrigger;

export default Tooltip;
