import { HotelService } from "@hotelspoint/api";
import { useQuery } from "@tanstack/react-query";

const useHotelSearchInfo = (searchId: number) => {
  const { data, isLoading } = useQuery({
    queryKey: ["hotels", searchId, "search_info"],
    queryFn: () => HotelService.getSearchInfo(searchId),
    enabled: !!searchId,
  });

  const ret: [typeof data, boolean] = [data, isLoading];

  return ret;
};

export default useHotelSearchInfo;
