import { mediaQuery, pxToRem, spacing } from "@hotelspoint/theme";
import styled from "styled-components";
import { theme } from "styled-tools";

export const Breadcrumb = styled.ol`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  justify-content: center;

  @media ${mediaQuery.tablet} {
    justify-content: flex-start;
  }
`;

export const BreadcrumbItem = styled.li`
  display: flex;
  align-items: center;
  column-gap: ${spacing(0.5)}px;

  & + &::before {
    content: "/";
    display: inline-block;
    padding: 0 ${spacing(0.5)}px 0 ${spacing(1)}px;
    font-size: ${pxToRem(15)};
    font-weight: 600;
    color: ${theme("palette.tundora")};
  }

  a,
  span {
    font-size: ${pxToRem(15)};
    line-height: ${pxToRem(15)};
  }

  a {
    color: ${theme("body.text")};
    text-decoration: underline;
  }

  span {
    color: ${theme("palette.black")};
  }
`;
