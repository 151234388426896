import { css } from "styled-components";

const fontFace = css`
  @font-face {
    font-family: "TT Commons";
    src: url("static/fonts/TTCommons-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: "TT Commons";
    src: url("static/fonts/TTCommons-LightItalic.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
    font-display: fallback;
  }

  @font-face {
    font-family: "TT Commons";
    src: url("static/fonts/TTCommons-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: "TT Commons";
    src: url("static/fonts/TTCommons-Italic.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
    font-display: fallback;
  }

  @font-face {
    font-family: "TT Commons";
    src: url("static/fonts/TTCommons-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: "TT Commons";
    src: url("static/fonts/TTCommons-MediumItalic.ttf") format("truetype");
    font-weight: 500;
    font-style: italic;
    font-display: fallback;
  }

  @font-face {
    font-family: "TT Commons";
    src: url("static/fonts/TTCommons-DemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: "TT Commons";
    src: url("static/fonts/TTCommons-DemiBoldItalic.ttf") format("truetype");
    font-weight: 600;
    font-style: italic;
    font-display: fallback;
  }

  @font-face {
    font-family: "TT Commons";
    src: url("static/fonts/TTCommons-BoldItalic.ttf") format("truetype");
    font-weight: bold;
    font-style: italic;
    font-display: fallback;
  }

  @font-face {
    font-family: "TT Commons";
    src: url("static/fonts/TTCommons-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: fallback;
  }
`;

export default fontFace;
