import { mediaQuery, spacing } from "@hotelspoint/theme";
import styled from "styled-components";
import { ifProp } from "styled-tools";

export const Wrapper = styled.section<{ $last?: boolean }>`
  margin: ${ifProp("$last", `${spacing(5)}px 0 0 0`, `${spacing(5)}px 0`)};

  @media ${mediaQuery.tablet} {
    margin: ${ifProp("$last", `${spacing(8)}px 0 0 0`, `${spacing(10)}px 0`)};
  }
`;

export const Heading = styled.div`
  margin-bottom: ${spacing(2)}px;

  @media ${mediaQuery.tablet} {
    margin-bottom: ${spacing(4)}px;
  }
`;
