import {
  handleError,
  useAddOfferRate,
  useDeleteOfferItem,
  useHotelRateStatus,
  useHotelRooms,
} from "@hotelspoint/api";
import {
  Accordion,
  AccordionTriggerBase,
  LoaderBlock,
  MarkupPrice,
  Price,
  Table,
} from "@hotelspoint/components";
import { useUserCurrentOfferStore } from "@hotelspoint/store";
import { ReservationType } from "@hotelspoint/types";
import qs from "query-string";
import { Fragment, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import getColumns from "./HotelRoomsTable.columns";
import * as S from "./HotelRoomsTable.styled";

interface HotelRoomsTableProps {
  searchId: number;
  hotelId: number;
}

const HotelRoomsTable = ({ searchId, hotelId }: HotelRoomsTableProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const offer = useUserCurrentOfferStore(state => state.offer);
  const offerItems = useMemo(() => offer?.items ?? [], [offer]);

  const [targetRateId, setTargetRateId] = useState<number | null>(null);

  const [data, isLoadingData] = useHotelRooms(searchId, hotelId);
  const [checkRateStatus, isCheckingRateStatus] = useHotelRateStatus(
    searchId,
    hotelId,
  );

  const [addRate, isAddingRate] = useAddOfferRate();
  const [deleteItem, isDeletingItem] = useDeleteOfferItem(offer?.id as number);

  const handleCheckRateStatus = useCallback(
    async (rateId: number) => {
      try {
        setTargetRateId(rateId);

        const response = await checkRateStatus(rateId);

        // @todo: handle response.isChanged
        if (response.isAvailable === true) {
          const search = qs.stringify({
            searchId,
            rateId,
          });

          navigate({
            pathname: `/search/hotels/${hotelId}/book`,
            search,
          });
        } else {
          // @todo: translate error message
          toast.error("Hotel rate is not available!");
        }
      } catch (error: any) {
        handleError({ t, error });
      } finally {
        setTargetRateId(null);
      }
    },
    [t, checkRateStatus, navigate, hotelId, searchId],
  );

  const handleAddToOffer = useCallback(
    async (rateId: number) => {
      try {
        setTargetRateId(rateId);

        await addRate({
          itemId: hotelId,
          itemType: ReservationType.Hotel,
          searchId,
          rateId,
        });
      } catch (error: any) {
        handleError({ t, error });
      } finally {
        setTargetRateId(null);
      }
    },
    [t, addRate, hotelId, searchId],
  );

  const handleRemoveFromOffer = useCallback(
    async (rateId: number, offerItemId: number) => {
      setTargetRateId(rateId);

      try {
        await deleteItem(offerItemId);
      } catch (error: any) {
        handleError({ t, error });
      } finally {
        setTargetRateId(null);
      }
    },
    [t, deleteItem],
  );

  const columns = useMemo(() => {
    return getColumns({
      t,
      searchId,
      hotelId,
      targetRateId,
      offerItems,
      handleCheckRateStatus,
      handleAddToOffer,
      handleRemoveFromOffer,
      isCheckingRateStatus,
      isAddingRate,
      isDeletingItem,
    });
  }, [
    t,
    hotelId,
    searchId,
    targetRateId,
    offerItems,
    handleCheckRateStatus,
    handleAddToOffer,
    handleRemoveFromOffer,
    isCheckingRateStatus,
    isAddingRate,
    isDeletingItem,
  ]);

  if (isLoadingData) {
    return <LoaderBlock />;
  }

  return (
    <>
      {data?.rooms.map((room, index) => (
        <Fragment key={index}>
          <S.Wrapper>
            <S.Headline>{room.name}</S.Headline>
            <MarkupPrice
              value={{ total: room.minPrice, net: room.minPriceNet }}
            >
              <S.PriceWrap>
                <S.Emphasized>
                  {t("searchHotelResults.hotelRoomsModal.from")}
                </S.Emphasized>
                <S.Price>
                  <Price value={room.minPrice} />
                </S.Price>
              </S.PriceWrap>
            </MarkupPrice>
          </S.Wrapper>
          <Accordion
            isInitiallyOpen={index === 0 ? true : false}
            head={({ isOpen }) => (
              <AccordionTriggerBase
                isOpen={isOpen}
                showText={t("searchHotelResults.hotelRoomsModal.showAll")}
                hideText={t("searchHotelResults.hotelRoomsModal.hideAll")}
              />
            )}
          >
            <S.TableWrapper>
              <Table
                data={room.rates}
                columns={columns}
                isLoading={isLoadingData}
              />
            </S.TableWrapper>
          </Accordion>
          {index !== data.rooms.length - 1 && <S.Divider />}
        </Fragment>
      ))}
    </>
  );
};

export default HotelRoomsTable;
