import { borderRadius, pxToRem, spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";
import { prop, theme } from "styled-tools";

import Paper from "../../../elements/Paper";
import TextItalic from "../../../elements/TextItalic";

export const PaperWrapper = styled(Paper)`
  padding: ${spacing(2)}px ${spacing(2)}px ${spacing(1)}px;
`;

export const Styles = styled.div<{
  $tooltip: string | undefined;
}>`
  .rdp {
    margin: 0;
  }

  .rdp-day {
    width: 30px;
    height: 30px;
    background-color: ${theme("palette.alabaster")};
    border-radius: ${borderRadius.medium}px;
  }

  .hp-selected {
    background-color: ${theme("palette.frenchPass")};
  }

  .hp-disabled {
    cursor: not-allowed;
  }

  .hp-range-start,
  .hp-range-end {
    color: ${theme("palette.white")};
    background-color: ${theme("palette.sky")};

    &:hover {
      &:not([disabled]):not(.rdp-day_selected) {
        background-color: ${theme("palette.bahamaBlue")};
      }
    }
  }

  .hp-range-end {
    &.hp-range-active {
      position: relative;
      overflow: visible;

      &:before {
        content: "${prop("$tooltip")}";
        position: absolute;
        width: max-content;
        padding: ${spacing(0.25)}px ${spacing(0.5)}px;
        border-radius: ${borderRadius.normal}px;
        top: -30px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        font-size: ${pxToRem(11)};
        font-weight: 600;
        color: ${theme("palette.white")};
        background-color: ${theme("palette.black")};
      }

      &:after {
        content: "";
        position: absolute;
        top: -50%;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-style: solid;
        border-right: 8px solid transparent;
        border-left: 8px solid transparent;
        border-top: 6px solid ${theme("palette.black")};
        border-bottom: 0;
      }
    }
  }
`;

export const Footer = styled(TextItalic)`
  display: flex;
  justify-content: flex-end;
  font-size: ${pxToRem(14)};
  font-weight: 600;
  margin-top: ${spacing(2)}px;
`;
