import * as S from "./PageSplitLayout.styled";

interface PageSplitLayoutProps {
  children: React.ReactNode | React.ReactNode;
  wide?: boolean;
}

const PageSplitLayout = ({ children, wide = false }: PageSplitLayoutProps) => {
  return <S.Wrapper $wide={wide}>{children}</S.Wrapper>;
};

PageSplitLayout.Sidebar = S.Sidebar;
PageSplitLayout.Content = S.Content;

export default PageSplitLayout;
