import {
  Box,
  Button,
  Flex,
  Form,
  FormAdapter,
  FormCheckbox,
  FormClearableInput,
  FormContext,
  FormLayout,
} from "@hotelspoint/components";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import useAdminMealConfigurationParams from "../useAdminMealConfigurationParams";
import {
  entity2Form,
  form2Entity,
  FormValues,
} from "./AdminMealConfigurationFilters.form";

interface AdminMealConfigurationFiltersProps {
  isLoading: boolean;
}

const AdminMealConfigurationFilters = ({
  isLoading,
}: AdminMealConfigurationFiltersProps) => {
  const { t } = useTranslation();

  const [query, setQuery] = useAdminMealConfigurationParams();

  const formValues = useMemo(() => {
    return entity2Form(query);
  }, [query]);

  const onSubmit = (formValues: FormValues) => {
    const payload = form2Entity(formValues);

    setQuery(payload);
  };

  return (
    <Form defaultValues={formValues}>
      <FormLayout>
        <Flex mx={[0, 0, -1, -1]}>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="id"
              label={t("adminMealConfiguration.filters.id.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  placeholder={t(
                    "adminMealConfiguration.filters.id.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="name"
              label={t("adminMealConfiguration.filters.name.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  placeholder={t(
                    "adminMealConfiguration.filters.name.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="mainName"
              label={t("adminMealConfiguration.filters.mainName.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  placeholder={t(
                    "adminMealConfiguration.filters.mainName.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            width={[1, 1, 1 / 2, 1 / 4]}
            px={[0, 0, 1, 1]}
            py={1}
          >
            <FormAdapter
              name="isMapped"
              label={t("adminMealConfiguration.filters.isMapped.label")}
              formControlProps={{ labelPosition: "inline-end" }}
            >
              {props => <FormCheckbox {...props} />}
            </FormAdapter>
          </Box>
        </Flex>
        <FormContext
          render={({ handleSubmit }) => (
            <Button
              type="submit"
              variant="secondary"
              isLoading={isLoading}
              onClick={handleSubmit(onSubmit)}
            >
              {t("adminMealConfiguration.filters.submit")}
            </Button>
          )}
        />
      </FormLayout>
    </Form>
  );
};

export default AdminMealConfigurationFilters;
