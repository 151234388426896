import { createContext, useContext } from "react";

import { TabsProps } from "./types";

type ContextType = Pick<TabsProps, "value" | "onChange"> | null;

const TabsContext = createContext<ContextType>(null);

export const useTabsContext = () => {
  const context = useContext(TabsContext);

  if (context == null) {
    throw new Error("Tab components must be wrapped in <Tabs />!");
  }

  return context;
};

export default TabsContext;
