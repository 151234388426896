export interface FileWithPreview extends File {
  src: string;
}

const createImagePreview = (file: File): FileWithPreview => {
  const newFile = Object.assign(
    new File([file], file.name, { type: file.type }),
    {
      src: URL.createObjectURL(file),
    },
  );

  return newFile;
};

export default createImagePreview;
