import { useTranslation } from "react-i18next";

import Button from "../../elements/Button";
import * as S from "./NewVersionToast.styled";

interface NewVersionToastProps {
  onConfirm: () => void;
}

const NewVersionToast = ({ onConfirm }: NewVersionToastProps) => {
  const { t } = useTranslation();

  return (
    <S.Wrapper>
      <p>{t("toast.newVersion.title")}</p>
      <Button small variant="secondary" onClick={onConfirm}>
        {t("toast.newVersion.action")}
      </Button>
    </S.Wrapper>
  );
};

export default NewVersionToast;
