import { SCHEMA_OTP } from "@hotelspoint/utils";
import { InferType, object } from "yup";

export const validationSchema = object({
  otp: SCHEMA_OTP,
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: FormValues = {
  otp: "",
};
