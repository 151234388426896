import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import FormLabel from "../FormLabel";
import * as S from "./FormControl.styled";
import { FormControlLabelPosition } from "./types";

export interface FormControlInjectedProps {
  id: string;
  name: string;
  value: any;
  error?: string;
  onChange: (...args: any[]) => any;
}

export interface FormControlProps
  extends Omit<FormControlInjectedProps, "id">,
    Omit<React.HTMLAttributes<HTMLLabelElement>, "children" | "onChange"> {
  children: (props: FormControlInjectedProps) => React.ReactNode;
  // Component specific props
  label?: string | React.ReactNode;
  isRequired?: boolean;
  labelPosition?: FormControlLabelPosition;
}

const FormControl = ({
  name,
  value,
  error,
  onChange,
  children,
  label,
  isRequired,
  labelPosition = "start",
  ...labelProps
}: FormControlProps) => {
  const { t } = useTranslation();

  const render = useMemo(
    () =>
      children({
        id: name,
        name,
        value,
        error,
        onChange,
      }),
    [children, name, value, error, onChange],
  );

  return (
    <S.Wrapper>
      <S.LabelWrapper $position={labelPosition}>
        {label && (
          <FormLabel id={`label-${name}`} htmlFor={name} {...labelProps}>
            {label}
            {isRequired && <S.Required>*</S.Required>}
          </FormLabel>
        )}
        <>{render}</>
      </S.LabelWrapper>
      {error && <S.Error>{t(error)}</S.Error>}
    </S.Wrapper>
  );
};

export default FormControl;
