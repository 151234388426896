import { useAdminSupplierStatement } from "@hotelspoint/api";
import { Panel } from "@hotelspoint/components";
import { useDocumentTitle } from "@hotelspoint/utils";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import AdminSupplierStatementForm from "./AdminSupplierStatementForm";
import AdminSupplierStatementPayments from "./AdminSupplierStatementPayments";
import AdminSupplierStatementsDocuments from "./AdminSupplierStatementsDocuments";
import AdminSupplierStatementItems from "./AdminSupplierStatementsItems";

interface AdminSupplierStatementParams extends Record<string, string> {
  id: string;
}

const AdminSupplierStatement = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("adminSupplierStatement.pageTitle"));

  const { id } = useParams<AdminSupplierStatementParams>();
  const supplierStatementId = Number(id);

  const [statement, isLoading] = useAdminSupplierStatement(supplierStatementId);

  const data = useMemo(() => statement?.items ?? [], [statement]);

  return (
    <Panel title={t("adminSupplierStatement.pageTitle")}>
      <AdminSupplierStatementForm id={supplierStatementId} />
      <AdminSupplierStatementsDocuments id={supplierStatementId} />
      <AdminSupplierStatementPayments id={supplierStatementId} />
      <AdminSupplierStatementItems data={data} isLoading={isLoading} />
    </Panel>
  );
};

export default AdminSupplierStatement;
