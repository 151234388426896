import {
  AdminSupplierStatementLoadReservationsResponse,
  handleError,
  useCreateAdminSupplierStatement,
  useLoadAdminSupplierStatementReservations,
} from "@hotelspoint/api";
import {
  Box,
  Button,
  Chip,
  Flex,
  Form,
  FormAdapter,
  FormChildrenMethods,
  FormClearableInput,
  FormContext,
  FormInput,
  FormLayout,
  FormSelect,
  FormTextArea,
  Panel,
  StatusBlock,
  StatusFlavour,
} from "@hotelspoint/components";
import {
  CHIP_COLORS,
  STATEMENT_SUPPLIER_OPTIONS,
  SUPPLIER_STATUS_OPTIONS,
} from "@hotelspoint/constants";
import { UserCurrency } from "@hotelspoint/types";
import {
  getSupplierStatementStatusColor,
  isNumeric,
  useDocumentTitle,
} from "@hotelspoint/utils";
import sumBy from "lodash/sumBy";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import AdminSupplierStatementItems from "../AdminSupplierStatement/AdminSupplierStatementsItems";
import * as S from "./AdminSupplierStatementsCreate.styled";
import {
  defaultValues,
  form2Entity,
  form2EntityLoad,
  FormValues,
  validationSchema,
} from "./AdminSupplierStatementsCreate.util";

const AdminSupplierStatementCreateInner = ({
  setValue,
}: FormChildrenMethods<FormValues>) => {
  const { t } = useTranslation();
  useDocumentTitle(t("adminSupplierStatementsCreate.pageTitle"));

  const navigate = useNavigate();

  const [additionalCharges] = useWatch({ name: ["additionalCharges"] });
  const [reservationIds] = useWatch({ name: ["reservationIds"] });
  const [voucherCodes] = useWatch({ name: ["voucherCodes"] });

  const [createStatement, isCreating] = useCreateAdminSupplierStatement();
  const [loadReservations, isLoadingReservations] =
    useLoadAdminSupplierStatementReservations();

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [response, setResponse] =
    useState<AdminSupplierStatementLoadReservationsResponse | null>(null);

  const items = useMemo(() => response?.reservations ?? [], [response]);
  const errors = useMemo(() => response?.errors, [response]);
  const warnings = useMemo(() => response?.warnings, [response]);

  const onLoadReservations = useCallback(
    async (formValues: FormValues) => {
      try {
        const payload = form2EntityLoad(formValues);

        const response = await loadReservations(payload);

        const isSuccess = Object.values({
          ...response.warnings,
          ...response.errors,
        }).every(arr => arr.length === 0);

        setResponse(response);
        setIsSubmitDisabled(!isSuccess);
      } catch (error: any) {
        setResponse(null);
        setIsSubmitDisabled(true);
        handleError({ t, error });
      }
    },
    [loadReservations, t],
  );

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        const payload = form2Entity(formValues);

        const response = await createStatement(payload);
        toast.success(t("toast.adminSupplierStatement.createStatement"));

        navigate(`/admin/statements/supplier/${response.id}`);
      } catch (error: any) {
        handleError({ t, error });
      }
    },
    [createStatement, navigate, t],
  );

  const itemsSum = useMemo(() => {
    if (Array.isArray(items)) {
      return sumBy(items, "priceBase");
    }

    return 0;
  }, [items]);

  const totalAmount = useMemo(() => {
    if (isNumeric(additionalCharges)) {
      return Number(additionalCharges) + itemsSum;
    } else {
      return itemsSum;
    }
  }, [additionalCharges, itemsSum]);

  // Set the total & remaining amounts when the value changes
  useEffect(() => {
    if (totalAmount) {
      setValue("totalAmount", totalAmount);
      setValue("remainingAmount", totalAmount);
    }
  }, [setValue, totalAmount]);

  // Set the reservations amount when the items sum changes
  useEffect(() => {
    if (itemsSum > 0) {
      setValue("reservationsAmount", itemsSum);
    }
  }, [setValue, itemsSum]);

  const statementSupplierOptions = STATEMENT_SUPPLIER_OPTIONS.map(option => ({
    ...option,
    label: <Chip $color={CHIP_COLORS.SUPPLIER}>{option.label}</Chip>,
  }));

  const statusOptions = useMemo(
    () =>
      SUPPLIER_STATUS_OPTIONS.map(status => ({
        ...status,
        label: (
          <Chip $color={getSupplierStatementStatusColor(status.value)}>
            {t(status.label)}
          </Chip>
        ),
      })),
    [t],
  );

  return (
    <Panel title={t("adminSupplierStatementsCreate.pageTitle")}>
      <FormLayout>
        <Flex mx={[0, 0, -1, -1]}>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="supplierId"
              label={t("adminSupplierStatementsCreate.form.supplierId.label")}
            >
              {props => (
                <FormSelect
                  {...props}
                  placeholder={t(
                    "adminSupplierStatementsCreate.form.supplierId.placeholder",
                  )}
                  options={statementSupplierOptions}
                  isClearable
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="supplierStatementNumber"
              label={t(
                "adminSupplierStatementsCreate.form.supplierStatementNumber.label",
              )}
            >
              {props => <FormClearableInput {...props} />}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="status"
              label={t("adminSupplierStatementsCreate.form.status.label")}
            >
              {props => (
                <FormSelect {...props} options={statusOptions} readOnly />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="totalAmount"
              label={t("adminSupplierStatementsCreate.form.totalAmount.label")}
            >
              {props => (
                <FormInput
                  {...props}
                  readOnly
                  adornmentStyle="filled"
                  endAdornment={UserCurrency.EUR}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="remainingAmount"
              label={t(
                "adminSupplierStatementsCreate.form.remainingAmount.label",
              )}
            >
              {props => (
                <FormInput
                  {...props}
                  readOnly
                  adornmentStyle="filled"
                  endAdornment={UserCurrency.EUR}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="payedAmount"
              label={t("adminSupplierStatementsCreate.form.payedAmount.label")}
            >
              {props => (
                <FormInput
                  {...props}
                  readOnly
                  adornmentStyle="filled"
                  endAdornment={UserCurrency.EUR}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="reservationsAmount"
              label={t(
                "adminSupplierStatementsCreate.form.reservationsAmount.label",
              )}
            >
              {props => (
                <FormInput
                  {...props}
                  readOnly
                  adornmentStyle="filled"
                  endAdornment={UserCurrency.EUR}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="additionalCharges"
              label={t(
                "adminSupplierStatementsCreate.form.additionalCharges.label",
              )}
            >
              {props => (
                <FormInput
                  {...props}
                  adornmentStyle="filled"
                  endAdornment={UserCurrency.EUR}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="comments"
              label={t("adminSupplierStatementsCreate.form.comments.label")}
            >
              {props => <FormTextArea {...props} />}
            </FormAdapter>
          </Box>
        </Flex>
        <S.Divider />
        <Flex mx={[0, 0, -1, -1]}>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="reservationIds"
              label={t(
                "adminSupplierStatementsCreate.form.reservationIds.label",
              )}
            >
              {props => (
                <FormTextArea {...props} disabled={voucherCodes.length !== 0} />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="voucherCodes"
              label={t("adminSupplierStatementsCreate.form.voucherCodes.label")}
            >
              {props => (
                <FormTextArea
                  {...props}
                  disabled={reservationIds.length !== 0}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="prices"
              label={t("adminSupplierStatementsCreate.form.prices.label")}
            >
              {props => <FormTextArea {...props} />}
            </FormAdapter>
          </Box>
          <Box
            width={[1, 1, 1 / 2, 1 / 4]}
            px={[0, 0, 1, 1]}
            py={1}
            marginTop={22}
          >
            <FormContext<FormValues>
              render={({ handleSubmit }) => (
                <Button
                  type="submit"
                  variant="outlined"
                  isLoading={isLoadingReservations}
                  onClick={handleSubmit(onLoadReservations)}
                >
                  {t("adminSupplierStatementsCreate.load")}
                </Button>
              )}
            />
          </Box>
        </Flex>
        <FormContext<FormValues>
          render={({ handleSubmit }) => (
            <Button
              type="submit"
              variant="secondary"
              isLoading={isCreating}
              onClick={handleSubmit(onSubmit)}
              isDisabled={isSubmitDisabled}
            >
              {t("adminSupplierStatementsCreate.submit")}
            </Button>
          )}
        />
        {warnings &&
          Object.entries(warnings)
            .filter(([key]) => ["status", "prices"].includes(key))
            .filter(([, value]) => value?.length !== 0)
            .map(([key, value]) => (
              <StatusBlock status={StatusFlavour.Warning}>
                <h6>
                  {t(
                    `adminSupplierStatementsCreate.response.warnings.${key}.title`,
                  )}
                </h6>
                <p>
                  {t(
                    `adminSupplierStatementsCreate.response.warnings.${key}.description`,
                    {
                      value: value.join(" ,"),
                    },
                  )}
                </p>
              </StatusBlock>
            ))}
        {errors &&
          Object.entries(errors)
            .filter(([key]) =>
              ["missing", "supplier", "duplicate"].includes(key),
            )
            .filter(([, value]) => value?.length !== 0)
            .map(([key, value]) => (
              <StatusBlock status={StatusFlavour.Error}>
                <h6>
                  {t(
                    `adminSupplierStatementsCreate.response.errors.${key}.title`,
                  )}
                </h6>
                <p>
                  {t(
                    `adminSupplierStatementsCreate.response.errors.${key}.description`,
                    {
                      value: value.join(" ,"),
                    },
                  )}
                </p>
              </StatusBlock>
            ))}
        <AdminSupplierStatementItems
          data={items}
          isLoading={isLoadingReservations}
        />
      </FormLayout>
    </Panel>
  );
};

const AdminSupplierStatementCreate = () => {
  return (
    <Form<FormValues>
      defaultValues={defaultValues}
      validationSchema={validationSchema}
    >
      {formMethods => <AdminSupplierStatementCreateInner {...formMethods} />}
    </Form>
  );
};

export default AdminSupplierStatementCreate;
