import {
  AdminActivityRequestType,
  AdminHotelRequestType,
  AdminPaymentRequestType,
  AdminTransferRequestType,
} from "@hotelspoint/api";

export const getHotelRequestTypeName = (type: AdminHotelRequestType) => {
  switch (type) {
    case AdminHotelRequestType.Search:
      return "hotelRequestType.search";

    case AdminHotelRequestType.Fees:
      return "hotelRequestType.fees";

    case AdminHotelRequestType.Remarks:
      return "hotelRequestType.remarks";

    case AdminHotelRequestType.ReservePrepend:
      return "hotelRequestType.reservePrepend";

    case AdminHotelRequestType.ReserveConfirm:
      return "hotelRequestType.reserveConfirm";

    case AdminHotelRequestType.ReserveAbort:
      return "hotelRequestType.reserveAbort";

    case AdminHotelRequestType.ReserveCancel:
      return "hotelRequestType.reserveCancel";

    case AdminHotelRequestType.ReserveCheck:
      return "hotelRequestType.reserveCheck";

    case AdminHotelRequestType.ReserveFees:
      return "hotelRequestType.reserveFees";

    default:
      return "Unknown hotel request type";
  }
};

export const getTransferRequestTypeName = (type: AdminTransferRequestType) => {
  switch (type) {
    case AdminTransferRequestType.Search:
      return "transferRequestType.search";

    case AdminTransferRequestType.ReservePrepare:
      return "transferRequestType.reservePrepare";

    case AdminTransferRequestType.ReserveConfirm:
      return "transferRequestType.reserveConfirm";

    case AdminTransferRequestType.ReserveCancel:
      return "transferRequestType.reserveCancel";

    case AdminTransferRequestType.ReserveStatus:
      return "transferRequestType.reserveStatus";

    default:
      return "Unknown transfer request type";
  }
};

export const getActivityRequestTypeName = (type: AdminActivityRequestType) => {
  switch (type) {
    case AdminActivityRequestType.Search:
      return "activityRequestType.search";

    case AdminActivityRequestType.SearchDetails:
      return "activityRequestType.searchDetails";

    case AdminActivityRequestType.SearchOptions:
      return "activityRequestType.searchOptions";

    case AdminActivityRequestType.ReservePrepare:
      return "activityRequestType.reservePrepare";

    case AdminActivityRequestType.ReserveConfirm:
      return "activityRequestType.reserveConfirm";

    case AdminActivityRequestType.ReserveCancel:
      return "activityRequestType.reserveCancel";

    case AdminActivityRequestType.ReserveStatus:
      return "activityRequestType.reserveStatus";

    default:
      return "Unknown activity request type";
  }
};

export const getPaymentRequestTypeName = (type: AdminPaymentRequestType) => {
  switch (type) {
    case AdminPaymentRequestType.CardList:
      return "paymentRequestType.cardList";

    case AdminPaymentRequestType.PaymentRegister:
      return "paymentRequestType.paymentRegister";

    case AdminPaymentRequestType.PaymentStatus:
      return "paymentRequestType.paymentStatus";

    case AdminPaymentRequestType.PaymentConfirm:
      return "paymentRequestType.paymentConfirm";

    case AdminPaymentRequestType.PaymentCancel:
      return "paymentRequestType.paymentCancel";

    case AdminPaymentRequestType.PaymentRefund:
      return "paymentRequestType.paymentRefund";

    default:
      return "Unknown payment request type";
  }
};
