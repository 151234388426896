import { PageWrapper } from "@hotelspoint/components";
import { mediaQuery, spacing } from "@hotelspoint/theme";
import styled from "styled-components";

// Map needs to be attached to the Footer
export const CustomPageWrapper = styled(PageWrapper)`
  margin-bottom: ${spacing(12)}px;
  min-height: initial;
`;

export const Section = styled.div`
  @media ${mediaQuery.tablet} {
    text-align: center;
  }
`;

export const SectionImage = styled.img`
  height: 50px;
`;

export const SectionDescription = styled.p`
  margin-top: ${spacing(1.5)}px;
`;

export const Separator = styled.hr`
  margin: ${spacing(2)}px 0;
`;

export const ContactDetail = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${spacing(1.5)}px;

  @media ${mediaQuery.tablet} {
    justify-content: center;
    text-align: center;
  }
`;
