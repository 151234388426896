import {
  handleError,
  HotelSearchPayload,
  useAddHotelToFavorites,
  useHotelAvailabilitySearch,
  useHotelDetails,
  useHotelSearchResults,
  usePlace,
  useRemoveHotelFromFavorites,
} from "@hotelspoint/api";
import {
  Breadcrumb,
  BreadcrumbContainer,
  Button,
  Container,
  HotelListing,
  LoaderBlock,
  Minimap,
  PageSpacer,
  PageSplitLayout,
  PageWrapper,
  PlaceTypeIcon,
  Tab,
  Tabs,
  VisibleMobile,
} from "@hotelspoint/components";
import {
  HotelSearch,
  PlaceType,
  SearchHotelsResultsModalType,
} from "@hotelspoint/types";
import { SearchHotelsResultsModal } from "@hotelspoint/types";
import { IconBed, IconHeartFilled, IconReload } from "@tabler/icons-react";
import omit from "lodash/omit";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

import { SearchHotelsViewState } from "../types";
import useHotelSearchParams from "../useHotelSearchParams";
import HotelDetailsGalleryModal from "./HotelDetailsModal/HotelDetailsGalleryModal";
import HotelRoomsModal from "./HotelRoomsModal";
import SearchHotelsForm from "./SearchHotelsForm";
import * as S from "./SearchHotelsResults.styled";

const SearchHotelsResults = () => {
  const { t } = useTranslation();

  const [query, setQuery] = useHotelSearchParams();

  const [searchId, setSearchId] = useState<number | undefined>(undefined);
  const [hotels, setHotels] = useState<HotelSearch[]>([]);
  const [hotelsCount, setHotelsCount] = useState<number | undefined>(undefined);
  const [pageIndex, setPageIndex] = useState(1);
  const [initialGalleryIndex, setInitialGalleryIndex] = useState(0);

  const [activeModal, setActiveModal] = useState<
    SearchHotelsResultsModal | undefined
  >(undefined);

  const [hotelDetails, isLoadingHotelDetails] = useHotelDetails(
    activeModal?.hotelId as number,
  );

  const [addToFavorites] = useAddHotelToFavorites();
  const [removeFromFavorites] = useRemoveHotelFromFavorites();

  const [place, isLoadingPlace] = usePlace({
    id: query.placeId as number,
    type: query.placeType as PlaceType,
  });

  const searchParams = omit(query, "view");
  const [initialSearch, isLoadingInitialSearch, { refetch }] =
    useHotelAvailabilitySearch(searchParams as HotelSearchPayload);

  const [searchResults, isLoadingSearchResults] = useHotelSearchResults(
    searchId as number,
    { pageIndex },
  );

  const isSearchCompleted = useMemo(() => {
    return initialSearch?.isCompleted || searchResults?.isCompleted;
  }, [initialSearch, searchResults]);

  const breadcrumbs = useMemo(
    () => [
      {
        title: t("searchHotels.pageTitle"),
        href: "/search/hotels",
        icon: () => <IconBed size={18} />,
      },
      ...(place?.parents
        ? place.parents.map(placeParent => ({
            title: placeParent.name as string,
            icon: () => (
              <PlaceTypeIcon
                type={placeParent.type as PlaceType}
                iconProps={{ size: 18 }}
              />
            ),
          }))
        : []),
      {
        title: place?.name as string,
        icon: () => (
          <PlaceTypeIcon
            type={place?.type as PlaceType}
            iconProps={{ size: 18 }}
          />
        ),
      },
    ],
    [t, place],
  );

  const minimap = useMemo(
    () => (
      <Minimap>
        <Tabs
          value={query.view as string}
          onChange={value => setQuery({ view: value as string })}
        >
          <Tab value={SearchHotelsViewState.List}>
            {t("searchHotelResults.viewToggle.list")}
          </Tab>
          <Tab value={SearchHotelsViewState.Map}>
            {t("searchHotelResults.viewToggle.map")}
          </Tab>
        </Tabs>
      </Minimap>
    ),
    [t, query, setQuery],
  );

  const handleFavoriteChange =
    (hotelId: number) => async (isFavorite: boolean) => {
      try {
        if (isFavorite) {
          await removeFromFavorites(hotelId);
        } else {
          await addToFavorites(hotelId);
        }
      } catch (error: any) {
        handleError({ t, error });
      }
    };

  const handleCloseModal = () => {
    setActiveModal(undefined);
  };

  useEffect(() => {
    if (initialSearch?.id) {
      const { isCompleted, id, results, total } = initialSearch;

      if (isCompleted) {
        // Update the initial set of hotels & count
        setHotels(results);
        setHotelsCount(total);
      } else {
        // Set the searchId when the initial call is not complete
        setSearchId(id);

        // Reset the page index when a new search is made
        setPageIndex(1);

        // If the initial search has hotels, update the state
        if (hotels.length !== 0) {
          setHotels(hotels);
          setHotelsCount(total);
        }
      }
    }
  }, [initialSearch, hotels]);

  // Update the internal state when results are fetched
  useEffect(() => {
    if (searchResults) {
      if (pageIndex === 1) {
        setHotels(searchResults.results);
      } else {
        setHotels(prevHotels => [...prevHotels, ...searchResults.results]);
      }

      setHotelsCount(searchResults.total);
    }
  }, [searchResults, pageIndex]);

  return (
    <PageWrapper>
      <HotelDetailsGalleryModal
        hotel={hotelDetails}
        isLoading={isLoadingHotelDetails}
        activeModal={activeModal}
        initialGalleryIndex={initialGalleryIndex}
        setInitialGalleryIndex={setInitialGalleryIndex}
        setActiveModal={setActiveModal}
      />
      <HotelRoomsModal
        isOpen={activeModal?.type === SearchHotelsResultsModalType.Rooms}
        searchId={searchId as number}
        hotelId={activeModal?.hotelId as number}
        onClose={handleCloseModal}
      />
      <Container>
        <PageSpacer>
          <BreadcrumbContainer>
            {isLoadingPlace ? (
              <Skeleton width={120} height={10} />
            ) : (
              <Breadcrumb items={breadcrumbs} />
            )}
          </BreadcrumbContainer>
          <VisibleMobile>{minimap}</VisibleMobile>
          <PageSplitLayout>
            <PageSplitLayout.Sidebar>
              <div>{minimap}</div>
              <S.SidebarCard>
                <SearchHotelsForm refetch={refetch} />
              </S.SidebarCard>
              <div>
                <h5 style={{ marginBottom: 12 }}>Filters</h5>
                <S.SidebarCard>
                  <Button variant="outlined" fullWidth>
                    <IconHeartFilled size={18} style={{ color: "#F96846" }} />
                    My Favorites
                  </Button>
                </S.SidebarCard>
              </div>
            </PageSplitLayout.Sidebar>
            <PageSplitLayout.Content>
              <h3>
                {isLoadingPlace || hotelsCount === undefined ? (
                  <Skeleton height={20} />
                ) : isSearchCompleted ? (
                  t("searchHotelResults.count", {
                    count: hotelsCount,
                    place: place?.name,
                  })
                ) : (
                  t("searchHotelResults.countPending", {
                    place: place?.name,
                  })
                )}
              </h3>
              <S.HotelListingWrapper>
                {isLoadingInitialSearch ? (
                  <LoaderBlock>
                    <h6>{t("searchHotelResults.loading")}</h6>
                  </LoaderBlock>
                ) : (
                  <>
                    {hotels.map(hotel => (
                      <HotelListing
                        key={hotel.id}
                        id={hotel.id}
                        name={hotel.name}
                        cityName={hotel.cityName}
                        thumbnail={hotel.thumbnail}
                        rating={hotel.rating}
                        isFavorite={hotel.isFavorite}
                        address={hotel.address}
                        gpsLocation={{
                          latitude: hotel.latitude,
                          longitude: hotel.longitude,
                        }}
                        rate={hotel.cheapestRate}
                        handleFavorite={handleFavoriteChange(hotel.id)}
                        handleShowOnMap={gpsLocation =>
                          console.log(gpsLocation)
                        }
                        handleShowDetails={() => {
                          setActiveModal({
                            hotelId: hotel.id,
                            type: SearchHotelsResultsModalType.Details,
                          });
                        }}
                        handleShowRooms={() => {
                          setActiveModal({
                            hotelId: hotel.id,
                            type: SearchHotelsResultsModalType.Rooms,
                          });
                        }}
                        handleThumbnailClick={() => {
                          setInitialGalleryIndex(0);
                          setActiveModal({
                            hotelId: hotel.id,
                            type: SearchHotelsResultsModalType.Gallery,
                          });
                        }}
                      />
                    ))}
                    {hotels?.length !== 0 && (
                      <S.LoadMoreWrapper>
                        <Button
                          variant="outlined"
                          onClick={() => setPageIndex(pageIndex + 1)}
                          isDisabled={hotels.length === hotelsCount}
                          isLoading={isLoadingSearchResults}
                        >
                          {!isLoadingSearchResults && <IconReload size={18} />}
                          <span>{t("searchHotelResults.loadMore")}</span>
                        </Button>
                      </S.LoadMoreWrapper>
                    )}
                  </>
                )}
              </S.HotelListingWrapper>
            </PageSplitLayout.Content>
          </PageSplitLayout>
        </PageSpacer>
      </Container>
    </PageWrapper>
  );
};

export default SearchHotelsResults;
