import { useHotelDetails } from "@hotelspoint/api";
import { Button, Panel } from "@hotelspoint/components";
import {
  SearchHotelsResultsModal,
  SearchHotelsResultsModalType,
} from "@hotelspoint/types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

import { HotelBaseInfo } from "../../../../Search/SearchHotels/components";
import HotelDetailsGalleryModal from "../../../../Search/SearchHotels/SearchHotelsResults/HotelDetailsModal/HotelDetailsGalleryModal";
import * as S from "./ReservationHotelInfo.styled";

interface ReservationHotelInfoProps {
  id: number;
  isLoading: boolean;
}

const ReservationHotelInfo = ({ id, isLoading }: ReservationHotelInfoProps) => {
  const { t } = useTranslation();

  const [initialGalleryIndex, setInitialGalleryIndex] = useState(0);
  const [activeModal, setActiveModal] = useState<
    SearchHotelsResultsModal | undefined
  >(undefined);

  const hotelId = Number(id);
  const [hotelDetails, isLoadingHotelDetails] = useHotelDetails(hotelId);

  return (
    <>
      <HotelDetailsGalleryModal
        hotel={hotelDetails}
        isLoading={isLoading}
        activeModal={activeModal}
        initialGalleryIndex={initialGalleryIndex}
        setInitialGalleryIndex={setInitialGalleryIndex}
        setActiveModal={setActiveModal}
      />
      <Panel title={t("profileReservation.hotel")}>
        <S.HotelWrapper>
          <HotelBaseInfo
            isLoading={isLoadingHotelDetails}
            name={hotelDetails?.name}
            rating={hotelDetails?.rating}
            address={hotelDetails?.address}
            cityName={hotelDetails?.cityName}
          />
          {isLoadingHotelDetails ? (
            <Skeleton width={150} height={40} />
          ) : (
            <Button
              variant="outlined"
              onClick={() =>
                setActiveModal({
                  hotelId: hotelDetails?.id as number,
                  type: SearchHotelsResultsModalType.Details,
                })
              }
            >
              {t("profileReservation.hotelDetails")}
            </Button>
          )}
        </S.HotelWrapper>
      </Panel>
    </>
  );
};

export default ReservationHotelInfo;
