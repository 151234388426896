import { ReservationItemData } from "@hotelspoint/api";
import { Panel } from "@hotelspoint/components";
import { useTranslation } from "react-i18next";

import * as S from "./ReservationGuests.styled";

interface ReservationGuestsProps {
  itemData: ReservationItemData;
}

const ReservationGuests = ({ itemData }: ReservationGuestsProps) => {
  const { t } = useTranslation();
  return (
    <Panel title={t("profileReservation.quests")}>
      {itemData.names.map((name, nameIndex) => (
        <div key={nameIndex}>
          <S.RoomName>{itemData.rooms[nameIndex].roomName}</S.RoomName>
          <S.ListWrapper>
            <div>
              <S.Title>{t("profileReservation.adults")}</S.Title>
              <S.NameList>
                {name.adults.map((adult, adultIndex) => (
                  <S.NameListItem key={adultIndex}>
                    {/* will add the titles after we merge the HS-92 PR */}
                    {adult.title} {adult.name} {adult.surname}
                  </S.NameListItem>
                ))}
              </S.NameList>
            </div>
            {name.children.length > 0 && (
              <div>
                <S.Title>{t("profileReservation.children")}</S.Title>
                <S.NameList>
                  {name.children.map((child, childIndex) => (
                    <S.NameListItem key={childIndex}>
                      {child.title} {child.name} {child.surname}
                    </S.NameListItem>
                  ))}
                </S.NameList>
              </div>
            )}
          </S.ListWrapper>
        </div>
      ))}
    </Panel>
  );
};

export default ReservationGuests;
