import { isNumeric } from "@hotelspoint/utils";
import { InferType, object, string } from "yup";

export const validationSchema = object({
  id: string(),
  name: string(),
  cityName: string(),
  destinationName: string(),
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: FormValues = {
  id: "",
  name: "",
  cityName: "",
  destinationName: "",
};

export const entity2Form = (query: any): FormValues => {
  return {
    id: query.id,
    name: query.name,
    cityName: query.cityName,
    destinationName: query.destinationName,
  };
};

export const form2Entity = (formValues: FormValues) => {
  return {
    id: isNumeric(formValues.id) ? Number(formValues.id) : undefined,
    name: formValues.name,
    cityName: formValues.cityName,
    destinationName: formValues.destinationName,
  };
};
