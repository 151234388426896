import { borderRadius, spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";
import { theme } from "styled-tools";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: fit-content;
`;

export const InnerWrapper = styled.button`
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${borderRadius.circle};
  background: ${theme("palette.geyser")};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: ${borderRadius.circle};
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${spacing(1)}px;
`;
