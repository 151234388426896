import { Button, LinkExternal } from "@hotelspoint/components";
import { useUserTokenStore } from "@hotelspoint/store";
import { getApiUrl } from "@hotelspoint/utils";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface AdminAgencyStatementActionsProps {
  id: number;
}

const AdminAgencyStatementActions = ({
  id,
}: AdminAgencyStatementActionsProps) => {
  const { t } = useTranslation();

  const token = useUserTokenStore(state => state.token);

  const downloadUrl = useMemo(() => {
    return getApiUrl(`admin/statements/${id}/download`, { token });
  }, [id, token]);

  return (
    <LinkExternal to={downloadUrl}>
      <Button variant="secondary" small>
        {t("adminAgencyStatement.actions.download")}
      </Button>
    </LinkExternal>
  );
};

export default AdminAgencyStatementActions;
