import { mediaQuery, spacing } from "@hotelspoint/theme";
import styled from "styled-components";
import { theme } from "styled-tools";

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 ${spacing(2)}px;
  background: ${theme("palette.cinderella")};
`;

export const Heading = styled.h1`
  font-weight: 500;
  margin: ${spacing(1)}px 0;

  @media ${mediaQuery.tablet} {
    margin: 0;
  }
`;

export const SubHeading = styled.h5`
  max-width: 420px;
`;

export const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${spacing(2)}px;
  flex-direction: column;
  text-align: center;

  @media ${mediaQuery.tablet} {
    flex-direction: row;
    text-align: left;
  }
`;

export const ActionWrapper = styled.div`
  margin-top: ${spacing(2)}px;

  @media ${mediaQuery.tablet} {
    margin-top: ${spacing(3)}px;
  }
`;
