import { useTabsContext } from "../TabsContext";
import * as S from "./Tab.styled";

interface TabProps {
  value: string | number;
  children: React.ReactNode;
}

const Tab = ({ value, children }: TabProps) => {
  const { value: activeTabId, onChange } = useTabsContext();
  const isActive = value === activeTabId;

  return (
    <S.Wrapper
      $active={isActive}
      role="tab"
      aria-selected={isActive}
      onClick={() => onChange(value)}
    >
      {children}
    </S.Wrapper>
  );
};

export default Tab;
