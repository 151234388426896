import { borderRadius, pxToRem, spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";
import { theme } from "styled-tools";

export const Calendar = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing(2)}px;
  list-style: none;
`;

export const Week = styled.ul`
  display: flex;
  column-gap: ${spacing(2)}px;
  list-style: none;
`;

export const Date = styled.p`
  font-size: ${pxToRem(14)};
`;

export const Price = styled.p`
  font-weight: 600;
  font-size: ${pxToRem(14)};
`;

export const DayTitle = styled.li`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 70px;
  text-align: center;
`;

export const Day = styled.button`
  flex: 1;
  min-width: 70px;
  display: flex;
  flex-direction: column;
  row-gap: ${spacing(0.5)}px;
  align-items: center;
  justify-content: center;
  padding: ${spacing(1.5)}px;
  background-color: ${theme("palette.alabaster")};
  border-radius: ${borderRadius.medium}px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  opacity: 1;
  text-align: center;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:focus:not(:disabled) {
    border-color: ${theme("palette.sky")};
  }

  &:hover:not(:disabled) {
    background-color: ${theme("palette.sky")};
    ${Date} {
      color: ${theme("palette.white")};
    }

    ${Price} {
      color: ${theme("palette.white")};
    }
  }
`;
