import { useInvoices } from "@hotelspoint/api";
import { useUserTokenStore } from "@hotelspoint/store";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import Panel from "../../elements/Panel";
import { TABLE_PAGE_SIZE_DEFAULT } from "../../table/constants";
import Table from "../../table/Table";
import TableFooter from "../../table/TableFooter";
import TableLayout from "../../table/TableLayout";
import TableResultsCount from "../../table/TableResultsCount";
import getColumns from "./ReservationInvoicesTable.columns";

interface ReservationInvoicesTableProps {
  id: number;
}

const ReservationInvoicesTable = ({ id }: ReservationInvoicesTableProps) => {
  const { t } = useTranslation();

  const token = useUserTokenStore(state => state.token);

  const [pageSize] = useState(TABLE_PAGE_SIZE_DEFAULT);
  const [pageIndex] = useState(1);

  const [invoices, isLoading] = useInvoices({
    pageIndex,
    pageSize,
    reservationId: id,
  });

  const data = useMemo(() => invoices?.results ?? [], [invoices]);

  const columns = useMemo(() => {
    return getColumns({ t, token: token as string });
  }, [t, token]);

  return (
    <Panel title={t("components.reservationInvoicesTable.title")}>
      <TableLayout>
        <Table data={data} columns={columns} isLoading={isLoading} />
        <TableFooter>
          <div>
            <TableResultsCount value={invoices?.count} />
          </div>
          <div />
        </TableFooter>
      </TableLayout>
    </Panel>
  );
};

export default ReservationInvoicesTable;
