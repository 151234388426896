import * as S from "./Panel.styled";

interface PanelProps {
  title: string | React.ReactNode;
  actions?: React.ReactNode;
  children: React.ReactNode;
}

const Panel = ({ title, actions, children }: PanelProps) => {
  return (
    <S.Wrapper>
      <S.Header>
        <S.Title>{title}</S.Title>
        {actions && <S.Actions>{actions}</S.Actions>}
      </S.Header>
      <S.InnerWrapper>{children}</S.InnerWrapper>
    </S.Wrapper>
  );
};

export default Panel;
