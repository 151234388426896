import {
  AdminAgencyStatementStatus,
  AdminSupplierStatementStatus,
} from "@hotelspoint/api";
import { CHIP_COLORS } from "@hotelspoint/constants";

export const getAgencyStatementStatusName = (
  status: AdminAgencyStatementStatus,
) => {
  switch (status) {
    case AdminAgencyStatementStatus.Pending:
      return "agencyStatementStatus.pending";

    case AdminAgencyStatementStatus.Paid:
      return "agencyStatementStatus.paid";

    default:
      return "Unknown agency statement status";
  }
};

export const getAgencyStatementStatusColor = (
  status: AdminAgencyStatementStatus,
) => {
  switch (status) {
    case AdminAgencyStatementStatus.Pending:
      return CHIP_COLORS.YELLOW;

    case AdminAgencyStatementStatus.Paid:
      return CHIP_COLORS.GREEN;

    default:
      return CHIP_COLORS.GREY;
  }
};

export const getSupplierStatementStatusName = (
  status: AdminSupplierStatementStatus,
) => {
  switch (status) {
    case AdminSupplierStatementStatus.PendingPayment:
      return "supplierStatementStatus.pendingPayment";

    case AdminSupplierStatementStatus.PartialPayment:
      return "supplierStatementStatus.partialPayment";

    case AdminSupplierStatementStatus.PaymentCompleted:
      return "supplierStatementStatus.paymentCompleted";

    default:
      return "Unknown supplier statement status";
  }
};

export const getSupplierStatementStatusColor = (
  status: AdminSupplierStatementStatus,
) => {
  switch (status) {
    case AdminSupplierStatementStatus.PendingPayment:
      return CHIP_COLORS.YELLOW;

    case AdminSupplierStatementStatus.PartialPayment:
      return CHIP_COLORS.BLUE;

    case AdminSupplierStatementStatus.PaymentCompleted:
      return CHIP_COLORS.GREEN;

    default:
      return CHIP_COLORS.GREY;
  }
};
