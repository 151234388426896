import { IconCircleCheckFilled, IconCircleXFilled } from "@tabler/icons-react";
import merge from "lodash/merge";
import { useTheme } from "styled-components";

import { IconType } from "../../common";
import * as S from "./Checklist.styled";

const DEFAULT_COMPONENTS = {
  checked: IconCircleCheckFilled,
  unchecked: IconCircleXFilled,
};

export interface ChecklistItem {
  id: number | string;
  title: string;
  available: boolean;
}

// This will allow us to customize the icons used for the checklist items
// e.g. HotelListing rate options not being available
interface ChecklistComponents {
  checked: IconType;
  unchecked: IconType;
}

interface ChecklistProps {
  items: ChecklistItem[];
  orientation?: "vertical" | "horizontal";
  components?: Partial<ChecklistComponents>;
}

const Checklist = ({
  items,
  orientation = "vertical",
  components: componentsProp,
}: ChecklistProps) => {
  const theme = useTheme();

  const components = merge(DEFAULT_COMPONENTS, componentsProp);

  return (
    <S.Wrapper $orientation={orientation}>
      {items.map(item => (
        <S.Item key={item.id}>
          {item.available ? (
            <components.checked
              size={16}
              style={{ color: theme.palette.eucalyptus }}
            />
          ) : (
            <components.unchecked
              size={16}
              style={{ color: theme.palette.red }}
            />
          )}
          <span>{item.title}</span>
        </S.Item>
      ))}
    </S.Wrapper>
  );
};

export default Checklist;
