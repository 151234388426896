import { type Agency } from "@hotelspoint/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { AgencyService } from "../../services";

const useUploadAgencyLogo = (id: number) => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: ["agencies", "delete_logo"],
    mutationFn: () => {
      return AgencyService.deleteLogo(id);
    },
    onSuccess: () => {
      queryClient.setQueriesData<Agency>(
        { queryKey: ["agencies", id] },
        oldData => {
          if (!oldData) return;

          return {
            ...oldData,
            logo: null,
          };
        },
      );
    },
  });

  const { mutateAsync, isPending } = mutation;
  const ret: [typeof mutateAsync, typeof isPending] = [mutateAsync, isPending];

  return ret;
};

export default useUploadAgencyLogo;
