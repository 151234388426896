const TABLE_PAGE_SIZE = [10, 25, 50];

export const TABLE_PAGE_SIZE_OPTIONS = TABLE_PAGE_SIZE.map(value => ({
  label: `${value}`,
  value,
}));

export const [TABLE_PAGE_SIZE_DEFAULT] = TABLE_PAGE_SIZE;

export const TABLE_COLUMN_SIZE = {
  ID: 60,
  ID_LONG: 100,
  NAME: 260,
  ASSIGNEE: 100,
  EMAIL: 260,
  DATE: 120,
  DATE_TIME: 160,
  ROLE: 240,
  STATUS: 160,
  STATUS_LONG: 220,
  TYPE: 140,
  TYPE_LONG: 240,
  INVOICE_NUMBER: 200,
  AMOUNT: 140,
  SUPPLIER: 140,
  CITY: 140,
  ACTION: 60,
  ACTIONS: 120,
};
