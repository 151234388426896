import { Paper } from "@hotelspoint/components";
import { borderRadius, mediaQuery, pxToRem, spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";
import { theme } from "styled-tools";

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: ${spacing(2)}px;
  margin-top: ${spacing(3)}px;
  margin-bottom: ${spacing(2)}px;
`;

export const Card = styled(Paper)`
  padding: ${spacing(2)}px;
`;

export const Title = styled.h4`
  margin-bottom: ${spacing(2)}px;
  font-weight: 700;
`;

export const PassengerTitle = styled.h6`
  display: flex;
  align-items: center;
  column-gap: ${spacing(1)}px;
  margin: ${spacing(1)}px 0;
  font-weight: 600;
`;

export const RoomName = styled.h6`
  font-weight: 600;
`;

export const Price = styled.p`
  font-weight: 600;
  font-size: ${pxToRem(18)};
`;

export const PriceTotal = styled(Price)`
  font-size: ${pxToRem(20)};
`;

export const StatusBlockWrapper = styled.div`
  margin-top: ${spacing(1)}px;
`;

export const StatusBlockHeader = styled.h6`
  margin-bottom: ${spacing(0.5)}px;
  font-size: ${pxToRem(14)};
  font-weight: 600;
`;

export const StatusBlockCopy = styled.p`
  font-size: ${pxToRem(13)};
`;

export const FormPriceWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: ${spacing(1)}px;
  margin-bottom: ${spacing(2)}px;
  @media ${mediaQuery.desktop} {
    justify-content: flex-start;
    margin-bottom: 0;
  }
`;

export const FormFooter = styled.div`
  margin-top: ${spacing(3)}px;
  column-gap: ${spacing(2)}px;
  button {
    max-width: 100%;
    @media ${mediaQuery.desktop} {
      max-width: fit-content;
    }
  }
  @media ${mediaQuery.desktop} {
    display: flex;
    justify-content: space-between;
  }
`;

export const FlexGrow = styled.div`
  flex: 1;
`;

export const Divider = styled.hr`
  margin: ${spacing(2)}px 0;
  background-color: rgba(0, 0, 0, 0.15);
`;

export const RadioGroup = styled.div`
  margin-top: ${spacing(3)}px;
  padding: ${spacing(1)}px;
  background-color: ${theme("palette.concrete")};
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: ${borderRadius.normal}px;
`;
