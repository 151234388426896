import { UserInList } from "@hotelspoint/api";
import {
  ButtonEdit,
  Chip,
  StatusChip,
  TABLE_COLUMN_SIZE,
  TableCell,
} from "@hotelspoint/components";
import {
  formatDate,
  getUserRoleColor,
  getUserRoleDisplayName,
  getUserStatusColor,
} from "@hotelspoint/utils";
import { createColumnHelper } from "@tanstack/react-table";
import { type TFunction } from "i18next";
import { Link } from "react-router-dom";

const columnHelper = createColumnHelper<UserInList>();

interface GetColumnsParams {
  t: TFunction;
}

const getColumns = ({ t }: GetColumnsParams) => [
  columnHelper.accessor("id", {
    header: t("adminAgents.columns.id"),
    size: TABLE_COLUMN_SIZE.ID,
  }),
  columnHelper.accessor("name", {
    header: t("adminAgents.columns.name"),
    size: TABLE_COLUMN_SIZE.NAME,
  }),
  columnHelper.accessor("email", {
    header: t("adminAgents.columns.email"),
    size: TABLE_COLUMN_SIZE.EMAIL,
  }),
  columnHelper.accessor("agencyName", {
    header: t("adminAgents.columns.agencyName"),
    size: TABLE_COLUMN_SIZE.NAME,
  }),
  columnHelper.accessor("roleId", {
    header: t("adminAgents.columns.roleId"),
    size: TABLE_COLUMN_SIZE.ROLE,
    cell: props => {
      const roleId = props.getValue();

      return (
        <Chip $color={getUserRoleColor(roleId)}>
          {t(getUserRoleDisplayName(roleId))}
        </Chip>
      );
    },
  }),
  columnHelper.accessor("isActive", {
    header: t("adminAgents.columns.isActive"),
    size: TABLE_COLUMN_SIZE.STATUS,
    cell: props => {
      const isActive = props.getValue();

      return (
        <StatusChip $variant={getUserStatusColor(isActive)}>
          {isActive ? t("userStatus.active") : t("userStatus.inactive")}
        </StatusChip>
      );
    },
  }),
  columnHelper.accessor("dateCreated", {
    header: t("adminAgents.columns.dateCreated"),
    size: TABLE_COLUMN_SIZE.DATE,
    cell: props => formatDate(props.getValue()),
  }),
  columnHelper.display({
    id: "actions",
    size: TABLE_COLUMN_SIZE.ACTION,
    cell: props => {
      const id = props.row.original.id;

      return (
        <TableCell>
          <Link to={`/admin/agents/${id}`}>
            <ButtonEdit />
          </Link>
        </TableCell>
      );
    },
  }),
];

export default getColumns;
