import { AdminAreaLocationAddItemPayload, PlaceParams } from "@hotelspoint/api";
import { PlaceType } from "@hotelspoint/types";
import { InferType, mixed, number, object, string } from "yup";

export const validationSchema = object({
  search: string()
    .required("adminAreaLocation.add.search.validation.required")
    .test("place", "__ctx__", (_, ctx) => {
      const place = ctx.parent.place;

      if (!place || !place.id || !place.type) {
        return ctx.createError({
          message: "adminAreaLocation.add.search.validation.place",
        });
      }

      return true;
    }),
  place: object({
    id: number(),
    type: mixed<PlaceType>().oneOf(Object.values(PlaceType)),
  }),
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: Partial<FormValues> = {
  search: "",
  place: undefined,
};

export const entity2Form = (entity: PlaceParams) => ({
  search: "",
  place: {
    id: entity.id,
    type: entity.type,
  },
});

export const form2Entity = (
  formValues: FormValues,
): AdminAreaLocationAddItemPayload => ({
  placeId: formValues.place?.id as number,
  placeType: formValues.place?.type as PlaceType,
});
