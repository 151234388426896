import { spacing } from "@hotelspoint/theme";
import styled from "styled-components";

export const Content = styled.div`
  max-height: 600px;
  overflow-y: auto;
`;

export const ActionGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: ${spacing(1)}px;
`;
