import { ReservationInList } from "@hotelspoint/api";
import {
  ButtonView,
  Chip,
  TABLE_COLUMN_SIZE,
  TableCell,
  TableCellEmpty,
} from "@hotelspoint/components";
import { CHIP_COLORS } from "@hotelspoint/constants";
import {
  formatDate,
  getPaymentStatusColor,
  getPaymentStatusName,
  getReservationStatusColor,
  getReservationStatusName,
  getReservationSupplierNameByType,
  getReservationTypeName,
  withCurrency,
} from "@hotelspoint/utils";
import { IconCheckbox, IconMail } from "@tabler/icons-react";
import { createColumnHelper } from "@tanstack/react-table";
import { TFunction } from "i18next";
import { Link } from "react-router-dom";

const columnHelper = createColumnHelper<ReservationInList>();

interface GetColumnsParams {
  t: TFunction;
}

const getColumns = ({ t }: GetColumnsParams) => [
  columnHelper.display({
    id: "state_icons",
    cell: props => {
      const { confirmationId, countNewMessages } = props.row.original;

      return (
        <TableCell>
          <IconCheckbox
            size={18}
            style={{
              color: confirmationId ? CHIP_COLORS.GREEN : CHIP_COLORS.GREY,
            }}
          />
          <IconMail
            size={18}
            style={{
              color:
                countNewMessages > 0 ? CHIP_COLORS.GREEN : CHIP_COLORS.GREY,
            }}
          />
        </TableCell>
      );
    },
  }),
  columnHelper.accessor("id", {
    header: t("profileReservations.columns.id"),
    size: TABLE_COLUMN_SIZE.ID,
  }),
  columnHelper.accessor("agencyName", {
    header: t("profileReservations.columns.agencyName"),
    size: TABLE_COLUMN_SIZE.NAME,
  }),
  columnHelper.accessor("userName", {
    header: t("profileReservations.columns.userName"),
    size: TABLE_COLUMN_SIZE.NAME,
  }),
  columnHelper.accessor("status", {
    header: t("profileReservations.columns.status"),
    size: TABLE_COLUMN_SIZE.STATUS,
    cell: props => {
      const status = props.getValue();

      return (
        <Chip $color={getReservationStatusColor(status)}>
          {t(getReservationStatusName(status))}
        </Chip>
      );
    },
  }),
  columnHelper.accessor("dateDeadline", {
    header: t("profileReservations.columns.dateDeadline"),
    size: TABLE_COLUMN_SIZE.DATE,
    cell: props => formatDate(props.getValue()),
  }),
  columnHelper.accessor("itemType", {
    header: t("profileReservations.columns.itemType"),
    size: TABLE_COLUMN_SIZE.TYPE,
    cell: props => t(getReservationTypeName(props.getValue())),
  }),
  columnHelper.accessor("dateFrom", {
    header: t("profileReservations.columns.dateFrom"),
    size: TABLE_COLUMN_SIZE.DATE,
    cell: props => formatDate(props.getValue()),
  }),
  columnHelper.accessor("itemData.price", {
    header: t("profileReservations.columns.price"),
    size: TABLE_COLUMN_SIZE.AMOUNT,
    cell: props => withCurrency(props.getValue()),
  }),
  columnHelper.accessor("paymentStatus", {
    header: t("profileReservations.columns.paymentStatus"),
    cell: props => {
      const status = props.getValue();

      return (
        <Chip $color={getPaymentStatusColor(status)}>
          {t(getPaymentStatusName(status))}
        </Chip>
      );
    },
  }),
  columnHelper.accessor("commissionAmount", {
    header: t("profileReservations.columns.commissionAmount"),
    size: TABLE_COLUMN_SIZE.AMOUNT,
    cell: props => {
      const value = props.getValue();

      return value ? withCurrency(value) : <TableCellEmpty />;
    },
  }),
  columnHelper.accessor("clientName", {
    header: t("profileReservations.columns.clientName"),
    size: TABLE_COLUMN_SIZE.NAME,
  }),
  columnHelper.accessor("cityName", {
    header: t("profileReservations.columns.cityName"),
    size: TABLE_COLUMN_SIZE.CITY,
  }),
  columnHelper.accessor("dateCreated", {
    header: t("profileReservations.columns.dateCreated"),
    size: TABLE_COLUMN_SIZE.DATE,
    cell: props => formatDate(props.getValue()),
  }),
  columnHelper.accessor("itemData.supplierId", {
    header: t("profileReservations.columns.supplierId"),
    size: TABLE_COLUMN_SIZE.SUPPLIER,
    cell: props => {
      const type = props.row.original.itemType;
      const supplierId = props.getValue();
      const supplierName = getReservationSupplierNameByType(type, supplierId);

      return <Chip $color={CHIP_COLORS.SUPPLIER}>{supplierName}</Chip>;
    },
  }),
  columnHelper.display({
    id: "actions",
    size: TABLE_COLUMN_SIZE.ACTION,
    cell: props => {
      const id = props.row.original.id;

      return (
        <TableCell>
          <Link to={`/profile/bookings/${id}`}>
            <ButtonView />
          </Link>
        </TableCell>
      );
    },
  }),
];

export default getColumns;
