import { useAgencies } from "@hotelspoint/api";
import {
  Pagination,
  Panel,
  Table,
  TABLE_PAGE_SIZE_DEFAULT,
  TableFooter,
  TableLayout,
  TablePageSize,
  TableResultsCount,
} from "@hotelspoint/components";
import { pickFilterValues, useDocumentTitle } from "@hotelspoint/utils";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import getColumns from "./AdminAgencies.columns";
import AdminAgenciesFilters from "./AdminAgenciesFilters";
import useAdminAgenciesParams from "./useAdminAgenciesParams";

const AdminAgencies = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("adminAgencies.pageTitle"));

  const [pageSize, setPageSize] = useState(TABLE_PAGE_SIZE_DEFAULT);
  const [pageIndex, setPageIndex] = useState(1);
  const [query] = useAdminAgenciesParams();
  const filters = useMemo(() => pickFilterValues(query), [query]);
  const [agencies, isLoadingAgencies] = useAgencies({
    pageIndex,
    pageSize,
    ...filters,
  });

  const data = useMemo(() => agencies?.results ?? [], [agencies]);

  const columns = useMemo(() => {
    return getColumns({ t });
  }, [t]);

  const stringifiedFilters = useMemo(() => JSON.stringify(filters), [filters]);

  useEffect(() => {
    setPageIndex(1);
  }, [pageSize, stringifiedFilters]);

  return (
    <Panel title={t("adminAgencies.pageTitle")}>
      <AdminAgenciesFilters isLoading={isLoadingAgencies} />
      <TableLayout>
        <Table
          data={data}
          columns={columns}
          isLoading={isLoadingAgencies}
          useReactTableProps={{
            state: {
              pagination: {
                pageIndex,
                pageSize,
              },
              columnPinning: {
                left: ["id"],
                right: ["actions"],
              },
            },
            manualPagination: true,
          }}
        />
        <TableFooter>
          <div>
            <TableResultsCount value={agencies?.count} />
          </div>
          <div>
            <Pagination
              pageIndex={pageIndex}
              pageSize={pageSize}
              setPageIndex={setPageIndex}
              totalCount={agencies?.count ?? 0}
            />
          </div>
          <TablePageSize value={pageSize} onChange={setPageSize} />
        </TableFooter>
      </TableLayout>
    </Panel>
  );
};

export default AdminAgencies;
