import { useQuery } from "@tanstack/react-query";

import { MessageService } from "../../services";

const useMessage = (id: number) => {
  const { data, isLoading } = useQuery({
    queryKey: ["messages", id],
    queryFn: () => MessageService.get(id),
    enabled: !!id,
  });

  const ret: [typeof data, boolean] = [data, isLoading];

  return ret;
};

export default useMessage;
