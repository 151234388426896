import { borderRadius, spacing } from "@hotelspoint/theme";
import styled from "styled-components";
import { prop } from "styled-tools";

import Paper from "../../elements/Paper";

export const Wrapper = styled.div`
  overflow: hidden;
  border-radius: ${borderRadius.large}px;
  box-shadow:
    0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;

export const InnerWrapper = styled.div<{ $src: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: ${spacing(1)}px;
  padding: ${spacing(3)}px 0;
  background: url(${prop("$src")});
  background-position: top center;
`;

export const Content = styled(Paper)`
  padding: ${spacing(1)}px;
  border-radius: ${borderRadius.medium}px;
`;
