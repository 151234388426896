import { MessagePriority, MessageStatus, MessageType } from "@hotelspoint/api";
import { assignees, CHIP_COLORS } from "@hotelspoint/constants";

export const getMessagesPriorityName = (priority: MessagePriority) => {
  switch (priority) {
    case MessagePriority.Low:
      return "messagePriority.low";

    case MessagePriority.Medium:
      return "messagePriority.medium";

    case MessagePriority.High:
      return "messagePriority.high";

    case MessagePriority.Critical:
      return "messagePriority.critical";

    default:
      return "Unknown message priority";
  }
};

export const getMessagesPriorityColor = (priority: MessagePriority) => {
  switch (priority) {
    case MessagePriority.Low:
      return CHIP_COLORS.GREY;

    case MessagePriority.Medium:
      return CHIP_COLORS.YELLOW;

    case MessagePriority.High:
      return CHIP_COLORS.ORANGE;

    case MessagePriority.Critical:
      return CHIP_COLORS.RED;

    default:
      return CHIP_COLORS.DARK_GREY;
  }
};

export const getMessagesStatusName = (status: MessageStatus) => {
  switch (status) {
    case MessageStatus.Pending:
      return "messageStatus.pending";

    case MessageStatus.InProgress:
      return "messageStatus.inProgress";

    case MessageStatus.Completed:
      return "messageStatus.completed";

    default:
      return "Unknown message status";
  }
};

export const getMessagesStatusColor = (status: MessageStatus) => {
  switch (status) {
    case MessageStatus.Pending:
      return CHIP_COLORS.YELLOW;

    case MessageStatus.InProgress:
      return CHIP_COLORS.BLUE;

    case MessageStatus.Completed:
      return CHIP_COLORS.GREEN;

    default:
      return CHIP_COLORS.GREY;
  }
};

export const getMessageTypeName = (type: MessageType) => {
  switch (type) {
    case MessageType.Agency:
      return "messageType.agency";

    case MessageType.Hotel:
      return "messageType.hotel";

    case MessageType.Supplier:
      return "messageType.supplier";

    default:
      return "Unknown message type";
  }
};

export const getAssigneeById = (id: number) => {
  return assignees[`${id}` as keyof typeof assignees] ?? "Unknown assignee";
};
