import { useAdminPaymentRequests } from "@hotelspoint/api";
import {
  Pagination,
  Panel,
  Table,
  TABLE_PAGE_SIZE_DEFAULT,
  TableFooter,
  TableLayout,
  TablePageSize,
  TableResultsCount,
} from "@hotelspoint/components";
import { useUserTokenStore } from "@hotelspoint/store";
import { pickFilterValues, useDocumentTitle } from "@hotelspoint/utils";
import omit from "lodash/omit";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import getColumns from "./AdminPaymentRequests.columns";
import AdminPaymentRequestsFilters from "./AdminPaymentRequestsFilters/AdminPaymentRequestsFilters";
import useAdminPaymentRequestsParams from "./useAdminPaymentRequestsParams";

const AdminPaymentRequests = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("adminPaymentRequests.pageTitle"));

  const token = useUserTokenStore(state => state.token);

  const [pageSize, setPageSize] = useState(TABLE_PAGE_SIZE_DEFAULT);
  const [pageIndex, setPageIndex] = useState(1);

  const [query] = useAdminPaymentRequestsParams();
  const filters = useMemo(
    () => omit(pickFilterValues(query), "userName"),
    [query],
  );

  const [requests, isLoading] = useAdminPaymentRequests({
    pageIndex,
    pageSize,
    ...filters,
  });

  const data = useMemo(() => requests?.results ?? [], [requests]);

  const columns = useMemo(() => {
    return getColumns({ t, token: token as string });
  }, [t, token]);

  const stringifiedFilters = useMemo(() => JSON.stringify(filters), [filters]);

  useEffect(() => {
    setPageIndex(1);
  }, [pageSize, stringifiedFilters]);

  return (
    <Panel title={t("adminPaymentRequests.pageTitle")}>
      <AdminPaymentRequestsFilters isLoading={isLoading} />
      <TableLayout>
        <Table
          data={data}
          columns={columns}
          isLoading={isLoading}
          useReactTableProps={{
            state: {
              pagination: { pageIndex, pageSize: TABLE_PAGE_SIZE_DEFAULT },
              columnPinning: { left: ["id"], right: ["actions"] },
            },
            manualPagination: true,
          }}
        />
        <TableFooter>
          <div>
            <TableResultsCount value={requests?.count} />
          </div>
          <div>
            <Pagination
              pageIndex={pageIndex}
              pageSize={pageSize}
              setPageIndex={setPageIndex}
              totalCount={requests?.count ?? 0}
            />
          </div>
          <TablePageSize value={pageSize} onChange={setPageSize} />
        </TableFooter>
      </TableLayout>
    </Panel>
  );
};

export default AdminPaymentRequests;
