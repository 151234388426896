import { Container, PageSpacer, PageWrapper } from "@hotelspoint/components";
import { useDocumentTitle } from "@hotelspoint/utils";
import { useTranslation } from "react-i18next";

const SearchCarRentals = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("searchCarRentals.pageTitle"));

  return (
    <PageWrapper>
      <Container>
        <PageSpacer>Car rentals</PageSpacer>
      </Container>
    </PageWrapper>
  );
};

export default SearchCarRentals;
