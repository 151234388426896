import { useMutation } from "@tanstack/react-query";

import {
  ReservationSendVoucherPayload,
  ReservationService,
} from "../../services";

const useSendReservationVoucher = (id: number) => {
  const mutation = useMutation({
    mutationKey: ["reservations", "send_voucher"],
    mutationFn: (payload: ReservationSendVoucherPayload) => {
      return ReservationService.sendVoucher(id, payload);
    },
  });

  const { mutateAsync, isPending } = mutation;
  const ret: [typeof mutateAsync, typeof isPending] = [mutateAsync, isPending];

  return ret;
};

export default useSendReservationVoucher;
