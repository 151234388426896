import { forwardRef } from "react";

import { type FormControlInjectedProps } from "../FormControl";
import * as S from "./FormInput.styled";

export interface FormInputProps
  extends FormControlInjectedProps,
    Pick<
      React.InputHTMLAttributes<HTMLInputElement>,
      | "type"
      | "placeholder"
      | "disabled"
      | "readOnly"
      | "autoComplete"
      | "onFocus"
      | "onBlur"
      | "defaultValue"
      | "style"
    > {
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  adornmentStyle?: "none" | "filled";
}

const FormInput = forwardRef<HTMLInputElement, FormInputProps>(
  (
    { startAdornment, endAdornment, adornmentStyle, error, ...inputProps },
    ref,
  ) => {
    return (
      <S.Wrapper $inputDisabled={inputProps?.disabled || inputProps?.readOnly}>
        {startAdornment && (
          <S.StartAdornment
            $hasError={!!error}
            $adornmentStyle={adornmentStyle}
          >
            {startAdornment}
          </S.StartAdornment>
        )}
        <S.Input
          ref={ref}
          $hasError={!!error}
          $hasStartAdornment={!!startAdornment}
          $hasEndAdornment={!!endAdornment}
          type="text"
          {...inputProps}
        />
        {endAdornment && (
          <S.EndAdornment $hasError={!!error} $adornmentStyle={adornmentStyle}>
            {endAdornment}
          </S.EndAdornment>
        )}
      </S.Wrapper>
    );
  },
);

export default FormInput;
