import { HotelSearchQuery } from "@hotelspoint/types";

import createRecentSearchesStore from "./utils/createRecentSearchesStore";

const PERSIST_NAME = "@hp::recent_hotel_searches";

export interface RecentHotelSearchQuery
  extends Omit<HotelSearchQuery, "nationality"> {
  meta: {
    placeName: string;
  };
}

const useRecentHotelSearchesStore =
  createRecentSearchesStore<RecentHotelSearchQuery>({
    persistName: PERSIST_NAME,
  });

export default useRecentHotelSearchesStore;
