import { Hotel, SearchHotelsResultsModalType } from "@hotelspoint/types";

import GalleryOverlay from "../GalleryOverlay";
import HotelDetailsModal from "../HotelDetailsModal";

interface SearchHotelsResultsModal {
  hotelId?: number;
  type: SearchHotelsResultsModalType;
}

interface HotelDetailsGalleryModalProps {
  hotel: Hotel | undefined;
  isLoading: boolean;
  activeModal: SearchHotelsResultsModal | undefined;
  initialGalleryIndex?: number;
  setInitialGalleryIndex?: (index: number) => void;
  setActiveModal: (modal: SearchHotelsResultsModal | undefined) => void;
}

const HotelDetailsGalleryModal = ({
  hotel,
  isLoading,
  activeModal,
  initialGalleryIndex = 0,
  setInitialGalleryIndex,
  setActiveModal,
}: HotelDetailsGalleryModalProps) => {
  const handleImageClick = (index: number) => {
    setInitialGalleryIndex && setInitialGalleryIndex(index);
    setActiveModal({
      hotelId: hotel?.id,
      type: SearchHotelsResultsModalType.Gallery,
    });
  };

  const handleCloseModal = () => {
    setActiveModal(undefined);
  };

  return (
    <>
      <GalleryOverlay
        isOpen={
          activeModal?.type === SearchHotelsResultsModalType.Gallery &&
          hotel?.images?.length !== 0
        }
        images={hotel?.images ?? []}
        initialSlideIndex={initialGalleryIndex}
        onClose={handleCloseModal}
      />
      <HotelDetailsModal
        isOpen={activeModal?.type === SearchHotelsResultsModalType.Details}
        hotel={hotel}
        isLoading={isLoading}
        handleImageClick={handleImageClick}
        onClose={handleCloseModal}
      />
    </>
  );
};

export default HotelDetailsGalleryModal;
