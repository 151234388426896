import { IconTrash } from "@tabler/icons-react";

import Button from "../Button/Button";
import { ButtonProps } from "../Button/types";

const ButtonDelete = (props: Omit<ButtonProps, "children">) => (
  <Button small variant="error" {...props}>
    <IconTrash size={14} />
  </Button>
);

export default ButtonDelete;
