import {
  Container,
  LegalPageContainer,
  LegalPageSkeleton,
  PagePaper,
  PageWrapper,
} from "@hotelspoint/components";
import { useUserLocaleStore } from "@hotelspoint/store";
import { useDocumentTitle } from "@hotelspoint/utils";
import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
const PrivacyPolicy = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("privacyPolicy.pageTitle"));

  const navigate = useNavigate();
  const language = useUserLocaleStore(state => state.language);
  const [content, setContent] = useState();

  useEffect(() => {
    const dynamicImport = async () => {
      try {
        const file = await import(`./PrivacyPolicy.${language}.mdx`);
        setContent(file.default);
      } catch (error: any) {
        navigate("/404");
        Sentry.captureException(error);
      }
    };

    dynamicImport();
  }, [language, navigate]);

  return (
    <PageWrapper>
      <Container>
        <PagePaper title={t("privacyPolicy.pageTitle")}>
          <LegalPageContainer>
            {content || <LegalPageSkeleton />}
          </LegalPageContainer>
        </PagePaper>
      </Container>
    </PageWrapper>
  );
};

export default PrivacyPolicy;
