import { FORM_ROOM_SELECTOR_INITIAL_STATE } from "@hotelspoint/components";
import {
  HotelRoomGuests,
  HotelSearchQuery,
  PlaceType,
} from "@hotelspoint/types";
import { formatDateIso } from "@hotelspoint/utils";
import { array, date, InferType, mixed, number, object, string } from "yup";

export const validationSchema = object({
  search: string()
    .required("searchHotels.search.validation.required")
    .test("place", "__ctx__", (_, ctx) => {
      const place = ctx.parent.place;

      if (!place || !place.id || !place.type) {
        return ctx.createError({
          message: "searchHotels.search.validation.place",
        });
      }

      return true;
    }),
  dates: object({ from: date(), to: date() }).test(
    "required",
    "_ctx_",
    (dates, ctx) => {
      if (!dates.from && !dates.to) {
        return ctx.createError({
          message: "searchHotels.dates.validation.both",
        });
      }

      // This check may not be needed
      // if (!dates.from) {
      //   return ctx.createError({
      //     message: "searchHotels.dates.validation.from",
      //   });
      // }

      if (!dates.to) {
        return ctx.createError({ message: "searchHotels.dates.validation.to" });
      }

      return true;
    },
  ),
  nationality: string().required(
    "searchHotels.nationality.validation.required",
  ),

  rooms: array()
    .of(
      object({
        adults: number(),
        children: array().of(number().required()),
      }),
    )
    .required()
    .test("adult", "searchHotels.rooms.validation.adult", value => {
      return value.every(room => room.adults !== 0);
    })
    .test("child age", "searchHotels.rooms.validation.childAge", value => {
      return value.every(room => {
        return room.children?.every(child => child !== 0);
      });
    }),

  // Read-only - set programmatically when selecting a place
  place: object({
    id: number(),
    type: mixed<PlaceType>().oneOf(Object.values(PlaceType)),
  }),
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: Partial<FormValues> = {
  search: "",
  dates: undefined,
  nationality: "",
  rooms: [FORM_ROOM_SELECTOR_INITIAL_STATE],
  place: undefined,
};

export const form2Entity = (formValues: FormValues): HotelSearchQuery => ({
  placeId: formValues.place?.id as number,
  placeType: formValues.place?.type as PlaceType,
  checkIn: formatDateIso(formValues.dates?.from as Date),
  checkOut: formatDateIso(formValues.dates?.to as Date),
  nationality: formValues.nationality,
  rooms: formValues.rooms as Required<HotelRoomGuests>[],
});
