import { spacing, timing } from "@hotelspoint/theme";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { theme } from "styled-tools";

export const styles = css`
  width: fit-content;
  position: relative;
  padding-bottom: ${spacing(0.5)}px;
  margin-top: ${spacing(0.5)}px;

  &:after {
    content: "";
    position: absolute;
    display: block;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 50%;
    background: ${theme("link.hover")};
    transition: all ease ${timing.normal}ms;
  }

  &:hover {
    &:after {
      width: 100%;
      left: 0;
    }
  }
`;

export const LinkUnderlineExternal = styled.a`
  ${styles}
`;

export default styled(Link)`
  ${styles}
`;
