import { useMutation, useQueryClient } from "@tanstack/react-query";

import { InvoiceRegeneratePayload, InvoiceService } from "../../services";

const useRegenerateInvoice = (id: number) => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: ["invoices", "regenerate"],
    mutationFn: (payload: InvoiceRegeneratePayload) => {
      return InvoiceService.regenerate(id, payload);
    },
    onMutate: () => {
      queryClient.cancelQueries({
        queryKey: ["invoices", id],
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["invoices", id],
      });

      queryClient.invalidateQueries({
        queryKey: ["invoices"],
      });
    },
  });

  const { mutateAsync, isPending } = mutation;
  const ret: [typeof mutateAsync, typeof isPending] = [mutateAsync, isPending];

  return ret;
};

export default useRegenerateInvoice;
