import { borderRadius, timing, zIndex } from "@hotelspoint/theme";
import styled from "styled-components";
import { theme } from "styled-tools";

export const Wrapper = styled.button<{ $isOpen: boolean }>`
  z-index: ${zIndex.burger};
  width: 24px;
  height: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: transparent;

  &:hover {
    div {
      background: ${theme("palette.geyser")};
    }
  }

  div {
    width: 100%;
    height: 2px;
    background: white;
    border-radius: ${borderRadius.large}px;
    transition: all ${timing.fast}ms linear;
    position: relative;
    transform-origin: 8% 50%;

    &:first-child {
      transform: ${({ $isOpen }) => ($isOpen ? "rotate(45deg)" : "rotate(0)")};
    }

    &:nth-child(2) {
      opacity: ${({ $isOpen }) => ($isOpen ? "0" : "1")};
    }

    &:nth-child(3) {
      transform: ${({ $isOpen }) => ($isOpen ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;
