import { spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing(2)}px;
`;

export const PlaceOption = styled.div`
  display: flex;
  column-gap: ${spacing(0.5)}px;
`;

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
`;
