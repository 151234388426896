import { Panel } from "@hotelspoint/components";
import { useDocumentTitle } from "@hotelspoint/utils";
import { useTranslation } from "react-i18next";
import { Navigate, useParams } from "react-router-dom";

import AdminDestinationLocationForm from "./AdminDestinationLocationForm";

interface AdminDestinationLocationParams extends Record<string, string> {
  id: string;
}

const AdminDestinationLocation = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("adminDestinationLocation.pageTitle"));

  const { id } = useParams<AdminDestinationLocationParams>();
  const destinationId = Number(id);

  if (!destinationId) {
    return <Navigate to="/404" replace={true} />;
  }

  return (
    <Panel title={t("adminDestinationLocation.pageTitle")}>
      <AdminDestinationLocationForm id={destinationId} />
    </Panel>
  );
};

export default AdminDestinationLocation;
