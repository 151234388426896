import {
  ApiResponseSuccessResult,
  ApiResponseSuccessResultList,
  PaginationOptions,
  ReservationType,
  UserCurrency,
} from "@hotelspoint/types";

import { UPLOAD_CONFIG } from "../constants";
import FetchService from "./base/FetchService";
import { PaymentType } from "./PaymentService";
import { ReservationStatus } from "./ReservationService";

const INVOICE_API_PATH = "invoices";

export enum InvoiceStatus {
  Pending = 1,
  Completed = 2,
  Cancelled = 3,
}

export enum InvoiceType {
  Proform = "proform",
  Invoice = "invoice",
  CreditNote = "credit-note",
  DebitNote = "debit-note",
}

export interface Invoice {
  id: number;
  number: number | null;
  fullNumber: string;
  orderNumber: number | null;
  type: InvoiceType;
  status: InvoiceStatus;
  paymentId: number | null;
  paymentType: PaymentType | null;
  agencyId: number;
  agencyName: string;
  amount: number;
  mainInvoiceId: number | null;
  currency: UserCurrency;
  dateCreated: string;
  dateIssued: string | null;
  dateReissued: string | null;
  reservationId: number;
  reservationStatus: ReservationStatus;
  itemType: ReservationType;
  supplierId: number;
  transactionId: number | null;
  voucherCode: string;
}

export interface InvoiceListParams extends PaginationOptions {
  id?: number;
  number?: number;
  reservationId?: number;
  agencyId?: number;
  paymentId?: number;
  status?: InvoiceStatus;
  dateFrom?: string;
  dateTo?: string;
  amountFrom?: number;
  amountTo?: number;
}

export type InvoiceListResponse = ApiResponseSuccessResultList<Invoice>;

export type InvoiceApprovePayload = Pick<Invoice, "amount">;

export type InvoiceRegeneratePayload = Pick<Invoice, "amount" | "dateIssued">;

export type InvoiceAmountPayload = Pick<Invoice, "amount">;

const InvoiceService = {
  get: (id: number) => {
    return FetchService.get<Invoice>(`${INVOICE_API_PATH}/${id}`);
  },
  approve: (id: number, payload: InvoiceApprovePayload) => {
    return FetchService.post<ApiResponseSuccessResult>(
      `${INVOICE_API_PATH}/${id}/approve`,
      payload,
    );
  },
  reject: (id: number) => {
    return FetchService.post<ApiResponseSuccessResult>(
      `${INVOICE_API_PATH}/${id}/reject`,
    );
  },
  regenerate: (id: number, payload: InvoiceRegeneratePayload) => {
    return FetchService.post<ApiResponseSuccessResult>(
      `${INVOICE_API_PATH}/${id}/regenerate`,
      payload,
    );
  },
  addCreditNote: (id: number, payload: InvoiceAmountPayload) => {
    return FetchService.post<Invoice>(
      `${INVOICE_API_PATH}/${id}/credit`,
      payload,
    );
  },
  addDebitNote: (id: number, payload: InvoiceAmountPayload) => {
    return FetchService.post<Invoice>(
      `${INVOICE_API_PATH}/${id}/debit`,
      payload,
    );
  },
  upload: (id: number, payload: FormData) => {
    return FetchService.post<ApiResponseSuccessResult>(
      `${INVOICE_API_PATH}/${id}/upload`,
      payload,
      undefined,
      UPLOAD_CONFIG,
    );
  },
  getList: (params?: InvoiceListParams) => {
    return FetchService.get<InvoiceListResponse>(INVOICE_API_PATH, params);
  },
};

export default InvoiceService;
