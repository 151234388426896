import { borderRadius } from "@hotelspoint/theme";
import { styled } from "styled-components";

import Button from "../Button/Button";

export const Wrapper = styled(Button)`
  justify-content: center;
  height: 30px;
  width: 30px;
  max-width: none;
  padding: 0;
  border-radius: ${borderRadius.circle};
  box-shadow:
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 2px 1px 0px rgba(0, 0, 0, 0.12),
    0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  border: none;
`;
