import {
  Agency,
  AgencyInList,
  AgencyStatus,
  ApiResponseSuccessResult,
  ApiResponseSuccessResultList,
  PaginationOptions,
} from "@hotelspoint/types";

import { UPLOAD_CONFIG } from "../constants";
import FetchService from "./base/FetchService";

const AGENCY_API_PATH = "agencies";

export type AgencyUpdatePayload = Partial<Agency>;

export interface AgencyUploadLogoResponse extends ApiResponseSuccessResult {
  fileName?: string;
}

export type AgencyListResponse = ApiResponseSuccessResultList<AgencyInList>;

export interface AgencyListParams extends PaginationOptions {
  id?: number;
  name?: string;
  email?: string;
  status?: AgencyStatus;
  registerFrom?: string;
  registerTo?: string;
}

const AgencyService = {
  get: (id: number) => {
    return FetchService.get<Agency>(`${AGENCY_API_PATH}/${id}`);
  },
  update: (id: number, payload: AgencyUpdatePayload) => {
    return FetchService.patch<ApiResponseSuccessResult>(
      `${AGENCY_API_PATH}/${id}`,
      payload,
    );
  },
  approve: (id: number) => {
    return FetchService.post<ApiResponseSuccessResult>(
      `${AGENCY_API_PATH}/${id}/approve`,
    );
  },
  reject: (id: number) => {
    return FetchService.post<ApiResponseSuccessResult>(
      `${AGENCY_API_PATH}/${id}/reject`,
    );
  },
  uploadLogo: (id: number, payload: FormData) => {
    return FetchService.post<AgencyUploadLogoResponse>(
      `${AGENCY_API_PATH}/${id}/logo`,
      payload,
      undefined,
      UPLOAD_CONFIG,
    );
  },
  deleteLogo: (id: number) => {
    return FetchService.delete<ApiResponseSuccessResult>(
      `${AGENCY_API_PATH}/${id}/logo`,
    );
  },
  getList: (params?: Partial<AgencyListParams>) => {
    return FetchService.get<AgencyListResponse>(AGENCY_API_PATH, params);
  },
};

export default AgencyService;
