import { useInvoices } from "@hotelspoint/api";
import {
  Breadcrumb,
  BreadcrumbContainer,
  Button,
  DateRangeDialog,
  Pagination,
  Panel,
  Table,
  TABLE_PAGE_SIZE_DEFAULT,
  TableFooter,
  TableLayout,
  TablePageSize,
  TableResultsCount,
} from "@hotelspoint/components";
import { useUserTokenStore } from "@hotelspoint/store";
import {
  getApiUrl,
  openInNewTab,
  pickFilterValues,
  useDocumentTitle,
} from "@hotelspoint/utils";
import { IconFileDollar, IconFileExport, IconUser } from "@tabler/icons-react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import getColumns from "./ProfileInvoices.columns";
import ProfileInvoicesFilters from "./ProfileInvoicesFilters";
import useProfileInvoicesParams from "./useProfileInvoicesParams";

const ProfileInvoices = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("profileInvoices.pageTitle"));

  const token = useUserTokenStore(state => state.token);

  const [pageSize, setPageSize] = useState(TABLE_PAGE_SIZE_DEFAULT);
  const [pageIndex, setPageIndex] = useState(1);

  const [isOpen, setIsOpen] = useState(false);

  const [query] = useProfileInvoicesParams();
  const filters = useMemo(() => pickFilterValues(query), [query]);

  const [invoices, isLoading] = useInvoices({
    pageIndex,
    pageSize,
    ...filters,
  });

  const data = useMemo(() => invoices?.results ?? [], [invoices]);

  const columns = useMemo(() => {
    return getColumns({ t, token: token as string });
  }, [t, token]);

  const stringifiedFilters = useMemo(() => JSON.stringify(filters), [filters]);

  const onConfirm = (dateFrom: string, dateTo: string) => {
    const url = getApiUrl("invoices/export", { token, dateFrom, dateTo });

    openInNewTab(url);
  };

  const breadcrumbs = useMemo(
    () => [
      {
        title: t("profile.pageTitle"),
        href: "/profile",
        icon: IconUser,
      },
      {
        title: t("profileInvoices.pageTitle"),
        icon: IconFileDollar,
      },
    ],
    [t],
  );

  useEffect(() => {
    setPageIndex(1);
  }, [pageSize, stringifiedFilters]);

  return (
    <>
      <DateRangeDialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title={t("profileInvoices.actions.export.modal.title")}
        onConfirm={onConfirm}
      />
      <BreadcrumbContainer>
        <Breadcrumb items={breadcrumbs} />
      </BreadcrumbContainer>
      <Panel
        title={t("profileInvoices.pageTitle")}
        actions={
          <Button variant="secondary" small onClick={() => setIsOpen(true)}>
            <IconFileExport size={16} />
            <span>{t("profileInvoices.actions.export.button")}</span>
          </Button>
        }
      >
        <ProfileInvoicesFilters isLoading={isLoading} />
        <TableLayout>
          <Table
            data={data}
            columns={columns}
            isLoading={isLoading}
            useReactTableProps={{
              state: {
                pagination: {
                  pageIndex,
                  pageSize: TABLE_PAGE_SIZE_DEFAULT,
                },
                columnPinning: {
                  left: ["id"],
                  right: ["actions"],
                },
              },
              manualPagination: true,
            }}
          />
          <TableFooter>
            <div>
              <TableResultsCount value={invoices?.count} />
            </div>
            <div>
              <Pagination
                pageIndex={pageIndex}
                pageSize={pageSize}
                setPageIndex={setPageIndex}
                totalCount={invoices?.count ?? 0}
              />
            </div>
            <TablePageSize value={pageSize} onChange={setPageSize} />
          </TableFooter>
        </TableLayout>
      </Panel>
    </>
  );
};

export default ProfileInvoices;
