import * as S from "./MosaicLayout.styled";

export interface MosaicLayoutTile {
  image: string;
  alt: string;
}

interface MosaicLayoutProps {
  value: MosaicLayoutTile[];
  //height value is for a single row
  height?: number;
  onClick: (index: number) => void;
}

const MosaicLayout = ({ value, height, onClick }: MosaicLayoutProps) => {
  return (
    <S.Wrapper $height={height}>
      {value.map((tile, index) => (
        <S.Tile key={index} $count={value.length}>
          <img src={tile.image} alt={tile.alt} onClick={() => onClick(index)} />
        </S.Tile>
      ))}
    </S.Wrapper>
  );
};
export default MosaicLayout;
