import { useLocation } from "react-router-dom";
import { useTheme } from "styled-components";

import * as S from "./Container.styled";

interface ContainerProps {
  children: React.ReactNode;
}

const Container = ({ children }: ContainerProps) => {
  const theme = useTheme();
  const { pathname } = useLocation();

  const isAdmin = pathname.includes("/admin");

  const maxWidth = isAdmin
    ? theme.custom.container.maxWidthAdmin
    : theme.custom.container.maxWidth;

  return <S.Wrapper $maxWidth={maxWidth}>{children}</S.Wrapper>;
};

export default Container;
