import { UserCurrency } from "@hotelspoint/types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { type ObjectSchema } from "yup";

import Button from "../../elements/Button";
import Form from "../../form/Form";
import FormAdapter from "../../form/FormAdapter";
import FormContext from "../../form/FormContext";
import FormInput from "../../form/FormInput";
import FormLayout from "../../form/FormLayout";
import Dialog from "../Dialog";
import Modal from "../Modal";
import { FormValues } from "./AmountDialog.util";

interface AmountDialogProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  title: string;
  onConfirm: (amount: number) => any;
  initialValue?: number;
  validationSchema?: ObjectSchema<any>;
  isLoading?: boolean;
}

const AmountDialog = ({
  isOpen,
  setIsOpen,
  title,
  onConfirm,
  initialValue = undefined,
  validationSchema,
  isLoading = false,
}: AmountDialogProps) => {
  const { t } = useTranslation();

  const handleCancel = () => {
    setIsOpen(false);
  };

  const onSubmit = (formValues: FormValues) => {
    const { amount } = formValues;

    setIsOpen(false);
    onConfirm(amount);
  };

  const formDefaultValues = useMemo(
    () => ({
      amount: initialValue,
    }),
    [initialValue],
  );

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Content>
        <Modal title={title} onClose={() => setIsOpen(false)}>
          <Form
            defaultValues={formDefaultValues}
            validationSchema={validationSchema}
          >
            <FormLayout>
              <FormAdapter
                name="amount"
                label={t("components.amountDialog.form.amount.label")}
              >
                {props => (
                  <FormInput
                    {...props}
                    type="number"
                    placeholder={t(
                      "components.amountDialog.form.amount.placeholder",
                    )}
                    endAdornment={UserCurrency.BGN}
                    adornmentStyle="filled"
                    disabled={isLoading}
                  />
                )}
              </FormAdapter>
            </FormLayout>
            <Modal.Actions>
              <Button variant="outlined" onClick={handleCancel}>
                {t("components.amountDialog.cancel")}
              </Button>
              <FormContext<FormValues>
                render={({ handleSubmit }) => (
                  <Button
                    onClick={handleSubmit(onSubmit)}
                    isLoading={isLoading}
                  >
                    {t("components.amountDialog.confirm")}
                  </Button>
                )}
              />
            </Modal.Actions>
          </Form>
        </Modal>
      </Dialog.Content>
    </Dialog>
  );
};

export default AmountDialog;
