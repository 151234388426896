import { NavLink as NavLinkBase, type NavLinkProps } from "react-router-dom";

export const NAV_LINK_ACTIVE_CLASSNAME = "nav-link-active";

const NavLink = (props: NavLinkProps) => (
  <NavLinkBase
    {...props}
    className={({ isActive }) => (isActive ? NAV_LINK_ACTIVE_CLASSNAME : "")}
  />
);

export default NavLink;
