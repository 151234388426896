import { spacing } from "@hotelspoint/theme";
import styled from "styled-components";
import { theme } from "styled-tools";

import { styles } from "../LinkUnderline";

export default styled.button.attrs({ type: "button" })`
  ${styles};

  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: ${spacing(0.5)}px;
  color: ${theme("link.text")};

  &:hover {
    color: ${theme("link.hover")};
  }
`;
