import { UserCurrency } from "@hotelspoint/types";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

const PERSIST_NAME = "@hp::user_currency";

interface UserCurrencyStore {
  currency: UserCurrency;
  set: (currency: UserCurrency) => void;
}

const useUserCurrencyStore = create<UserCurrencyStore>()(
  persist(
    set => ({
      currency: UserCurrency.EUR,
      set: currency => set({ currency }),
    }),
    {
      name: PERSIST_NAME,
      storage: createJSONStorage(() => localStorage),
      partialize: state => ({
        currency: state.currency,
      }),
    },
  ),
);

export default useUserCurrencyStore;
