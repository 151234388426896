import productActivities from "./assets/product-activities.jpg";
import productHotels from "./assets/product-hotels.jpg";
import productTransfers from "./assets/product-transfers.jpg";

export default [
  {
    src: productHotels,
    title: "home.products.cards.hotels.title",
    description: "home.products.cards.hotels.description",
  },
  {
    src: productTransfers,
    title: "home.products.cards.transfers.title",
    description: "home.products.cards.transfers.description",
  },
  {
    src: productActivities,
    title: "home.products.cards.activities.title",
    description: "home.products.cards.activities.description",
  },
];
