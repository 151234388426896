import { useMutation, useQueryClient } from "@tanstack/react-query";

import { InvoiceApprovePayload, InvoiceService } from "../../services";

const useApproveInvoice = (id: number) => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: ["invoices", "approve"],
    mutationFn: (payload: InvoiceApprovePayload) => {
      return InvoiceService.approve(id, payload);
    },
    onMutate: () => {
      queryClient.cancelQueries({
        queryKey: ["invoices", id],
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["invoices", id],
      });

      queryClient.invalidateQueries({
        queryKey: ["invoices"],
      });
    },
  });

  const { mutateAsync, isPending } = mutation;
  const ret: [typeof mutateAsync, typeof isPending] = [mutateAsync, isPending];

  return ret;
};

export default useApproveInvoice;
