import {
  BooleanParam,
  NumberParam,
  StringParam,
  useQueryParams,
} from "use-query-params";

const useAdminMealConfigurationParams = () => {
  const [query, setQuery] = useQueryParams({
    id: NumberParam,
    name: StringParam,
    mainName: StringParam,
    isMapped: BooleanParam,
  });

  const ret: [typeof query, typeof setQuery] = [query, setQuery];

  return ret;
};

export default useAdminMealConfigurationParams;
