import { Box, Container, Flex, Map, PagePaper } from "@hotelspoint/components";
import {
  contactAddress,
  contactAddressCoordinates,
  contactAddressGoogleMaps,
  contactEmail,
  contactPhone,
  contactPhoneSpaced,
} from "@hotelspoint/constants";
import { useDocumentTitle } from "@hotelspoint/utils";
import {
  IconMail,
  IconMapPinFilled,
  IconPhone,
  IconPin,
} from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { Marker } from "react-map-gl";
import { useTheme } from "styled-components";

import * as S from "./About.styled";
import sections from "./sections";

const About = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("about.pageTitle"));

  const theme = useTheme();

  return (
    <>
      <S.CustomPageWrapper>
        <Container>
          <PagePaper title={t("about.pageTitle")}>
            <Flex mx={[0, -1, -2, -2]} my={[-1, -1, -2, -4]}>
              {sections.map((section, index) => (
                <Box
                  key={`section-${index}`}
                  width={[1, 1 / 2, 1 / 3, 1 / 3]}
                  px={[0, 4, 4, 4]}
                  py={[2, 4, 2, 4]}
                  as={S.Section}
                >
                  <S.SectionImage src={section.icon} alt={section.title} />
                  <h4>{t(section.title)}</h4>
                  <S.SectionDescription>
                    {t(section.description)}
                  </S.SectionDescription>
                </Box>
              ))}
            </Flex>
            <S.Separator />
            <Flex mt={3}>
              <Box width={[1, 1 / 3, 1 / 3, 1 / 3]} py={[1, 0, 0, 0]}>
                <a href={`mailto:${contactEmail}`}>
                  <S.ContactDetail>
                    <IconMail size={18} />
                    <span>{contactEmail}</span>
                  </S.ContactDetail>
                </a>
              </Box>
              <Box width={[1, 1 / 3, 1 / 3, 1 / 3]} py={[1, 0, 0, 0]}>
                <a href={`tel:${contactPhone}`}>
                  <S.ContactDetail>
                    <IconPhone size={18} />
                    <span>{contactPhoneSpaced}</span>
                  </S.ContactDetail>
                </a>
              </Box>
              <Box width={[1, 1 / 3, 1 / 3, 1 / 3]} py={[1, 0, 0, 0]}>
                <a
                  href={contactAddressGoogleMaps}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <S.ContactDetail>
                    <IconPin size={18} />
                    <span>{contactAddress}</span>
                  </S.ContactDetail>
                </a>
              </Box>
            </Flex>
          </PagePaper>
        </Container>
      </S.CustomPageWrapper>
      <Map
        initialViewState={{
          longitude: contactAddressCoordinates.longitude,
          latitude: contactAddressCoordinates.latitude,
          zoom: 14.2,
        }}
        interactive={false}
        mapStyle="mapbox://styles/mapbox/streets-v12"
        style={{ height: 420 }}
      >
        <Marker
          longitude={contactAddressCoordinates.longitude}
          latitude={contactAddressCoordinates.latitude}
          anchor="bottom"
        >
          <a
            href={contactAddressGoogleMaps}
            rel="noopener noreferrer"
            target="_blank"
          >
            <IconMapPinFilled size={48} style={{ color: theme.tertiary.bg }} />
          </a>
        </Marker>
      </Map>
    </>
  );
};

export default About;
