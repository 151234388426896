import * as S from "./MetricCard.styled";

interface MetricCardProps {
  title: string;
  value: React.ReactNode;
  icon?: React.ReactNode;
}

const MetricCard = ({ title, value, icon }: MetricCardProps) => {
  return (
    <S.Wrapper $elevation={2}>
      <S.Content>
        <S.Title>{title}</S.Title>
        <S.Value>{value}</S.Value>
      </S.Content>
      {icon && <S.IconWrapper>{icon}</S.IconWrapper>}
    </S.Wrapper>
  );
};

export default MetricCard;
