import * as S from "./Loader.styled";

const Loader = () => (
  <S.Wrapper>
    <S.Pin />
    <S.Pulse />
  </S.Wrapper>
);

export default Loader;
