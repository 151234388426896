import { pxToRem, spacing } from "@hotelspoint/theme";
import styled from "styled-components";
import { switchProp, theme } from "styled-tools";

import { StatusFlavour } from "../../common";

export const Wrapper = styled.div<{ $status: StatusFlavour }>`
  display: flex;
  align-items: center;
  column-gap: ${spacing(0.625)}px;

  svg {
    color: ${switchProp("$status", {
      [StatusFlavour.Success]: theme("success.primary"),
      [StatusFlavour.Error]: theme("error.primary"),
      [StatusFlavour.Info]: theme("info.primary"),
      [StatusFlavour.Warning]: theme("warning.primary"),
    })};

    min-width: 18px;
  }
`;

export const Content = styled.p`
  font-size: ${pxToRem(15)};
`;
