import { Container, PageSpacer, PageWrapper } from "@hotelspoint/components";
import { useDocumentTitle } from "@hotelspoint/utils";
import { useTranslation } from "react-i18next";

const SearchTransferPage = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("searchTransferPage.pageTitle"));

  return (
    <PageWrapper>
      <Container>
        <PageSpacer>Transfer page</PageSpacer>
      </Container>
    </PageWrapper>
  );
};

export default SearchTransferPage;
