import { Panel } from "@hotelspoint/components";
import { useDocumentTitle } from "@hotelspoint/utils";
import { useTranslation } from "react-i18next";
import { Navigate, useParams } from "react-router-dom";

import AdminInvoiceActions from "./AdminInvoiceActions";
import AdminInvoiceForm from "./AdminInvoiceForm";

interface AdminInvoiceParams extends Record<string, string> {
  id: string;
}

const AdminInvoice = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("adminInvoice.pageTitle"));

  const { id } = useParams<AdminInvoiceParams>();
  const invoiceId = Number(id);

  if (!invoiceId) {
    return <Navigate to="/404" replace={true} />;
  }

  return (
    <Panel
      title={t("adminInvoice.pageTitle")}
      actions={<AdminInvoiceActions id={invoiceId} />}
    >
      <AdminInvoiceForm id={invoiceId} />
    </Panel>
  );
};

export default AdminInvoice;
