import { useMutation, useQueryClient } from "@tanstack/react-query";

import { PaymentRefundPayload, PaymentService } from "../../services";

const useCardRefundPayment = (id: number) => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: ["payments", "card_refund"],
    mutationFn: (payload: PaymentRefundPayload) => {
      return PaymentService.cardRefund(id, payload);
    },
    onMutate: () => {
      queryClient.cancelQueries({
        queryKey: ["payments", id],
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["payments", id],
      });

      queryClient.invalidateQueries({
        queryKey: ["payments"],
      });
    },
  });

  const { mutateAsync, isPending } = mutation;
  const ret: [typeof mutateAsync, typeof isPending] = [mutateAsync, isPending];

  return ret;
};

export default useCardRefundPayment;
