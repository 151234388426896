import { Reservation } from "@hotelspoint/api";
import {
  Box,
  Button,
  Flex,
  LinkExternal,
  MarkupPrice,
  MetricCard,
  Price,
} from "@hotelspoint/components";
import { useUserTokenStore } from "@hotelspoint/store";
import { getApiUrl } from "@hotelspoint/utils";
import { IconCash, IconNumber, IconUserCheck } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

import ProfileReservationSendVoucher from "../ProfileReservationSendVoucher";
import * as S from "./ReservationMetricCards.styled";

interface ReservationMetricCardsProps {
  reservation: Reservation;
}

const ReservationMetricCards = ({
  reservation,
}: ReservationMetricCardsProps) => {
  const { t } = useTranslation();

  const token = useUserTokenStore(state => state.token);

  return (
    <Flex mx={[0, 0, -1, -1]}>
      <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
        <MetricCard
          title={t("profileReservation.metricCards.bookingNumber")}
          value={reservation?.id}
          icon={<IconNumber size={33} />}
        />
      </Box>
      <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
        <MetricCard
          title={t("profileReservation.metricCards.clientName")}
          value={reservation?.clientName}
          icon={<IconUserCheck size={33} />}
        />
      </Box>
      <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
        <MetricCard
          title={t("profileReservation.metricCards.totalPrice")}
          value={
            <MarkupPrice
              value={{
                total: reservation?.itemData.price,
                net: reservation?.itemData.priceNet,
              }}
            >
              <S.Price>
                <Price value={reservation?.itemData.price} />
              </S.Price>
            </MarkupPrice>
          }
          icon={<IconCash size={33} />}
        />
      </Box>
      <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
        <MetricCard
          title={t("profileReservation.metricCards.vouchers")}
          value={
            <S.MetricCardActions>
              <LinkExternal
                to={getApiUrl(
                  `reservations/${reservation?.id}/vouchers/preview`,
                  {
                    token,
                  },
                )}
              >
                <Button small variant="outlined">
                  {t("profileReservation.actions.vouchers.download")}
                </Button>
              </LinkExternal>
              <ProfileReservationSendVoucher id={reservation.id} />
            </S.MetricCardActions>
          }
        />
      </Box>
    </Flex>
  );
};

export default ReservationMetricCards;
