import { forwardRef } from "react";

import * as S from "./Button.styled";
import { ButtonProps } from "./types";

const Button = forwardRef(
  (
    {
      children,
      variant,
      small,
      isLoading,
      isDisabled,
      fullWidth,
      ...restProps
    }: ButtonProps,
    ref: any,
  ) => {
    return (
      <S.Wrapper
        ref={ref}
        $variant={variant}
        $small={small}
        $fullWidth={fullWidth}
        type="button"
        disabled={isLoading || isDisabled}
        {...restProps}
      >
        {isLoading && <S.IconLoading size={16} style={{ minWidth: 16 }} />}
        {children}
      </S.Wrapper>
    );
  },
);

export default Button;
