import { formatDateIso, isNumeric } from "@hotelspoint/utils";
import { date, InferType, number, object, string } from "yup";

export const validationSchema = object({
  id: string(),
  name: string(),
  email: string(),
  registerFrom: date(),
  registerTo: date(),
  status: number(),
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: FormValues = {
  id: "",
  name: "",
  email: "",
  registerFrom: undefined,
  registerTo: undefined,
  status: undefined,
};

export const entity2Form = (query: any): FormValues => {
  return {
    id: query.id,
    name: query.name,
    email: query.email,
    registerFrom: query.registerFrom ? new Date(query.registerFrom) : undefined,
    registerTo: query.registerTo ? new Date(query.registerTo) : undefined,
    status: query.status,
  };
};

export const form2Entity = (formValues: FormValues) => {
  return {
    id: isNumeric(formValues.id) ? Number(formValues.id) : undefined,
    name: formValues.name,
    email: formValues.email,
    registerFrom: formValues.registerFrom
      ? formatDateIso(formValues.registerFrom)
      : undefined,
    registerTo: formValues.registerTo
      ? formatDateIso(formValues.registerTo)
      : undefined,
    status: formValues.status,
  };
};
