import { AdminAgencyStatementItem } from "@hotelspoint/api";
import {
  Table,
  TableFooter,
  TableLayout,
  TableProps,
  TableResultsCount,
} from "@hotelspoint/components";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import getColumns from "./AdminAgencyStatementItems.columns";

type AdminAgencyStatementItemsProps = Pick<
  TableProps<AdminAgencyStatementItem>,
  "data" | "isLoading" | "useReactTableProps"
>;

const AdminAgencyStatementItems = ({
  data,
  isLoading = false,
  useReactTableProps,
}: AdminAgencyStatementItemsProps) => {
  const { t } = useTranslation();

  const columns = useMemo(() => {
    return getColumns({
      t,
    });
  }, [t]);

  return (
    <TableLayout>
      <Table
        data={data ?? []}
        columns={columns}
        isLoading={isLoading}
        useReactTableProps={useReactTableProps}
      />
      <TableFooter>
        <div>
          <TableResultsCount value={data?.length} />
        </div>
        <div />
      </TableFooter>
    </TableLayout>
  );
};

export default AdminAgencyStatementItems;
