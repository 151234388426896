import { css } from "styled-components";
import { theme } from "styled-tools";

import { mediaQuery, zIndex } from "../utils";

export default css`
  .fui-dialog-overlay {
    z-index: ${zIndex.overlay};
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    @media ${mediaQuery.tablet} {
      align-items: center;
      background: rgba(0, 0, 0, 0.5);
    }
  }

  .fui-dialog-full-screen {
    width: 100%;
    height: 100%;
    min-height: calc(100vh - ${theme("custom.header.heightS")}px);
    transform: translate(0, ${theme("custom.header.heightS")}px);

    @media ${mediaQuery.tablet} {
      min-height: calc(100vh - ${theme("custom.header.heightL")}px);
      transform: translate(0, ${theme("custom.header.heightL")}px);
    }
  }
`;
