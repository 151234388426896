import { AgencyUpdatePayload } from "@hotelspoint/api";
import { Agency } from "@hotelspoint/types";
import {
  SCHEMA_COMPANY_ADDRESS,
  SCHEMA_COMPANY_LICENSE,
  SCHEMA_NAMES,
  SCHEMA_PHONE,
} from "@hotelspoint/utils";
import { InferType, mixed, number, object } from "yup";

export const validationSchema = object({
  name: SCHEMA_NAMES,
  companyPhone: SCHEMA_PHONE,
  companyLicense: SCHEMA_COMPANY_LICENSE,
  companyManager: SCHEMA_NAMES,
  companyAddress: SCHEMA_COMPANY_ADDRESS,
  markupHotels: number()
    .typeError("profileAgencySettings.markupHotels.validation.required")
    .required("profileAgencySettings.markupHotels.validation.required")
    .min(0, "profileAgencySettings.markupHotels.validation.min"),
  markupTransfers: number()
    .typeError("profileAgencySettings.markupTransfers.validation.required")
    .required("profileAgencySettings.markupTransfers.validation.required")
    .min(0, "profileAgencySettings.markupTransfers.validation.min"),
  markupActivities: number()
    .typeError("profileAgencySettings.markupActivities.validation.required")
    .required("profileAgencySettings.markupActivities.validation.required")
    .min(0, "profileAgencySettings.markupActivities.validation.min"),

  // Read-only
  email: mixed().nullable(),
  countryCode: mixed().nullable(),
  uicNumber: mixed().nullable(),
  creditUsed: mixed().nullable(),
  creditBalance: mixed().nullable(),
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: FormValues = {
  name: "",
  email: "",
  companyPhone: "",
  countryCode: "",
  uicNumber: "",
  companyLicense: "",
  companyManager: "",
  companyAddress: "",
  markupHotels: 0,
  markupTransfers: 0,
  markupActivities: 0,
  creditUsed: 0,
  creditBalance: 0,
};

export const entity2Form = (entity: Agency): FormValues => ({
  ...defaultValues,
  name: entity?.name,
  email: entity?.email,
  companyPhone: entity?.companyPhone,
  countryCode: `${entity?.countryCode}`.toLowerCase(),
  uicNumber: entity?.uicNumber,
  companyLicense: entity?.companyLicense,
  companyManager: entity?.companyManager,
  companyAddress: entity?.companyAddress,
  markupHotels: entity?.markupHotels ?? 0,
  markupTransfers: entity?.markupTransfers ?? 0,
  markupActivities: entity?.markupActivities ?? 0,
  creditUsed: entity?.credit?.used ?? 0,
  creditBalance: entity?.credit?.balance ?? 0,
});

export const form2Entity = (formValues: FormValues): AgencyUpdatePayload => ({
  name: formValues.name,
  // email: formValues.email,
  companyPhone: formValues.companyPhone,
  // countryCode: formValues.countryCode,
  // uicNumber: formValues.uicNumber,
  companyLicense: formValues.companyLicense,
  companyManager: formValues.companyManager,
  companyAddress: formValues.companyAddress,
  markupHotels: formValues.markupHotels,
  markupTransfers: formValues.markupTransfers,
  markupActivities: formValues.markupActivities,
  // credit: {
  //   used: formValues.creditUsed,
  //   balance: formValues.creditBalance,
  // },
});
