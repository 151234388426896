import { IconFile, IconX } from "@tabler/icons-react";
import prettyBytes from "pretty-bytes";

import * as S from "./FileList.styled";

interface FileListProps {
  value: File[];
  onRemove: (file: File) => void;
}

const FileList = ({ value, onRemove }: FileListProps) => {
  if (!value) return null;

  return (
    <S.Wrapper>
      {value.map((file: File, index: number) => (
        <S.Item key={`${index}-${file.name}`}>
          <IconFile size={18} />
          <b>{file.name}</b>
          <i>{prettyBytes(file.size)}</i>
          <S.RemoveFile onClick={() => onRemove(file)}>
            <IconX size={14} />
          </S.RemoveFile>
        </S.Item>
      ))}
    </S.Wrapper>
  );
};

export default FileList;
