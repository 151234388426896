import { useMutation, useQueryClient } from "@tanstack/react-query";

import {
  AdminService,
  AdminSupplierStatementUpdatePayload,
} from "../../../../services";

const useUpdateAdminSupplierStatement = (id: number) => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: ["admin", "statements_supplier", "update"],
    mutationFn: (payload: AdminSupplierStatementUpdatePayload) => {
      return AdminService.updateSupplierStatement(id, payload);
    },
    onMutate: () => {
      queryClient.cancelQueries({
        queryKey: ["admin", "statements_supplier", id],
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["admin", "statements_supplier", id],
      });

      queryClient.invalidateQueries({
        queryKey: ["admin", "statements_supplier"],
      });
    },
  });

  const { mutateAsync, isPending } = mutation;
  const ret: [typeof mutateAsync, typeof isPending] = [mutateAsync, isPending];

  return ret;
};

export default useUpdateAdminSupplierStatement;
