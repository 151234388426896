import {
  contactAddress,
  contactAddressGoogleMaps,
  contactEmail,
  contactPhone,
  contactPhoneSpaced,
} from "@hotelspoint/constants";
import { IconMail, IconPhone, IconPin } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

import LinkUnderline, {
  LinkUnderlineExternal,
} from "../../elements/LinkUnderline";
import Container from "../Container";
import logoBorica from "./assets/logo-borica.png";
import logoMastercard from "./assets/logo-mastercard.png";
import logoVisa from "./assets/logo-visa.png";
import * as S from "./Footer.styled";
import sitemap from "./sitemap.json";

const Footer = () => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  const contactDetails = (
    <S.Contacts>
      <LinkUnderlineExternal href={`mailto:${contactEmail}`}>
        <S.ContactDetail>
          <IconMail size={18} />
          <span>{contactEmail}</span>
        </S.ContactDetail>
      </LinkUnderlineExternal>
      <LinkUnderlineExternal href={`tel:${contactPhone}`}>
        <S.ContactDetail>
          <IconPhone size={18} />
          <span>{contactPhoneSpaced}</span>
        </S.ContactDetail>
      </LinkUnderlineExternal>
      <LinkUnderlineExternal
        href={contactAddressGoogleMaps}
        rel="noopener noreferrer"
        target="_blank"
      >
        <S.ContactDetail>
          <IconPin size={18} />
          <span>{contactAddress}</span>
        </S.ContactDetail>
      </LinkUnderlineExternal>
    </S.Contacts>
  );

  return (
    <S.OuterWrapper>
      <Container>
        <S.Wrapper>
          <div>
            <S.Sitemap>
              <ul>
                {sitemap.map(item => (
                  <li key={item.href}>
                    <LinkUnderline to={item.href}>
                      {t(item.title)}
                    </LinkUnderline>
                  </li>
                ))}
              </ul>
            </S.Sitemap>
            <S.Copyright>
              <p>
                {t("footer.copyright", {
                  year: currentYear,
                })}
              </p>
            </S.Copyright>
            <S.ContactsMobile>{contactDetails}</S.ContactsMobile>
            <S.PaymentLogos>
              <img src={logoBorica} alt="Borica AD - bank services" />
              <img src={logoVisa} alt="Visa - It's everywhere you want to be" />
              <img
                src={logoMastercard}
                alt="Mastercard - There are some things money can’t buy"
              />
            </S.PaymentLogos>
          </div>
          <S.ContactsDesktop>{contactDetails}</S.ContactsDesktop>
        </S.Wrapper>
      </Container>
    </S.OuterWrapper>
  );
};

export default Footer;
