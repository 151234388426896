import { handleError, useUpdateProfile } from "@hotelspoint/api";
import {
  Box,
  Button,
  Flex,
  Form,
  FormAdapter,
  FormContext,
  FormInput,
  FormLayout,
} from "@hotelspoint/components";
import { useUserProfileStore } from "@hotelspoint/store";
import { UserProfile } from "@hotelspoint/types";
import { useDocumentTitle } from "@hotelspoint/utils";
import { IconMail, IconUser } from "@tabler/icons-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import {
  entity2Form,
  FormValues,
  validationSchema,
} from "./ProfileAgentSettings.form";

const ProfileAgentSettings = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("profileAgentSettings.pageTitle"));

  const profile = useUserProfileStore(state => state.profile) as UserProfile;

  const setProfile = useUserProfileStore(state => state.set);
  const [updateProfile, isUpdatingProfile] = useUpdateProfile();

  const formValues = useMemo(() => {
    return entity2Form(profile);
  }, [profile]);

  const onSubmit = async (formValues: FormValues) => {
    try {
      const user = await updateProfile(formValues);
      setProfile(user);

      toast.success(t("toast.profileAgentSettings"));
    } catch (error: any) {
      handleError({ t, error });
    }
  };

  return (
    <Form<FormValues>
      defaultValues={formValues}
      validationSchema={validationSchema}
    >
      <FormLayout>
        <Flex mx={[0, 0, -1, -1]}>
          <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="name"
              label={t("profileAgentSettings.name.label")}
            >
              {props => (
                <FormInput
                  {...props}
                  placeholder={t("profileAgentSettings.name.placeholder")}
                  startAdornment={<IconUser size={18} />}
                  disabled={isUpdatingProfile}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="email"
              label={t("profileAgentSettings.email.label")}
            >
              {props => (
                <FormInput
                  {...props}
                  placeholder={t("profileAgentSettings.email.placeholder")}
                  startAdornment={<IconMail size={18} />}
                  disabled={isUpdatingProfile}
                />
              )}
            </FormAdapter>
          </Box>
        </Flex>
        <FormContext<FormValues>
          render={({ handleSubmit }) => (
            <Button
              type="submit"
              variant="secondary"
              isLoading={isUpdatingProfile}
              onClick={handleSubmit(onSubmit)}
            >
              {t("profileAgentSettings.submit")}
            </Button>
          )}
        />
      </FormLayout>
    </Form>
  );
};

export default ProfileAgentSettings;
