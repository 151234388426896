import { useFetchUsers, useUser } from "@hotelspoint/api";
import {
  Box,
  Button,
  Chip,
  Flex,
  Form,
  FormAdapter,
  FormAsyncSelect,
  FormClearableInput,
  FormContext,
  FormDatePickerSingle,
  FormLayout,
  FormSelect,
} from "@hotelspoint/components";
import {
  CHIP_COLORS,
  TRANSFER_REQUEST_TYPE_OPTIONS,
  TRANSFER_SUPPLIER_OPTIONS,
} from "@hotelspoint/constants";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import useAdminTransferRequestsParams from "../useAdminTransferRequestsParams";
import {
  entity2Form,
  form2Entity,
  FormValues,
} from "./AdminTransferRequestsFilters.form";

interface AdminTransferRequestsFiltersProps {
  isLoading: boolean;
}

const AdminTransferRequestsFilters = ({
  isLoading,
}: AdminTransferRequestsFiltersProps) => {
  const { t } = useTranslation();

  const [query, setQuery] = useAdminTransferRequestsParams();

  const [fetchUsers, isFetchingUsers] = useFetchUsers();
  const [user, isLoadingUser] = useUser(Number(query?.userId));

  const defaultUserOptions = useMemo(() => {
    if (!user) return [];

    return [
      {
        value: user.id,
        label: user.name,
      },
    ];
  }, [user]);

  const handleLoadUserOptions = useCallback(
    (searchQuery: string, callback: any) => {
      fetchUsers({ name: searchQuery }).then(response => {
        if (response && Array.isArray(response.results)) {
          callback(
            response.results.map(user => ({
              value: user.id,
              label: user.name,
            })),
          );
        }
      });
    },
    [fetchUsers],
  );

  const typeOptions = useMemo(
    () =>
      TRANSFER_REQUEST_TYPE_OPTIONS.map(type => ({
        ...type,
        label: t(type.label),
      })),
    [t],
  );

  const transferSupplierOptions = TRANSFER_SUPPLIER_OPTIONS.map(option => ({
    ...option,
    label: <Chip $color={CHIP_COLORS.SUPPLIER}>{option.label}</Chip>,
  }));

  const formValues = useMemo(() => {
    return entity2Form(query);
  }, [query]);

  const onSubmit = (formValues: FormValues) => {
    const payload = form2Entity(formValues);

    setQuery(payload);
  };

  return (
    <Form defaultValues={formValues}>
      <FormLayout>
        <Flex mx={[0, 0, -1, -1]}>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="id"
              label={t("adminTransferRequests.filters.id.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  placeholder={t(
                    "adminTransferRequests.filters.id.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="recordId"
              label={t("adminTransferRequests.filters.recordId.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  placeholder={t(
                    "adminTransferRequests.filters.recordId.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="userId"
              label={t("adminTransferRequests.filters.userId.label")}
            >
              {props => (
                <FormAsyncSelect
                  {...props}
                  placeholder={t(
                    "adminTransferRequests.filters.userId.placeholder",
                  )}
                  defaultOptions={defaultUserOptions}
                  loadOptions={handleLoadUserOptions}
                  isLoading={isLoadingUser || isFetchingUsers}
                  isClearable
                  noOptionsMessage={() =>
                    t("adminTransferRequests.filters.userId.noOptions")
                  }
                  loadingMessage={() =>
                    t("adminTransferRequests.filters.userId.loading")
                  }
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="dateFrom"
              label={t("adminTransferRequests.filters.dateFrom.label")}
            >
              {props => (
                <FormDatePickerSingle
                  {...props}
                  placeholder={t(
                    "adminTransferRequests.filters.dateFrom.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="dateTo"
              label={t("adminTransferRequests.filters.dateTo.label")}
            >
              {props => (
                <FormDatePickerSingle
                  {...props}
                  placeholder={t(
                    "adminTransferRequests.filters.dateTo.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="type"
              label={t("adminTransferRequests.filters.type.label")}
            >
              {props => (
                <FormSelect
                  {...props}
                  placeholder={t(
                    "adminTransferRequests.filters.type.placeholder",
                  )}
                  options={typeOptions}
                  isClearable
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="supplierId"
              label={t("adminTransferRequests.filters.supplierId.label")}
            >
              {props => (
                <FormSelect
                  {...props}
                  placeholder={t(
                    "adminTransferRequests.filters.supplierId.placeholder",
                  )}
                  options={transferSupplierOptions}
                  isClearable
                />
              )}
            </FormAdapter>
          </Box>
        </Flex>
        <FormContext<FormValues>
          render={({ handleSubmit }) => (
            <Button
              type="submit"
              variant="secondary"
              isLoading={isLoading}
              onClick={handleSubmit(onSubmit)}
            >
              {t("adminTransferRequests.filters.submit")}
            </Button>
          )}
        />
      </FormLayout>
    </Form>
  );
};

export default AdminTransferRequestsFilters;
