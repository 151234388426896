import { borderRadius } from "@hotelspoint/theme";
import styled from "styled-components";
import { theme } from "styled-tools";

export const Wrapper = styled.div`
  width: 55px;
  height: 55px;
  border-radius: ${borderRadius.circle};
  background: ${theme("primary.bg")};
  overflow: hidden;

  img {
    width: 100%;
  }
`;
