import "rc-switch/assets/index.css";

import Switch from "rc-switch";

import { type FormControlInjectedProps } from "../FormControl";

export interface FormCheckboxProps
  extends FormControlInjectedProps,
    Pick<React.InputHTMLAttributes<HTMLInputElement>, "disabled"> {}

const FormToggleSwitch = ({ value, ...inputProps }: FormCheckboxProps) => {
  return <Switch {...inputProps} checked={value} />;
};

export default FormToggleSwitch;
