import { IconMinus, IconPlus } from "@tabler/icons-react";

import { type FormControlInjectedProps } from "../FormControl";
import * as S from "./FormCounter.styled";

interface FormCounterProps extends FormControlInjectedProps {
  value: number;
  onChange: (counter: number) => void;
  disabled?: {
    decrement?: boolean;
    increment?: boolean;
  };
}

const FormCounter = ({
  id,
  name,
  value,
  onChange,
  disabled,
}: FormCounterProps) => {
  return (
    <S.Wrapper>
      <S.CounterBtn
        onClick={() => onChange(value - 1)}
        variant="outlined"
        isDisabled={value <= 0 || disabled?.decrement}
      >
        <IconMinus size={14} />
      </S.CounterBtn>
      <S.DisplayValue>{value}</S.DisplayValue>
      <input type="hidden" id={id} name={name} value={value} />
      <S.CounterBtn
        onClick={() => onChange(value + 1)}
        variant="outlined"
        isDisabled={disabled?.increment}
      >
        <IconPlus size={14} />
      </S.CounterBtn>
    </S.Wrapper>
  );
};

export default FormCounter;
