import { useUserLocaleStore } from "@hotelspoint/store";
import { IconWorld } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

import { MenuHeader, MenuItem } from "../../../composites/Menu";
import HeaderMenu from "../HeaderMenu";
import languages from "../languages";
import * as S from "./UserLocale.styled";

const UserLocale = () => {
  const { t } = useTranslation();

  const { language, set: setLanguage } = useUserLocaleStore(state => state);

  return (
    <HeaderMenu menuButton={<IconWorld size={22} />}>
      <MenuHeader>{t("header.userLocale")}</MenuHeader>
      {languages.map(lang => (
        <MenuItem
          key={lang.value}
          disabled={lang.value === language}
          onClick={() => setLanguage(lang.value)}
        >
          <S.MenuItem>
            <S.FlagIcon src={lang.icon} alt={lang.title} />
            <span>{lang.title}</span>
          </S.MenuItem>
        </MenuItem>
      ))}
    </HeaderMenu>
  );
};

export default UserLocale;
