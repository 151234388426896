import { useQuery } from "@tanstack/react-query";

import { AgencyListParams, AgencyService } from "../../services";

const useAgencies = (params?: AgencyListParams) => {
  const { data, isLoading } = useQuery({
    queryKey: ["agencies", params],
    queryFn: () => AgencyService.getList(params),
  });

  const ret: [typeof data, boolean] = [data, isLoading];

  return ret;
};

export default useAgencies;
