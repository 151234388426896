import {
  PaymentCreditStatus,
  PaymentStatus,
  PaymentType,
} from "@hotelspoint/api";
import { CHIP_COLORS, paymentProviders } from "@hotelspoint/constants";

export const getPaymentStatusName = (status: PaymentStatus) => {
  switch (status) {
    case PaymentStatus.Progress:
      return "paymentStatus.progress";

    case PaymentStatus.Reserved:
      return "paymentStatus.reserved";

    case PaymentStatus.Confirmed:
      return "paymentStatus.confirmed";

    case PaymentStatus.Cancelled:
      return "paymentStatus.cancelled";

    case PaymentStatus.Refunded:
      return "paymentStatus.refunded";

    case PaymentStatus.Failed:
      return "paymentStatus.failed";

    case PaymentStatus.PendingRefund:
      return "paymentStatus.pendingRefund";

    default:
      return "Unknown payment status";
  }
};

export const getPaymentStatusColor = (status: PaymentStatus) => {
  switch (status) {
    case PaymentStatus.Progress:
    case PaymentStatus.PendingRefund:
      return CHIP_COLORS.YELLOW;

    case PaymentStatus.Reserved:
      return CHIP_COLORS.BLUE;

    case PaymentStatus.Confirmed:
    case PaymentStatus.Refunded:
      return CHIP_COLORS.GREEN;

    case PaymentStatus.Cancelled:
    case PaymentStatus.Failed:
      return CHIP_COLORS.RED;

    default:
      return CHIP_COLORS.GREY;
  }
};

export const getPaymentTypeName = (type: PaymentType) => {
  switch (type) {
    case PaymentType.BankTransfer:
      return "paymentType.bankTransfer";

    case PaymentType.CreditCard:
      return "paymentType.creditCard";

    case PaymentType.CreditLine:
      return "paymentType.creditLine";

    default:
      return "Unknown payment type";
  }
};

export const getPaymentCreditStatusName = (status: PaymentCreditStatus) => {
  switch (status) {
    case PaymentCreditStatus.Pending:
      return "creditStatus.pending";

    case PaymentCreditStatus.Paid:
      return "creditStatus.paid";

    default:
      return "Unknown credit status";
  }
};

export const getPaymentCreditStatusColor = (status: PaymentCreditStatus) => {
  switch (status) {
    case PaymentCreditStatus.Pending:
      return CHIP_COLORS.YELLOW;

    case PaymentCreditStatus.Paid:
      return CHIP_COLORS.GREEN;

    default:
      return CHIP_COLORS.GREY;
  }
};

export const getPaymentProviderNameById = (id: number) => {
  const providerName =
    paymentProviders[`${id}` as keyof typeof paymentProviders];

  if (!providerName) {
    return "Unknown payment provider";
  }

  return providerName;
};
