import { useMutation } from "@tanstack/react-query";

import { AuthService, type ResetPasswordPayload } from "../../services";

const useResetPassword = () => {
  const mutation = useMutation({
    mutationKey: ["auth", "reset_password"],
    mutationFn: (payload: ResetPasswordPayload) => {
      return AuthService.resetPassword(payload);
    },
  });

  const { mutateAsync, isPending } = mutation;
  const ret: [typeof mutateAsync, typeof isPending] = [mutateAsync, isPending];

  return ret;
};

export default useResetPassword;
