import { AdminActivityRequest } from "@hotelspoint/api";
import {
  ButtonApiHeaders,
  ButtonApiRequest,
  ButtonApiResponse,
  Chip,
  LinkExternal,
  TABLE_COLUMN_SIZE,
  TableCell,
  TableCellEmpty,
} from "@hotelspoint/components";
import { CHIP_COLORS } from "@hotelspoint/constants";
import {
  formatDateTime,
  getActivityRequestTypeName,
  getActivitySupplierName,
  getApiUrl,
} from "@hotelspoint/utils";
import { createColumnHelper } from "@tanstack/react-table";
import { TFunction } from "i18next";

import { AdminApiPayloadType } from "../../common";

const columnHelper = createColumnHelper<AdminActivityRequest>();

interface GetColumnsParams {
  t: TFunction;
  token: string;
}

const getColumns = ({ t, token }: GetColumnsParams) => [
  columnHelper.accessor("id", {
    header: t("adminActivityRequests.columns.id"),
    size: TABLE_COLUMN_SIZE.ID_LONG,
  }),
  columnHelper.accessor("userName", {
    header: t("adminActivityRequests.columns.userName"),
    size: TABLE_COLUMN_SIZE.NAME,
  }),
  columnHelper.accessor("type", {
    header: t("adminActivityRequests.columns.type"),
    size: TABLE_COLUMN_SIZE.TYPE_LONG,
    cell: props => t(getActivityRequestTypeName(props.getValue())),
  }),
  columnHelper.accessor("supplierId", {
    header: t("adminActivityRequests.columns.supplierId"),
    size: TABLE_COLUMN_SIZE.SUPPLIER,
    cell: props => (
      <Chip $color={CHIP_COLORS.SUPPLIER}>
        {getActivitySupplierName(props.getValue())}
      </Chip>
    ),
  }),
  columnHelper.accessor("recordId", {
    header: t("adminActivityRequests.columns.recordId"),
    size: TABLE_COLUMN_SIZE.ID_LONG,
    cell: props => props.getValue() || <TableCellEmpty />,
  }),
  columnHelper.accessor("dateCreated", {
    header: t("adminActivityRequests.columns.dateCreated"),
    size: TABLE_COLUMN_SIZE.DATE_TIME,
    cell: props => formatDateTime(props.getValue()),
  }),
  columnHelper.display({
    id: "actions",
    size: TABLE_COLUMN_SIZE.ACTIONS,
    cell: props => {
      const id = props.row.original.id;
      const basePath = "admin/requests/activities";

      return (
        <TableCell>
          <LinkExternal
            to={getApiUrl(`${basePath}/${id}/${AdminApiPayloadType.Request}`, {
              token,
            })}
          >
            <ButtonApiRequest />
          </LinkExternal>
          <LinkExternal
            to={getApiUrl(`${basePath}/${id}/${AdminApiPayloadType.Response}`, {
              token,
            })}
          >
            <ButtonApiResponse />
          </LinkExternal>
          <LinkExternal
            to={getApiUrl(`${basePath}/${id}/${AdminApiPayloadType.Headers}`, {
              token,
            })}
          >
            <ButtonApiHeaders />
          </LinkExternal>
        </TableCell>
      );
    },
  }),
];

export default getColumns;
