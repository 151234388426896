import { useQuery } from "@tanstack/react-query";

import { PlaceParams, PlaceService } from "../../services";

const usePlace = (params: Partial<PlaceParams>) => {
  const { data, isLoading } = useQuery({
    queryKey: ["places", params],
    queryFn: () => PlaceService.get(params),
    enabled: !!params.id && !!params.type,
  });

  const ret: [typeof data, boolean] = [data, isLoading];

  return ret;
};

export default usePlace;
