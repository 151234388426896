import { Container, HeaderImage, PageWrapper } from "@hotelspoint/components";
import { useDocumentTitle } from "@hotelspoint/utils";
import { useTranslation } from "react-i18next";

import headerImage from "./assets/header-bg.jpg";
import * as S from "./SearchActivities.styled";
import SearchActivitiesForm from "./SearchActivitiesForm";

const SearchActivities = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("searchActivities.pageTitle"));

  return (
    <PageWrapper>
      <HeaderImage src={headerImage}>
        <Container>
          <S.HeaderWrapper>
            <S.HeaderCard>
              <SearchActivitiesForm />
            </S.HeaderCard>
          </S.HeaderWrapper>
        </Container>
      </HeaderImage>
    </PageWrapper>
  );
};

export default SearchActivities;
