import {
  AdminAgencyStatement,
  AdminAgencyStatementStatus,
} from "@hotelspoint/api";
import { InferType, mixed, number, object, string } from "yup";

export const validationSchema = object({
  agencyName: string(),
  amount: number(),
  status: mixed<AdminAgencyStatementStatus>()
    .oneOf(
      Object.values(AdminAgencyStatementStatus).map(
        e => e as AdminAgencyStatementStatus,
      ),
    )
    .required(),
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: Partial<FormValues> = {
  agencyName: "",
  amount: undefined,
  status: undefined,
};

export const entity2Form = (entity: AdminAgencyStatement): FormValues => ({
  agencyName: entity.agencyName,
  amount: entity.amount,
  status: entity.status,
});
