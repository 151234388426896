import { AdminAgencyStatementListParams, AdminService } from "@hotelspoint/api";
import { useQuery } from "@tanstack/react-query";

const useAdminAgencyStatements = (params?: AdminAgencyStatementListParams) => {
  const { data, isLoading } = useQuery({
    queryKey: ["admin", "statements_agency", params],
    queryFn: () => AdminService.getAgencyStatements(params),
  });

  const ret: [typeof data, boolean] = [data, isLoading];

  return ret;
};

export default useAdminAgencyStatements;
