import { Dialog, Modal, MosaicLayout } from "@hotelspoint/components";
import { Hotel } from "@hotelspoint/types";
import { getImagePathFromCDN, ImageType } from "@hotelspoint/utils";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { HotelAmenities, HotelBaseInfo, HotelSummary } from "../../components";
import * as S from "./HotelDetailsModal.styled";

interface HotelDetailsModalProps {
  isOpen: boolean;
  hotel: Hotel | undefined;
  isLoading: boolean;
  handleImageClick: (index: number) => void;
  onClose: () => void;
}

const MOSAIC_IMAGES_MAX = 3;

const HotelDetailsModal = ({
  isOpen,
  hotel,
  isLoading,
  handleImageClick,
  onClose,
}: HotelDetailsModalProps) => {
  const { t } = useTranslation();

  const images = useMemo(() => {
    if (!hotel?.images) return [];

    return hotel?.images.slice(0, MOSAIC_IMAGES_MAX).map(img => ({
      image: getImagePathFromCDN(img, ImageType.Hotel),
      alt: `Image ${img}`,
    }));
  }, [hotel?.images]);

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <Dialog.Content>
        <Modal
          title={t("searchHotelResults.hotelDetailsModal.title")}
          onClose={onClose}
        >
          <div>
            {hotel?.images.length !== 0 && (
              <S.MosaicWrapper>
                <MosaicLayout
                  value={images}
                  height={120}
                  onClick={handleImageClick}
                />
              </S.MosaicWrapper>
            )}
            <HotelBaseInfo
              isLoading={isLoading}
              name={hotel?.name}
              rating={hotel?.rating}
              address={hotel?.address}
              cityName={hotel?.cityName}
            />
            <S.Divider />
            <HotelSummary
              isLoading={isLoading}
              information={hotel?.information}
            />
            <S.Divider />
            <HotelAmenities
              isLoading={isLoading}
              hotelAmenities={hotel?.amenities.hotel}
              roomAmenities={hotel?.amenities.room}
            />
          </div>
        </Modal>
      </Dialog.Content>
    </Dialog>
  );
};

export default HotelDetailsModal;
