import { useMemo, useState } from "react";
import { OptionsOrGroups } from "react-select";
import AsyncSelect from "react-select/async";
import { useTheme } from "styled-components";

import { Option } from "../../../common";
import { type FormControlInjectedProps } from "../../FormControl";
import getStyles from "../FormSelect.styles";

interface FormAsyncSelectProps
  extends Partial<FormControlInjectedProps>,
    Pick<
      React.InputHTMLAttributes<HTMLInputElement>,
      "disabled" | "placeholder"
    > {
  value?: any;
  defaultOptions: OptionsOrGroups<Option, any> | boolean;
  loadOptions: (
    inputValue: string,
    callback: (options: OptionsOrGroups<Option, any>) => void,
  ) => Promise<OptionsOrGroups<Option, any>> | void;
  disabled?: boolean;
  isLoading?: boolean;
  isClearable?: boolean;
  noOptionsMessage?: () => string;
  loadingMessage?: () => string;
}

// @todo: not supporting error, read-only states for now
const FormAsyncSelect = ({
  id,
  name,
  value,
  onChange,
  defaultOptions,
  loadOptions,
  placeholder,
  disabled = false,
  isLoading = false,
  isClearable = false,
  noOptionsMessage,
  loadingMessage,
}: FormAsyncSelectProps) => {
  const theme = useTheme();

  const [inputValue, setInputValue] = useState("");

  const styles = useMemo(() => getStyles({ theme }), [theme]);

  return (
    <AsyncSelect
      inputId={id}
      name={name}
      value={value}
      onChange={(value, meta) => {
        // Reset the input value when clearing the selection
        if (meta.action === "clear") {
          setInputValue("");
        }

        if (typeof onChange === "function") onChange(value);
      }}
      styles={styles}
      cacheOptions
      defaultOptions={defaultOptions}
      isDisabled={disabled}
      isLoading={isLoading}
      isClearable={isClearable}
      placeholder={placeholder}
      loadOptions={loadOptions}
      inputValue={value ? undefined : inputValue}
      noOptionsMessage={noOptionsMessage}
      loadingMessage={loadingMessage}
      onInputChange={(value, action) => {
        if (action.action === "input-change") {
          setInputValue(value);
        }

        if (action.action === "set-value") {
          return inputValue;
        }

        if (action.action === "menu-close") {
          return action.prevInputValue;
        }

        return undefined;
      }}
    />
  );
};

export default FormAsyncSelect;
