import { useAdminAgencyStatement } from "@hotelspoint/api";
import {
  Box,
  Chip,
  Flex,
  Form,
  FormAdapter,
  FormInput,
  FormLayout,
  FormSelect,
  LoaderBlock,
} from "@hotelspoint/components";
import { AGENCY_STATEMENTS_STATUS_OPTIONS } from "@hotelspoint/constants";
import { UserCurrency } from "@hotelspoint/types";
import { getAgencyStatementStatusColor } from "@hotelspoint/utils";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  defaultValues,
  entity2Form,
  validationSchema,
} from "./AdminAgencyStatementForm.util";

interface AdminAgencyStatementProps {
  id: number;
}

const AdminAgencyStatementForm = ({ id }: AdminAgencyStatementProps) => {
  const { t } = useTranslation();

  const [agencyStatement, isLoadingAgencyStatement] =
    useAdminAgencyStatement(id);

  const formValues = useMemo(() => {
    if (!agencyStatement) return defaultValues;

    return entity2Form(agencyStatement);
  }, [agencyStatement]);

  const statusOptions = useMemo(
    () =>
      AGENCY_STATEMENTS_STATUS_OPTIONS.map(status => ({
        ...status,
        label: (
          <Chip $color={getAgencyStatementStatusColor(status.value)}>
            {t(status.label)}
          </Chip>
        ),
      })),
    [t],
  );

  if (isLoadingAgencyStatement) {
    return <LoaderBlock />;
  }

  return (
    <Form
      defaultValues={formValues}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <FormLayout>
        <Flex mx={[0, 0, -1, -1]}>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="agencyName"
              label={t("adminAgencyStatement.form.agencyName.label")}
            >
              {props => <FormInput {...props} readOnly />}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="amount"
              label={t("adminAgencyStatement.form.amount.label")}
            >
              {props => (
                <FormInput
                  {...props}
                  readOnly
                  adornmentStyle="filled"
                  endAdornment={UserCurrency.EUR}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="status"
              label={t("adminAgencyStatement.form.status.label")}
            >
              {props => (
                <FormSelect {...props} options={statusOptions} readOnly />
              )}
            </FormAdapter>
          </Box>
        </Flex>
      </FormLayout>
    </Form>
  );
};

export default AdminAgencyStatementForm;
