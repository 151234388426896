import { useMutation, useQueryClient } from "@tanstack/react-query";

import { PaymentService } from "../../services";

const useRejectPayment = (id: number) => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: ["payments", "reject"],
    mutationFn: () => {
      return PaymentService.reject(id);
    },
    onMutate: () => {
      queryClient.cancelQueries({
        queryKey: ["payments", id],
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["payments", id],
      });

      queryClient.invalidateQueries({
        queryKey: ["payments"],
      });
    },
  });

  const { mutateAsync, isPending } = mutation;
  const ret: [typeof mutateAsync, typeof isPending] = [mutateAsync, isPending];

  return ret;
};

export default useRejectPayment;
