import { spacing } from "@hotelspoint/theme";
import styled from "styled-components";
import { theme } from "styled-tools";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  column-gap: ${spacing(1)}px;

  &::before {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    width: 100%;
    height: 1px;
    background: ${theme("palette.silver")};
  }
`;
