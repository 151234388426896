import { SCHEMA_EMAIL, SCHEMA_PASSWORD_BASIC } from "@hotelspoint/utils";
import { InferType, object } from "yup";

export const validationSchema = object({
  email: SCHEMA_EMAIL,
  password: SCHEMA_PASSWORD_BASIC,
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: FormValues = {
  email: "",
  password: "",
};
