import Avatar from "../../elements/Avatar";
import * as S from "./ReviewCard.styled";

interface ReviewCardProps {
  author: {
    names: string;
    avatar?: string;
    company: string;
  };
  content: React.ReactNode;
}

const ReviewCard = ({ author, content }: ReviewCardProps) => {
  return (
    <S.Wrapper>
      <S.Icon size={26} />
      <S.Content>{content}</S.Content>
      <Avatar src={author.avatar} alt={author.company} />
      <S.AuthorNames>{author.names}</S.AuthorNames>
      <S.AuthorCompany>@{author.company}</S.AuthorCompany>
    </S.Wrapper>
  );
};

export default ReviewCard;
