import { useMutation, useQueryClient } from "@tanstack/react-query";

import { AdminService } from "../../../services";

const useDeleteAdminAreaLocation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: ["admin", "locations_area", "delete"],
    mutationFn: (id: number) => {
      return AdminService.deleteAreaLocation(id);
    },
    onSuccess: (_, id: number) => {
      queryClient.removeQueries({
        queryKey: ["admin", "locations_area", id],
      });

      queryClient.invalidateQueries({
        queryKey: ["admin", "locations_area"],
      });
    },
  });

  const { mutateAsync, isPending } = mutation;
  const ret: [typeof mutateAsync, typeof isPending] = [mutateAsync, isPending];

  return ret;
};

export default useDeleteAdminAreaLocation;
