import { number, object } from "yup";

export const getDebitNoteValidationSchema = ({
  maxAmount,
}: {
  maxAmount: number;
}) => {
  return object({
    amount: number()
      .typeError(
        "adminInvoice.actions.addDebitNote.modal.amount.validation.required",
      )
      .min(0, "adminInvoice.actions.addDebitNote.modal.amount.validation.min")
      .max(
        maxAmount,
        "adminInvoice.actions.addDebitNote.modal.amount.validation.max",
      )
      .required(
        "adminInvoice.actions.addDebitNote.modal.amount.validation.required",
      ),
  }).required();
};

export const getCreditNoteValidationSchema = ({
  minAmount,
}: {
  minAmount: number;
}) => {
  return object({
    amount: number()
      .typeError(
        "adminInvoice.actions.addCreditNote.modal.amount.validation.required",
      )
      .min(
        minAmount,
        "adminInvoice.actions.addCreditNote.modal.amount.validation.min",
      )
      .max(0, "adminInvoice.actions.addCreditNote.modal.amount.validation.max")
      .required(
        "adminInvoice.actions.addDebitNote.modal.amount.validation.required",
      ),
  }).required();
};
