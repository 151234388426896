import styled from "styled-components";

export default styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    &:first-child,
    &:last-child {
      flex-grow: 1;
      flex-basis: 0;
    }

    &:last-child {
      justify-content: flex-end;
    }
  }
`;
