import { AdminService } from "@hotelspoint/api";
import { useQuery } from "@tanstack/react-query";

const useAdminAgencyStatementOptions = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["admin", "statements_agency", "agency_options"],
    queryFn: () => AdminService.getAgencyStatementOptions(),
  });

  const ret: [typeof data, boolean] = [data, isLoading];

  return ret;
};

export default useAdminAgencyStatementOptions;
