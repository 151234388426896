import { mediaQuery, pxToRem, spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";

export const DetailsWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  row-gap: ${spacing(1)}px;
  padding: ${spacing(2)}px 0;
  @media ${mediaQuery.mobileL} {
    flex-wrap: nowrap;
    row-gap: 0;
    justify-content: space-between;
  }
`;

export const Heading = styled.h6`
  margin-bottom: ${spacing(2)}px;
  color: rgba(18, 50, 102, 0.6);
  font-weight: 700;
  font-size: ${pxToRem(14)};
  text-transform: uppercase;
`;
