import { AdminCommissionStatus } from "@hotelspoint/api";
import { CHIP_COLORS } from "@hotelspoint/constants";

export const getCommissionStatusName = (status: AdminCommissionStatus) => {
  switch (status) {
    case AdminCommissionStatus.Pending:
      return "commissionStatus.pending";

    case AdminCommissionStatus.Paid:
      return "commissionStatus.paid";

    default:
      return "Unknown commission status";
  }
};

export const getCommissionStatusColor = (status: AdminCommissionStatus) => {
  switch (status) {
    case AdminCommissionStatus.Pending:
      return CHIP_COLORS.YELLOW;

    case AdminCommissionStatus.Paid:
      return CHIP_COLORS.GREEN;

    default:
      return CHIP_COLORS.GREY;
  }
};
