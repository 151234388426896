import { USER_TOKEN_STORE_PERSIST_NAME } from "@hotelspoint/store";
import { v4 as uuidv4 } from "uuid";

import mergeHeaders from "../../utils/mergeHeaders";
import ApiError from "./ApiError";

interface MakeRequestConfig {
  headers?: Headers | Record<string, string>;
  parseBody?: (body: any) => any;
}

interface MakeRequestOptions {
  method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
  path: string;
  params?: Record<string, any>;
  body?: object;
  config?: MakeRequestConfig;
}

// https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API
class FetchService {
  get defaultHeaders() {
    const headers = new Headers();

    headers.append("Accept", "application/json");
    headers.append("Content-Type", "application/json");

    if (import.meta.env.DEV) {
      headers.append("X-Request-Id", uuidv4());
    }

    let token = undefined;
    try {
      const sessionItem = localStorage.getItem(USER_TOKEN_STORE_PERSIST_NAME);

      if (sessionItem) {
        const { state } = JSON.parse(sessionItem);

        token = state?.token;
      }
    } catch (error: any) {
      // Token state is corrupted, remove item from session storage
      localStorage.removeItem(USER_TOKEN_STORE_PERSIST_NAME);
    }

    if (token) {
      headers.append("Authorization", `Basic ${token}`);
    }

    return headers;
  }

  async makeRequest({
    path,
    method,
    params,
    body,
    config,
  }: MakeRequestOptions) {
    const searchParams = new URLSearchParams(params);

    const url = `${import.meta.env.VITE_API_URL}/${path}${
      params ? `?${searchParams}` : ""
    }`;

    const headers = config?.headers
      ? mergeHeaders(this.defaultHeaders, config.headers)
      : this.defaultHeaders;

    const payload =
      typeof config?.parseBody === "function"
        ? config?.parseBody(body)
        : JSON.stringify(body);

    const response = await fetch(url, {
      method,
      mode: "cors",
      headers,
      body: payload,
    });

    const result = await response.json();

    if (!response.ok) {
      if (result.error && typeof result.error.code === "number") {
        throw new ApiError(result.error.code);
      }

      throw new Error("Something went wrong!");
    }

    return result;
  }

  get<T = unknown>(path: string, params?: Record<string, any>): Promise<T> {
    return this.makeRequest({
      method: "GET",
      path,
      params,
    });
  }

  post<T = unknown>(
    path: string,
    body?: object,
    params?: Record<string, any>,
    config?: MakeRequestConfig,
  ): Promise<T> {
    return this.makeRequest({
      method: "POST",
      path,
      body,
      params,
      config,
    });
  }

  put<T = unknown>(
    path: string,
    body: object,
    params?: Record<string, any>,
    config?: MakeRequestConfig,
  ): Promise<T> {
    return this.makeRequest({
      method: "PUT",
      path,
      body,
      params,
      config,
    });
  }

  patch<T = unknown>(
    path: string,
    body: object,
    params?: Record<string, any>,
    config?: MakeRequestConfig,
  ): Promise<T> {
    return this.makeRequest({
      method: "PATCH",
      path,
      body,
      params,
      config,
    });
  }

  delete<T = unknown>(
    path: string,
    body?: object,
    params?: Record<string, any>,
    config?: MakeRequestConfig,
  ): Promise<T> {
    return this.makeRequest({
      method: "DELETE",
      path,
      body,
      params,
      config,
    });
  }
}

export default new FetchService();
