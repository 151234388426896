import { useUserProfileStore } from "@hotelspoint/store";
import Skeleton from "react-loading-skeleton";

import * as S from "./UserWelcome.styled";
import getNameInitials from "./utils/getNameInitials";

const UserWelcome = () => {
  const profile = useUserProfileStore(state => state.profile);

  if (!profile) return null;

  return (
    <S.Wrapper>
      <S.Initials>{getNameInitials(profile?.name)}</S.Initials>
      <div>
        <h6>{profile?.name || <Skeleton />}</h6>
        <S.UserEmail>{profile?.email || <Skeleton />}</S.UserEmail>
      </div>
    </S.Wrapper>
  );
};

export default UserWelcome;
