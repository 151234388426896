import {
  Box,
  Container,
  Flex,
  HeaderImage,
  HeadingMuted,
  PageSection,
  PageWrapper,
  ReviewCard,
} from "@hotelspoint/components";
import { useUserProfileStore } from "@hotelspoint/store";
import { useDocumentTitle } from "@hotelspoint/utils";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";

import headerBg from "./assets/header-bg.jpg";
import { FormStage } from "./constants";
import * as S from "./Home.styled";
import LoginForm from "./LoginForm";
import LoginVerifyForm from "./LoginVerifyForm";
import products from "./products";
import ResetPasswordForm from "./ResetPasswordForm";
import reviews from "./reviews";

const Home = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("home.pageTitle"));

  const [formStage, setFormStage] = useState(FormStage.Login);

  const loggedIn = useUserProfileStore(state => !!state.profile);

  const formComponent = useMemo(() => {
    switch (formStage) {
      case FormStage.Login:
        return <LoginForm setFormStage={setFormStage} />;

      case FormStage.LoginVerify:
        return <LoginVerifyForm />;

      case FormStage.ResetPassword:
        return <ResetPasswordForm setFormStage={setFormStage} />;
    }
  }, [formStage]);

  // Redirect the user to the hotels search if logged in

  if (loggedIn) {
    return <Navigate to="/search/hotels" replace={true} />;
  }

  return (
    <PageWrapper>
      <HeaderImage src={headerBg}>
        <Container>
          <S.HeaderWrapper>
            <S.Slogans>
              <h1>{t("home.header.title")}</h1>
              <S.Subtitle>{t("home.header.subTitle")}</S.Subtitle>
            </S.Slogans>
            <S.UserSection>{formComponent}</S.UserSection>
          </S.HeaderWrapper>
        </Container>
      </HeaderImage>
      <Container>
        <PageSection
          heading={
            <S.Heading>
              <HeadingMuted>{t("home.products.subTitle")}</HeadingMuted>
              <h2>{t("home.products.title")}</h2>
            </S.Heading>
          }
        >
          <Flex mx={[0, 0, -3, -1]}>
            {products.map(product => (
              <Box
                key={`product-card-${product.title}`}
                width={[1, 1, 1 / 3, 1 / 3]}
                px={[0, 0, 1, 1]}
                py={[1, 1, 0, 0]}
              >
                <S.ProductCard>
                  <S.ProductImage $src={product.src} />
                  <S.ProductContent>
                    <S.ProductTitle>{t(product.title)}</S.ProductTitle>
                    <p>{t(product.description)}</p>
                  </S.ProductContent>
                </S.ProductCard>
              </Box>
            ))}
          </Flex>
        </PageSection>
        <PageSection
          last
          heading={
            <S.Heading>
              <HeadingMuted>{t("home.reviews.subTitle")}</HeadingMuted>
              <h2>{t("home.reviews.title")}</h2>
            </S.Heading>
          }
        >
          <Flex mx={[0, 0, -3, -1]}>
            {reviews.map((review, index) => (
              <Box
                key={`review-${index}`}
                width={[1, 1, 1 / 3, 1 / 3]}
                px={[0, 0, 1, 1]}
                py={[1, 1, 0, 0]}
              >
                <ReviewCard author={review.author} content={review.content} />
              </Box>
            ))}
          </Flex>
        </PageSection>
      </Container>
    </PageWrapper>
  );
};

export default Home;
