import {
  Box,
  Flex,
  Form,
  FormAdapter,
  FormChildrenMethods,
  FormInput,
  FormLayout,
} from "@hotelspoint/components";
import { isNumeric, parseRate } from "@hotelspoint/utils";
import { IconArrowsExchange } from "@tabler/icons-react";
import { useEffect } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  defaultValues,
  FormValues,
} from "./AdminCurrencyConfigurationConverter.util";

interface AdminCurrencyConfigurationConverterProps {
  rate?: number;
}

interface AdminCurrencyConfigurationConverterInnerProps
  extends FormChildrenMethods<FormValues>,
    AdminCurrencyConfigurationConverterProps {}

const AdminCurrencyConfigurationConverterInner = ({
  rate,
  setValue,
}: AdminCurrencyConfigurationConverterInnerProps) => {
  const { t } = useTranslation();

  const [from] = useWatch({ name: ["from"] });

  useEffect(() => {
    if (rate && isNumeric(from)) {
      setValue("to", parseRate(rate / Number(from)));
    } else {
      setValue("to", undefined);
    }
  }, [rate, from, setValue]);

  if (!rate) return null;

  return (
    <FormLayout>
      <Flex mx={[0, 0, -1, -1]}>
        <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter
            name="from"
            label={t("adminCurrencyConfiguration.converter.from.label")}
          >
            {props => <FormInput {...props} />}
          </FormAdapter>
        </Box>
        <Box
          px={[0, 0, 1, 1]}
          py={1}
          display="flex"
          alignItems="flex-end"
          mb={[0, 0, 1, 1]}
        >
          <IconArrowsExchange size={24} />
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 4]} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter
            name="to"
            label={t("adminCurrencyConfiguration.converter.to.label")}
          >
            {props => <FormInput {...props} readOnly />}
          </FormAdapter>
        </Box>
      </Flex>
    </FormLayout>
  );
};

const AdminCurrencyConfigurationConverter = (
  props: AdminCurrencyConfigurationConverterProps,
) => {
  return (
    <Form defaultValues={defaultValues}>
      {formMethods => (
        <AdminCurrencyConfigurationConverterInner {...formMethods} {...props} />
      )}
    </Form>
  );
};

export default AdminCurrencyConfigurationConverter;
