import { Paper } from "@hotelspoint/components";
import { mediaQuery, pxToRem, spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";
import { theme } from "styled-tools";

export const Card = styled(Paper)`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing(3)}px;
  margin-bottom: ${spacing(3)}px;
  padding: ${spacing(2)}px;
`;

export const NameWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  column-gap: ${spacing(1)}px;
  margin-bottom: ${spacing(1)}px;

  h5 {
    flex: 1;
    font-weight: 600;
  }
`;

export const HeadlineWrapper = styled.div`
  display: flex;
  column-gap: ${spacing(1)}px;
  margin-bottom: ${spacing(1)}px;

  h6 {
    font-weight: 600;
  }
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: ${spacing(1)}px;
  column-gap: ${spacing(1)}px;

  @media ${mediaQuery.desktop} {
    flex-wrap: nowrap;
    row-gap: 0;
  }
`;

export const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing(1)}px;

  h6 {
    font-weight: 600;
  }
`;

export const DateTitle = styled.p`
  font-size: ${pxToRem(14)};
  font-weight: 700;
  color: rgba(18, 50, 102, 0.6);
  text-transform: uppercase;
`;

export const Divider = styled.div`
  width: 1px;
  height: 100%;
  background: ${theme("palette.silver")};
`;

export const Price = styled.p`
  font-weight: 600;
  font-size: ${pxToRem(16)};
`;

export const PriceTotal = styled(Price)`
  font-size: ${pxToRem(20)};
`;

export const FlexGrow = styled.div`
  flex: 1;
`;
