import { type Agency } from "@hotelspoint/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { AgencyService, type AgencyUploadLogoResponse } from "../../services";

const useUploadAgencyLogo = (id: number) => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: ["agencies", "upload_logo"],
    mutationFn: (payload: FormData) => {
      return AgencyService.uploadLogo(id, payload);
    },
    onSuccess: (data: AgencyUploadLogoResponse) => {
      queryClient.setQueriesData<Agency>(
        { queryKey: ["agencies", id] },
        oldData => {
          if (!oldData) return;

          return {
            ...oldData,
            logo: data.fileName as string,
          };
        },
      );
    },
  });

  const { mutateAsync, isPending } = mutation;
  const ret: [typeof mutateAsync, typeof isPending] = [mutateAsync, isPending];

  return ret;
};

export default useUploadAgencyLogo;
