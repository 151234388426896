import { formatDateIso, isNumeric } from "@hotelspoint/utils";
import { date, InferType, number, object, string } from "yup";

export const validationSchema = object({
  id: string(),
  number: string(),
  reservationId: string(),
  paymentId: string(),
  agencyId: object({
    value: number(),
    label: string(),
  }).nullable(),
  status: number(),
  dateFrom: date(),
  dateTo: date(),
  amountFrom: string(),
  amountTo: string(),
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: FormValues = {
  id: "",
  number: "",
  reservationId: "",
  paymentId: "",
  agencyId: {},
  status: undefined,
  dateFrom: undefined,
  dateTo: undefined,
  amountFrom: "",
  amountTo: "",
};

export const entity2Form = (query: any): FormValues => {
  return {
    id: query.id,
    number: query.number,
    reservationId: query.reservationId,
    paymentId: query.paymentId,
    agencyId: query.agencyId
      ? {
          value: query.agencyId,
          label: query.agencyName,
        }
      : null,
    status: query.status,
    dateFrom: query.dateFrom ? new Date(query.dateFrom) : undefined,
    dateTo: query.dateTo ? new Date(query.dateTo) : undefined,
    amountFrom: query.amountFrom,
    amountTo: query.amountTo,
  };
};

export const form2Entity = (formValues: FormValues) => {
  return {
    id: isNumeric(formValues.id) ? Number(formValues.id) : undefined,
    number: formValues.number,
    reservationId: isNumeric(formValues.reservationId)
      ? Number(formValues.reservationId)
      : undefined,
    paymentId: isNumeric(formValues.paymentId)
      ? Number(formValues.paymentId)
      : undefined,
    agencyId: formValues.agencyId?.value,
    agencyName: formValues.agencyId?.label,
    status: formValues.status,
    dateFrom: formValues.dateFrom
      ? formatDateIso(formValues.dateFrom)
      : undefined,
    dateTo: formValues.dateTo ? formatDateIso(formValues.dateTo) : undefined,
    amountFrom: formValues.amountFrom,
    amountTo: formValues.amountTo,
  };
};
