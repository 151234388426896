import { mediaQuery, spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: ${spacing(2)}px;
  & > div {
    flex-basis: 50%;
  }
  @media ${mediaQuery.tablet} {
    flex-wrap: nowrap;
    row-gap: 0;
    & > div {
      flex-basis: auto;
    }
  }
`;

export const IconItem = styled.div`
  display: flex;
  column-gap: ${spacing(1)}px;
`;
