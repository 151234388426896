import { SCHEMA_NAMES } from "@hotelspoint/utils";
import { InferType, object } from "yup";

export const validationSchema = object({
  name: SCHEMA_NAMES,
  client: SCHEMA_NAMES,
  prices: object({}).required(),
}).required();

export type FormValues = InferType<typeof validationSchema>;

const defaultValues: FormValues = {
  name: "",
  client: "",
  prices: {},
};

export const entity2Form = (entity: any): FormValues => {
  return {
    ...defaultValues,
    prices: entity?.prices,
  };
};
