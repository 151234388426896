import { IconChevronDown } from "@tabler/icons-react";

import * as S from "./AccordionTriggerBase.styled";

interface AccordionTriggerBaseProps {
  isOpen: boolean;
  showText: string;
  hideText: string;
}

const AccordionTriggerBase = ({
  isOpen,
  showText,
  hideText,
}: AccordionTriggerBaseProps) => {
  return (
    <S.Button>
      <IconChevronDown
        size={14}
        style={{
          transform: isOpen ? "rotate(180deg)" : undefined,
        }}
      />
      <span>{isOpen ? hideText : showText}</span>
    </S.Button>
  );
};

export default AccordionTriggerBase;
