import { HotelRateResponse, HotelSearchInfoResponse } from "@hotelspoint/api";
import {
  Button,
  MarkupPrice,
  Price,
  Rating,
  StatusChip,
  VisibleDesktop,
} from "@hotelspoint/components";
import {
  Hotel,
  RateType,
  SearchHotelsResultsModal,
  SearchHotelsResultsModalType,
} from "@hotelspoint/types";
import { daysBetween, formatDate } from "@hotelspoint/utils";
import {
  IconCalendar,
  IconCalendarX,
  IconTag,
  IconToolsKitchen2,
} from "@tabler/icons-react";
import sumBy from "lodash/sumBy";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

import HotelDetailsGalleryModal from "../../SearchHotelsResults/HotelDetailsModal/HotelDetailsGalleryModal";
import { totalPrice, totalPriceNet } from "../SearchHotelBook.util";
import * as S from "./SearchHotelBookSidebar.styled";

interface SearchHotelBookSidebarProps {
  hotelData?: Hotel;
  searchData?: HotelSearchInfoResponse;
  hotelRateData?: HotelRateResponse;
  isLoading: boolean;
}

const SearchHotelsBookSidebar = ({
  hotelData,
  searchData,
  hotelRateData,
  isLoading,
}: SearchHotelBookSidebarProps) => {
  const { t } = useTranslation();
  const [initialGalleryIndex, setInitialGalleryIndex] = useState(0);
  const [activeModal, setActiveModal] = useState<
    SearchHotelsResultsModal | undefined
  >(undefined);

  const nightsCount = useMemo(() => {
    if (!searchData || isLoading) return null;

    return daysBetween({
      to: searchData?.search.checkOut ?? "",
      from: searchData?.search.checkIn ?? "",
    });
  }, [searchData, isLoading]);

  return (
    <div>
      <HotelDetailsGalleryModal
        hotel={hotelData}
        isLoading={isLoading}
        activeModal={activeModal}
        initialGalleryIndex={initialGalleryIndex}
        setInitialGalleryIndex={setInitialGalleryIndex}
        setActiveModal={setActiveModal}
      />
      <S.Card>
        <div>
          <S.NameWrapper>
            <h5>{isLoading ? <Skeleton /> : hotelData?.name}</h5>
            {!isLoading && <Rating value={hotelData?.rating as number} />}
          </S.NameWrapper>
          <p>
            {isLoading ? (
              <Skeleton />
            ) : (
              [hotelData?.address, hotelData?.cityName].join(", ")
            )}
          </p>
        </div>
        <div>
          {isLoading ? (
            <Skeleton width={150} height={40} />
          ) : (
            <Button
              variant="outlined"
              onClick={() =>
                setActiveModal({
                  hotelId: hotelData?.id as number,
                  type: SearchHotelsResultsModalType.Details,
                })
              }
            >
              {t("searchHotelsBook.sidebar.hotelDetails")}
            </Button>
          )}
        </div>
      </S.Card>
      <S.HeadlineWrapper>
        <IconCalendar size={18} />
        <h6>{t("searchHotelsBook.sidebar.dates.title")}</h6>
      </S.HeadlineWrapper>
      <S.Card>
        <S.Flex>
          <S.DateWrapper>
            <S.DateTitle>
              {t("searchHotelsBook.sidebar.dates.checkIn")}
            </S.DateTitle>
            <h6>
              {isLoading ? (
                <Skeleton />
              ) : (
                formatDate(searchData?.search.checkIn as string)
              )}
            </h6>
          </S.DateWrapper>
          <VisibleDesktop>
            <S.Divider />
          </VisibleDesktop>
          <S.DateWrapper>
            <S.DateTitle>
              {t("searchHotelsBook.sidebar.dates.checkOut")}
            </S.DateTitle>
            <h6>
              {isLoading ? (
                <Skeleton />
              ) : (
                formatDate(searchData?.search.checkOut as string)
              )}
            </h6>
          </S.DateWrapper>
        </S.Flex>
        <S.Flex>
          <p>{t("searchHotelsBook.sidebar.dates.lengthOfStay")}</p>
          {isLoading ? (
            <S.FlexGrow>
              <Skeleton />
            </S.FlexGrow>
          ) : (
            <StatusChip>
              {t("searchHotelsBook.sidebar.dates.night", {
                count: nightsCount as number,
              })}
            </StatusChip>
          )}
        </S.Flex>
      </S.Card>
      <S.HeadlineWrapper>
        <IconToolsKitchen2 size={18} />
        <h6>{t("searchHotelsBook.sidebar.meals.title")}</h6>
      </S.HeadlineWrapper>
      <S.Card>
        <S.Flex>
          <p>{t("searchHotelsBook.sidebar.meals.meal")}</p>
          {isLoading ? (
            <S.FlexGrow>
              <Skeleton />
            </S.FlexGrow>
          ) : (
            <p>{hotelRateData?.mealName}</p>
          )}
        </S.Flex>
      </S.Card>
      <S.HeadlineWrapper>
        <IconCalendarX size={18} />
        <h6>{t("searchHotelsBook.sidebar.cancelationPolicy.title")}</h6>
      </S.HeadlineWrapper>
      <S.Card>
        <S.Flex>
          {isLoading ? (
            <S.FlexGrow>
              <Skeleton count={2} />
            </S.FlexGrow>
          ) : (
            <>
              {hotelRateData?.rateType === RateType.NonRefundable ? (
                <p>
                  {t(
                    "searchHotelsBook.sidebar.cancelationPolicy.nonRefundable",
                  )}
                </p>
              ) : (
                <p>
                  {t(
                    "searchHotelsBook.sidebar.cancelationPolicy.freeCancelationUntil",
                    { date: hotelRateData?.cancellation?.deadline },
                  )}
                </p>
              )}
            </>
          )}
        </S.Flex>
      </S.Card>
      <S.HeadlineWrapper>
        <IconTag size={18} />
        <h6>{t("searchHotelsBook.sidebar.priceBreakdown.title")}</h6>
      </S.HeadlineWrapper>
      <S.Card>
        <div>
          {isLoading ? (
            <Skeleton />
          ) : (
            hotelRateData?.rooms.map(room => (
              <S.Flex key={room.id}>
                <p>{room.roomName}</p>
                <MarkupPrice value={{ total: room.price, net: room.priceNet }}>
                  <S.Price>
                    <Price value={room.price} />
                  </S.Price>
                </MarkupPrice>
              </S.Flex>
            ))
          )}
        </div>
        <div>
          {isLoading ? (
            <Skeleton />
          ) : (
            <S.Flex>
              <p>{t("searchHotelsBook.sidebar.priceBreakdown.totalPrice")}</p>
              <MarkupPrice
                value={{
                  total: totalPrice(hotelRateData),
                  net: totalPriceNet(hotelRateData),
                }}
              >
                <S.PriceTotal>
                  <Price value={sumBy(hotelRateData?.rooms, "price")} />
                </S.PriceTotal>
              </MarkupPrice>
            </S.Flex>
          )}
        </div>
      </S.Card>
    </div>
  );
};

export default SearchHotelsBookSidebar;
