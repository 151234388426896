import { InferType, mixed, object } from "yup";

const MAX_AVATAR_SIZE = 1024 * 1024 * 2; // 2MB
const AVATAR_ALLOWED_TYPES = ["image/jpeg", "image/png"];

export const validationSchema = object({
  logo: mixed<File>()
    .nullable()
    .test("format", "profileAgentSettings.logo.validation.format", value => {
      if (!value) return true;

      return AVATAR_ALLOWED_TYPES.includes(value.type);
    })
    .test("size", "profileAgentSettings.logo.validation.size", value => {
      if (!value) return true;

      return value.size <= MAX_AVATAR_SIZE;
    }),
});

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: FormValues = {
  logo: null,
};
