import { HotelService } from "@hotelspoint/api";
import { useQuery } from "@tanstack/react-query";

const useHotelRooms = (searchId: number, hotelId: number) => {
  const { data, isLoading } = useQuery({
    queryKey: ["hotels", searchId, hotelId, "rooms"],
    queryFn: () => HotelService.getRooms(searchId, hotelId),
    enabled: !!searchId && !!hotelId,
  });

  const ret: [typeof data, boolean] = [data, isLoading];

  return ret;
};

export default useHotelRooms;
