import { Container, PageSpacer, PageWrapper } from "@hotelspoint/components";
import { Outlet } from "react-router-dom";

const Profile = () => (
  <PageWrapper>
    <Container>
      <PageSpacer>
        <Outlet />
      </PageSpacer>
    </Container>
  </PageWrapper>
);

export default Profile;
