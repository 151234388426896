import { mediaQuery, spacing } from "@hotelspoint/theme";
import styled from "styled-components";
import { prop } from "styled-tools";

export const Wrapper = styled.div<{ $maxWidth: number }>`
  width: 100%;
  height: 100%;
  max-width: ${prop("$maxWidth")}px;
  margin: 0 auto;
  padding-left: ${spacing(1)}px;
  padding-right: ${spacing(1)}px;

  /* @todo: resolve notch issues */
  /* @supports (padding-left: env(safe-area-inset-left)) {
    padding-left: max(env(safe-area-inset-left), ${spacing(1.5)}px);
    padding-right: max(env(safe-area-inset-right), ${spacing(1.5)}px);
  } */

  @media ${mediaQuery.mobileL} {
    padding-left: ${spacing(1.5)}px;
    padding-right: ${spacing(1.5)}px;
  }

  @media ${mediaQuery.tablet} {
    padding-left: ${spacing(2.5)}px;
    padding-right: ${spacing(2.5)}px;

    /*
    @supports (padding-left: env(safe-area-inset-left)) {
      padding-left: max(env(safe-area-inset-left), ${spacing(2)}px);
      padding-right: max(env(safe-area-inset-right), ${spacing(2)}px);
    } */

    /* custom.container.maxWidth + 40px */
    @media only screen and (min-width: calc(${prop("$maxWidth")}px + ${spacing(
        5,
      )}px)) {
      padding-left: 0;
      padding-right: 0;
    }
  }
`;
