import * as S from "./PageSection.styled";

interface PageSectionProps {
  heading: React.ReactNode;
  children: React.ReactNode;
  last?: boolean;
}

const PageSection = ({ heading, children, last }: PageSectionProps) => {
  return (
    <S.Wrapper $last={last}>
      {heading && <S.Heading>{heading}</S.Heading>}
      {children}
    </S.Wrapper>
  );
};

export default PageSection;
