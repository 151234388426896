import { borderRadius, spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";
import { ifProp } from "styled-tools";

export const Wrapper = styled.ul`
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: ${spacing(2)}px;
`;

export const Button = styled.button.attrs({ type: "button" })<{
  $active?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  min-width: 32px;
  height: 32px;
  padding: 0 ${spacing(0.5)}px;
  border-radius: ${borderRadius.medium}px;
  background: ${ifProp("$active", "rgba(0, 0, 0, 0.15)")};
  pointer-events: ${ifProp("$active", "none")};

  &:disabled {
    opacity: 0.1;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;
