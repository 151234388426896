import { usePlaces } from "@hotelspoint/api";
import {
  Box,
  Button,
  Flex,
  Form,
  FormAdapter,
  FormAutoComplete,
  FormAutoCompleteOption,
  FormChildrenMethods,
  FormContext,
  FormDatePickerSingle,
  FormLayout,
  FormPersonSelector,
  OptionGroup,
  PlaceTypeIcon,
} from "@hotelspoint/components";
import { PlaceType } from "@hotelspoint/types";
import { getPlaceTypeName } from "@hotelspoint/utils";
import qs from "query-string";
import { useCallback, useMemo } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";

import { PERSON_SELECTOR_CONFIG, PLACE_TYPE_ORDER } from "../constants";
import {
  defaultValues,
  form2Entity,
  FormValues,
  validationSchema,
} from "./SearchActivitiesForm.form";
import * as S from "./SearchActivitiesForm.styled";

type PlaceOption = Required<FormAutoCompleteOption<{ type: PlaceType }>>;

const SearchActivitiesFormInner = ({
  setValue,
}: FormChildrenMethods<FormValues>) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const dateToday = useMemo(() => new Date(), []);
  const currentYear = useMemo(() => dateToday.getFullYear(), [dateToday]);

  const [search, dateIn, dateOut] = useWatch({
    name: ["search", "dateIn", "dateOut"],
  });

  const [debouncedSearch] = useDebounce(search, 350);

  const [places, isLoadingPlaces] = usePlaces({
    types: [PlaceType.Area, PlaceType.Destination, PlaceType.City],
    search: debouncedSearch ?? "",
  });

  const placeGroupOptions: OptionGroup<PlaceOption>[] = useMemo(() => {
    if (!places || isLoadingPlaces) return [];

    const groups = Object.entries(places)
      .map(([type, places]) => {
        return {
          label: t(getPlaceTypeName(type as PlaceType)),
          value: type as PlaceType,
          options: places?.map(p => ({
            value: p.id,
            label: p.name,
            meta: {
              type: type as PlaceType,
            },
          })),
        };
      })
      .filter(group => group.options.length > 0);

    return groups.sort((a, b) => {
      return (
        PLACE_TYPE_ORDER.indexOf(a.value) - PLACE_TYPE_ORDER.indexOf(b.value)
      );
    });
  }, [t, places, isLoadingPlaces]);

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      const payload = form2Entity(formValues);

      // @todo: Find the place option and set the name inside the meta
      // Populate the recent searches store

      const search = qs.stringify(payload);

      navigate({
        pathname: "/search/activities",
        search,
      });
    },
    [navigate],
  );

  const handleSelectPlace = useCallback(
    (option: any) => {
      if (option) {
        setValue("place", {
          id: option.value,
          type: option.meta.type,
        });
      } else {
        setValue("place", {});
      }
    },
    [setValue],
  );

  return (
    <FormLayout>
      <Flex mx={[0, 0, -1, -1]}>
        <Box width={1} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter name="search" label={t("searchActivities.search.label")}>
            {props => (
              <FormAutoComplete
                {...props}
                placeholder={t("searchActivities.search.placeholder")}
                options={placeGroupOptions}
                isLoading={isLoadingPlaces}
                renderOption={option => (
                  <S.PlaceOption key={option.value}>
                    <PlaceTypeIcon
                      type={option.meta.type}
                      iconProps={{ size: 22 }}
                    />
                    <span>{option.label}</span>
                  </S.PlaceOption>
                )}
                onSelect={handleSelectPlace}
              />
            )}
          </FormAdapter>
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter name="dateIn" label={t("searchActivities.dateIn.label")}>
            {props => (
              <FormDatePickerSingle
                {...props}
                placeholder={t("searchActivities.dateIn.placeholder")}
                dayPickerProps={{
                  defaultMonth: dateOut
                    ? new Date(currentYear, dateOut.getMonth())
                    : new Date(currentYear, dateToday.getMonth()),
                  disabled: [
                    { before: dateToday },
                    dateOut && { after: dateOut },
                  ],
                  fromYear: currentYear,
                }}
              />
            )}
          </FormAdapter>
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter
            name="dateOut"
            label={t("searchActivities.dateOut.label")}
          >
            {props => (
              <FormDatePickerSingle
                {...props}
                placeholder={t("searchActivities.dateOut.placeholder")}
                dayPickerProps={{
                  defaultMonth: new Date(
                    currentYear,
                    dateIn ? dateIn.getMonth() : dateToday.getMonth(),
                  ),
                  disabled: [dateIn ? { before: dateIn } : false],
                  fromYear: currentYear,
                }}
              />
            )}
          </FormAdapter>
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter
            name="travelers"
            label={t("searchActivities.travelers.label")}
          >
            {props => (
              <FormPersonSelector
                {...props}
                placeholder={t("searchActivities.travelers.placeholder")}
                config={PERSON_SELECTOR_CONFIG}
              />
            )}
          </FormAdapter>
        </Box>
        <Box
          width={[1, 1, 1 / 2, 1 / 2]}
          px={[0, 0, 1, 1]}
          py={1}
          display="flex"
          alignItems="flex-end"
        >
          <FormContext<FormValues>
            render={({ handleSubmit }) => (
              <Button
                type="submit"
                variant="secondary"
                fullWidth
                onClick={handleSubmit(onSubmit)}
              >
                {t("searchActivities.submit")}
              </Button>
            )}
          />
        </Box>
      </Flex>
    </FormLayout>
  );
};

const SearchActivitiesForm = () => {
  return (
    <Form<FormValues>
      defaultValues={defaultValues}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {formMethods => <SearchActivitiesFormInner {...formMethods} />}
    </Form>
  );
};

export default SearchActivitiesForm;
