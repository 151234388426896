import { Button } from "@hotelspoint/components";
import { useUserTokenStore } from "@hotelspoint/store";
import { getApiUrl, openInNewTab } from "@hotelspoint/utils";
import { IconFileExport } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

interface ProfileOfferFormActionsProps {
  offerId: number;
}

const ProfileOfferFormActions = ({ offerId }: ProfileOfferFormActionsProps) => {
  const { t } = useTranslation();

  const token = useUserTokenStore(state => state.token);

  const generateReport = () => {
    const url = getApiUrl(`offers/${offerId}/preview`, { token });

    openInNewTab(url);
  };

  return (
    <Button variant="secondary" small onClick={() => generateReport()}>
      <IconFileExport size={16} />
      <span>{t("profileOffer.formActions.preview")}</span>
    </Button>
  );
};

export default ProfileOfferFormActions;
