import { HotelRoomRate } from "@hotelspoint/types";
import { array, InferType, mixed, number, object, string } from "yup";

import { FormType } from "./types";

const personSchema = object({
  title: string().required(
    "searchHotelsBook.content.form.title.validation.required",
  ),
  name: string().required(
    "searchHotelsBook.content.form.firstName.validation.required",
  ),
  surname: string().required(
    "searchHotelsBook.content.form.lastName.validation.required",
  ),
  age: number(),
}).required();

export const defaultValues = {
  type: FormType.AllPassengers,
};

export const validationSchema = object()
  .shape({
    type: mixed<FormType>().oneOf(
      Object.values(FormType).map(e => e as FormType),
    ),
    guests: array().when("type", ([type], schema) => {
      return type === FormType.AllPassengers
        ? schema.of(array().of(personSchema)).required()
        : schema.notRequired();
    }),
    children: array().when("type", ([type], schema) => {
      return type === FormType.AllPassengers
        ? schema.of(array().of(personSchema))
        : schema.notRequired();
    }),
    leader: object().when("type", ([type], schema) => {
      return type === FormType.LeaderOnly
        ? personSchema.required()
        : schema.notRequired();
    }),
  })
  .required();

export type FormValues = InferType<typeof validationSchema>;

export const form2Entity = (values: FormValues): Array<object> => {
  if (values.type === FormType.LeaderOnly) {
    return [values.leader];
  } else if (values.type === FormType.AllPassengers && values.guests) {
    return values.guests.map((adults, roomId) => ({
      roomId,
      adults,
      children: values.children?.[roomId] || [],
    }));
  }
  return [];
};

export const entity2Form = (entity: HotelRoomRate[]): FormValues => {
  return {
    type: FormType.AllPassengers,
    guests: [],
    leader: {},
    children: entity.map((room: any) =>
      room.children.map((child: any) => {
        return {
          age: child,
        };
      }),
    ),
  };
};
