import {
  handleError,
  useAdminCurrencies,
  useUpdateAdminCurrencyRate,
} from "@hotelspoint/api";
import {
  Form,
  Pagination,
  Panel,
  Table,
  TABLE_PAGE_SIZE_DEFAULT,
  TableFooter,
  TableLayout,
  TablePageSize,
  TableResultsCount,
} from "@hotelspoint/components";
import { UserCurrency } from "@hotelspoint/types";
import { useDocumentTitle } from "@hotelspoint/utils";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import getColumns from "./AdminCurrencyConfiguration.columns";
import { entity2Form, form2Entity } from "./AdminCurrencyConfiguration.util";
import AdminCurrencyConfigurationConverter from "./AdminCurrencyConfigurationConverter";

const AdminCurrencyConfiguration = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("adminCurrencyConfiguration.pageTitle"));

  const [targetId, setTargetId] = useState<number | null>(null);

  const [pageSize, setPageSize] = useState(TABLE_PAGE_SIZE_DEFAULT);
  const [pageIndex, setPageIndex] = useState(1);

  const [currencies, isLoading] = useAdminCurrencies({
    pageIndex,
    pageSize,
  });

  const [updateRate, isUpdating] = useUpdateAdminCurrencyRate();

  const data = useMemo(() => currencies?.results ?? [], [currencies]);

  const formValues = useMemo(() => {
    if (!data) return {};

    return entity2Form(data);
  }, [data]);

  const onSubmit = useCallback(
    (id: number) => async (formValues: any) => {
      try {
        setTargetId(id);
        const rate = formValues[id];
        const payload = form2Entity(rate);

        await updateRate({ id, payload });

        toast.success(t("toast.adminAgency.approve"));
      } catch (error: any) {
        handleError({ t, error });
      } finally {
        setTargetId(null);
      }
    },
    [t, updateRate],
  );

  const columns = useMemo(() => {
    return getColumns({ t, targetId, onSubmit, isUpdating });
  }, [t, targetId, onSubmit, isUpdating]);

  const rate = useMemo(() => {
    return data?.find(currency => currency.code === UserCurrency.BGN)?.rate;
  }, [data]);

  return (
    <Panel title={t("adminCurrencyConfiguration.pageTitle")}>
      <AdminCurrencyConfigurationConverter rate={rate} />
      <TableLayout>
        <Form
          defaultValues={formValues}
          enableReinitialize
          submitOnEnter={false}
        >
          <Table
            data={data}
            columns={columns}
            isLoading={isLoading}
            useReactTableProps={{
              state: {
                pagination: { pageIndex, pageSize: TABLE_PAGE_SIZE_DEFAULT },
                columnPinning: { left: ["id"], right: ["actions"] },
              },
              manualPagination: true,
            }}
          />
        </Form>
        <TableFooter>
          <div>
            <TableResultsCount value={currencies?.count} />
          </div>
          <div>
            <Pagination
              pageIndex={pageIndex}
              pageSize={pageSize}
              setPageIndex={setPageIndex}
              totalCount={currencies?.count ?? 0}
            />
          </div>
          <TablePageSize value={pageSize} onChange={setPageSize} />
        </TableFooter>
      </TableLayout>
    </Panel>
  );
};

export default AdminCurrencyConfiguration;
