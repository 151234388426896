import { AdminCommissionItem } from "@hotelspoint/api";
import {
  ButtonView,
  TABLE_COLUMN_SIZE,
  TableCell,
} from "@hotelspoint/components";
import { formatDate, withCurrency } from "@hotelspoint/utils";
import { createColumnHelper } from "@tanstack/react-table";
import { TFunction } from "i18next";
import { Link } from "react-router-dom";

const columnHelper = createColumnHelper<AdminCommissionItem>();

interface GetColumnsParams {
  t: TFunction;
}

const getColumns = ({ t }: GetColumnsParams) => [
  columnHelper.accessor("reservationId", {
    header: t("adminCommission.items.columns.reservationId"),
    size: TABLE_COLUMN_SIZE.ID_LONG,
  }),
  columnHelper.accessor("agencyName", {
    header: t("adminCommission.items.columns.agencyName"),
    size: TABLE_COLUMN_SIZE.NAME,
  }),
  columnHelper.accessor("priceNet", {
    header: t("adminCommission.items.columns.priceNet"),
    size: TABLE_COLUMN_SIZE.AMOUNT,
    cell: props => withCurrency(props.getValue()),
  }),
  columnHelper.accessor("commissionAmount", {
    header: t("adminCommission.items.columns.commissionAmount"),
    size: TABLE_COLUMN_SIZE.AMOUNT,
    cell: props => withCurrency(props.getValue()),
  }),
  columnHelper.accessor("dateFrom", {
    header: t("adminCommission.items.columns.dateFrom"),
    size: TABLE_COLUMN_SIZE.DATE,
    cell: props => formatDate(props.getValue()),
  }),
  columnHelper.accessor("cityName", {
    header: t("adminCommission.items.columns.cityName"),
    size: TABLE_COLUMN_SIZE.CITY,
  }),
  columnHelper.accessor("clientName", {
    header: t("adminCommission.items.columns.clientName"),
    size: TABLE_COLUMN_SIZE.NAME,
  }),
  columnHelper.display({
    id: "actions",
    size: TABLE_COLUMN_SIZE.ACTION,
    cell: props => {
      const reservationId = props.row.original.reservationId;

      return (
        <TableCell>
          <Link to={`/profile/bookings/${reservationId}`}>
            <ButtonView />
          </Link>
        </TableCell>
      );
    },
  }),
];

export default getColumns;
