import * as S from "./NumericRating.styled";

const DEFAULT_SIZE = 33;

interface NumericRatingProps {
  value: number;
  size?: number;
}

const NumericRating = ({ value, size = DEFAULT_SIZE }: NumericRatingProps) => {
  return <S.Wrapper $size={size}>{value}</S.Wrapper>;
};

export default NumericRating;
