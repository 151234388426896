import { EUR_BGN_RATE } from "@hotelspoint/constants";
import { useUserCurrencyStore } from "@hotelspoint/store";
import { UserCurrency } from "@hotelspoint/types";
import { parseMarkup } from "@hotelspoint/utils";

interface PriceProps {
  value: number;
  withSymbol?: boolean;
}

const Price = ({ value, withSymbol = true }: PriceProps) => {
  const currency = useUserCurrencyStore(state => state.currency);

  const formatValue = (value: number) => {
    const parsedValue = parseMarkup(value);

    return withSymbol ? `${parsedValue} ${currency}` : parsedValue;
  };

  if (currency === UserCurrency.EUR) {
    return formatValue(value);
  }

  return formatValue(value * EUR_BGN_RATE);
};

export default Price;
