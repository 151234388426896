import { formatDateIso, isNumeric } from "@hotelspoint/utils";
import { date, InferType, number, object, string } from "yup";

export const validationSchema = object({
  id: string(),
  userId: object({
    value: number(),
    label: string(),
  }).nullable(),
  dateFrom: date(),
  dateTo: date(),
  type: number(),
  supplierId: number(),
  recordId: string(),
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: FormValues = {
  id: "",
  userId: {},
  dateFrom: undefined,
  dateTo: undefined,
  type: undefined,
  supplierId: undefined,
  recordId: "",
};

export const entity2Form = (query: any): FormValues => {
  return {
    id: query.id,
    userId: query.userId
      ? {
          value: query.userId,
          label: query.userName,
        }
      : null,
    dateFrom: query.dateFrom ? new Date(query.dateFrom) : undefined,
    dateTo: query.dateTo ? new Date(query.dateTo) : undefined,
    type: query.type,
    supplierId: query.supplierId,
    recordId: query.recordId,
  };
};

export const form2Entity = (formValues: FormValues) => {
  return {
    id: isNumeric(formValues.id) ? Number(formValues.id) : undefined,
    userId: formValues.userId?.value,
    userName: formValues.userId?.label,
    dateFrom: formValues.dateFrom
      ? formatDateIso(formValues.dateFrom)
      : undefined,
    dateTo: formValues.dateTo ? formatDateIso(formValues.dateTo) : undefined,
    type: formValues.type,
    supplierId: isNumeric(formValues.supplierId)
      ? Number(formValues.supplierId)
      : undefined,
    recordId: formValues.recordId,
  };
};
