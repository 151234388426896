import { useMutation, useQueryClient } from "@tanstack/react-query";

import { PaymentService, PaymentUpdatePayload } from "../../services";

const useUpdatePayment = (id: number) => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: ["payments", "update"],
    mutationFn: (payload: PaymentUpdatePayload) => {
      return PaymentService.update(id, payload);
    },
    onMutate: () => {
      queryClient.cancelQueries({
        queryKey: ["payments", id],
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["payments", id],
      });

      queryClient.invalidateQueries({
        queryKey: ["payments"],
      });
    },
  });

  const { mutateAsync, isPending } = mutation;
  const ret: [typeof mutateAsync, typeof isPending] = [mutateAsync, isPending];

  return ret;
};

export default useUpdatePayment;
