import { formatDateIso, isNumeric } from "@hotelspoint/utils";
import { date, InferType, number, object, string } from "yup";

export const validationSchema = object({
  id: string(),
  agency: object({
    value: number(),
    label: string(),
  }).nullable(),
  dateFrom: date(),
  dateTo: date(),
  status: number(),
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: FormValues = {
  id: "",
  agency: {},
  dateFrom: undefined,
  dateTo: undefined,
  status: undefined,
};

export const entity2Form = (query: any): FormValues => {
  return {
    id: query.id,
    agency: query.agencyId
      ? {
          value: query.agencyId,
          label: query.agencyName,
        }
      : null,
    dateFrom: query.dateFrom ? new Date(query.dateFrom) : undefined,
    dateTo: query.dateTo ? new Date(query.dateTo) : undefined,
    status: query.status,
  };
};

export const form2Entity = (formValues: FormValues) => {
  return {
    id: isNumeric(formValues.id) ? Number(formValues.id) : undefined,
    agencyId: formValues.agency?.value,
    dateFrom: formValues.dateFrom
      ? formatDateIso(formValues.dateFrom)
      : undefined,
    dateTo: formValues.dateTo ? formatDateIso(formValues.dateTo) : undefined,
    status: formValues.status,
  };
};
