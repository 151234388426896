import { type IconProps } from "@tabler/icons-react";

import { getStatusIconComponent, StatusFlavour } from "../../common";
import * as S from "./StatusText.styled";

interface StatusTextProps {
  status: StatusFlavour;
  children: React.ReactNode;
  iconProps?: IconProps;
}

const StatusText = ({ status, children, iconProps }: StatusTextProps) => {
  const IconComponent = getStatusIconComponent(status);

  return (
    <S.Wrapper $status={status}>
      <IconComponent size={18} {...iconProps} />
      <S.Content>{children}</S.Content>
    </S.Wrapper>
  );
};

export default StatusText;
