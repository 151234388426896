import {
  AdminAgencyStatementStatus,
  handleError,
  useUpdateAdminAgencyStatement,
} from "@hotelspoint/api";
import { Button } from "@hotelspoint/components";
import { type RowSelectionState } from "@tanstack/react-table";
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import * as S from "./AdminAgencyStatementItemsActions.styled";

interface AdminAgencyStatementActionsProps {
  id: number;
  rowSelection: RowSelectionState;
  setRowSelection: Dispatch<SetStateAction<RowSelectionState>>;
}

const AdminAgencyStatementItemsActions = ({
  id,
  rowSelection,
  setRowSelection,
}: AdminAgencyStatementActionsProps) => {
  const { t } = useTranslation();

  const [targetStatus, setTargetStatus] =
    useState<AdminAgencyStatementStatus | null>(null);

  const [updateStatement, isUpdatingStatement] =
    useUpdateAdminAgencyStatement(id);

  const handlePaidStatus = useCallback(
    async (status: AdminAgencyStatementStatus) => {
      try {
        if (Object.keys(rowSelection).length === 0) {
          toast.warning(t("toast.adminAgencyStatement.noSelection"));
          return;
        }

        setTargetStatus(status);

        const ids = Object.keys(rowSelection).map(Number);

        if (status === AdminAgencyStatementStatus.Paid) {
          await updateStatement({
            paidIds: ids,
          });
        } else {
          await updateStatement({
            notPaidIds: ids,
          });
        }

        toast.success(t("toast.adminAgencyStatement.update"));
      } catch (error: any) {
        handleError({ t, error });
      } finally {
        setRowSelection({});
        setTargetStatus(null);
      }
    },
    [rowSelection, setRowSelection, t, updateStatement],
  );

  return (
    <S.Wrapper>
      <Button
        type="submit"
        variant="success"
        isLoading={
          isUpdatingStatement &&
          targetStatus === AdminAgencyStatementStatus.Paid
        }
        isDisabled={
          isUpdatingStatement &&
          targetStatus === AdminAgencyStatementStatus.Pending
        }
        onClick={() => handlePaidStatus(AdminAgencyStatementStatus.Paid)}
      >
        {t("adminAgencyStatement.actions.setAsPaid")}
      </Button>
      <Button
        type="submit"
        variant="secondary"
        isLoading={
          isUpdatingStatement &&
          targetStatus === AdminAgencyStatementStatus.Pending
        }
        isDisabled={
          isUpdatingStatement &&
          targetStatus === AdminAgencyStatementStatus.Paid
        }
        onClick={() => handlePaidStatus(AdminAgencyStatementStatus.Pending)}
      >
        {t("adminAgencyStatement.actions.setAsPending")}
      </Button>
    </S.Wrapper>
  );
};

export default AdminAgencyStatementItemsActions;
