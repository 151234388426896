import { NumberParam, StringParam, useQueryParams } from "use-query-params";

const useAdminAgentsParams = () => {
  const [query, setQuery] = useQueryParams({
    id: NumberParam,
    name: StringParam,
    email: StringParam,
    agencyId: NumberParam,
    roleId: NumberParam,
    status: NumberParam,
    registerFrom: StringParam,
    registerTo: StringParam,
    // Misc
    agencyName: StringParam,
  });

  const ret: [typeof query, typeof setQuery] = [query, setQuery];

  return ret;
};

export default useAdminAgentsParams;
