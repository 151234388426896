import "mapbox-gl/dist/mapbox-gl.css";

import MapComponent, { MapProps } from "react-map-gl";

const Map = (props: Partial<MapProps>) => (
  <MapComponent
    mapboxAccessToken={import.meta.env.VITE_APP_MAPBOX_TOKEN}
    // @todo: check if this attribute is against TOS
    attributionControl={false}
    mapStyle="mapbox://styles/mapbox/standard"
    {...props}
  />
);

export default Map;
