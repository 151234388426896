import { IconHome, IconUsers } from "@tabler/icons-react";

export default [
  {
    icon: IconHome,
    href: "/",
    title: "home.shortPageTitle",
  },
  {
    icon: IconUsers,
    href: "/about",
    title: "about.pageTitle",
  },
];
