import { Fragment } from "react";
import { Link } from "react-router-dom";

import Button from "../Button/Button";
import * as S from "./DashboardCard.styled";

interface DashboardCardItem {
  value?: number | string;
  href: string;
}

interface DashboardCardProps {
  title: string;
  data: DashboardCardItem | DashboardCardItem[];
}

const DashboardCard = ({ title, data }: DashboardCardProps) => {
  return (
    <S.Wrapper $elevation={3}>
      <S.Header>
        <h6>{title}</h6>
      </S.Header>
      <S.Body>
        {Array.isArray(data) ? (
          data.map((item, index) => (
            <Fragment key={index}>
              <S.ValueWrapper>
                <S.Value>{item.value}</S.Value>
                <Link to={item.href}>
                  <Button small variant="secondary">
                    View
                  </Button>
                </Link>
              </S.ValueWrapper>
              {index !== data.length - 1 && <S.Separator />}
            </Fragment>
          ))
        ) : (
          <S.ValueWrapper>
            <S.Value>{data.value}</S.Value>
            <Link to={data.href}>
              <Button small variant="secondary">
                View
              </Button>
            </Link>
          </S.ValueWrapper>
        )}
      </S.Body>
    </S.Wrapper>
  );
};

export default DashboardCard;
