import { useMutation } from "@tanstack/react-query";

import { AuthService } from "../../services";

const useFetchProfile = () => {
  const mutation = useMutation({
    mutationKey: ["auth", "fetch_profile"],
    mutationFn: () => {
      return AuthService.getProfile();
    },
  });

  const { mutateAsync, isPending } = mutation;
  const ret: [typeof mutateAsync, typeof isPending] = [mutateAsync, isPending];

  return ret;
};

export default useFetchProfile;
