import { spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";

export const Headline = styled.h6`
  font-weight: 600;
`;

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${spacing(1)}px;
  margin-bottom: ${spacing(1)}px;
`;
