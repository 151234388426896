import { Agency, AgencyStatus } from "@hotelspoint/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { AgencyService } from "../../services";

const useRejectAgency = (id: number) => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: ["agencies", "reject"],
    mutationFn: () => {
      return AgencyService.reject(id);
    },
    onMutate: () => {
      queryClient.cancelQueries({
        queryKey: ["agencies", id],
      });
    },
    onSuccess: () => {
      queryClient.setQueriesData<Agency>(
        { queryKey: ["agencies", id] },
        oldData => {
          if (!oldData) return;

          return {
            ...oldData,
            status: AgencyStatus.Rejected,
          };
        },
      );

      queryClient.invalidateQueries({
        queryKey: ["agencies"],
      });
    },
  });

  const { mutateAsync, isPending } = mutation;
  const ret: [typeof mutateAsync, typeof isPending] = [mutateAsync, isPending];

  return ret;
};

export default useRejectAgency;
