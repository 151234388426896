import { HotelSearchQuery } from "@hotelspoint/types";

import { FormValues } from "../../SearchHotelsForm/SearchHotelsForm.form";

export const entity2Form = (formValues: HotelSearchQuery): FormValues => ({
  search: "",
  dates: {
    from: new Date(formValues.checkIn),
    to: new Date(formValues.checkOut),
  },
  nationality: formValues.nationality,
  rooms: formValues.rooms,
  place: {
    id: formValues.placeId,
    type: formValues.placeType,
  },
});
