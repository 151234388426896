import flatten from "lodash/flatten";
import { useCallback, useMemo } from "react";
import Select, { type ActionMeta, type OptionsOrGroups } from "react-select";
import { useTheme } from "styled-components";

import { Option, OptionGroup } from "../../common";
import { type FormControlInjectedProps } from "../FormControl";
import getStyles from "./FormSelect.styles";
import { getSelectedOption, getSelectedOptions } from "./utils";

export interface FormSelectProps
  extends Partial<FormControlInjectedProps>,
    Pick<
      React.InputHTMLAttributes<HTMLInputElement>,
      "disabled" | "placeholder"
    > {
  value?: any | any[];
  options: OptionsOrGroups<Option, OptionGroup>;
  isMulti?: boolean;
  isLoading?: boolean;
  readOnly?: boolean;
  isSearchable?: boolean;
  isClearable?: boolean;
  closeMenuOnSelect?: boolean;
  noOptionsMessage?: () => string;
}

const FormSelect = ({
  id,
  name,
  value,
  onChange,
  error,
  options,
  isMulti = false,
  disabled = false,
  isLoading = false,
  placeholder,
  readOnly = false,
  isSearchable = true,
  isClearable = false,
  closeMenuOnSelect = true,
  noOptionsMessage,
}: FormSelectProps) => {
  const theme = useTheme();
  const flatOptions = flatten(
    options.map(o => (o as OptionGroup).options ?? o),
  );

  const internalValue = useMemo(() => {
    if (isMulti) {
      return getSelectedOptions(
        flatOptions,
        (value as any[])?.map(v => ({ value: v })),
      );
    }

    return getSelectedOption(flatOptions, { value });
  }, [flatOptions, value, isMulti]);

  const handleChange = useCallback(
    (val: any, meta: ActionMeta<Option>) => {
      if (typeof onChange === "function") {
        const option = isMulti
          ? getSelectedOptions(flatOptions, val)
          : getSelectedOption(flatOptions, val);

        const arg = Array.isArray(option)
          ? option.map(({ value }) => value)
          : option?.value;

        onChange(arg, meta);
      }
    },
    [onChange, flatOptions, isMulti],
  );

  const styles = useMemo(
    () => getStyles({ theme, readOnly, hasError: !!error }),
    [theme, readOnly, error],
  );

  return (
    <Select
      inputId={id}
      name={name}
      value={internalValue}
      onChange={handleChange}
      options={options}
      isMulti={isMulti}
      isDisabled={disabled}
      isLoading={isLoading}
      placeholder={placeholder}
      menuPlacement="auto"
      minMenuHeight={250}
      menuPortalTarget={document.body}
      styles={styles}
      isClearable={!readOnly && isClearable}
      isSearchable={!readOnly && isSearchable}
      openMenuOnClick={!readOnly}
      closeMenuOnSelect={closeMenuOnSelect}
      noOptionsMessage={noOptionsMessage}
    />
  );
};

export default FormSelect;
