import { formatDateIso, getFirstDayOfMonth } from "@hotelspoint/utils";
import { date, InferType, object } from "yup";

const dateToday = new Date();

export const validationSchema = object({
  dateFrom: date()
    .typeError("components.dateRangeDialog.form.dateFrom.validation.required")
    .required("components.dateRangeDialog.form.dateFrom.validation.required"),
  dateTo: date()
    .typeError("components.dateRangeDialog.form.dateTo.validation.required")
    .required("components.dateRangeDialog.form.dateTo.validation.required"),
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: Partial<FormValues> = {
  dateFrom: new Date(getFirstDayOfMonth(dateToday)),
  dateTo: dateToday,
};

export const form2Entity = (formValues: FormValues) => {
  return {
    dateFrom: formatDateIso(formValues.dateFrom),
    dateTo: formatDateIso(formValues.dateTo),
  };
};
