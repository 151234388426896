import { PaginationOptions } from "@hotelspoint/types";
import { useQuery } from "@tanstack/react-query";

import { HotelService } from "../../services";

const INTERVAL_REFETCH = 1_000;

const useHotelSearchResults = (
  searchId: number,
  params?: PaginationOptions,
) => {
  const { data, isLoading } = useQuery({
    queryKey: ["hotels_search", searchId, params],
    queryFn: () => HotelService.getResultBySearchId(searchId, params),
    enabled: searchId !== undefined,
    refetchInterval: query => {
      const data = query.state.data;

      if (data?.isCompleted) return false;

      return INTERVAL_REFETCH;
    },
  });

  const ret: [typeof data, boolean] = [data, isLoading];

  return ret;
};

export default useHotelSearchResults;
