import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";

import * as S from "./Accordion.styled";

interface AccordionHeadProps {
  isOpen: boolean;
}

export interface AccordionProps {
  head: ({ isOpen }: AccordionHeadProps) => React.ReactNode;
  children: React.ReactNode;
  headPosition?: "top" | "bottom";
  isInitiallyOpen?: boolean;
}

const Accordion = ({
  head,
  children,
  headPosition = "top",
  isInitiallyOpen = false,
}: AccordionProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(isInitiallyOpen);

  const handleClick = () => {
    setIsOpen(prevState => !prevState);
  };

  return (
    <S.Wrapper>
      <S.Button onClick={handleClick} $position={headPosition}>
        {head({ isOpen })}
      </S.Button>
      {/* It's seems like there is a bug in the current version so we have two options. 
      The first one is to remove the opacity from tha animation or (the current solution)
       we need to revert and use the specific "11.0.10" version
       Link with the open issue: https://github.com/framer/motion/issues/2554 */}
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </S.Wrapper>
  );
};

export default Accordion;
