import styled from "styled-components";
import { switchProp, theme } from "styled-tools";

import { StatusFlavour } from "../../common";
import BaseChip from "../BaseChip";

export default styled(BaseChip)<{ $variant?: StatusFlavour }>`
  font-weight: 400;
  color: ${switchProp(
    "$variant",
    {
      [StatusFlavour.Success]: "white",
      [StatusFlavour.Info]: "white",
      [StatusFlavour.Warning]: "white",
      [StatusFlavour.Error]: "white",
    },
    theme("primary.bg"),
  )};
  background: ${switchProp(
    "$variant",
    {
      [StatusFlavour.Success]: theme("success.primary"),
      [StatusFlavour.Info]: theme("info.primary"),
      [StatusFlavour.Warning]: theme("warning.primary"),
      [StatusFlavour.Error]: theme("error.primary"),
    },
    theme("palette.whiteSand"),
  )};
  border-color: ${switchProp(
    "$variant",
    {
      [StatusFlavour.Success]: "transparent",
      [StatusFlavour.Info]: "transparent",
      [StatusFlavour.Warning]: "transparent",
      [StatusFlavour.Error]: "transparent",
    },
    "transparent",
  )};
`;
