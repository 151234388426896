import { useMemo } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import Button from "../../elements/Button";
import Form from "../../form/Form";
import FormAdapter from "../../form/FormAdapter";
import FormContext from "../../form/FormContext";
import FormDatePickerSingle from "../../form/FormDatePicker/FormDatePickerSingle";
import FormLayout from "../../form/FormLayout";
import Dialog from "../Dialog";
import Modal from "../Modal";
import {
  defaultValues,
  form2Entity,
  FormValues,
  validationSchema,
} from "./DateRangeDialog.util";

interface DateRangeDialogProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  title: string;
  onConfirm: (dateFrom: string, dateTo: string) => any;
}

const DateRangeDialogInner = ({
  isOpen,
  setIsOpen,
  title,
  onConfirm,
}: DateRangeDialogProps) => {
  const { t } = useTranslation();

  const dateToday = useMemo(() => new Date(), []);
  const [dateFrom] = useWatch<FormValues>({ name: ["dateFrom"] });
  const [dateTo] = useWatch<FormValues>({ name: ["dateTo"] });

  const handleCancel = () => {
    setIsOpen(false);
  };

  const onSubmit = (formValues: FormValues) => {
    const { dateFrom, dateTo } = form2Entity(formValues);

    setIsOpen(false);
    onConfirm(dateFrom, dateTo);
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Content>
        <Modal title={title} onClose={() => setIsOpen(false)}>
          <FormLayout>
            <FormAdapter
              name="dateFrom"
              label={t("components.dateRangeDialog.form.dateFrom.label")}
            >
              {props => (
                <FormDatePickerSingle
                  {...props}
                  placeholder={t(
                    "components.dateRangeDialog.form.dateFrom.placeholder",
                  )}
                  dayPickerProps={{
                    disabled: [
                      dateTo ? { after: dateTo } : { after: dateToday },
                    ],
                  }}
                />
              )}
            </FormAdapter>
            <FormAdapter
              name="dateTo"
              label={t("components.dateRangeDialog.form.dateTo.label")}
            >
              {props => (
                <FormDatePickerSingle
                  {...props}
                  placeholder={t(
                    "components.dateRangeDialog.form.dateTo.label",
                  )}
                  dayPickerProps={{
                    disabled: [
                      dateFrom ? { before: dateFrom } : false,
                      { after: dateToday },
                    ],
                  }}
                />
              )}
            </FormAdapter>
          </FormLayout>
          <Modal.Actions>
            <Button variant="outlined" onClick={handleCancel}>
              {t("components.dateRangeDialog.cancel")}
            </Button>
            <FormContext<FormValues>
              render={({ handleSubmit }) => (
                <Button onClick={handleSubmit(onSubmit)}>
                  {t("components.dateRangeDialog.confirm")}
                </Button>
              )}
            />
          </Modal.Actions>
        </Modal>
      </Dialog.Content>
    </Dialog>
  );
};

const DateRangeDialog = (props: DateRangeDialogProps) => {
  return (
    <Form defaultValues={defaultValues} validationSchema={validationSchema}>
      {formMethods => <DateRangeDialogInner {...formMethods} {...props} />}
    </Form>
  );
};

export default DateRangeDialog;
