import { RateType } from "@hotelspoint/types";

export const getRateTypeName = (type: RateType) => {
  switch (type) {
    case RateType.Refundable:
      return "rateType.refundable";

    case RateType.NonRefundable:
      return "rateType.nonRefundable";

    case RateType.NotAvailable:
      return "rateType.notAvailable";

    case RateType.Unknown:
    default:
      return "rateType.unknown";
  }
};
