import { borderRadius, pxToRem, spacing, zIndex } from "@hotelspoint/theme";
import { type StylesConfig } from "react-select";
import { type DefaultTheme } from "styled-components";

interface GetStylesProps {
  theme: DefaultTheme;
  readOnly?: boolean;
  hasError?: boolean;
}

const getStyles = ({
  theme,
  readOnly,
  hasError,
}: GetStylesProps): StylesConfig<any, any, any> => ({
  menuPortal: base => ({
    ...base,
    zIndex: zIndex.formSelect,
  }),
  placeholder: (base, { isDisabled }) => ({
    ...base,
    fontStyle: "italic",
    color: hasError ? theme.error.primary : theme.palette.silverChalice,
    fontSize: pxToRem(15),
    ...(isDisabled
      ? {
          color: theme.disabled.text,
        }
      : {}),
    ...(readOnly
      ? {
          color: theme.palette.silverChalice,
        }
      : {}),
  }),
  control: (base, { isDisabled, isFocused }) => ({
    ...base,
    minHeight: 40,
    borderColor: hasError ? theme.error.primary : theme.palette.silver,
    borderRadius: borderRadius.normal,
    boxShadow: isFocused && !isDisabled && readOnly ? "none" : base.boxShadow,
    "&:hover": {
      borderColor: hasError ? theme.error.primary : theme.palette.dustyGrey,
    },
    "&:focus": {
      borderColor: hasError ? theme.error.primary : theme.palette.dodgerBlue,
    },
    ...(isDisabled
      ? {
          userSelect: "none",
          color: theme.disabled.text,
          background: theme.disabled.bg,
          borderColor: theme.palette.silverChalice,
        }
      : {}),
    ...(readOnly
      ? {
          userSelect: "none",
          color: theme.palette.silverChalice,
          background: theme.palette.concrete,
          borderColor: theme.palette.silver,
        }
      : {}),
  }),
  valueContainer: base => ({
    ...base,
    padding: `0 ${spacing(1.25)}px`,
  }),
  option: (base, { isDisabled, isSelected }) => ({
    ...base,
    ...(isSelected
      ? {
          color: "white",
          background: theme.tertiary.bg,
        }
      : {}),
    ...(isDisabled
      ? {
          cursor: "default",
          color: theme.disabled.text,
        }
      : {}),
    ...(readOnly
      ? {
          cursor: "default",
          color: theme.palette.silverChalice,
        }
      : {}),
  }),
  singleValue: base => ({
    ...base,
    color: hasError ? theme.error.primary : theme.body.text,
    ...(readOnly
      ? {
          color: theme.palette.silverChalice,
        }
      : {}),
  }),
  indicatorSeparator: (base, { isDisabled }) => ({
    ...base,
    ...(isDisabled
      ? {
          backgroundColor: theme.disabled.text,
        }
      : {}),
    ...(readOnly
      ? {
          backgroundColor: theme.palette.silverChalice,
        }
      : {}),
    ...(hasError
      ? {
          backgroundColor: theme.error.primary,
        }
      : {}),
  }),
  dropdownIndicator: (base, { isDisabled }) => ({
    ...base,
    ...(isDisabled
      ? {
          color: theme.disabled.text,
        }
      : {}),
    ...(hasError
      ? {
          color: theme.error.primary,
        }
      : {}),
    ...(readOnly
      ? {
          color: theme.palette.silverChalice,
        }
      : {}),
  }),
});

export default getStyles;
