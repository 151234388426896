import { pxToRem, spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";

export const MetricCardActions = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: ${spacing(2)}px;
`;

export const Price = styled.span`
  font-weight: 600;
  font-size: ${pxToRem(18)};
`;
