import { Panel } from "@hotelspoint/components";
import { useDocumentTitle } from "@hotelspoint/utils";
import { useTranslation } from "react-i18next";
import { Navigate, useParams } from "react-router-dom";

import AdminAreaLocationAddItem from "./AdminAreaLocationAddItem";
import AdminAreaLocationForm from "./AdminAreaLocationForm";
import AdminAreaLocationItems from "./AdminAreaLocationItems";

interface AdminAreaLocationParams extends Record<string, string> {
  id: string;
}

const AdminAreaLocation = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("adminAreaLocation.pageTitle"));

  const { id } = useParams<AdminAreaLocationParams>();
  const areaId = Number(id);

  if (!areaId) {
    return <Navigate to="/404" replace={true} />;
  }

  return (
    <Panel title={t("adminAreaLocation.pageTitle")}>
      <AdminAreaLocationForm id={areaId} />
      <AdminAreaLocationAddItem id={areaId} />
      <AdminAreaLocationItems id={areaId} />
    </Panel>
  );
};

export default AdminAreaLocation;
