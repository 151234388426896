import { useAdminMeals } from "@hotelspoint/api";
import {
  Pagination,
  Panel,
  Table,
  TABLE_PAGE_SIZE_DEFAULT,
  TableFooter,
  TableLayout,
  TablePageSize,
  TableResultsCount,
} from "@hotelspoint/components";
import { pickFilterValues, useDocumentTitle } from "@hotelspoint/utils";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import getColumns from "./AdminMealConfiguration.columns";
import AdminMealConfigurationFilters from "./AdminMealConfigurationFilters";
import AdminMealConfigurationMapModal from "./AdminMealConfigurationMapModal";
import useAdminMealConfigurationParams from "./useAdminMealConfigurationParams";

const AdminMealConfiguration = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("adminMealConfiguration.pageTitle"));

  const [targetId, setTargetId] = useState<number | null>(null);
  const [isMappingDialogOpen, setIsMappingDialogOpen] = useState(false);

  const [pageSize, setPageSize] = useState(TABLE_PAGE_SIZE_DEFAULT);
  const [pageIndex, setPageIndex] = useState(1);
  const [query] = useAdminMealConfigurationParams();

  const filters = useMemo(() => pickFilterValues(query), [query]);

  const [meals, isLoading] = useAdminMeals({
    pageIndex,
    pageSize,
    ...filters,
  });

  const data = useMemo(() => meals?.results ?? [], [meals]);

  const handleMap = (id: number) => {
    setTargetId(id);
    setIsMappingDialogOpen(true);
  };

  const columns = useMemo(() => {
    return getColumns({
      t,
      handleMap,
    });
  }, [t]);

  const stringifiedFilters = useMemo(() => JSON.stringify(filters), [filters]);

  useEffect(() => {
    setPageIndex(1);
  }, [pageSize, stringifiedFilters]);

  return (
    <Panel title={t("adminMealConfiguration.pageTitle")}>
      <AdminMealConfigurationMapModal
        id={targetId}
        isOpen={isMappingDialogOpen}
        setIsOpen={setIsMappingDialogOpen}
        handleSuccess={() => setTargetId(null)}
      />
      <AdminMealConfigurationFilters isLoading={isLoading} />
      <TableLayout>
        <Table
          data={data}
          columns={columns}
          isLoading={isLoading}
          useReactTableProps={{
            state: {
              pagination: { pageIndex, pageSize: TABLE_PAGE_SIZE_DEFAULT },
              columnPinning: { left: ["id"], right: ["actions"] },
            },
            manualPagination: true,
          }}
        />
        <TableFooter>
          <div>
            <TableResultsCount value={meals?.count} />
          </div>
          <div>
            <Pagination
              pageIndex={pageIndex}
              pageSize={pageSize}
              setPageIndex={setPageIndex}
              totalCount={meals?.count ?? 0}
            />
          </div>
          <TablePageSize value={pageSize} onChange={setPageSize} />
        </TableFooter>
      </TableLayout>
    </Panel>
  );
};

export default AdminMealConfiguration;
