import { OfferItem } from "@hotelspoint/types";

export const buildPriceObject = (
  items: OfferItem[],
): { [key: number]: { [key: number]: number } } => {
  const priceObject: { [key: number]: { [key: number]: number } } = {};

  items.map(item => {
    item.itemData.rooms.map(room => {
      priceObject[item.id] = {
        ...priceObject[item.id],
        [room.id]: room.price,
      };
    });
  });

  return priceObject;
};
