import { IconCloudUpload } from "@tabler/icons-react";
import { useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";

import FileList from "../../composites/FileList";
import { type FormControlInjectedProps } from "../FormControl";
import * as S from "./FormDropZone.styled";

interface FormDropZoneProps extends Omit<FormControlInjectedProps, "value"> {
  value?: File[];
  disabled?: boolean;
  placeholder?: string;
}

const FormDropZone = ({
  value = [],
  onChange,
  error,
  disabled = false,
  placeholder,
}: FormDropZoneProps) => {
  const { getRootProps, getInputProps } = useDropzone({
    disabled,
    multiple: true,
    onDrop: files => {
      onChange([...value, ...files]);
    },
  });

  const hasFiles = useMemo(() => value?.length !== 0, [value]);

  const handleRemoveFile = useCallback(
    (file: File) => {
      const newValue = value.filter((f: File) => f !== file);

      onChange(newValue);
    },
    [value, onChange],
  );

  return (
    <div>
      <S.Wrapper {...getRootProps()} $disabled={disabled} $error={!!error}>
        <input {...getInputProps()} />
        <IconCloudUpload size={32} />
        <S.Placeholder>{placeholder}</S.Placeholder>
      </S.Wrapper>
      {hasFiles && (
        <S.FilesWrapper>
          <FileList value={value} onRemove={handleRemoveFile} />
        </S.FilesWrapper>
      )}
    </div>
  );
};

export default FormDropZone;
