import { handleError, useCreateAdminAreaLocation } from "@hotelspoint/api";
import {
  Box,
  Button,
  Flex,
  Form,
  FormAdapter,
  FormContext,
  FormInput,
  FormLayout,
  Panel,
} from "@hotelspoint/components";
import { useDocumentTitle } from "@hotelspoint/utils";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import {
  defaultValues,
  form2Entity,
  FormValues,
  validationSchema,
} from "./AdminAreaLocationsCreate.util";

const AdminAreaLocationsCreate = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("adminAreaLocationsCreate.pageTitle"));

  const [createLocation, isCreating] = useCreateAdminAreaLocation();

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        const payload = form2Entity(formValues);

        await createLocation(payload);
      } catch (error: any) {
        handleError({ t, error });
      }
    },
    [t, createLocation],
  );

  return (
    <Panel title={t("adminAreaLocationsCreate.pageTitle")}>
      <Form defaultValues={defaultValues} validationSchema={validationSchema}>
        <FormLayout>
          <Flex mx={[0, 0, -1, -1]}>
            <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
              <FormAdapter
                name="name"
                label={t("adminAreaLocationsCreate.name.label")}
              >
                {props => <FormInput {...props} disabled={isCreating} />}
              </FormAdapter>
            </Box>
            <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
              <FormAdapter
                name="latitude"
                label={t("adminAreaLocationsCreate.latitude.label")}
              >
                {props => (
                  <FormInput {...props} type="number" disabled={isCreating} />
                )}
              </FormAdapter>
            </Box>
            <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
              <FormAdapter
                name="longitude"
                label={t("adminAreaLocationsCreate.longitude.label")}
              >
                {props => (
                  <FormInput {...props} type="number" disabled={isCreating} />
                )}
              </FormAdapter>
            </Box>
          </Flex>
          <FormContext<FormValues>
            render={({ handleSubmit }) => (
              <Button
                type="submit"
                variant="secondary"
                isLoading={isCreating}
                onClick={handleSubmit(onSubmit)}
              >
                {t("adminAreaLocationsCreate.submit")}
              </Button>
            )}
          />
        </FormLayout>
      </Form>
    </Panel>
  );
};

export default AdminAreaLocationsCreate;
