import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

export const USER_TOKEN_STORE_PERSIST_NAME = "@hp::user_token";

interface UserTokenStore {
  token: string | null;
  set: (token: string) => void;
  reset: () => void;
}

const useUserTokenStore = create<UserTokenStore>()(
  persist(
    set => ({
      token: null,
      set: token => set({ token }),
      reset: () => set({ token: null }),
    }),
    {
      name: USER_TOKEN_STORE_PERSIST_NAME,
      storage: createJSONStorage(() => localStorage),
      partialize: state => ({
        token: state.token,
      }),
    },
  ),
);

export default useUserTokenStore;
