import { mediaQuery, pxToRem, spacing } from "@hotelspoint/theme";
import styled from "styled-components";

export const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
`;

export const Title = styled.h1`
  font-size: ${pxToRem(60)};

  @media ${mediaQuery.tablet} {
    font-size: ${pxToRem(100)};
  }
`;

export const BackHome = styled.div`
  margin-top: ${spacing(2)}px;
`;
