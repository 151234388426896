import { useAdminAgencyStatements } from "@hotelspoint/api";
import {
  Button,
  Pagination,
  Panel,
  Table,
  TABLE_PAGE_SIZE_DEFAULT,
  TableFooter,
  TableLayout,
  TablePageSize,
  TableResultsCount,
} from "@hotelspoint/components";
import { pickFilterValues, useDocumentTitle } from "@hotelspoint/utils";
import omit from "lodash/omit";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import getColumns from "./AdminAgencyStatements.columns";
import AdminAgencyStatementsFilters from "./AdminAgencyStatementsFilters";
import useAdminAgencyStatementsParams from "./useAdminAgencyStatementsParams";

const AdminAgencyStatements = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("adminAgencyStatements.pageTitle"));

  const [pageSize, setPageSize] = useState(TABLE_PAGE_SIZE_DEFAULT);
  const [pageIndex, setPageIndex] = useState(1);

  const [query] = useAdminAgencyStatementsParams();
  const filters = useMemo(
    () => omit(pickFilterValues(query), "agencyName"),
    [query],
  );

  const [statements, isLoading] = useAdminAgencyStatements({
    pageIndex,
    pageSize,
    ...filters,
  });

  const data = useMemo(() => statements?.results ?? [], [statements]);

  const columns = useMemo(() => {
    return getColumns({ t });
  }, [t]);

  const stringifiedFilters = useMemo(() => JSON.stringify(filters), [filters]);

  useEffect(() => {
    setPageIndex(1);
  }, [pageSize, stringifiedFilters]);

  return (
    <Panel
      title={t("adminAgencyStatements.pageTitle")}
      actions={
        <Link to="/admin/statements/agencies/create">
          <Button small variant="secondary">
            {t("adminAgencyStatements.actions.create")}
          </Button>
        </Link>
      }
    >
      <AdminAgencyStatementsFilters isLoading={isLoading} />
      <TableLayout>
        <Table
          data={data}
          columns={columns}
          isLoading={isLoading}
          useReactTableProps={{
            state: {
              pagination: { pageIndex, pageSize: TABLE_PAGE_SIZE_DEFAULT },
              columnPinning: { left: ["id"], right: ["actions"] },
            },
            manualPagination: true,
          }}
        />
        <TableFooter>
          <div>
            <TableResultsCount value={statements?.count} />
          </div>
          <div>
            <Pagination
              pageIndex={pageIndex}
              pageSize={pageSize}
              setPageIndex={setPageIndex}
              totalCount={statements?.count ?? 0}
            />
          </div>
          <TablePageSize value={pageSize} onChange={setPageSize} />
        </TableFooter>
      </TableLayout>
    </Panel>
  );
};

export default AdminAgencyStatements;
