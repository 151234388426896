import { create } from "zustand";

interface UserVerifyTokenStore {
  token: string | null;
  set: (token: string) => void;
  reset: () => void;
}

// Store the user token temporary when using OTP
const useUserVerifyTokenStore = create<UserVerifyTokenStore>()(set => ({
  token: null,
  set: token => set({ token }),
  reset: () => set({ token: null }),
}));

export default useUserVerifyTokenStore;
