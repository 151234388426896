import { useMutation } from "@tanstack/react-query";

import { UserListParams, UserService } from "../../services";

const useFetchUsers = () => {
  const mutation = useMutation({
    mutationKey: ["users", "fetch"],
    mutationFn: (params?: Partial<UserListParams>) => {
      return UserService.getList(params);
    },
  });

  const { mutateAsync, isPending } = mutation;
  const ret: [typeof mutateAsync, typeof isPending] = [mutateAsync, isPending];

  return ret;
};

export default useFetchUsers;
