import lodashRange from "lodash/range";
import { useMemo } from "react";

const DOTS = "pseudo_dots";

// lodash `range` function doesn't include the end in the returned range
const range = (start: number, end: number) => {
  return lodashRange(start, end + 1);
};

interface UsePaginationParams {
  pageIndex: number;
  pagesCount: number;
  // Siblings amount on left/right side of selected page, defaults to 1
  siblings?: number;
  // Amount of elements visible on left/right edges, defaults to 1
  boundaries?: number;
}

// Partially taken from https://mantine.dev/hooks/use-pagination/
const usePagination = ({
  pageIndex,
  pagesCount,
  siblings = 1,
  boundaries = 1,
}: UsePaginationParams) => {
  const paginationRange = useMemo(() => {
    const totalPageNumbers = siblings * 2 + 3 + boundaries * 2;
    if (totalPageNumbers >= pagesCount) {
      if (pagesCount === 0) return [1];

      return range(1, pagesCount);
    }

    const leftSiblingIndex = Math.max(pageIndex - siblings, boundaries);
    const rightSiblingIndex = Math.min(
      pageIndex + siblings,
      pagesCount - boundaries,
    );

    const shouldShowLeftDots = leftSiblingIndex > boundaries + 2;
    const shouldShowRightDots =
      rightSiblingIndex < pagesCount - (boundaries + 1);

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = siblings * 2 + boundaries + 2;

      return [
        ...range(1, leftItemCount),
        DOTS,
        ...range(pagesCount - (boundaries - 1), pagesCount),
      ];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = boundaries + 1 + 2 * siblings;

      return [
        ...range(1, boundaries),
        DOTS,
        ...range(pagesCount - rightItemCount, pagesCount),
      ];
    }

    return [
      ...range(1, boundaries),
      DOTS,
      ...range(leftSiblingIndex, rightSiblingIndex),
      DOTS,
      ...range(pagesCount - boundaries + 1, pagesCount),
    ];
  }, [pageIndex, pagesCount, siblings, boundaries]);

  return paginationRange;
};

export default usePagination;
