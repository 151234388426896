import { AdminAirportLocationListParams, AdminService } from "@hotelspoint/api";
import { useQuery } from "@tanstack/react-query";

const useAdminAirportLocations = (params?: AdminAirportLocationListParams) => {
  const { data, isLoading } = useQuery({
    queryKey: ["admin", "locations_airport", params],
    queryFn: () => AdminService.getAirportLocations(params),
  });

  const ret: [typeof data, boolean] = [data, isLoading];

  return ret;
};

export default useAdminAirportLocations;
