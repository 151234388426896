import { useMutation, useQueryClient } from "@tanstack/react-query";

import { AdminService } from "../../../../services";

const useDeleteAdminSupplierStatement = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: ["admin", "statements_supplier", "delete"],
    mutationFn: (id: number) => {
      return AdminService.deleteSupplierStatement(id);
    },
    onSuccess: (_, id: number) => {
      queryClient.removeQueries({
        queryKey: ["admin", "statements_supplier", id],
      });

      queryClient.invalidateQueries({
        queryKey: ["admin", "statements_supplier"],
      });
    },
  });

  const { mutateAsync, isPending } = mutation;
  const ret: [typeof mutateAsync, typeof isPending] = [mutateAsync, isPending];

  return ret;
};

export default useDeleteAdminSupplierStatement;
