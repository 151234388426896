import { borderRadius } from "@hotelspoint/theme";
import { styled } from "styled-components";
import { theme } from "styled-tools";

export const Checkbox = styled.input`
  box-sizing: border-box;
  width: 14px;
  height: 14px;
  border: 1px solid rgba(0, 0, 0, 0.6);
  border-radius: ${borderRadius.small}px;
  appearance: none;
  background-color: transparent;
  outline: none;

  &:checked {
    background-size: cover;
  }

  &:not(:disabled):checked {
    border-color: ${theme("palette.ocean")};
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 32 32" xml:space="preserve"><path style="fill: %23123266" d="M11.941,28.877l-11.941-11.942l5.695-5.696l6.246,6.246l14.364-14.364L32,8.818"/></svg>');
  }

  &:disabled {
    background-color: ${theme("disabled.bg")};
  }

  &:disabled:checked {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 32 32" xml:space="preserve"><path style="fill: %23636E72" d="M11.941,28.877l-11.941-11.942l5.695-5.696l6.246,6.246l14.364-14.364L32,8.818"/></svg>');
  }

  &:focus-visible {
    outline: 1px solid ${theme("palette.ocean")};
    transform: scale(1.05);
  }
`;
