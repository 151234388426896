import TableCellEmpty from "../../table/TableCellEmpty";
import * as S from "./DataItem.styled";

interface DataItemProps {
  title: string;
  value: string | number | React.ReactNode | null;
}

const DataItem = ({ title, value }: DataItemProps) => {
  return (
    <S.Wrapper>
      <S.Title>{title}</S.Title>
      <S.Value>{value || <TableCellEmpty />}</S.Value>
    </S.Wrapper>
  );
};

export default DataItem;
