import { mediaQuery, spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";

export const HotelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing(2)}px;
  @media ${mediaQuery.tablet} {
    flex-direction: row;
    row-gap: 0;
    justify-content: space-between;
    align-items: center;
  }
`;
