import { borderRadius, pxToRem, spacing } from "@hotelspoint/theme";
import styled from "styled-components";

import Paper from "../Paper";

export const Wrapper = styled(Paper)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: ${spacing(2)}px;
  border-radius: ${borderRadius.medium}px;
  padding: ${spacing(2)}px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: ${spacing(1)}px;
`;

export const Title = styled.h6`
  color: rgba(18, 50, 102, 0.6);
  font-weight: 700;
  font-size: ${pxToRem(14)};
  text-transform: uppercase;
`;

export const Value = styled.h6`
  font-weight: 600;
  font-size: ${pxToRem(20)};
`;

export const IconWrapper = styled.div`
  opacity: 0.5;
`;
