import { IconEye } from "@tabler/icons-react";

import Button from "../Button/Button";
import { ButtonProps } from "../Button/types";

const ButtonView = (props: Omit<ButtonProps, "children">) => (
  <Button small variant="tertiary" {...props}>
    <IconEye size={14} />
  </Button>
);

export default ButtonView;
