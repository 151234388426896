import { useQuery } from "@tanstack/react-query";

import { PaymentListParams, PaymentService } from "../../services";

const usePayments = (params?: PaymentListParams) => {
  const { data, isLoading } = useQuery({
    queryKey: ["payments", params],
    queryFn: () => PaymentService.getList(params),
  });

  const ret: [typeof data, boolean] = [data, isLoading];

  return ret;
};

export default usePayments;
