import { RegisterPayload } from "@hotelspoint/api";
import {
  SCHEMA_COMPANY_ADDRESS,
  SCHEMA_COMPANY_LICENSE,
  SCHEMA_COMPANY_NAME,
  SCHEMA_COMPANY_UIC_NUMBER,
  SCHEMA_COUNTRY,
  SCHEMA_EMAIL,
  SCHEMA_NAMES,
  SCHEMA_PASSWORD_CONFIRM,
  SCHEMA_PASSWORD_STRICT,
  SCHEMA_PHONE,
} from "@hotelspoint/utils";
import omit from "lodash/omit";
import { InferType, object } from "yup";

export const validationSchema = object({
  name: SCHEMA_NAMES,
  email: SCHEMA_EMAIL,
  password: SCHEMA_PASSWORD_STRICT,
  confirmPassword: SCHEMA_PASSWORD_CONFIRM,
  companyName: SCHEMA_COMPANY_NAME,
  companyAddress: SCHEMA_COMPANY_ADDRESS,
  uicNumber: SCHEMA_COMPANY_UIC_NUMBER,
  companyLicense: SCHEMA_COMPANY_LICENSE,
  companyManager: SCHEMA_NAMES,
  countryCode: SCHEMA_COUNTRY,
  companyEmail: SCHEMA_EMAIL,
  companyPhone: SCHEMA_PHONE,
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: FormValues = {
  name: "",
  email: "",
  password: "",
  confirmPassword: "",
  companyName: "",
  companyAddress: "",
  uicNumber: "",
  companyLicense: "",
  companyManager: "",
  countryCode: "",
  companyEmail: "",
  companyPhone: "",
};

export const form2Entity = (values: FormValues): RegisterPayload => ({
  ...omit(values, "confirmPassword"),
  countryCode: `${values.countryCode}`.toUpperCase(),
});
