import { spacing } from "@hotelspoint/theme";
import styled from "styled-components";
import { theme } from "styled-tools";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing(0.5)}px;
`;

export const RemoveFile = styled.div`
  cursor: pointer;
  opacity: 0;
  color: ${theme("error.primary")};
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${spacing(0.5)}px;

  &:hover {
    ${RemoveFile} {
      opacity: 1;
    }
  }
`;
