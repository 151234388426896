import {
  handleError,
  Reservation,
  useCancelReservation,
} from "@hotelspoint/api";
import {
  Button,
  Chip,
  ConfirmDialog,
  Panel,
  StatusFlavour,
} from "@hotelspoint/components";
import {
  formatDate,
  getPaymentStatusColor,
  getPaymentStatusName,
  getReservationStatusColor,
  getReservationStatusName,
} from "@hotelspoint/utils";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import * as S from "./ReservationBaseInfo.styled";

interface ReservationBaseInfoProps {
  reservation: Reservation;
}

const ReservationBaseInfo = ({ reservation }: ReservationBaseInfoProps) => {
  const { t } = useTranslation();

  const [cancelReservation, isCancelingReservation] = useCancelReservation(
    reservation.id,
  );

  const handleCancelReservation = useCallback(async () => {
    try {
      await cancelReservation();

      toast.success(t("toast.profileReservations.cancel"));
    } catch (error: any) {
      handleError({ t, error });
    }
  }, [t, cancelReservation]);

  return (
    <Panel title={t("profileReservation.information")}>
      <S.InformationWrapper>
        <S.InformationBlockWrapper>
          <S.InformationBlock>
            <S.InformationBlockRow>
              <p>{t("profileReservation.bookingStatus")}</p>
              <Chip $color={getReservationStatusColor(reservation?.status)}>
                {t(getReservationStatusName(reservation?.status))}
              </Chip>
            </S.InformationBlockRow>
            <S.InformationBlockRow>
              <p>{t("profileReservation.deadline")}</p>
              <p>{formatDate(reservation?.dateDeadline)}</p>
            </S.InformationBlockRow>
          </S.InformationBlock>
          <S.Divider />
          <S.InformationBlock>
            <S.InformationBlockRow>
              <p>{t("profileReservation.paymentStatus")}</p>
              <Chip $color={getPaymentStatusColor(reservation?.paymentStatus)}>
                {t(getPaymentStatusName(reservation?.paymentStatus))}
              </Chip>
            </S.InformationBlockRow>
            <S.InformationBlockRow>
              <p>{t("profileReservation.paymentDeadline")}</p>
              <p>{formatDate(reservation?.dateDeadline)}</p>
              {/* when Dido is ready this should be change with payment deadline */}
            </S.InformationBlockRow>
          </S.InformationBlock>
        </S.InformationBlockWrapper>
        <S.InformationActionWrapper>
          <ConfirmDialog
            title={t(
              "profileReservation.actions.cancelReservation.modal.title",
            )}
            description={t(
              "profileReservation.actions.cancelReservation.modal.description",
            )}
            onConfirm={handleCancelReservation}
            isLoading={isCancelingReservation}
            status={StatusFlavour.Error}
          >
            <Button variant="error">
              {t("profileReservation.actions.cancelReservation.button")}
            </Button>
          </ConfirmDialog>
          <Button variant="success" onClick={() => {}}>
            {t("profileReservation.actions.makePayment")}
          </Button>
        </S.InformationActionWrapper>
      </S.InformationWrapper>
    </Panel>
  );
};

export default ReservationBaseInfo;
