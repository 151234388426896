import { Paper } from "@hotelspoint/components";
import { mediaQuery, spacing } from "@hotelspoint/theme";
import styled from "styled-components";
import { prop } from "styled-tools";

export const HeaderWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Slogans = styled.div`
  color: white;
  text-align: center;

  h1,
  h4 {
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
`;

export const Subtitle = styled.h4`
  font-weight: 400;
  margin-top: ${spacing(0.5)}px;
`;

export const UserSection = styled(Paper)`
  width: 100%;
  margin-top: ${spacing(2)}px;
  padding: ${spacing(2)}px ${spacing(1.5)}px;

  @media ${mediaQuery.tablet} {
    width: 450px;
    margin-top: ${spacing(4)}px;
    padding: ${spacing(2.5)}px ${spacing(4)}px;
  }
`;

export const Heading = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing(0.75)}px;
  text-align: center;
`;

export const ProductCard = styled(Paper)`
  height: 100%;
`;

export const ProductImage = styled.div<{ $src: string }>`
  width: 100%;
  height: 170px;
  background: url(${prop("$src")}) no-repeat;
  background-size: cover;
  background-position: center center;
`;

export const ProductContent = styled.div`
  padding: ${spacing(1.5)}px;

  @media ${mediaQuery.tablet} {
    padding: ${spacing(3)}px;
  }
`;

export const ProductTitle = styled.h4`
  font-weight: 600;
  margin-bottom: ${spacing(1)}px;
`;
