import { useQuery } from "@tanstack/react-query";

import { UserListParams, UserService } from "../../services";

const useUsers = (params?: Partial<UserListParams>) => {
  const { data, isLoading } = useQuery({
    queryKey: ["users", params],
    queryFn: () => UserService.getList(params),
  });

  const ret: [typeof data, boolean] = [data, isLoading];

  return ret;
};

export default useUsers;
