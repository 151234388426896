import { Footer, Header } from "@hotelspoint/components";
import { motion } from "framer-motion";
import { Outlet, useLocation } from "react-router-dom";

import { pageTransition, pageVariants } from "./motion.config";

const RootLayout = () => {
  const { pathname } = useLocation();

  return (
    <>
      <Header />
      <motion.div
        key={pathname}
        initial="initial"
        animate="in"
        variants={pageVariants}
        transition={pageTransition}
      >
        <Outlet />
      </motion.div>
      <Footer />
    </>
  );
};

export default RootLayout;
