import { number, object } from "yup";

export const getRefundValidationSchema = ({
  maxAmount,
}: {
  maxAmount: number;
}) => {
  return object({
    amount: number()
      .typeError("adminPayment.actions.refund.modal.amount.validation.required")
      .min(0, "adminPayment.actions.refund.modal.amount.validation.min")
      .max(maxAmount, "adminPayment.actions.refund.modal.amount.validation.max")
      .required("adminPayment.actions.refund.modal.amount.validation.required"),
  }).required();
};
