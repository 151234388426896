import { mediaQuery, spacing } from "@hotelspoint/theme";
import styled from "styled-components";

import Paper from "../Paper";

const Card = styled(Paper)`
  padding: ${spacing(1.5)}px;

  @media ${mediaQuery.tablet} {
    padding: ${spacing(3)}px;
  }
`;

export default Card;
