const safelyParseJSON = (json: any) => {
  let parsed;

  try {
    parsed = JSON.parse(json);
  } catch (e) {
    parsed = {};
  }

  return parsed;
};

export default safelyParseJSON;
