import { useUserProfileStore } from "@hotelspoint/store";
import { UserRole } from "@hotelspoint/types";
import { Navigate, Outlet } from "react-router-dom";

interface UserRoleProtectedRouteProps {
  roles: UserRole[];
}

const UserRoleProtectedRoute = ({ roles }: UserRoleProtectedRouteProps) => {
  const profile = useUserProfileStore(state => state.profile);
  const canAccessRoute = roles.includes(profile?.roleId as UserRole);

  return canAccessRoute ? <Outlet /> : <Navigate to="/" />;
};

export default UserRoleProtectedRoute;
