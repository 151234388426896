import { Invoice } from "@hotelspoint/api";
import {
  ButtonView,
  LinkExternal,
  TABLE_COLUMN_SIZE,
  TableCell,
} from "@hotelspoint/components";
import {
  formatDateTime,
  getApiUrl,
  getInvoiceTypeName,
  withCurrency,
} from "@hotelspoint/utils";
import { createColumnHelper } from "@tanstack/react-table";
import { TFunction } from "i18next";

const columnHelper = createColumnHelper<Invoice>();

interface GetColumnsParams {
  t: TFunction;
  token: string;
}

const getColumns = ({ t, token }: GetColumnsParams) => [
  columnHelper.accessor("reservationId", {
    header: t("profileInvoices.columns.reservationId"),
    size: TABLE_COLUMN_SIZE.ID,
  }),
  columnHelper.accessor("dateCreated", {
    header: t("profileInvoices.columns.dateCreated"),
    size: TABLE_COLUMN_SIZE.DATE_TIME,
    cell: props => formatDateTime(props.getValue()),
  }),
  columnHelper.accessor("fullNumber", {
    header: t("profileInvoices.columns.fullNumber"),
    size: TABLE_COLUMN_SIZE.INVOICE_NUMBER,
  }),
  columnHelper.accessor("amount", {
    header: t("profileInvoices.columns.amount"),
    size: TABLE_COLUMN_SIZE.AMOUNT,
    cell: props => withCurrency(props.getValue(), props.row.original.currency),
  }),
  columnHelper.accessor("type", {
    header: t("profileInvoices.columns.type"),
    size: TABLE_COLUMN_SIZE.TYPE,
    cell: props => {
      const type = props.getValue();

      return t(getInvoiceTypeName(type));
    },
  }),
  columnHelper.display({
    id: "actions",
    size: TABLE_COLUMN_SIZE.ACTION,
    cell: props => {
      const id = props.row.original.id;
      const url = getApiUrl(`invoices/${id}/view`, { token });

      return (
        <TableCell>
          <LinkExternal to={url}>
            <ButtonView />
          </LinkExternal>
        </TableCell>
      );
    },
  }),
];

export default getColumns;
