import { useMutation } from "@tanstack/react-query";

import { AgencyService } from "../../services";

const useFetchAgency = () => {
  const mutation = useMutation({
    mutationKey: ["fetch_agency"],
    mutationFn: (id: number) => {
      return AgencyService.get(id);
    },
  });

  const { mutateAsync, isPending } = mutation;
  const ret: [typeof mutateAsync, typeof isPending] = [mutateAsync, isPending];

  return ret;
};

export default useFetchAgency;
