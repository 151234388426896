import { PlaceType } from "@hotelspoint/types";

export const getPlaceTypeName = (type: PlaceType) => {
  switch (type) {
    case PlaceType.Area:
      return "placeType.area";

    case PlaceType.Destination:
      return "placeType.destination";

    case PlaceType.City:
      return "placeType.city";

    case PlaceType.Hotel:
      return "placeType.hotel";

    case PlaceType.Airport:
      return "placeType.airport";

    case PlaceType.PointOfInterest:
      return "placeType.poi";

    case PlaceType.Address:
      return "placeType.address";

    default:
      return "Unknown place type";
  }
};
