import { borderRadius, spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";
import { theme } from "styled-tools";

export const Wrapper = styled.div`
  padding: 0 ${spacing(5)}px;

  .slick-slider {
    margin-inline: -${2 * spacing(1)}px;
  }

  .slick-slide {
    padding: ${spacing(0.5)}px;

    > div {
      margin-inline: ${spacing(1)}px;
    }
  }
`;

export const Arrow = styled.div`
  width: 48px;
  height: 48px;
  color: ${theme("palette.ocean")};

  &:hover {
    color: inherit;
  }

  &:before {
    content: none;
  }

  &.slick-next {
    right: -${spacing(6)}px;
  }

  &.slick-prev {
    left: -${spacing(6)}px;
  }
`;

export const Dot = styled.div`
  width: 8px !important;
  height: 8px;
  border-radius: ${borderRadius.circle};
  background-color: ${theme("palette.ocean")};
  cursor: pointer;
`;

export const DotsWrapper = styled.div`
  max-width: 60px;
  margin: 0 auto;
  .slick-current {
    ${Dot} {
      width: 12px !important;
      height: 12px;
      margin-bottom: -2px;
    }
  }
`;
