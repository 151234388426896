import { Message } from "@hotelspoint/api";
import { InferType, number, object, string } from "yup";

export const validationSchema = object({
  id: string(),
  priority: number().required(),
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const entity2Form = (entity: Message) => ({
  id: entity.id,
  priority: entity.priority,
});

export const form2Entity = (formValues: FormValues) => ({
  id: Number(formValues.id),
  priority: formValues.priority,
});
