import { AdminCurrency } from "@hotelspoint/api";
import {
  Button,
  FormAdapter,
  FormContext,
  FormInput,
  TABLE_COLUMN_SIZE,
  TableCell,
} from "@hotelspoint/components";
import { UserCurrency } from "@hotelspoint/types";
import { createColumnHelper } from "@tanstack/react-table";
import { TFunction } from "i18next";

const columnHelper = createColumnHelper<AdminCurrency>();

interface GetColumnsParams {
  t: TFunction;
  targetId: number | null;
  onSubmit: (id: number) => (values: any) => void;
  isUpdating: boolean;
}

const getColumns = ({
  t,
  targetId,
  onSubmit,
  isUpdating,
}: GetColumnsParams) => [
  columnHelper.accessor("id", {
    header: t("adminCurrencyConfiguration.columns.id"),
    size: TABLE_COLUMN_SIZE.ID,
  }),
  columnHelper.accessor("code", {
    header: t("adminCurrencyConfiguration.columns.code"),
    size: TABLE_COLUMN_SIZE.ID_LONG,
  }),
  columnHelper.accessor("name", {
    header: t("adminCurrencyConfiguration.columns.name"),
    size: TABLE_COLUMN_SIZE.NAME,
  }),
  columnHelper.accessor("rate", {
    header: t("adminCurrencyConfiguration.columns.rate"),
    size: TABLE_COLUMN_SIZE.AMOUNT,
    cell: props => {
      const { id, code } = props.row.original;

      return (
        <FormAdapter name={`${id}`} label={null}>
          {props => (
            <FormInput {...props} readOnly={code === UserCurrency.EUR} />
          )}
        </FormAdapter>
      );
    },
  }),
  columnHelper.display({
    id: "actions",
    size: TABLE_COLUMN_SIZE.ACTION,
    cell: props => {
      const { id, code } = props.row.original;
      const isTarget = id === targetId;

      if (code === UserCurrency.EUR) return null;

      return (
        <TableCell>
          <FormContext
            render={({ handleSubmit }) => (
              <Button
                small
                variant="tertiary"
                type="submit"
                isLoading={isTarget && isUpdating}
                onClick={handleSubmit(onSubmit(id))}
              >
                {t("adminCurrencyConfiguration.columns.actions.save")}
              </Button>
            )}
          />
        </TableCell>
      );
    },
  }),
];

export default getColumns;
