import Skeleton from "react-loading-skeleton";

const LegalPageSkeleton = () => (
  <>
    <h4>
      <Skeleton />
    </h4>
    <p>
      <Skeleton count={3} />
    </p>
    <p>
      <Skeleton count={2} />
    </p>
    <p>
      <Skeleton count={2} />
    </p>
    <p>
      <Skeleton />
    </p>
    <p>
      <Skeleton />
    </p>
    <p>
      <Skeleton count={3} />
    </p>
    <hr />
    <h4>
      <Skeleton />
    </h4>
    <p>
      <Skeleton count={2} />
    </p>
    <p>
      <Skeleton count={2} />
    </p>
    <p>
      <Skeleton />
    </p>
    <p>
      <Skeleton count={2} />
    </p>
    <hr />
    <h4>
      <Skeleton />
    </h4>
    <p>
      <Skeleton count={2} />
    </p>
    <p>
      <Skeleton count={2} />
    </p>
    <p>
      <Skeleton />
    </p>
    <p>
      <Skeleton />
    </p>
  </>
);

export default LegalPageSkeleton;
