export enum Breakpoint {
  MobileS = 320,
  MobileM = 375,
  MobileL = 425,
  Tablet = 768,
  Desktop = 1024,
  AppMaxWidth = 1150,
}

const mediaQuery = {
  // mobileS: `only screen and (min-width: ${Breakpoint.MobileS}px)`,
  mobileM: `only screen and (min-width: ${Breakpoint.MobileM}px)`,
  mobileL: `only screen and (min-width: ${Breakpoint.MobileL}px)`,
  tablet: `only screen and (min-width: ${Breakpoint.Tablet}px)`,
  desktop: `only screen and (min-width: ${Breakpoint.Desktop}px)`,
};

export default mediaQuery;
