export enum UserRole {
  SuperAdministrator = 1,
  AgencyAdministrator = 2,
  AgencyMember = 3,
  SalesManager = 4,
}

export interface UserProfile {
  id: number;
  name: string;
  email: string;
  roleId: UserRole;
  agencyId: number;
}

export enum UserLocale {
  English = "en",
  Bulgarian = "bg",
}

export enum UserCurrency {
  EUR = "EUR",
  BGN = "BGN",
}
