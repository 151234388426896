import { useTranslation } from "react-i18next";

import FormSelect from "../../form/FormSelect";
import { TABLE_PAGE_SIZE_OPTIONS } from "../constants";
import * as S from "./TablePageSize.styled";

interface TablePageSizeProps {
  value: number;
  onChange: (value: number) => void;
}

const TablePageSize = ({ value, onChange }: TablePageSizeProps) => {
  const { t } = useTranslation();

  return (
    <S.Wrapper>
      <S.Label htmlFor="tablePageSize">
        {t("components.tablePageSize.label")}
      </S.Label>
      <FormSelect
        id="tablePageSize"
        value={value}
        onChange={onChange}
        options={TABLE_PAGE_SIZE_OPTIONS}
      />
    </S.Wrapper>
  );
};

export default TablePageSize;
