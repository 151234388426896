import stubAvatar from "./assets/_avatar.png";

export default [
  {
    author: {
      names: "Jane Smith",
      company: "SomeCompany",
      avatar: stubAvatar,
    },
    content:
      "I’ve been working with these guys for a long time and I can say that my business is in the perfect hands.",
  },
  {
    author: {
      names: "Jane Smith",
      company: "SomeCompany",
      avatar: stubAvatar,
    },
    content:
      "Working with HotelsPoint is just great, every problem in my business is solved in a matter of days.",
  },
  {
    author: {
      names: "Jane Smith",
      company: "SomeCompany",
      avatar: stubAvatar,
    },
    content:
      "I’ve been working with these guys for a long time and I can say that my business is in the perfect hands.++",
  },
];
