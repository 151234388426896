import {
  IconChevronLeft,
  IconChevronRight,
  IconDots,
} from "@tabler/icons-react";
import { useCallback, useMemo } from "react";

import * as S from "./Pagination.styled";
import usePagination from "./usePagination";

const DEFAULT_PAGE_SIZE = 20;

export interface PaginationProps {
  pageIndex: number;
  totalCount: number;
  setPageIndex: (pageIndex: number) => void;
  pageSize?: number;
}

const Pagination = ({
  pageIndex,
  totalCount,
  setPageIndex,
  pageSize = DEFAULT_PAGE_SIZE,
}: PaginationProps) => {
  const pagesCount = Math.ceil(totalCount / pageSize);

  const range = usePagination({ pageIndex, pagesCount });

  const isFirstPage = useMemo(() => pageIndex === 1, [pageIndex]);
  const isLastPage = useMemo(
    () => pageIndex === pagesCount,
    [pageIndex, pagesCount],
  );

  const handlePreviousPage = useCallback(() => {
    setPageIndex(pageIndex - 1);
  }, [setPageIndex, pageIndex]);

  const handleNextPage = useCallback(() => {
    setPageIndex(pageIndex + 1);
  }, [setPageIndex, pageIndex]);

  return (
    <S.Wrapper>
      <li>
        <S.Button onClick={handlePreviousPage} disabled={isFirstPage}>
          <IconChevronLeft size={20} />
        </S.Button>
      </li>
      {range?.map((page, index) => {
        return (
          <li key={index}>
            {typeof page === "number" ? (
              <S.Button
                onClick={() => setPageIndex(page)}
                $active={pageIndex === page}
              >
                {page}
              </S.Button>
            ) : (
              <IconDots size={16} />
            )}
          </li>
        );
      })}
      <li>
        <S.Button
          onClick={handleNextPage}
          disabled={isLastPage || pagesCount <= 1}
        >
          <IconChevronRight size={20} />
        </S.Button>
      </li>
    </S.Wrapper>
  );
};

export default Pagination;
