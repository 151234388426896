import { useResetUserStores } from "@hotelspoint/store";
import { useMutation } from "@tanstack/react-query";

import { AuthService } from "../../services";

const useLogout = () => {
  const resetUserStores = useResetUserStores();

  const mutation = useMutation({
    mutationKey: ["auth", "logout"],
    mutationFn: () => {
      return AuthService.logout();
    },
    onSuccess: () => resetUserStores(),
    onError: () => resetUserStores(),
  });

  const { mutateAsync, isPending } = mutation;
  const ret: [typeof mutateAsync, typeof isPending] = [mutateAsync, isPending];

  return ret;
};

export default useLogout;
