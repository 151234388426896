import { spacing, timing } from "@hotelspoint/theme";
import { IconChevronDown } from "@tabler/icons-react";
import styled from "styled-components";

export const ButtonWrapper = styled.button`
  display: flex;
  height: 100%;
  align-items: center;
  color: white;
`;

export const CaretIcon = styled(IconChevronDown)`
  margin-left: ${spacing(0.5)}px;
  transition: all ${timing.fast}ms;
`;
