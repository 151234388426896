import { type HotelSearchPayload, HotelService } from "@hotelspoint/api";
import { useQuery } from "@tanstack/react-query";

const useHotelAvailabilitySearch = (params: HotelSearchPayload) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["hotels_availability", params],
    queryFn: () => HotelService.search(params),
  });

  const queryInstance = {
    refetch,
  };

  const ret: [typeof data, boolean, typeof queryInstance] = [
    data,
    isLoading,
    queryInstance,
  ];

  return ret;
};

export default useHotelAvailabilitySearch;
