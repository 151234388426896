import { ReservationInList } from "@hotelspoint/api";
import {
  Chip,
  TABLE_COLUMN_SIZE,
  TableActions,
  TableCell,
  TableCellEmpty,
} from "@hotelspoint/components";
import { CHIP_COLORS } from "@hotelspoint/constants";
import {
  formatDate,
  getApiUrl,
  getAssigneeById,
  getPaymentStatusColor,
  getPaymentStatusName,
  getReservationHotelConfirmationStatusName,
  getReservationStatusColor,
  getReservationStatusName,
  getReservationSupplierConfirmationStatusName,
  getReservationSupplierNameByType,
  getReservationTransferConfirmationStatusName,
  getReservationTypeName,
  openInNewTab,
  withCurrency,
} from "@hotelspoint/utils";
import { IconCheckbox, IconMail } from "@tabler/icons-react";
import { createColumnHelper } from "@tanstack/react-table";
import { TFunction } from "i18next";
import { NavigateFunction } from "react-router-dom";

const columnHelper = createColumnHelper<ReservationInList>();

interface GetColumnsParams {
  t: TFunction;
  token: string;
  navigate: NavigateFunction;
  handleAssignTo: (id: number) => void;
}

const getColumns = ({
  t,
  token,
  navigate,
  handleAssignTo,
}: GetColumnsParams) => [
  columnHelper.display({
    id: "state_icons",
    cell: props => {
      const { confirmationId, countNewMessages } = props.row.original;

      return (
        <TableCell>
          <IconCheckbox
            size={18}
            style={{
              color: confirmationId ? CHIP_COLORS.GREEN : CHIP_COLORS.GREY,
            }}
          />
          <IconMail
            size={18}
            style={{
              color:
                countNewMessages > 0 ? CHIP_COLORS.GREEN : CHIP_COLORS.GREY,
            }}
          />
        </TableCell>
      );
    },
  }),
  columnHelper.accessor("id", {
    header: t("adminReservations.columns.id"),
    size: TABLE_COLUMN_SIZE.ID,
  }),
  columnHelper.accessor("agencyName", {
    header: t("adminReservations.columns.agencyName"),
    size: TABLE_COLUMN_SIZE.NAME,
  }),
  columnHelper.accessor("userName", {
    header: t("adminReservations.columns.userName"),
    size: TABLE_COLUMN_SIZE.NAME,
  }),
  columnHelper.accessor("itemType", {
    header: t("adminReservations.columns.itemType"),
    size: TABLE_COLUMN_SIZE.TYPE,
    cell: props => t(getReservationTypeName(props.getValue())),
  }),
  columnHelper.accessor("dateDeadline", {
    header: t("adminReservations.columns.dateDeadline"),
    size: TABLE_COLUMN_SIZE.DATE,
    cell: props => formatDate(props.getValue()),
  }),
  columnHelper.accessor("clientName", {
    header: t("adminReservations.columns.clientName"),
    size: TABLE_COLUMN_SIZE.NAME,
  }),
  columnHelper.accessor("cityName", {
    header: t("adminReservations.columns.cityName"),
    size: TABLE_COLUMN_SIZE.CITY,
  }),
  columnHelper.accessor("itemData.price", {
    header: t("adminReservations.columns.price"),
    size: TABLE_COLUMN_SIZE.AMOUNT,
    cell: props => withCurrency(props.getValue()),
  }),
  columnHelper.accessor("status", {
    header: t("adminReservations.columns.status"),
    size: TABLE_COLUMN_SIZE.STATUS,
    cell: props => {
      const status = props.getValue();

      return (
        <Chip $color={getReservationStatusColor(status)}>
          {t(getReservationStatusName(status))}
        </Chip>
      );
    },
  }),
  columnHelper.accessor("paymentStatus", {
    header: t("adminReservations.columns.paymentStatus"),
    cell: props => {
      const status = props.getValue();

      return (
        <Chip $color={getPaymentStatusColor(status)}>
          {t(getPaymentStatusName(status))}
        </Chip>
      );
    },
  }),
  columnHelper.accessor("itemData.supplierId", {
    header: t("adminReservations.columns.supplierId"),
    size: TABLE_COLUMN_SIZE.SUPPLIER,
    cell: props => {
      const type = props.row.original.itemType;
      const supplierId = props.getValue();
      const supplierName = getReservationSupplierNameByType(type, supplierId);

      return <Chip $color={CHIP_COLORS.SUPPLIER}>{supplierName}</Chip>;
    },
  }),
  columnHelper.accessor("confirmationAssigneeId", {
    header: t("adminReservations.columns.confirmationAssigneeId"),
    size: TABLE_COLUMN_SIZE.ASSIGNEE,
    cell: props => {
      const id = props.getValue();

      return getAssigneeById(id);
    },
  }),
  columnHelper.accessor("confirmationHotelStatus", {
    header: t("adminReservations.columns.confirmationHotelStatus"),
    size: TABLE_COLUMN_SIZE.STATUS_LONG,
    cell: props => {
      const status = props.getValue();

      if (!status) return <TableCellEmpty />;

      return t(getReservationHotelConfirmationStatusName(status));
    },
  }),
  columnHelper.accessor("confirmationSupplierStatus", {
    header: t("adminReservations.columns.confirmationSupplierStatus"),
    size: TABLE_COLUMN_SIZE.STATUS_LONG,
    cell: props => {
      const status = props.getValue();

      if (!status) return <TableCellEmpty />;

      return t(getReservationSupplierConfirmationStatusName(status));
    },
  }),
  columnHelper.accessor("confirmationOutboundTransferStatus", {
    header: t("adminReservations.columns.confirmationOutboundTransferStatus"),
    size: TABLE_COLUMN_SIZE.STATUS_LONG,
    cell: props => {
      const status = props.getValue();

      if (!status) return <TableCellEmpty />;

      return t(getReservationTransferConfirmationStatusName(status));
    },
  }),
  columnHelper.accessor("confirmationReturnTransferStatus", {
    header: t("adminReservations.columns.confirmationReturnTransferStatus"),
    size: TABLE_COLUMN_SIZE.STATUS_LONG,
    cell: props => {
      const status = props.getValue();

      if (!status) return <TableCellEmpty />;

      return t(getReservationTransferConfirmationStatusName(status));
    },
  }),
  columnHelper.accessor("dateCreated", {
    header: t("adminReservations.columns.dateCreated"),
    size: TABLE_COLUMN_SIZE.DATE,
    cell: props => formatDate(props.getValue()),
  }),
  columnHelper.accessor("dateCancelled", {
    header: t("adminReservations.columns.dateCancelled"),
    size: TABLE_COLUMN_SIZE.DATE,
    cell: props => {
      const value = props.getValue();

      if (!value) return <TableCellEmpty />;

      return formatDate(value);
    },
  }),
  columnHelper.accessor("dateFrom", {
    header: t("adminReservations.columns.dateFrom"),
    size: TABLE_COLUMN_SIZE.DATE,
    cell: props => formatDate(props.getValue()),
  }),
  columnHelper.accessor("dateTo", {
    header: t("adminReservations.columns.dateTo"),
    size: TABLE_COLUMN_SIZE.DATE,
    cell: props => formatDate(props.getValue()),
  }),
  columnHelper.accessor("itemData.priceNet", {
    header: t("adminReservations.columns.priceNet"),
    size: TABLE_COLUMN_SIZE.AMOUNT,
    cell: props => withCurrency(props.getValue()),
  }),
  columnHelper.display({
    id: "actions",
    size: TABLE_COLUMN_SIZE.ACTION,
    cell: props => {
      const { id } = props.row.original;

      const logUrl = getApiUrl(`admin/requests/bookings/${id}/response`, {
        token,
      });

      return (
        <TableActions
          options={[
            {
              id: "edit",
              title: t("adminReservations.columns.actions.edit"),
              onClick: () => navigate(`/admin/bookings/${id}`),
            },
            {
              id: "view_booking",
              title: t("adminReservations.columns.actions.view"),
              onClick: () => navigate(`/profile/bookings/${id}`),
            },
            {
              id: "view_log",
              title: t("adminReservations.columns.actions.viewLog"),
              onClick: () => openInNewTab(logUrl),
            },
            {
              id: "assign",
              title: t("adminReservations.columns.actions.assign.button"),
              onClick: () => handleAssignTo(id),
            },
          ]}
        />
      );
    },
  }),
];

export default getColumns;
