import {
  Payment,
  PaymentCreditStatus,
  PaymentStatus,
  PaymentType,
  PaymentUpdatePayload,
} from "@hotelspoint/api";
import { InferType, mixed, number, object, string } from "yup";

export const validationSchema = object({
  id: number(),
  reservationId: number(),
  amount: number(),
  type: mixed<PaymentType>().oneOf(
    Object.values(PaymentType).map(e => e as PaymentType),
  ),
  agencyName: string(),
  status: mixed<PaymentStatus>()
    .oneOf(Object.values(PaymentStatus).map(e => e as PaymentStatus))
    .required(),
  creditStatus: mixed<PaymentCreditStatus>()
    .oneOf(
      Object.values(PaymentCreditStatus).map(e => e as PaymentCreditStatus),
    )
    .required(),
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: Partial<FormValues> = {
  id: undefined,
  reservationId: undefined,
  amount: undefined,
  type: undefined,
  agencyName: "",
  status: undefined,
  creditStatus: undefined,
};

export const entity2Form = (entity: Payment): FormValues => ({
  id: entity.id,
  reservationId: entity.reservationId,
  amount: entity.amount,
  type: entity.type,
  agencyName: entity.agencyName,
  status: entity.status,
  creditStatus: entity.creditStatus,
});

export const form2Entity = (formValues: FormValues): PaymentUpdatePayload => ({
  status: formValues.status,
  creditStatus: formValues.creditStatus,
});
