import { mediaQuery, spacing } from "@hotelspoint/theme";
import styled from "styled-components";
import { theme } from "styled-tools";

export default styled.div.attrs({ role: "tablist" })`
  position: relative;
  display: flex;
  width: 100%;
  column-gap: ${spacing(1)}px;
  justify-content: space-evenly;

  &::before {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    width: 100%;
    height: 1px;
    background: ${theme("palette.silver")};
  }

  @media ${mediaQuery.tablet} {
    width: fit-content;
  }
`;
