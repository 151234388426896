import { useMutation, useQueryClient } from "@tanstack/react-query";

import {
  AdminAreaLocationUpdatePayload,
  AdminService,
} from "../../../services";

const useUpdateAdminAreaLocation = (id: number) => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: ["admin", "locations_area", "update"],
    mutationFn: (payload: AdminAreaLocationUpdatePayload) => {
      return AdminService.updateAreaLocation(id, payload);
    },
    onMutate: () => {
      queryClient.cancelQueries({
        queryKey: ["admin", "locations_area", id],
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["admin", "locations_area", id],
      });

      queryClient.invalidateQueries({
        queryKey: ["admin", "locations_area"],
      });
    },
  });

  const { mutateAsync, isPending } = mutation;
  const ret: [typeof mutateAsync, typeof isPending] = [mutateAsync, isPending];

  return ret;
};

export default useUpdateAdminAreaLocation;
