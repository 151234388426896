import * as S from "./Tabs.styled";
import TabsContext from "./TabsContext";
import { TabsProps } from "./types";

const Tabs = ({ value, onChange, children }: TabsProps) => {
  return (
    <TabsContext.Provider value={{ value, onChange }}>
      <S.Wrapper role="tablist">{children}</S.Wrapper>
    </TabsContext.Provider>
  );
};

export default Tabs;
