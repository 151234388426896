import { HotelService } from "@hotelspoint/api";
import { useMutation } from "@tanstack/react-query";

const useHotelRateStatus = (searchId: number, hotelId: number) => {
  const mutation = useMutation({
    mutationKey: ["hotels", "rate_status"],
    mutationFn: (rateId: number) => {
      return HotelService.getRateStatus(searchId, hotelId, rateId);
    },
  });

  const { mutateAsync, isPending } = mutation;
  const ret: [typeof mutateAsync, typeof isPending] = [mutateAsync, isPending];

  return ret;
};

export default useHotelRateStatus;
