import { AdminAgencyStatementItem } from "@hotelspoint/api";
import {
  Chip,
  TABLE_COLUMN_SIZE,
  TableCheckbox,
} from "@hotelspoint/components";
import {
  formatDate,
  getAgencyStatementStatusColor,
  getAgencyStatementStatusName,
  getReservationStatusColor,
  getReservationStatusName,
  withCurrency,
} from "@hotelspoint/utils";
import { createColumnHelper } from "@tanstack/react-table";
import { TFunction } from "i18next";

const columnHelper = createColumnHelper<AdminAgencyStatementItem>();

interface GetColumnsParams {
  t: TFunction;
}

const getColumns = ({ t }: GetColumnsParams) => [
  columnHelper.display({
    id: "row_selection",
    size: TABLE_COLUMN_SIZE.ACTION,
    header: ({ table }) => (
      <TableCheckbox
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
        }}
      />
    ),
    cell: ({ row }) => (
      <TableCheckbox
        {...{
          checked: row.getIsSelected(),
          disabled: !row.getCanSelect(),
          indeterminate: row.getIsSomeSelected(),
          onChange: row.getToggleSelectedHandler(),
        }}
      />
    ),
  }),
  columnHelper.accessor("reservationId", {
    header: t("adminAgencyStatement.items.columns.reservationId"),
    size: TABLE_COLUMN_SIZE.ID,
  }),
  columnHelper.accessor("amount", {
    header: t("adminAgencyStatement.items.columns.amount"),
    size: TABLE_COLUMN_SIZE.AMOUNT,
    cell: props => withCurrency(props.getValue()),
  }),
  columnHelper.accessor("clientName", {
    header: t("adminAgencyStatement.items.columns.clientName"),
    size: TABLE_COLUMN_SIZE.NAME,
  }),
  columnHelper.accessor("reservationStatus", {
    header: t("adminAgencyStatement.items.columns.reservationStatus"),
    size: TABLE_COLUMN_SIZE.STATUS,
    cell: props => {
      const status = props.getValue();

      return (
        <Chip $color={getReservationStatusColor(status)}>
          {t(getReservationStatusName(status))}
        </Chip>
      );
    },
  }),
  columnHelper.accessor("dateFrom", {
    header: t("adminAgencyStatement.items.columns.dateFrom"),
    size: TABLE_COLUMN_SIZE.DATE,
    cell: props => formatDate(props.getValue()),
  }),
  columnHelper.accessor("dateDeadline", {
    header: t("adminAgencyStatement.items.columns.dateDeadline"),
    size: TABLE_COLUMN_SIZE.DATE,
    cell: props => formatDate(props.getValue()),
  }),
  columnHelper.accessor("status", {
    header: t("adminAgencyStatement.items.columns.status"),
    size: TABLE_COLUMN_SIZE.STATUS,
    cell: props => {
      const status = props.getValue();

      return (
        <Chip $color={getAgencyStatementStatusColor(status)}>
          {t(getAgencyStatementStatusName(status))}
        </Chip>
      );
    },
  }),
];

export default getColumns;
