import { useQuery } from "@tanstack/react-query";

import {
  AdminCommissionItemsListParams,
  AdminService,
} from "../../../services";

const useAdminCommissionItems = (
  id: number,
  params: AdminCommissionItemsListParams,
) => {
  const { data, isLoading } = useQuery({
    queryKey: ["admin", "commissions", id, "items", params],
    queryFn: () => AdminService.getCommissionItems(id, params),
    enabled: !!id,
  });

  const ret: [typeof data, boolean] = [data, isLoading];

  return ret;
};

export default useAdminCommissionItems;
