import { mediaQuery, spacing } from "@hotelspoint/theme";
import styled from "styled-components";
import { theme } from "styled-tools";

export const OuterWrapper = styled.footer`
  width: 100%;
  background: ${theme("footer.bg")};
  padding: ${spacing(5)}px 0 ${spacing(7)}px;
  color: ${theme("footer.text")};
  border-style: solid;
  border-width: 6px 0 0;
  border-color: ${theme("secondary.bg")};

  a {
    color: ${theme("footer.link.text")};

    &:after {
      background: ${theme("footer.link.hover")};
    }

    &:hover {
      color: ${theme("footer.link.hover")};
    }
  }
`;

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media ${mediaQuery.tablet} {
    flex-direction: row;
  }
`;

export const Sitemap = styled.nav`
  ul {
    list-style-type: none;
    display: flex;
    column-gap: ${spacing(2)}px;
  }
`;

export const Copyright = styled.div`
  margin-top: ${spacing(2)}px;
`;

export const Contacts = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing(1.5)}px;
`;

export const ContactsMobile = styled.div`
  margin-top: ${spacing(3)}px;

  @media ${mediaQuery.tablet} {
    display: none;
  }
`;

export const ContactsDesktop = styled.div`
  display: none;

  @media ${mediaQuery.tablet} {
    display: block;
  }
`;

export const ContactDetail = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${spacing(1.5)}px;
`;

export const PaymentLogos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${spacing(4)}px;
  row-gap: ${spacing(3)}px;

  img {
    align-self: flex-start;

    @media ${mediaQuery.tablet} {
      align-self: center;
    }
  }

  @media ${mediaQuery.tablet} {
    flex-direction: row;
    align-items: center;
    column-gap: ${spacing(4)}px;
  }
`;
