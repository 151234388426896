import { zIndex } from "@hotelspoint/theme";
import styled from "styled-components";

export default styled.div`
  z-index: ${zIndex.overlay};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
`;
