import { InferType, number, object } from "yup";

export const validationSchema = object({
  mainId: number()
    .typeError(
      "adminMealConfiguration.columns.actions.map.modal.mainId.validation.required",
    )
    .required(
      "adminMealConfiguration.columns.actions.map.modal.mainId.validation.required",
    ),
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: Partial<FormValues> = {
  mainId: undefined,
};

export const form2Entity = (formValues: FormValues) => ({
  mainId: formValues.mainId,
});
