import {
  BooleanParam,
  NumberParam,
  StringParam,
  useQueryParams,
} from "use-query-params";

const useAdminMessagesParams = () => {
  const [query, setQuery] = useQueryParams({
    id: NumberParam,
    reservationId: NumberParam,
    priority: NumberParam,
    type: StringParam,
    status: NumberParam,
    assigneeId: StringParam,
    dateFrom: StringParam,
    dateTo: StringParam,
    alert: BooleanParam,
    checkInFrom: StringParam,
    checkInTo: StringParam,
    seen: BooleanParam,
    reservationStatus: NumberParam,
    reservationConfirmed: BooleanParam,
    orderBy: NumberParam,
  });

  const ret: [typeof query, typeof setQuery] = [query, setQuery];

  return ret;
};

export default useAdminMessagesParams;
