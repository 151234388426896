import { css } from "styled-components";
import { theme } from "styled-tools";

import { mediaQuery, pxToRem, spacing } from "../utils";

export default css`
  .Toastify__toast-container {
    --toastify-toast-width: 460px;
    --toastify-toast-min-height: 36px;
    --toastify-text-color-light: ${theme("body.text")};
    --toastify-color-success: ${theme("success.primary")};
    --toastify-color-info: ${theme("info.primary")};
    --toastify-color-warning: ${theme("warning.primary")};
    --toastify-color-error: ${theme("error.primary")};

    font-size: ${pxToRem(14)};
    padding: 0 ${spacing(1)}px;
    top: calc(${theme("custom.header.heightS")}px + ${spacing(0.5)}px);

    @media ${mediaQuery.tablet} {
      padding: 0;
      top: calc(${theme("custom.header.heightL")}px + ${spacing(1)}px);
    }
  }

  .Toastify__toast-icon {
    width: 16px;
  }
`;
