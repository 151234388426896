import { NumberParam, StringParam, useQueryParams } from "use-query-params";

const useAdminAgenciesParams = () => {
  const [query, setQuery] = useQueryParams({
    id: NumberParam,
    name: StringParam,
    email: StringParam,
    registerFrom: StringParam,
    registerTo: StringParam,
    status: NumberParam,
  });

  const ret: [typeof query, typeof setQuery] = [query, setQuery];

  return ret;
};

export default useAdminAgenciesParams;
