import { SCHEMA_EMAIL } from "@hotelspoint/utils";
import { InferType, object } from "yup";

export const validationSchema = object({
  email: SCHEMA_EMAIL,
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: FormValues = {
  email: "",
};
