import { handleError, useRegister } from "@hotelspoint/api";
import {
  Box,
  Button,
  Flex,
  Form,
  FormAdapter,
  FormContext,
  FormInput,
  FormLayout,
  FormSelect,
  LinkUnderline,
  OptionGroup,
  StatusBlock,
  StatusFlavour,
  StatusText,
} from "@hotelspoint/components";
import { countryGroups } from "@hotelspoint/constants";
import { IconLock, IconMail, IconUser } from "@tabler/icons-react";
import { useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import {
  defaultValues,
  form2Entity,
  FormValues,
  validationSchema,
} from "./RegisterForm.form";
import * as S from "./RegisterForm.styled";

const RegisterForm = () => {
  const { t } = useTranslation();

  const [isSuccess, setIsSuccess] = useState(false);
  const [registerUser, isRegisteringUser] = useRegister();

  const countryOptions: OptionGroup[] = useMemo(
    () =>
      countryGroups.map(group => ({
        label: t(`countries.groups.${group.label}`),
        options: group.options.map(value => ({
          value,
          label: t(`countries.${value}`),
        })),
      })),
    [t],
  );

  const onSubmit = async (formValues: FormValues) => {
    try {
      const payload = form2Entity(formValues);
      const response = await registerUser(payload);

      if (response.result) {
        setIsSuccess(true);
      } else {
        throw new Error(t("toast.unhandled"));
      }
    } catch (error: any) {
      handleError({ t, error });
    }
  };

  return (
    <Form<FormValues>
      defaultValues={defaultValues}
      validationSchema={validationSchema}
    >
      {isSuccess ? (
        <StatusBlock status={StatusFlavour.Success}>
          <h6>{t("register.success.title")}</h6>
          <p>{t("register.success.description")}</p>
        </StatusBlock>
      ) : (
        <FormLayout>
          <S.StatusWrapper>
            <StatusBlock status={StatusFlavour.Info}>
              {t("register.contract")}
            </StatusBlock>
          </S.StatusWrapper>
          <h4>{t("register.userInfo")}</h4>
          <Flex mx={[0, 0, -1, -1]}>
            <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
              <FormAdapter
                name="name"
                label={t("register.name.label")}
                formControlProps={{ isRequired: true }}
              >
                {props => (
                  <FormInput
                    {...props}
                    placeholder={t("register.name.placeholder")}
                    startAdornment={<IconUser size={18} />}
                    disabled={isRegisteringUser}
                  />
                )}
              </FormAdapter>
            </Box>
            <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
              <FormAdapter
                name="email"
                label={t("register.email.label")}
                formControlProps={{ isRequired: true }}
              >
                {props => (
                  <FormInput
                    {...props}
                    autoComplete="username"
                    placeholder={t("register.email.placeholder")}
                    startAdornment={<IconMail size={18} />}
                    disabled={isRegisteringUser}
                  />
                )}
              </FormAdapter>
            </Box>
            <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
              <FormAdapter
                name="password"
                label={t("register.password.label")}
                formControlProps={{ isRequired: true }}
              >
                {props => (
                  <FormInput
                    {...props}
                    type="password"
                    autoComplete="new-password"
                    placeholder={t("register.password.placeholder")}
                    startAdornment={<IconLock size={18} />}
                    disabled={isRegisteringUser}
                  />
                )}
              </FormAdapter>
            </Box>
            <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
              <FormAdapter
                name="confirmPassword"
                label={t("register.confirmPassword.label")}
                formControlProps={{ isRequired: true }}
              >
                {props => (
                  <FormInput
                    {...props}
                    type="password"
                    autoComplete="new-password"
                    placeholder={t("register.confirmPassword.placeholder")}
                    startAdornment={<IconLock size={18} />}
                    disabled={isRegisteringUser}
                  />
                )}
              </FormAdapter>
            </Box>
          </Flex>
          <S.Separator />
          <h4>{t("register.companyInfo")}</h4>
          <Flex mx={[0, 0, -1]}>
            <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
              <FormAdapter
                name="companyName"
                label={t("register.companyName.label")}
                formControlProps={{ isRequired: true }}
              >
                {props => (
                  <FormInput
                    {...props}
                    placeholder={t("register.companyName.placeholder")}
                    disabled={isRegisteringUser}
                  />
                )}
              </FormAdapter>
            </Box>
            <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
              <FormAdapter
                name="companyAddress"
                label={t("register.companyAddress.label")}
                formControlProps={{ isRequired: true }}
              >
                {props => (
                  <FormInput
                    {...props}
                    placeholder={t("register.companyAddress.placeholder")}
                    disabled={isRegisteringUser}
                  />
                )}
              </FormAdapter>
            </Box>
            <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
              <FormAdapter
                name="uicNumber"
                label={t("register.uicNumber.label")}
                formControlProps={{ isRequired: true }}
              >
                {props => (
                  <FormInput
                    {...props}
                    placeholder={t("register.uicNumber.placeholder")}
                    disabled={isRegisteringUser}
                  />
                )}
              </FormAdapter>
            </Box>
            <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
              <FormAdapter
                name="companyLicense"
                label={t("register.companyLicense.label")}
                formControlProps={{ isRequired: true }}
              >
                {props => (
                  <FormInput
                    {...props}
                    placeholder={t("register.companyLicense.placeholder")}
                    disabled={isRegisteringUser}
                  />
                )}
              </FormAdapter>
            </Box>
          </Flex>
          <S.Separator />
          <h4>{t("register.contactInfo")}</h4>
          <Flex mx={[0, 0, -1]}>
            <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
              <FormAdapter
                name="companyManager"
                label={t("register.companyManager.label")}
                formControlProps={{ isRequired: true }}
              >
                {props => (
                  <FormInput
                    {...props}
                    placeholder={t("register.companyManager.placeholder")}
                    disabled={isRegisteringUser}
                  />
                )}
              </FormAdapter>
            </Box>
            <Box width={[1, 1, 1 / 2]} px={[0, 0, 1]} py={1}>
              <FormAdapter
                name="countryCode"
                label={t("register.countryCode.label")}
                formControlProps={{ isRequired: true }}
              >
                {props => (
                  <FormSelect
                    {...props}
                    placeholder={t("register.countryCode.placeholder")}
                    options={countryOptions}
                    disabled={isRegisteringUser}
                  />
                )}
              </FormAdapter>
            </Box>
            <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
              <FormAdapter
                name="companyEmail"
                label={t("register.companyEmail.label")}
                formControlProps={{ isRequired: true }}
              >
                {props => (
                  <FormInput
                    {...props}
                    placeholder={t("register.companyEmail.placeholder")}
                    disabled={isRegisteringUser}
                  />
                )}
              </FormAdapter>
            </Box>
            <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
              <FormAdapter
                name="companyPhone"
                label={t("register.companyPhone.label")}
                formControlProps={{ isRequired: true }}
              >
                {props => (
                  <FormInput
                    {...props}
                    placeholder={t("register.companyPhone.placeholder")}
                    disabled={isRegisteringUser}
                  />
                )}
              </FormAdapter>
            </Box>
          </Flex>
          <S.LegalTerms>
            <StatusText status={StatusFlavour.Info}>
              <Trans i18nKey="register.terms">
                By pressing the Register button you agree to the{" "}
                <LinkUnderline
                  to="/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy policy
                </LinkUnderline>{" "}
                and the{" "}
                <LinkUnderline
                  to="/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms and conditions
                </LinkUnderline>{" "}
                to use our website.
              </Trans>
            </StatusText>
          </S.LegalTerms>
          <FormContext<FormValues>
            render={({ handleSubmit }) => (
              <Button
                type="submit"
                variant="secondary"
                isLoading={isRegisteringUser}
                onClick={handleSubmit(onSubmit)}
              >
                {t("register.submit")}
              </Button>
            )}
          />
        </FormLayout>
      )}
    </Form>
  );
};

export default RegisterForm;
