import { AdminAreaLocationInList } from "@hotelspoint/api";
import {
  ButtonDelete,
  ButtonEdit,
  ConfirmDialog,
  StatusFlavour,
  TABLE_COLUMN_SIZE,
  TableCell,
  TableCellEmpty,
} from "@hotelspoint/components";
import { createColumnHelper } from "@tanstack/react-table";
import { TFunction } from "i18next";
import { Link } from "react-router-dom";

const columnHelper = createColumnHelper<AdminAreaLocationInList>();

interface GetColumnsParams {
  t: TFunction;
  targetId: number | null;
  handleDelete: (id: number) => void;
  isDeleting: boolean;
}

const getColumns = ({
  t,
  targetId,
  handleDelete,
  isDeleting,
}: GetColumnsParams) => [
  columnHelper.accessor("id", {
    header: t("adminAreaLocations.columns.id"),
    size: TABLE_COLUMN_SIZE.ACTION,
  }),
  columnHelper.accessor("name", {
    header: t("adminAreaLocations.columns.name"),
    size: TABLE_COLUMN_SIZE.NAME,
  }),
  columnHelper.accessor("destinations", {
    header: t("adminAreaLocations.columns.destinations"),
    size: TABLE_COLUMN_SIZE.NAME,
  }),
  columnHelper.accessor("cities", {
    header: t("adminAreaLocations.columns.cities"),
    size: TABLE_COLUMN_SIZE.NAME,
    cell: props => props.getValue() || <TableCellEmpty />,
  }),
  columnHelper.display({
    id: "actions",
    size: TABLE_COLUMN_SIZE.ACTION,
    cell: props => {
      const id = props.row.original.id;
      const isTarget = id === targetId;

      return (
        <TableCell>
          <Link to={`/admin/locations/areas/${id}`}>
            <ButtonEdit />
          </Link>
          <ConfirmDialog
            title={t("adminAreaLocations.columns.actions.delete.modal.title")}
            description={t(
              "adminAreaLocations.columns.actions.delete.modal.description",
            )}
            onConfirm={() => handleDelete(id)}
            isLoading={isTarget && isDeleting}
            status={StatusFlavour.Error}
          >
            <ButtonDelete />
          </ConfirmDialog>
        </TableCell>
      );
    },
  }),
];

export default getColumns;
