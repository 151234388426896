import {
  ApiResponseSuccessResult,
  ApiResponseSuccessResultList,
  HotelRate,
  PaginationOptions,
  ReservationType,
} from "@hotelspoint/types";

import FetchService from "./base/FetchService";
import { PaymentStatus } from "./PaymentService";
import {
  TransferOutbound,
  TransferReturn,
  TransferTripType,
} from "./TransferService";

const RESERVATION_API_PATH = "reservations";

export enum ReservationStatus {
  CreatedReservation = 1,
  PendingConfirmation = 2,
  ConfirmedReservation = 4,
  PendingCancellation = 5,
  CancelledReservation = 6,
  FailedConfirmation = 9,
  CancelledWithFee = 10,
}

export enum ReservationVoucherStatus {
  Pending = 1,
  Ready = 2,
}

export enum ReservationHotelConfirmationStatus {
  NotSend = 1,
  EmailNotFound = 2,
  EmailSend = 3,
  EmailResend = 4,
  EmailReceived = 5,
  EmailNotReceived = 6,
  CallRequired = 7,
  Escalation1 = 8,
  Escalation2 = 9,
  Confirmed = 10,
}

export enum ReservationSupplierConfirmationStatus {
  NotSend = 1,
  EmailSend = 2,
  EmailReceived = 3,
  EmailNotReceived = 4,
  Escalation1 = 5,
  Escalation2 = 6,
  Confirmed = 7,
  Verified = 8,
  NotVerified = 9,
}

export enum ReservationTransferConfirmationStatus {
  NotConfirmed = 1,
  ConfirmedWithNumber = 2,
  ConfirmedWithoutNumber = 3,
  NoFlightTracking = 4,
}

export interface ReservationNames {
  title: string;
  name: string;
  surname: string;
  age: number;
}

export interface ReservationFlights {
  arrival: {
    date: Date;
    number: string;
  };
  return: {
    date: Date;
    number: string;
  };
}

export interface ReservationCreateNames {
  roomId: number;
  adults: ReservationNames[];
  children: ReservationNames[];
}

export interface ReservationCreatePayload {
  type: ReservationType;
  rateId: number;
  hotelId: number;
  searchId: number;
  names: ReservationCreateNames[];
}

export interface ReservationCreateResponse {
  id: number;
  status: ReservationStatus;
}

export interface ReservationItemData extends Omit<HotelRate, "supplierId"> {
  names: ReservationCreateNames[];
  flights: ReservationFlights;
}

export interface Reservation {
  id: number;
  itemId: number | null;
  itemType: ReservationType;
  itemData: ReservationItemData;
  userId: number;
  userName: string;
  agencyId: number;
  agencyName: string;
  confirmationId: string | null;
  clientName: string;
  cityId: number;
  cityName: string;
  status: ReservationStatus;
  paymentStatus: PaymentStatus;
  commissionAmount: number | null;
  commissionStatus: any | null;
  voucherUrl: string | null;
  voucherCode: string | null;
  voucherStatus: ReservationVoucherStatus | null;
  confirmationAssigneeId: number;
  confirmationHotelStatus: ReservationHotelConfirmationStatus | null;
  confirmationSupplierStatus: ReservationSupplierConfirmationStatus | null;
  confirmationOutboundTransferStatus: ReservationTransferConfirmationStatus | null;
  confirmationReturnTransferStatus: ReservationTransferConfirmationStatus | null;
  dateFrom: string;
  dateTo: string;
  dateDeadline: string;
  dateCancelled: string | null;
  dateCreated: string;
  invoiceId: string | boolean;
}

export interface ReservationInList extends Omit<Reservation, "itemData"> {
  countMessages: number;
  countNewMessages: number;
  countAlertMessages: number;
  itemData: {
    offerId: number;
    pickUp: string;
    dropOff: string;
    price: number;
    priceNet: number;
    priceBase: number;
    supplierId: number;
    name: string;
    type: any; // @todo
    image: string;
    vehicle: string;
    deadline: string;
    adults: number;
    children: number[];
    names: {
      adults: ReservationNames[];
      children: Omit<ReservationNames, "title">[];
    };
    search: {
      pickUp: string;
      dropOff: string;
      typeId: number; // @todo
      outboundDate: string;
      returnDate: string;
      adults: number;
      children: number;
      force: number; // @todo
      pickUpId: number;
      pickUpType: number; // @todo
      pickUpName: string;
      droppOffId: number;
      dropOffType: number; // @todo
      dropOffName: string;
    };
    flights: ReservationFlights;
    namesType: number; // @todo
    phoneCode: string;
    phoneNumber: string;
    rateId: number;
    minPassengers: number;
    maxPassengers: number;
    tripType: TransferTripType;
    outboundTransfer: TransferOutbound;
    returnTransfer: TransferReturn;
  };
}

export enum ReservationMessageFilter {
  All = 1,
  New = 2,
  Pending = 3,
  Progress = 4,
  Completed = 5,
  Alerts = 6,
}

export enum ReservationOrderBy {
  BookingDate = 1,
  DeadlineDate = 2,
  CheckInDate = 3,
  CancelledDate = 4,
}

export enum Titles {
  Mr = 1,
  Mrs = 2,
}

export interface ReservationListParams extends PaginationOptions {
  id?: number;
  dateFrom?: string;
  dateTo?: string;
  status?: ReservationStatus;
  deadlineFrom?: string;
  deadlineTo?: string;
  agencyId?: number;
  userId?: number;
  supplierId?: number;
  cityName?: string;
  checkInFrom?: string;
  checkInTo?: string;
  name?: string;
  checkOutFrom?: string;
  checkOutTo?: string;
  priceFrom?: number;
  priceTo?: number;
  paymentStatus?: PaymentStatus;
  messageFilter?: ReservationMessageFilter;
  type?: ReservationType;
  confirmed?: boolean;
  orderBy?: ReservationOrderBy;
  assignee?: string;
  hotelConfirmationStatus?: ReservationHotelConfirmationStatus;
  supplierConfirmationStatus?: ReservationSupplierConfirmationStatus;
  confirmationOutboundTransferStatus?: ReservationTransferConfirmationStatus;
  confirmationReturnTransferStatus?: ReservationTransferConfirmationStatus;
}

export type ReservationListResponse =
  ApiResponseSuccessResultList<ReservationInList>;

export interface ReservationUpdateAssigneePayload {
  assigneeId: number;
}

export interface ReservationSendVoucherPayload {
  email: string;
}

const ReservationService = {
  create: (payload: ReservationCreatePayload) => {
    return FetchService.post<ReservationCreateResponse>(
      RESERVATION_API_PATH,
      payload,
    );
  },
  get: (id: number) => {
    return FetchService.get<Reservation>(`${RESERVATION_API_PATH}/${id}`);
  },
  cancel: (id: number) => {
    return FetchService.post<Reservation>(
      `${RESERVATION_API_PATH}/${id}/cancel`,
    );
  },
  getList: (params?: ReservationListParams) => {
    return FetchService.get<ReservationListResponse>(
      RESERVATION_API_PATH,
      params,
    );
  },
  updateAssignee: (id: number, payload: ReservationUpdateAssigneePayload) => {
    return FetchService.patch<ReservationListResponse>(
      `${RESERVATION_API_PATH}/${id}/updateAssignee`,
      payload,
    );
  },
  sendVoucher: (id: number, payload: ReservationSendVoucherPayload) => {
    return FetchService.post<ApiResponseSuccessResult>(
      `${RESERVATION_API_PATH}/${id}/vouchers/send`,
      payload,
    );
  },
};

export default ReservationService;
