import { borderRadius, mediaQuery, spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";
import { ifProp, switchProp } from "styled-tools";

export const Wrapper = styled.div<{ $height?: number }>`
  > *:not(:first-child) {
    display: none;
  }
  @media ${mediaQuery.mobileL} {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(
      2,
      ${props => (props.$height ? `${props.$height}px` : "1fr")}
    );
    grid-gap: ${spacing(1)}px;

    > *:not(:first-child) {
      display: block;
    }
  }
`;

export const Tile = styled.div<{ $count: number }>`
  height: 250px;
  @media ${mediaQuery.mobileL} {
    height: auto;
    &:nth-child(1) {
      grid-column: ${switchProp(
        "$count",
        {
          1: "span 6",
          2: "span 3",
          4: "span 2",
        },
        "span 4",
      )};
      grid-row: span 2;
    }
    &:nth-child(2) {
      grid-column: ${ifProp({ $count: 2 }, "span 3", "span 2")};
      grid-row: ${ifProp({ $count: 2 }, "span 2", "auto")};
    }
    &:nth-child(3) {
      grid-column: span 2;
      grid-row: auto;
    }

    &:nth-child(4) {
      grid-column: ${ifProp({ $count: 4 }, "span 4", "span 3")};
      grid-row: auto;
    }

    &:nth-child(5) {
      grid-column: span 3;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: ${borderRadius.medium}px;
    &:hover {
      cursor: pointer;
    }
  }
`;
