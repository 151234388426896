import {
  ApiResponseSuccessResult,
  ApiResponseSuccessResultList,
  OfferCurrent,
  OfferItem,
  PaginationOptions,
  ReservationType,
} from "@hotelspoint/types";

import FetchService from "./base/FetchService";

const OFFER_API_PATH = "offers";

export interface OfferPrices {
  // key = offerItemId
  [key: string]: {
    // key = roomId
    [key: string]: number;
  };
}

export interface OfferItemShort {
  itemId: number;
  itemType: ReservationType;
  searchId: number;
  rateId: number;
}

export interface Offer {
  id: number;
  name: string;
  userId: number;
  agencyId: number;
  agencyName: string;
  clientName: string;
  dateUpdated: string;
  dateCreated: string;
  items: OfferItem[];
}

export interface OfferListParams extends PaginationOptions {
  id?: number;
  name?: string;
  dateFrom?: string;
  dateTo?: string;
  clientName?: string;
  agencyId?: number;
}

export type OfferInList = Omit<Offer, "items">;

export type OfferListResponse = ApiResponseSuccessResultList<OfferInList>;

export interface OfferCreatePayload {
  name: string;
  client: string;
  prices: OfferPrices;
}

export interface OfferUpdatePayload {
  name: string;
  client: string;
  prices: OfferPrices;
}

export interface OfferCurrentResponse extends OfferCurrent {}

const OfferService = {
  getList: (params?: OfferListParams) => {
    return FetchService.get<OfferListResponse>(OFFER_API_PATH, params);
  },
  create: (payload: OfferCreatePayload) => {
    return FetchService.post<Offer>(OFFER_API_PATH, payload);
  },
  get: (id: number) => {
    return FetchService.get<Offer>(`${OFFER_API_PATH}/${id}`);
  },
  update: (id: number, payload: OfferUpdatePayload) => {
    return FetchService.put<Offer>(`${OFFER_API_PATH}/${id}`, payload);
  },
  delete: (id: number) => {
    return FetchService.delete<ApiResponseSuccessResult>(
      `${OFFER_API_PATH}/${id}`,
    );
  },
  deleteItem: (id: number, offerItemId: number) => {
    return FetchService.delete<ApiResponseSuccessResult>(
      `${OFFER_API_PATH}/${id}/items/${offerItemId}`,
    );
  },
  // Endpoints for current (draft) offer
  getCurrent: () => {
    return FetchService.get<OfferCurrentResponse>(`${OFFER_API_PATH}/current`);
  },
  addItem: (payload: OfferItemShort) => {
    return FetchService.put<ApiResponseSuccessResult>(
      `${OFFER_API_PATH}/current`,
      payload,
    );
  },
};

export default OfferService;
