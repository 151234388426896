import { createImagePreview, type FileWithPreview } from "@hotelspoint/utils";
import { IconUserFilled } from "@tabler/icons-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";

import Button from "../../elements/Button";
import { type FormControlInjectedProps } from "../FormControl";
import * as S from "./FormAvatarUpload.styled";

interface FormAvatarUploadProps
  extends Omit<FormControlInjectedProps, "value"> {
  value: File | null;
  isLoading?: boolean;
  isUploading?: boolean;
  isDeleting?: boolean;
}

const FormAvatarUpload = ({
  value,
  onChange,
  isLoading = false,
  isUploading = false,
  isDeleting = false,
}: FormAvatarUploadProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [imagePreview, setImagePreview] = useState<FileWithPreview | null>(
    null,
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    multiple: false,
    onDrop: files => {
      const [file] = files;
      onChange(file);
    },
  });

  const handleClick = useCallback(() => {
    if (value) {
      onChange(null);
    } else {
      open();
    }
  }, [value, onChange, open]);

  const actionText = useMemo(() => {
    if (isLoading) return t("components.formAvatarUpload.loading");
    if (isUploading) return t("components.formAvatarUpload.uploading");
    if (isDeleting) return t("components.formAvatarUpload.deleting");

    return value
      ? t("components.formAvatarUpload.remove")
      : t("components.formAvatarUpload.upload");
  }, [t, value, isLoading, isUploading, isDeleting]);

  // Set the preview when the value changes
  useEffect(() => {
    if (value) {
      const imagePreview = createImagePreview(value);

      setImagePreview(imagePreview);
    } else {
      setImagePreview(null);
    }
  }, [value]);

  return (
    <S.Wrapper>
      <S.InnerWrapper type="button" {...getRootProps()}>
        <input {...getInputProps()} />
        {imagePreview && !isDeleting ? (
          <img src={imagePreview.src} alt="Avatar picture" />
        ) : (
          <IconUserFilled
            size={124}
            style={{ color: theme.palette.subMarine }}
          />
        )}
      </S.InnerWrapper>
      <S.Actions>
        <Button
          small
          variant={value ? "error" : "primary"}
          isLoading={isLoading || isUploading || isDeleting}
          onClick={handleClick}
        >
          {actionText}
        </Button>
      </S.Actions>
    </S.Wrapper>
  );
};

export default FormAvatarUpload;
