import { useMutation, useQueryClient } from "@tanstack/react-query";

import {
  AdminAgencyStatementCreatePayload,
  AdminService,
} from "../../../../services";

const useCreateAdminAgencyStatement = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: ["admin", "statements_agency", "create"],
    mutationFn: (payload: AdminAgencyStatementCreatePayload) => {
      return AdminService.createAgencyStatement(payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["admin", "statements_agency"],
      });
    },
  });

  const { mutateAsync, isPending } = mutation;
  const ret: [typeof mutateAsync, typeof isPending] = [mutateAsync, isPending];

  return ret;
};

export default useCreateAdminAgencyStatement;
