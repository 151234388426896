import { useAdminCommissionItems } from "@hotelspoint/api";
import {
  Pagination,
  Table,
  TABLE_PAGE_SIZE_DEFAULT,
  TableFooter,
  TableLayout,
  TablePageSize,
  TableResultsCount,
} from "@hotelspoint/components";
import { useDocumentTitle } from "@hotelspoint/utils";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import getColumns from "./AdminCommissionItems.columns";

interface AdminCommissionItemsProps {
  id: number;
}

const AdminCommissionItems = ({ id }: AdminCommissionItemsProps) => {
  const { t } = useTranslation();
  useDocumentTitle(t("adminCommissions.pageTitle"));

  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(TABLE_PAGE_SIZE_DEFAULT);

  const [commissionItems, isLoading] = useAdminCommissionItems(id, {
    pageIndex,
    pageSize,
  });

  const data = useMemo(() => commissionItems?.results ?? [], [commissionItems]);

  const columns = useMemo(() => {
    return getColumns({ t });
  }, [t]);

  return (
    <TableLayout>
      <Table
        data={data}
        columns={columns}
        isLoading={isLoading}
        useReactTableProps={{
          state: {
            pagination: {
              pageIndex,
              pageSize: TABLE_PAGE_SIZE_DEFAULT,
            },
            columnPinning: {
              left: ["reservationId"],
              right: ["actions"],
            },
          },
          manualPagination: true,
        }}
      />
      <TableFooter>
        <div>
          <TableResultsCount value={commissionItems?.count} />
        </div>
        <div>
          <Pagination
            pageIndex={pageIndex}
            pageSize={pageSize}
            setPageIndex={setPageIndex}
            totalCount={commissionItems?.count ?? 0}
          />
        </div>
        <TablePageSize value={pageSize} onChange={setPageSize} />
      </TableFooter>
    </TableLayout>
  );
};

export default AdminCommissionItems;
