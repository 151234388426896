import { useUserCurrentOfferStore } from "@hotelspoint/store";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { OfferService } from "../../services";
import useFetchCurrentOffer from "./useFetchCurrentOffer";

const useDeleteOfferItem = (id: number) => {
  const queryClient = useQueryClient();
  const [fetchCurrentOffer] = useFetchCurrentOffer();
  const { set: setCurrentOffer, offer: currentOffer } =
    useUserCurrentOfferStore(state => state);

  const mutation = useMutation({
    mutationKey: ["offers", "delete_rate"],
    mutationFn: (rateId: number) => {
      return OfferService.deleteItem(id, rateId);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["offers", id],
      });

      if (currentOffer?.id === id) {
        const offer = await fetchCurrentOffer();
        setCurrentOffer(offer);
      }
    },
  });

  const { mutateAsync, isPending } = mutation;
  const ret: [typeof mutateAsync, typeof isPending] = [mutateAsync, isPending];

  return ret;
};

export default useDeleteOfferItem;
