import { useAdminCommissions } from "@hotelspoint/api";
import {
  Pagination,
  Panel,
  Table,
  TABLE_PAGE_SIZE_DEFAULT,
  TableFooter,
  TableLayout,
  TablePageSize,
  TableResultsCount,
} from "@hotelspoint/components";
import { pickFilterValues, useDocumentTitle } from "@hotelspoint/utils";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import getColumns from "./AdminCommissions.columns";
import AdminCommissionsFilters from "./AdminCommissionsFilters";
import useAdminCommissionsParams from "./useAdminCommissionsParams";

const AdminCommissions = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("adminCommissions.pageTitle"));

  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(TABLE_PAGE_SIZE_DEFAULT);
  const [query] = useAdminCommissionsParams();
  const filters = useMemo(() => pickFilterValues(query), [query]);

  const [commissions, isLoading] = useAdminCommissions({
    pageIndex,
    pageSize,
    ...filters,
  });

  const data = useMemo(() => commissions?.results ?? [], [commissions]);

  const columns = useMemo(() => {
    return getColumns({ t });
  }, [t]);

  const stringifiedFilters = useMemo(() => JSON.stringify(filters), [filters]);

  useEffect(() => {
    setPageIndex(1);
  }, [pageSize, stringifiedFilters]);

  return (
    <Panel title={t("adminCommissions.pageTitle")}>
      <AdminCommissionsFilters isLoading={isLoading} />
      <TableLayout>
        <Table
          data={data}
          columns={columns}
          isLoading={isLoading}
          useReactTableProps={{
            state: {
              pagination: {
                pageIndex,
                pageSize: TABLE_PAGE_SIZE_DEFAULT,
              },
              columnPinning: {
                left: ["id"],
                right: ["actions"],
              },
            },
            manualPagination: true,
          }}
        />
        <TableFooter>
          <div>
            <TableResultsCount value={commissions?.count} />
          </div>
          <div>
            <Pagination
              pageIndex={pageIndex}
              pageSize={pageSize}
              setPageIndex={setPageIndex}
              totalCount={commissions?.count ?? 0}
            />
          </div>
          <TablePageSize value={pageSize} onChange={setPageSize} />
        </TableFooter>
      </TableLayout>
    </Panel>
  );
};

export default AdminCommissions;
