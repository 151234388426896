import {
  Breadcrumb,
  BreadcrumbContainer,
  NavTab,
  NavTabs,
  Panel,
} from "@hotelspoint/components";
import { useUserProfileStore } from "@hotelspoint/store";
import { UserRole } from "@hotelspoint/types";
import { IconUser, IconUserCog } from "@tabler/icons-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

import * as S from "./ProfileSettings.styled";

const ProfileSettings = () => {
  const { t } = useTranslation();

  const profile = useUserProfileStore(state => state.profile);
  const userIsAgencyMember = profile?.roleId === UserRole.AgencyMember;

  const breadcrumbs = useMemo(
    () => [
      {
        title: t("profile.pageTitle"),
        href: "/profile",
        icon: IconUser,
      },
      {
        title: t("profileSettings.pageTitle"),
        icon: IconUserCog,
      },
    ],
    [t],
  );

  return (
    <>
      <BreadcrumbContainer>
        <Breadcrumb items={breadcrumbs} />
      </BreadcrumbContainer>
      <Panel title={t("profileSettings.pageTitle")}>
        {!userIsAgencyMember && (
          <S.TabsWrapper>
            <NavTabs>
              <NavTab to="/profile/settings/agent">
                {t("profileAgentSettings.pageTitle")}
              </NavTab>
              <NavTab to="/profile/settings/agency">
                {t("profileAgencySettings.pageTitle")}
              </NavTab>
            </NavTabs>
          </S.TabsWrapper>
        )}
        <Outlet />
      </Panel>
    </>
  );
};

export default ProfileSettings;
