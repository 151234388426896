import { USD_BGN_RATE } from "@hotelspoint/constants";
import { InferType, number, object } from "yup";

export const validationSchema = object({
  from: number(),
  to: number(),
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: FormValues = {
  from: USD_BGN_RATE,
  to: 0,
};
