import {
  borderRadius,
  mediaQuery,
  pxToRem,
  spacing,
  timing,
  zIndex,
} from "@hotelspoint/theme";
import styled from "styled-components";
import { ifNotProp, ifProp, theme } from "styled-tools";

import { NAV_LINK_ACTIVE_CLASSNAME } from "../../misc/NavLink";

export const Wrapper = styled.header`
  z-index: ${zIndex.header};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: ${theme("custom.header.heightS")}px;
  background: ${theme("header.bg")};
  color: white;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.6);

  @media ${mediaQuery.tablet} {
    height: ${theme("custom.header.heightL")}px;
  }
`;

export const InnerWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SideContent = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const SideMenus = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${spacing(2)}px;
  height: 100%;
`;

export const LogoSmall = styled.div`
  img {
    height: 28px;
    vertical-align: middle;
  }

  @media ${mediaQuery.mobileL} {
    display: none;
  }
`;

export const LogoLarge = styled.div`
  display: none;

  @media ${mediaQuery.mobileL} {
    display: block;

    img {
      vertical-align: middle;
      height: 24px;
    }
  }

  @media ${mediaQuery.tablet} {
    img {
      height: 26px;
    }
  }
`;

export const Nav = styled.nav`
  height: 100%;
  margin-left: ${spacing(4)}px;

  @media ${mediaQuery.desktop} {
    margin-left: ${spacing(6)}px;
  }
`;

export const NavHeading = styled.h6`
  color: ${theme("palette.waikawaGray")};
  text-transform: uppercase;
  font-weight: 600;
  font-size: ${pxToRem(14)};
  padding: 0 ${spacing(1)}px;
  margin-bottom: ${spacing(1.5)}px;
`;

export const NavSeparator = styled.hr`
  margin: ${spacing(2)}px 0 ${spacing(3)}px;
`;

export const NavItems = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  row-gap: ${spacing(1)}px;

  @media ${mediaQuery.tablet} {
    height: 100%;
    flex-direction: row;
    row-gap: 0;
    column-gap: ${spacing(5)}px;
  }
`;

export const NavItem = styled.li<{ $isDisabled?: boolean }>`
  cursor: ${ifNotProp("$isDisabled", "pointer")};
  position: relative;
  color: ${theme("body.text")};
  pointer-events: ${ifProp("$isDisabled", "none")};

  a,
  button {
    display: flex;
    width: 100%;
    padding: ${spacing(1)}px;
    color: ${ifProp("$isDisabled", theme("disabled.text"), theme("body.text"))};
    font-size: ${pxToRem(17)};
    column-gap: ${spacing(1.5)}px;
    align-items: center;
    justify-content: space-between;

    svg {
      color: ${ifProp(
        "$isDisabled",
        theme("disabled.text"),
        theme("palette.royalBlue"),
      )};
    }

    &:hover {
      color: ${theme("body.text")};
      background: rgba(0, 0, 0, 0.04);
      border-radius: ${borderRadius.normal}px;
    }

    &.${NAV_LINK_ACTIVE_CLASSNAME} {
      cursor: default;
      pointer-events: none;
      background: rgba(0, 0, 0, 0.08);
      border-radius: ${borderRadius.normal}px;
    }
  }

  @media ${mediaQuery.tablet} {
    display: flex;
    align-items: center;
    font-size: ${pxToRem(19)};
    padding: 0 ${spacing(1)}px;
    justify-content: normal;

    a,
    button {
      width: auto;
      padding: 0;
      color: ${ifProp("$isDisabled", "rgba(255, 255, 255, 0.3)", "white")};
      font-size: ${pxToRem(18)};

      &:hover {
        color: white;
        background: transparent;
        border-radius: 0;

        &::before {
          opacity: 1;
        }
      }

      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 5px;
        background: ${theme("secondary.bg")};
        opacity: 0;
        transition: opacity ${timing.fast}ms;
      }

      &.${NAV_LINK_ACTIVE_CLASSNAME} {
        background: transparent;
        border-radius: 0;

        &::before {
          opacity: ${ifNotProp("$isDisabled", 1)};
        }
      }
    }
  }
`;

export const NavItemHeader = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${spacing(1)}px;
`;

export const Stats = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${spacing(1.5)}px;
`;

export const UserMenus = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const NavOverlay = styled.div<{ $isOpen: boolean }>`
  z-index: ${zIndex.navigationOverlay};
  position: fixed;
  top: ${theme("custom.header.heightS")}px;
  bottom: 0;
  left: 0;
  right: 0;
  left: ${ifProp("$isOpen", 0, "100%")};
  width: 100%;
  height: 100%;
  transition: all ease ${timing.normal}ms;
`;

export const NavOverlayHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 65px;
  background: ${theme("palette.royalBlue")};
  padding: 0 ${spacing(1.5)}px;
`;

export const NavOverlayMenu = styled.div`
  height: calc(100% - 65px);
  background: white;
  padding: ${spacing(3)}px ${spacing(1.5)}px;
`;

export const NavSide = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${spacing(1)}px;
`;
