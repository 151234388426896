import Loader from "../../elements/Loader";
import Overlay from "../../elements/Overlay";

const LoaderOverlay = () => (
  <Overlay>
    <Loader />
  </Overlay>
);

export default LoaderOverlay;
