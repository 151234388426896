import {
  Panel,
  Price,
  StatusBlock,
  StatusFlavour,
} from "@hotelspoint/components";
import { HotelRoomRate } from "@hotelspoint/types";
import { useTranslation } from "react-i18next";

import * as S from "./ReservationRoomsTable.styled";

interface ReservationRoomsTableProps {
  rooms: HotelRoomRate[];
}

const ReservationRoomsTable = ({ rooms }: ReservationRoomsTableProps) => {
  const { t } = useTranslation();

  return (
    <Panel title={t("profileReservation.rooms")}>
      <div>
        {rooms.map((room, roomKey) => (
          <S.TableRow key={roomKey}>
            <S.RoomNameCell>
              <S.Title>{t("profileReservation.table.roomName.label")}</S.Title>
              <S.RoomName>{room.roomName}</S.RoomName>
            </S.RoomNameCell>
            <S.RoomPersonsCell>
              <S.Title>{t("profileReservation.table.persons.label")}</S.Title>
              <p>{room.adults}</p>
            </S.RoomPersonsCell>
            <S.RoomPromotionsCell>
              <S.Title>
                {t("profileReservation.table.promotions.label")}
              </S.Title>
              {Array.isArray(room.promotions) ? (
                <S.StatusBlockWrapper>
                  {room.promotions.map((promotion, promotionIndex) => (
                    <StatusBlock
                      key={promotionIndex}
                      status={StatusFlavour.Info}
                    >
                      <S.StatusBlockHeader>
                        {t("searchHotelsBook.content.promoOffer")}
                      </S.StatusBlockHeader>
                      <S.StatusBlockCopy>
                        {promotion.name}
                        {promotion.amount && (
                          <>
                            : <Price value={promotion.amount} />
                          </>
                        )}
                      </S.StatusBlockCopy>
                    </StatusBlock>
                  ))}
                </S.StatusBlockWrapper>
              ) : (
                <p>-</p>
              )}
            </S.RoomPromotionsCell>
            <S.RoomPriceCell>
              <S.Title>{t("profileReservation.table.price.label")}</S.Title>
              <S.Price>
                <Price value={room.price} />
              </S.Price>
            </S.RoomPriceCell>
          </S.TableRow>
        ))}
      </div>
    </Panel>
  );
};

export default ReservationRoomsTable;
