import { SkeletonTheme } from "react-loading-skeleton";
import {
  StyleSheetManager,
  ThemeProvider as StyledThemeProvider,
} from "styled-components";

import shouldForwardProp from "./shouldForwardProp";
import theme from "./theme";

interface ThemeProviderProps {
  children: React.ReactNode;
}

const ThemeProvider = ({ children }: ThemeProviderProps) => (
  <StyleSheetManager shouldForwardProp={shouldForwardProp}>
    <StyledThemeProvider theme={theme}>
      <SkeletonTheme>{children}</SkeletonTheme>
    </StyledThemeProvider>
  </StyleSheetManager>
);

export default ThemeProvider;
