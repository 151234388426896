import { handleError, useDeleteOfferItem } from "@hotelspoint/api";
import {
  ButtonDelete,
  ConfirmDialog,
  StatusFlavour,
} from "@hotelspoint/components";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

interface ProfileOfferPanelDeleteRateProps {
  id: number;
  offerItemId: number;
}

const ProfileOfferPanelDeleteRate = ({
  id,
  offerItemId,
}: ProfileOfferPanelDeleteRateProps) => {
  const { t } = useTranslation();

  const [deleteItem, isDeletingItem] = useDeleteOfferItem(id);

  const handleDeleteRate = async () => {
    try {
      await deleteItem(offerItemId);
      toast.success(t("toast.profileOffer.offerRateDeleted"));
    } catch (error: any) {
      handleError({ t, error });
    }
  };

  return (
    <ConfirmDialog
      title={t("profileOffer.modal.title")}
      description={t("profileOffer.modal.description")}
      onConfirm={handleDeleteRate}
      isLoading={isDeletingItem}
      status={StatusFlavour.Warning}
    >
      <ButtonDelete small={false} />
    </ConfirmDialog>
  );
};

export default ProfileOfferPanelDeleteRate;
