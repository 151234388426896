import { AdminCityLocationListParams, AdminService } from "@hotelspoint/api";
import { useQuery } from "@tanstack/react-query";

const useAdminCityLocations = (params?: AdminCityLocationListParams) => {
  const { data, isLoading } = useQuery({
    queryKey: ["admin", "locations_city", params],
    queryFn: () => AdminService.getCityLocations(params),
  });

  const ret: [typeof data, boolean] = [data, isLoading];

  return ret;
};

export default useAdminCityLocations;
