import {
  handleError,
  useAgency,
  useApproveAgency,
  useRejectAgency,
} from "@hotelspoint/api";
import { Button, ConfirmDialog, StatusFlavour } from "@hotelspoint/components";
import { AgencyStatus } from "@hotelspoint/types";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

interface AdminAgencyActionsProps {
  id: number;
}

const AdminAgencyActions = ({ id }: AdminAgencyActionsProps) => {
  const { t } = useTranslation();

  const [agency, isLoadingAgency] = useAgency(id);

  const [approveAgency, isApprovingAgency] = useApproveAgency(id);
  const [rejectAgency, isRejectingAgency] = useRejectAgency(id);

  const handleApprove = async () => {
    try {
      await approveAgency();

      toast.success(t("toast.adminAgency.approve"));
    } catch (error: any) {
      handleError({ t, error });
    }
  };

  const handleReject = async () => {
    try {
      await rejectAgency();

      toast.success(t("toast.adminAgency.reject"));
    } catch (error: any) {
      handleError({ t, error });
    }
  };

  if (!agency || isLoadingAgency) return null;

  return (
    <>
      {agency?.status === AgencyStatus.Pending && (
        <>
          <ConfirmDialog
            title={t("adminAgency.actions.approve.modal.title")}
            description={t("adminAgency.actions.approve.modal.description")}
            onConfirm={handleApprove}
            isLoading={isApprovingAgency}
            status={StatusFlavour.Success}
          >
            <Button small variant="success">
              {t("adminAgency.actions.approve.button")}
            </Button>
          </ConfirmDialog>
          <ConfirmDialog
            title={t("adminAgency.actions.reject.modal.title")}
            description={t("adminAgency.actions.reject.modal.description")}
            onConfirm={handleReject}
            isLoading={isRejectingAgency}
            status={StatusFlavour.Error}
          >
            <Button small variant="error">
              {t("adminAgency.actions.reject.button")}
            </Button>
          </ConfirmDialog>
        </>
      )}
    </>
  );
};

export default AdminAgencyActions;
