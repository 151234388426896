import { useQuery } from "@tanstack/react-query";

import { PaymentService } from "../../services";

const usePayment = (id: number) => {
  const { data, isLoading } = useQuery({
    queryKey: ["payments", id],
    queryFn: () => PaymentService.get(id),
    enabled: !!id,
  });

  const ret: [typeof data, boolean] = [data, isLoading];

  return ret;
};

export default usePayment;
