import { useUserCurrentOfferStore } from "@hotelspoint/store";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { OfferCreatePayload, OfferService } from "../../services";

const useCreateOffer = () => {
  const queryClient = useQueryClient();
  const { reset: resetCurrentOffer } = useUserCurrentOfferStore();

  const mutation = useMutation({
    mutationKey: ["offers", "create"],
    mutationFn: (payload: OfferCreatePayload) => {
      return OfferService.create(payload);
    },
    onSuccess: () => {
      resetCurrentOffer();
      queryClient.invalidateQueries({
        queryKey: ["offers"],
      });
    },
  });

  const { mutateAsync, isPending } = mutation;
  const ret: [typeof mutateAsync, typeof isPending] = [mutateAsync, isPending];

  return ret;
};

export default useCreateOffer;
