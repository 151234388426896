import Accordion, { AccordionProps } from "../Accordion/Accordion";

interface AccordionSummaryProps extends AccordionProps {
  summary: React.ReactNode;
}

const AccordionSummary = ({
  head,
  summary,
  children,
}: AccordionSummaryProps) => {
  return (
    <div>
      {summary}
      <Accordion head={head} headPosition="bottom">
        {children}
      </Accordion>
    </div>
  );
};

export default AccordionSummary;
