import { mediaQuery, spacing } from "@hotelspoint/theme";
import styled from "styled-components";
import { theme } from "styled-tools";

export const TitleWrapper = styled.div`
  text-align: center;
  margin-bottom: ${spacing(3)}px;

  @media ${mediaQuery.tablet} {
    text-align: left;
  }
`;

export const Title = styled.h3`
  position: relative;
  display: inline-block;
  text-align: center;

  &:after {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    left: 0;
    bottom: -${spacing(1)}px;
    background: ${theme("body.text")};
  }

  @media ${mediaQuery.tablet} {
    text-align: left;
  }
`;
