import { useMutation, useQueryClient } from "@tanstack/react-query";

import { OfferService } from "../../services";

const useDeleteOffer = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: ["offers", "delete"],
    mutationFn: (id: number) => {
      return OfferService.delete(id);
    },
    onSuccess: (_, id: number) => {
      queryClient.removeQueries({
        queryKey: ["offers", id],
      });

      queryClient.invalidateQueries({
        queryKey: ["offers"],
      });
    },
  });

  const { mutateAsync, isPending } = mutation;
  const ret: [typeof mutateAsync, typeof isPending] = [mutateAsync, isPending];

  return ret;
};

export default useDeleteOffer;
