import { useMutation, useQueryClient } from "@tanstack/react-query";

import { AdminCurrencyUpdatePayload, AdminService } from "../../../services";

const useUpdateAdminCurrencyRate = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: ["admin", "currencies", "update_rate"],
    mutationFn: (vars: { id: number; payload: AdminCurrencyUpdatePayload }) => {
      return AdminService.updateCurrencyRate(vars.id, vars.payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["admin", "currencies"],
      });
    },
  });

  const { mutateAsync, isPending } = mutation;
  const ret: [typeof mutateAsync, typeof isPending] = [mutateAsync, isPending];

  return ret;
};

export default useUpdateAdminCurrencyRate;
