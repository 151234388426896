import DialogContent from "./DialogContent";
import DialogContext from "./DialogContext";
import DialogTrigger from "./DialogTrigger";
import useDialog, { DialogOptions } from "./useDialog";

interface DialogProps extends DialogOptions {
  children: React.ReactNode;
}

const Dialog = ({ children, ...options }: DialogProps) => {
  const dialog = useDialog(options);

  return (
    <DialogContext.Provider value={dialog}>{children}</DialogContext.Provider>
  );
};

Dialog.Trigger = DialogTrigger;
Dialog.Content = DialogContent;

export default Dialog;
