import {
  SCHEMA_PASSWORD_CONFIRM,
  SCHEMA_PASSWORD_STRICT,
} from "@hotelspoint/utils";
import { InferType, object } from "yup";

export const validationSchema = object({
  password: SCHEMA_PASSWORD_STRICT,
  confirmPassword: SCHEMA_PASSWORD_CONFIRM,
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: FormValues = {
  password: "",
  confirmPassword: "",
};
