import { UserProfile } from "@hotelspoint/types";
import { create } from "zustand";

interface UserProfileStore {
  profile: UserProfile | null;
  set: (profile: UserProfile) => void;
  reset: () => void;
}

const useUserProfileStore = create<UserProfileStore>(set => ({
  profile: null,
  set: profile => set({ profile }),
  reset: () => set({ profile: null }),
}));

export default useUserProfileStore;
