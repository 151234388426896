import { isNumeric } from "@hotelspoint/utils";
import { boolean, InferType, object, string } from "yup";

export const validationSchema = object({
  id: string(),
  name: string(),
  mainName: string(),
  isMapped: boolean(),
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: FormValues = {
  id: "",
  name: "",
  mainName: "",
  isMapped: false,
};

export const entity2Form = (query: any): FormValues => {
  return {
    id: query.id,
    name: query.name,
    mainName: query.mainName,
    isMapped: query.isMapped,
  };
};

export const form2Entity = (formValues: FormValues) => {
  return {
    id: isNumeric(formValues.id) ? Number(formValues.id) : undefined,
    name: formValues.name,
    mainName: formValues.mainName,
    isMapped: formValues.isMapped,
  };
};
