import { Carousel, Dialog, Modal } from "@hotelspoint/components";
import { getImagePathFromCDN, ImageType } from "@hotelspoint/utils";
import { useTranslation } from "react-i18next";

interface GalleryOverlayProps {
  isOpen: boolean;
  images: string[];
  initialSlideIndex: number;
  onClose: () => void;
}

const imageStyles = {
  height: 560,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center center",
  backgroundSize: "contain",
  borderRadius: "12px",
};

const GalleryOverlay = ({
  isOpen,
  images,
  initialSlideIndex,
  onClose,
}: GalleryOverlayProps) => {
  const { t } = useTranslation();

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <Dialog.Content>
        <Modal
          title={t("searchHotelResults.hotelDetailsModal.gallery")}
          onClose={onClose}
        >
          <div style={{ maxWidth: 1000, margin: "auto" }}>
            <Carousel initialSlide={initialSlideIndex}>
              {images?.map((img, index) => (
                <div key={index}>
                  <div
                    style={{
                      ...imageStyles,
                      backgroundImage: `url(${getImagePathFromCDN(
                        img,
                        ImageType.Hotel,
                      )})`,
                    }}
                  />
                </div>
              ))}
            </Carousel>
          </div>
        </Modal>
      </Dialog.Content>
    </Dialog>
  );
};

export default GalleryOverlay;
