import { pxToRem, spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";

import Button from "../../elements/Button";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CounterBtn = styled(Button)`
  justify-content: center;
  width: 30px;
  height: 30px;
  max-width: unset;
  padding: 0;
`;

export const DisplayValue = styled.div`
  min-width: 34px;
  text-align: center;
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(14)};
  font-weight: 600;
  padding: 0 ${spacing(1.5)}px;
`;
