import { handleError, useResetPassword } from "@hotelspoint/api";
import {
  Button,
  ButtonText,
  Form,
  FormAdapter,
  FormContext,
  FormInput,
  FormLayout,
  LinkUnderline,
} from "@hotelspoint/components";
import { IconMail } from "@tabler/icons-react";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { FormStage } from "../constants";
import * as S from "./ResetPasswordForm.styled";
import {
  defaultValues,
  type FormValues,
  validationSchema,
} from "./ResetPasswordForm.util";

interface ResetPasswordFormProps {
  setFormStage: (state: FormStage) => void;
}

const ResetPasswordForm = ({ setFormStage }: ResetPasswordFormProps) => {
  const { t } = useTranslation();

  const [isSuccess, setIsSuccess] = useState(false);
  const [resetPassword, isResettingPassword] = useResetPassword();

  const onSubmit = async (formValues: FormValues) => {
    try {
      const response = await resetPassword(formValues);

      if (response.result) {
        setIsSuccess(true);
      } else {
        throw new Error(t("toast.unhandled"));
      }
    } catch (error: any) {
      handleError({ t, error });
    }
  };

  return (
    <Form<FormValues>
      defaultValues={defaultValues}
      validationSchema={validationSchema}
    >
      <FormLayout>
        <S.Title>{t("home.resetPassword.title")}</S.Title>
        {isSuccess ? (
          <>
            <h6>{t("home.resetPassword.success.confirmation")}</h6>
            <p>
              <Trans i18nKey="home.resetPassword.success.assistance">
                If you need assistance, please contact us at the phone number or
                email in the{" "}
                <LinkUnderline to="/about">Contacts section</LinkUnderline>.
              </Trans>
            </p>
          </>
        ) : (
          <>
            <FormAdapter
              name="email"
              label={t("home.resetPassword.email.label")}
            >
              {props => (
                <FormInput
                  {...props}
                  autoComplete="username"
                  placeholder={t("home.resetPassword.email.placeholder")}
                  startAdornment={<IconMail size={18} />}
                  disabled={isResettingPassword}
                />
              )}
            </FormAdapter>
            <S.Login>
              <ButtonText onClick={() => setFormStage(FormStage.Login)}>
                {t("home.login.title")}
              </ButtonText>
            </S.Login>
            <FormContext<FormValues>
              render={({ handleSubmit }) => (
                <Button
                  type="submit"
                  variant="secondary"
                  fullWidth
                  isLoading={isResettingPassword}
                  onClick={handleSubmit(onSubmit)}
                >
                  {t("home.resetPassword.submit")}
                </Button>
              )}
            />
          </>
        )}
      </FormLayout>
    </Form>
  );
};

export default ResetPasswordForm;
