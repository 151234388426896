import { ApiResponseSuccessResult, UserProfile } from "@hotelspoint/types";

import { UPLOAD_CONFIG } from "../constants";
import FetchService from "./base/FetchService";

const AUTH_API_PATH = "auth";

export interface LoginPayload {
  email: string;
  password: string;
}

export interface LoginResponse {
  token: string;
  requireOTP: boolean;
}

export interface LoginVerifyPayload {
  token: string;
  otp: string;
}

export interface RegisterPayload {
  name: string;
  email: string;
  password: string;
  countryCode: string;
  companyName: string;
  companyLicense: string;
  companyPhone: string;
  uicNumber: string;
  companyEmail: string;
  companyManager: string;
  companyAddress: string;
}

export interface ResetPasswordPayload {
  email: string;
}

export interface ChangePasswordPayload {
  token: string;
  password: string;
}

export interface UploadDocumentsPayload {
  token: string;
  formData: FormData;
}

export interface UploadDocumentsParams {
  agencyId: number;
}

export type UpdateProfilePayload = Partial<Pick<UserProfile, "name" | "email">>;

const AuthService = {
  getProfile: () => {
    return FetchService.get<UserProfile>(`${AUTH_API_PATH}/profile`);
  },
  login: (payload: LoginPayload) => {
    return FetchService.post<LoginResponse>(`${AUTH_API_PATH}/login`, payload);
  },
  loginVerify: (payload: LoginVerifyPayload) => {
    return FetchService.post<ApiResponseSuccessResult>(
      `${AUTH_API_PATH}/otp/verify`,
      payload,
    );
  },
  logout: () => {
    return FetchService.post<ApiResponseSuccessResult>(
      `${AUTH_API_PATH}/logout`,
    );
  },
  register: (payload: RegisterPayload) => {
    return FetchService.post<ApiResponseSuccessResult>(
      `${AUTH_API_PATH}/register`,
      payload,
    );
  },
  resetPassword: (payload: ResetPasswordPayload) => {
    return FetchService.post<ApiResponseSuccessResult>(
      `${AUTH_API_PATH}/password/reset`,
      payload,
    );
  },
  changePassword: (payload: ChangePasswordPayload) => {
    return FetchService.patch<ApiResponseSuccessResult>(
      `${AUTH_API_PATH}/password/change`,
      payload,
    );
  },
  uploadDocuments: (
    payload: UploadDocumentsPayload,
    params?: UploadDocumentsParams,
  ) => {
    return FetchService.post<ApiResponseSuccessResult>(
      `${AUTH_API_PATH}/upload`,
      payload.formData,
      { token: payload.token, ...params },
      UPLOAD_CONFIG,
    );
  },
  updateProfile: (payload: UpdateProfilePayload) => {
    return FetchService.patch<UserProfile>(`${AUTH_API_PATH}/profile`, payload);
  },
};

export default AuthService;
