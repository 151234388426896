import { borderRadius, spacing, timing } from "@hotelspoint/theme";
import styled from "styled-components";
import { ifProp, theme } from "styled-tools";

export const Wrapper = styled.div<{ $disabled?: boolean; $error?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: ${spacing(6)}px;
  cursor: ${ifProp("$disabled", "not-allowed", "pointer")};
  user-select: ${ifProp("$disabled", "none")};
  color: ${ifProp(
    "$disabled",
    theme("palette.silver"),
    ifProp("$error", theme("error.primary"), theme("primary.bg")),
  )};

  border-style: dashed;
  border-width: 1px;
  border-color: ${ifProp(
    "$disabled",
    theme("palette.silver"),
    ifProp("$error", theme("error.primary"), theme("primary.bg")),
  )};
  border-radius: ${borderRadius.medium}px;
  background: white;
  transition: background ${timing.fast}ms;
`;

export const Placeholder = styled.p`
  font-style: italic;
  margin-top: ${spacing(1)}px;
`;

export const FilesWrapper = styled.div`
  margin-top: ${spacing(2)}px;
`;
