import * as yup from "yup";
import YupPassword from "yup-password";

YupPassword(yup);

export const SCHEMA_EMAIL = yup
  .string()
  .email("validation.email.valid")
  .required("validation.email.required");

export const SCHEMA_PASSWORD_BASIC = yup
  .string()
  .required("validation.password.required");

export const SCHEMA_PASSWORD_STRICT = yup
  .string()
  .min(8, "validation.password.minLength")
  .minLowercase(1, "validation.password.minLowercase")
  .minUppercase(1, "validation.password.minUppercase")
  // .minNumbers(1, "validation.password.minNumbers")
  .minSymbols(1, "validation.password.minSymbols")
  .required("validation.password.required");

export const SCHEMA_PASSWORD_CONFIRM = yup
  .string()
  .required("validation.confirmPassword.required")
  .oneOf([yup.ref("password")], "validation.confirmPassword.match");

export const SCHEMA_OTP = yup.string().required("validation.otp.required");

export const SCHEMA_NAMES = yup.string().required("validation.names.required");

export const SCHEMA_PHONE = yup.string().required("validation.phone.required");

export const SCHEMA_COUNTRY = yup
  .string()
  .required("validation.countryCode.required");

export const SCHEMA_COMPANY_NAME = yup
  .string()
  .required("validation.companyName.required");

export const SCHEMA_COMPANY_ADDRESS = yup
  .string()
  .required("validation.companyAddress.required");

export const SCHEMA_COMPANY_LICENSE = yup
  .string()
  .required("validation.companyLicense.required");

export const SCHEMA_COMPANY_UIC_NUMBER = yup
  .string()
  .required("validation.uicNumber.required");
