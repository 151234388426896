import { borderRadius, spacing } from "@hotelspoint/theme";
import styled from "styled-components";
import { switchProp, theme } from "styled-tools";

import { StatusFlavour } from "../../common";

export const Wrapper = styled.div<{ $status: StatusFlavour }>`
  overflow: hidden;
  border-radius: ${borderRadius.normal}px;
  display: flex;
  align-items: center;
  column-gap: ${spacing(1.5)}px;
  padding: ${spacing(1.5)}px ${spacing(2)}px;
  color: ${theme("palette.outerSpace")};
  background: ${switchProp(
    "$status",
    {
      [StatusFlavour.Success]: theme("success.secondary"),
      [StatusFlavour.Info]: theme("info.secondary"),
      [StatusFlavour.Warning]: theme("warning.secondary"),
      [StatusFlavour.Error]: theme("error.secondary"),
    },
    theme("palette.silverChalice"),
  )};

  svg {
    color: ${switchProp(
      "$status",
      {
        [StatusFlavour.Success]: theme("success.primary"),
        [StatusFlavour.Info]: theme("info.primary"),
        [StatusFlavour.Warning]: theme("warning.primary"),
        [StatusFlavour.Error]: theme("error.primary"),
      },
      theme("palette.mineShaft"),
    )};
  }
`;
