import { useCookieConsentStore } from "@hotelspoint/store";
import { IconX } from "@tabler/icons-react";
import { Trans } from "react-i18next";

import LinkUnderline from "../../elements/LinkUnderline";
import * as S from "./CookieConsent.styled";

const CookieConsent = () => {
  const setConsent = useCookieConsentStore(state => state.setConsent);

  return (
    <S.Wrapper>
      <p>
        <Trans i18nKey="components.cookieConsent.title">
          This website uses cookies to enhance your browsing experience and to
          analyze site traffic. By using this website, you consent to the use of
          cookies in accordance with our{" "}
          <LinkUnderline to="/privacy">Privacy Policy</LinkUnderline>
        </Trans>
      </p>
      <S.CloseButton type="button" onClick={() => setConsent(true)}>
        <IconX size={16} />
      </S.CloseButton>
    </S.Wrapper>
  );
};

export default CookieConsent;
