import { NumberParam, StringParam, useQueryParams } from "use-query-params";

const useAdminAgencyStatementsParams = () => {
  const [query, setQuery] = useQueryParams({
    id: NumberParam,
    agencyId: NumberParam,
    status: NumberParam,
    dateFrom: StringParam,
    dateTo: StringParam,
    // Misc
    agencyName: StringParam,
  });

  const ret: [typeof query, typeof setQuery] = [query, setQuery];

  return ret;
};

export default useAdminAgencyStatementsParams;
