import { IconCopy, IconCopyCheck } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { CopyToClipboard as ReactCopyToClipboard } from "react-copy-to-clipboard";

import * as S from "./CopyToClipboard.styled";

const RESET_TIMER = 5_000;

interface CopyToClipboardProps {
  value: string;
}

const CopyToClipboard = ({ value }: CopyToClipboardProps) => {
  const [isCopied, setIsCopied] = useState(false);

  // Reset the copied state after 5 seconds
  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (isCopied) {
      timeout = setTimeout(() => {
        setIsCopied(false);
        clearTimeout(timeout);
      }, RESET_TIMER);
    }

    return () => clearTimeout(timeout);
  }, [isCopied]);

  return (
    <ReactCopyToClipboard text={value} onCopy={() => setIsCopied(true)}>
      <S.Wrapper>
        <span>{value}</span>
        {isCopied ? (
          <IconCopyCheck size={16} style={{ minWidth: 16 }} />
        ) : (
          <IconCopy size={16} style={{ minWidth: 16 }} />
        )}
      </S.Wrapper>
    </ReactCopyToClipboard>
  );
};

export default CopyToClipboard;
