import {
  Breadcrumb,
  BreadcrumbContainer,
  Form,
  Panel,
} from "@hotelspoint/components";
import { useUserCurrentOfferStore } from "@hotelspoint/store";
import {
  IconFilePercent,
  IconFilePlus,
  IconMoodPuzzled,
  IconUser,
} from "@tabler/icons-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import ProfileOfferPanel from "../ProfileOffer/ProfileOfferPanel";
import ProfileOfferForm from "../ProfileOfferForm";
import { buildPriceObject } from "../ProfileOffers.util";
import {
  entity2Form,
  FormValues,
  validationSchema,
} from "./ProfileOffersCreate.form";
import * as S from "./ProfileOffersCreate.styled";

const ProfileOffersCreate = () => {
  const { t } = useTranslation();

  const offer = useUserCurrentOfferStore(state => state.offer);
  const offerItems = useMemo(() => offer?.items ?? [], [offer]);

  const breadcrumbs = useMemo(
    () => [
      {
        title: t("profile.pageTitle"),
        href: "/profile",
        icon: IconUser,
      },
      {
        title: t("profileOffers.pageTitle"),
        href: "/profile/offers",
        icon: IconFilePercent,
      },
      {
        title: t("profileOffersCreate.pageTitle"),
        icon: IconFilePlus,
      },
    ],
    [t],
  );

  const formData = useMemo(() => {
    return {
      prices: buildPriceObject(offerItems),
    };
  }, [offerItems]);

  const formValues = useMemo(() => {
    return entity2Form(formData);
  }, [formData]);

  return (
    <>
      <BreadcrumbContainer>
        <Breadcrumb items={breadcrumbs} />
      </BreadcrumbContainer>

      <Form<FormValues>
        defaultValues={formValues}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {formMethods => (
          <>
            <div style={{ marginBottom: 32 }}>
              <Panel title={t("profileOffersCreate.pageTitle")}>
                <ProfileOfferForm
                  offerId={offer?.id}
                  currentOffer={true}
                  {...formMethods}
                />
              </Panel>
            </div>
            {offerItems.length !== 0 ? (
              <ProfileOfferPanel
                offerId={offer?.id as number}
                offerItems={offerItems}
                {...formMethods}
              />
            ) : (
              <S.EmptyState>
                <IconMoodPuzzled size={32} />
                <div>
                  <S.EmptyStateTitle>
                    {t("profileOffersCreate.emptyState.title")}
                  </S.EmptyStateTitle>
                  <S.EmptyStateDescription>
                    {t("profileOffersCreate.emptyState.description")}
                  </S.EmptyStateDescription>
                </div>
              </S.EmptyState>
            )}
          </>
        )}
      </Form>
    </>
  );
};

export default ProfileOffersCreate;
