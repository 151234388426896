import { ErrorMessage } from "@hookform/error-message";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import * as S from "./FormErrorMessage.styled";

interface FormErrorMessageProps {
  name: string;
}

const FormErrorMessage = ({ name }: FormErrorMessageProps) => {
  const { t } = useTranslation();
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <ErrorMessage
      name={name}
      errors={errors}
      render={({ message }) => <S.Wrapper>{t(message)}</S.Wrapper>}
    />
  );
};

export default FormErrorMessage;
