import { zIndex } from "@hotelspoint/theme";

import { Menu } from "../../../composites/Menu";
import * as S from "./HeaderMenu.styled";

interface HeaderMenuProps {
  menuButton: React.ReactNode;
  children: React.ReactNode;
}

const HeaderMenu = ({ menuButton, children }: HeaderMenuProps) => {
  return (
    <Menu
      transition
      arrow
      direction="bottom"
      align="center"
      viewScroll="close"
      gap={-14}
      initialMounted
      menuStyle={{ zIndex: zIndex.headerMenu }}
      menuButton={({ open }: { open: boolean }) => (
        <S.ButtonWrapper type="button">
          {menuButton}
          <S.CaretIcon
            size={18}
            style={{
              transform: open ? "rotate(180deg)" : undefined,
            }}
          />
        </S.ButtonWrapper>
      )}
    >
      {children}
    </Menu>
  );
};

export default HeaderMenu;
