import { HotelService } from "@hotelspoint/api";
import { useQuery } from "@tanstack/react-query";

const useHotelRate = (searchId: number, hotelId: number, rateId: number) => {
  const { data, isLoading } = useQuery({
    queryKey: ["hotels", searchId, hotelId, rateId, "rate"],
    queryFn: () => HotelService.getRate(searchId, hotelId, rateId),
    enabled: !!searchId && !!hotelId && !!rateId,
  });

  const ret: [typeof data, boolean] = [data, isLoading];

  return ret;
};

export default useHotelRate;
