import { IconDownload } from "@tabler/icons-react";

import Button from "../Button/Button";
import { ButtonProps } from "../Button/types";

const ButtonDownload = (props: Omit<ButtonProps, "children">) => (
  <Button small variant="secondary" {...props}>
    <IconDownload size={14} />
  </Button>
);

export default ButtonDownload;
