import { useInvoices } from "@hotelspoint/api";
import {
  Button,
  DateRangeDialog,
  Pagination,
  Panel,
  Table,
  TABLE_PAGE_SIZE_DEFAULT,
  TableFooter,
  TableLayout,
  TablePageSize,
  TableResultsCount,
} from "@hotelspoint/components";
import { useUserTokenStore } from "@hotelspoint/store";
import {
  getApiUrl,
  openInNewTab,
  pickFilterValues,
  useDocumentTitle,
} from "@hotelspoint/utils";
import { IconFileExport, IconReport } from "@tabler/icons-react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import getColumns from "./AdminInvoices.columns";
import AdminInvoicesFilters from "./AdminInvoicesFilters";
import useAdminInvoicesParams from "./useAdminInvoicesParams";

const AdminInvoices = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("adminInvoices.pageTitle"));

  const token = useUserTokenStore(state => state.token);

  const [pageSize, setPageSize] = useState(TABLE_PAGE_SIZE_DEFAULT);
  const [pageIndex, setPageIndex] = useState(1);

  const [isExportDialogOpen, setIsExportDialogOpen] = useState(false);
  const [isReportDialogOpen, setIsReportDialogOpen] = useState(false);

  const [query] = useAdminInvoicesParams();
  const filters = useMemo(() => pickFilterValues(query), [query]);

  const [invoices, isLoading] = useInvoices({
    pageIndex,
    pageSize,
    ...filters,
  });

  const data = useMemo(() => invoices?.results ?? [], [invoices]);

  const columns = useMemo(() => {
    return getColumns({ t });
  }, [t]);

  const stringifiedFilters = useMemo(() => JSON.stringify(filters), [filters]);

  useEffect(() => {
    setPageIndex(1);
  }, [pageSize, stringifiedFilters]);

  return (
    <>
      <DateRangeDialog
        isOpen={isExportDialogOpen}
        setIsOpen={setIsExportDialogOpen}
        title={t("adminInvoices.actions.export.modal.title")}
        onConfirm={(dateFrom, dateTo) => {
          const url = getApiUrl("invoices/export", { token, dateFrom, dateTo });

          openInNewTab(url);
          setIsExportDialogOpen(false);
        }}
      />
      <DateRangeDialog
        isOpen={isReportDialogOpen}
        setIsOpen={setIsReportDialogOpen}
        title={t("adminInvoices.actions.report.modal.title")}
        onConfirm={(dateFrom, dateTo) => {
          const url = getApiUrl("invoices/report", { token, dateFrom, dateTo });

          openInNewTab(url);
          setIsReportDialogOpen(false);
        }}
      />
      <Panel
        title={t("adminInvoices.pageTitle")}
        actions={
          <>
            <Button
              variant="secondary"
              small
              onClick={() => setIsExportDialogOpen(true)}
            >
              <IconFileExport size={16} />
              <span>{t("adminInvoices.actions.export.button")}</span>
            </Button>
            <Button
              variant="secondary"
              small
              onClick={() => setIsReportDialogOpen(true)}
            >
              <IconReport size={16} />
              <span>{t("adminInvoices.actions.report.button")}</span>
            </Button>
          </>
        }
      >
        <AdminInvoicesFilters isLoading={isLoading} />
        <TableLayout>
          <Table
            data={data}
            columns={columns}
            isLoading={isLoading}
            useReactTableProps={{
              state: {
                pagination: {
                  pageIndex,
                  pageSize: TABLE_PAGE_SIZE_DEFAULT,
                },
                columnPinning: {
                  left: ["id"],
                  right: ["actions"],
                },
              },
              manualPagination: true,
            }}
          />
          <TableFooter>
            <div>
              <TableResultsCount value={invoices?.count} />
            </div>
            <div>
              <Pagination
                pageIndex={pageIndex}
                pageSize={pageSize}
                setPageIndex={setPageIndex}
                totalCount={invoices?.count ?? 0}
              />
            </div>
            <TablePageSize value={pageSize} onChange={setPageSize} />
          </TableFooter>
        </TableLayout>
      </Panel>
    </>
  );
};

export default AdminInvoices;
