import { useReservation } from "@hotelspoint/api";
import {
  Breadcrumb,
  BreadcrumbContainer,
  LoaderBlock,
  ReservationInvoicesTable,
  ReservationPaymentsTable,
} from "@hotelspoint/components";
import { useDocumentTitle } from "@hotelspoint/utils";
import { IconCalendarEvent, IconUser, IconZoom } from "@tabler/icons-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useParams } from "react-router-dom";

import * as S from "./ProfileReservation.styled";
import ReservationBaseInfo from "./ReservationBaseInfo";
import ReservationFlightsInfo from "./ReservationFlightsInfo";
import ReservationGuests from "./ReservationGuests";
import ReservationHotelInfo from "./ReservationHotelInfo";
import ReservationMetricCards from "./ReservationMetricCards";
import ReservationRoomsTable from "./ReservationRoomsTable";

interface ProfileReservationParams extends Record<string, string> {
  id: string;
}

const ProfileReservation = () => {
  const { t } = useTranslation();

  useDocumentTitle(t("profileReservation.pageTitle"));

  const { id } = useParams<ProfileReservationParams>();
  const reservationId = Number(id);
  const [reservation, isLoading] = useReservation(reservationId);

  const breadcrumbs = useMemo(
    () => [
      {
        title: t("profile.pageTitle"),
        href: "/profile",
        icon: IconUser,
      },
      {
        title: t("profileReservations.pageTitle"),
        href: "/profile/bookings",
        icon: IconCalendarEvent,
      },
      {
        title: t("profileReservation.pageTitle"),
        icon: IconZoom,
      },
    ],
    [t],
  );

  if (!reservationId) {
    return <Navigate to="/404" replace={true} />;
  }

  if (isLoading || !reservation) {
    return <LoaderBlock />;
  }

  return (
    <>
      <BreadcrumbContainer>
        <Breadcrumb items={breadcrumbs} />
      </BreadcrumbContainer>
      <S.LayoutWrapper>
        <ReservationMetricCards reservation={reservation} />
        <ReservationBaseInfo reservation={reservation} />
        <ReservationHotelInfo id={reservation.id} isLoading={isLoading} />
        <ReservationRoomsTable rooms={reservation.itemData.rooms} />
        <ReservationGuests itemData={reservation.itemData} />
        {reservation.itemData?.hasTransfers && (
          <ReservationFlightsInfo flights={reservation.itemData.flights} />
        )}
        <ReservationPaymentsTable id={reservationId} />
        <ReservationInvoicesTable id={reservationId} />
      </S.LayoutWrapper>
    </>
  );
};

export default ProfileReservation;
