import {
  Breadcrumb,
  BreadcrumbContainer,
  Container,
  PageSpacer,
  PageSplitLayout,
  PageWrapper,
  SidebarNav,
} from "@hotelspoint/components";
import { IconSettings } from "@tabler/icons-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { matchPath, Outlet, useLocation } from "react-router-dom";

import * as S from "./Admin.styled";
import pageTitles from "./pageTitles.json";
import sidebarNavItems from "./sidebarNavItems.json";

const Admin = () => {
  const { t } = useTranslation();

  const { pathname } = useLocation();

  const breadcrumbTitle = useMemo(() => {
    const matchedPath = Object.entries(pageTitles).find(([key]) => {
      return matchPath({ path: key }, pathname);
    });

    if (!matchedPath) return undefined;

    const [, title] = matchedPath;

    return title;
  }, [pathname]);

  const breadcrumbs = useMemo(() => {
    return [
      {
        title: t("admin.pageTitle"),
        href: "/admin",
        icon: IconSettings,
      },
      {
        title: breadcrumbTitle ? t(breadcrumbTitle) : "",
      },
    ];
  }, [t, breadcrumbTitle]);

  const sidebarItems = useMemo(
    () =>
      sidebarNavItems.map(item => ({
        href: item.href,
        title: t(item.title),
        children: item.children?.map(child => ({
          title: t(child.title),
          href: child.href,
        })),
      })),
    [t],
  );

  return (
    <PageWrapper>
      <Container>
        <PageSpacer>
          <BreadcrumbContainer>
            <Breadcrumb items={breadcrumbs} />
          </BreadcrumbContainer>
          <PageSplitLayout>
            <PageSplitLayout.Sidebar>
              <S.SidebarCard>
                <S.HeadingWrapper>
                  <S.Heading>{t("admin.pageTitle")}</S.Heading>
                </S.HeadingWrapper>
                <SidebarNav items={sidebarItems} />
              </S.SidebarCard>
            </PageSplitLayout.Sidebar>
            <PageSplitLayout.Content>
              <Outlet />
            </PageSplitLayout.Content>
          </PageSplitLayout>
        </PageSpacer>
      </Container>
    </PageWrapper>
  );
};

export default Admin;
