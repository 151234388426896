import { formatDateIso, isNumeric } from "@hotelspoint/utils";
import { date, InferType, number, object, string } from "yup";

export const validationSchema = object({
  id: string(),
  type: number(),
  dateFrom: date(),
  dateTo: date(),
  status: number(),
  paymentStatus: number(),
  deadlineFrom: date(),
  deadlineTo: date(),
  name: string(),
  cityName: string(),
  checkInFrom: date(),
  checkInTo: date(),
  messageFilter: number(),
  userId: object({
    value: number(),
    label: string(),
  }).nullable(),
  agencyId: object({
    value: number(),
    label: string(),
  }).nullable(),
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: FormValues = {
  id: "",
  type: undefined,
  dateFrom: undefined,
  dateTo: undefined,
  status: undefined,
  paymentStatus: undefined,
  deadlineFrom: undefined,
  deadlineTo: undefined,
  name: "",
  cityName: "",
  checkInFrom: undefined,
  checkInTo: undefined,
  messageFilter: undefined,
  userId: {},
  agencyId: {},
};

export const entity2Form = (query: any): FormValues => {
  return {
    id: query.id,
    type: query.type,
    dateFrom: query.dateFrom ? new Date(query.dateFrom) : undefined,
    dateTo: query.dateTo ? new Date(query.dateTo) : undefined,
    status: query.status,
    paymentStatus: query.paymentStatus,
    deadlineFrom: query.deadlineFrom ? new Date(query.deadlineFrom) : undefined,
    deadlineTo: query.deadlineTo ? new Date(query.deadlineTo) : undefined,
    name: query.name,
    cityName: query.cityName,
    checkInFrom: query.checkInFrom ? new Date(query.checkInFrom) : undefined,
    checkInTo: query.checkInTo ? new Date(query.checkInTo) : undefined,
    messageFilter: query.messageFilter,
    userId: query.userId
      ? {
          value: query.userId,
          label: query.userName,
        }
      : null,
    agencyId: query.agencyId
      ? {
          value: query.agencyId,
          label: query.agencyName,
        }
      : null,
  };
};

export const form2Entity = (formValues: FormValues) => {
  return {
    id: isNumeric(formValues.id) ? Number(formValues.id) : undefined,
    type: formValues.type,
    dateFrom: formValues.dateFrom
      ? formatDateIso(formValues.dateFrom)
      : undefined,
    dateTo: formValues.dateTo ? formatDateIso(formValues.dateTo) : undefined,
    status: formValues.status,
    paymentStatus: formValues.paymentStatus,
    deadlineFrom: formValues.deadlineFrom
      ? formatDateIso(formValues.deadlineFrom)
      : undefined,
    deadlineTo: formValues.deadlineTo
      ? formatDateIso(formValues.deadlineTo)
      : undefined,
    name: formValues.name,
    cityName: formValues.cityName,
    checkInFrom: formValues.checkInFrom
      ? formatDateIso(formValues.checkInFrom)
      : undefined,
    checkInTo: formValues.checkInTo
      ? formatDateIso(formValues.checkInTo)
      : undefined,
    messageFilter: formValues.messageFilter,
    userId: formValues.userId?.value,
    userName: formValues.userId?.label,
    agencyId: formValues.agencyId?.value,
    agencyName: formValues.agencyId?.label,
  };
};
