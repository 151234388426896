import {
  AdminSupplierStatementCreatePayload,
  AdminSupplierStatementLoadReservationsPayload,
  AdminSupplierStatementStatus,
} from "@hotelspoint/api";
import { splitStringByNewLine } from "@hotelspoint/utils";
import { InferType, number, object, string } from "yup";

export const validationSchema = object({
  supplierId: string().required(
    "adminSupplierStatementsCreate.form.supplierId.validation.required",
  ),
  supplierStatementNumber: string(),
  reservationIds: string().required(
    "adminSupplierStatementsCreate.form.reservationIds.validation.required",
  ),
  additionalCharges: number().typeError(
    "adminSupplierStatementsCreate.form.additionalCharges.validation.required",
  ),
  comments: string(),
  voucherCodes: string(),
  prices: string().required(
    "adminSupplierStatementsCreate.form.prices.validation.required",
  ),
  // Read-only
  status: number(),
  totalAmount: number(),
  remainingAmount: number(),
  payedAmount: number(),
  reservationsAmount: number(),
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: FormValues = {
  supplierId: "",
  supplierStatementNumber: "",
  reservationIds: "",
  additionalCharges: 0,
  comments: "",
  voucherCodes: "",
  prices: "",
  // Read-only
  status: AdminSupplierStatementStatus.PendingPayment,
  totalAmount: 0,
  remainingAmount: 0,
  payedAmount: 0,
  reservationsAmount: 0,
};

export const form2Entity = (
  formValues: FormValues,
): AdminSupplierStatementCreatePayload => ({
  supplierId: formValues.supplierId,
  supplierStatementNumber: formValues.supplierStatementNumber || "",
  reservationIds: splitStringByNewLine(formValues.reservationIds),
  additionalCharges: formValues.additionalCharges ?? 0,
  comments: formValues.comments || "",
});

export const form2EntityLoad = (
  formValues: FormValues,
): AdminSupplierStatementLoadReservationsPayload => ({
  supplierId: formValues.supplierId,
  prices: formValues.prices,
  reservationIds: formValues.reservationIds,
  voucherCodes: formValues.voucherCodes as string,
});
