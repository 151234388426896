import { useMutation } from "@tanstack/react-query";

import { AuthService, type LoginPayload } from "../../services";

const useLogin = () => {
  const mutation = useMutation({
    mutationKey: ["auth", "login"],
    mutationFn: (payload: LoginPayload) => {
      return AuthService.login(payload);
    },
  });

  const { mutateAsync, isPending } = mutation;
  const ret: [typeof mutateAsync, typeof isPending] = [mutateAsync, isPending];

  return ret;
};

export default useLogin;
