import { keyframes } from "styled-components";

export const menuShow = keyframes`
  from {
    opacity: 0;
  }
`;

export const menuHide = keyframes`
  to {
    opacity: 0;
  }
`;
