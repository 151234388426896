import { useMutation, useQueryClient } from "@tanstack/react-query";

import { AdminService } from "../../../../services";

const useDeleteAdminSupplierStatementPayment = (id: number) => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: ["admin", "statements_supplier", "delete_payment"],
    mutationFn: (paymentId: number) => {
      return AdminService.deleteSupplierStatementPayment(id, paymentId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["admin", "statements_supplier", id],
      });

      queryClient.invalidateQueries({
        queryKey: ["admin", "statements_supplier"],
      });
    },
  });

  const { mutateAsync, isPending } = mutation;
  const ret: [typeof mutateAsync, typeof isPending] = [mutateAsync, isPending];

  return ret;
};

export default useDeleteAdminSupplierStatementPayment;
