import { handleError, useAgency, useUpdateAgency } from "@hotelspoint/api";
import {
  Box,
  Button,
  Chip,
  Flex,
  Form,
  FormAdapter,
  FormClearableInput,
  FormContext,
  FormLayout,
  FormSelect,
  LoaderBlock,
  OptionGroup,
} from "@hotelspoint/components";
import {
  ACTIVITY_SUPPLIER_OPTIONS,
  AGENCY_STATUS_OPTIONS,
  CHIP_COLORS,
  countryGroups,
  HOTEL_SUPPLIER_OPTIONS,
  TRANSFER_SUPPLIER_OPTIONS,
} from "@hotelspoint/constants";
import { UserCurrency } from "@hotelspoint/types";
import { getAgencyStatusColor } from "@hotelspoint/utils";
import { IconPercentage } from "@tabler/icons-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import {
  defaultValues,
  entity2Form,
  form2Entity,
  FormValues,
  validationSchema,
} from "./AdminAgencyForm.util";

const hotelSupplierOptions = HOTEL_SUPPLIER_OPTIONS.map(option => ({
  ...option,
  label: <Chip $color={CHIP_COLORS.SUPPLIER}>{option.label}</Chip>,
}));

const transferSupplierOptions = TRANSFER_SUPPLIER_OPTIONS.map(option => ({
  ...option,
  label: <Chip $color={CHIP_COLORS.SUPPLIER}>{option.label}</Chip>,
}));

const activitySupplierOptions = ACTIVITY_SUPPLIER_OPTIONS.map(option => ({
  ...option,
  label: <Chip $color={CHIP_COLORS.SUPPLIER}>{option.label}</Chip>,
}));

interface AdminAgencyFormProps {
  id: number;
}

const AdminAgencyForm = ({ id }: AdminAgencyFormProps) => {
  const { t } = useTranslation();

  const [agency, isLoadingAgency] = useAgency(id);
  const [updateAgency, isUpdatingAgency] = useUpdateAgency(id);

  const countryOptions: OptionGroup[] = useMemo(
    () =>
      countryGroups.map(group => ({
        label: t(`countries.groups.${group.label}`),
        options: group.options.map(value => ({
          value,
          label: t(`countries.${value}`),
        })),
      })),
    [t],
  );

  const statusOptions = useMemo(
    () =>
      AGENCY_STATUS_OPTIONS.map(status => ({
        ...status,
        label: (
          <Chip $color={getAgencyStatusColor(status.value)}>
            {t(status.label)}
          </Chip>
        ),
      })),
    [t],
  );

  const formValues = useMemo(() => {
    if (!agency) return defaultValues;

    return entity2Form(agency);
  }, [agency]);

  const onSubmit = async (formValues: FormValues) => {
    try {
      const payload = form2Entity(formValues);

      await updateAgency(payload);

      toast.success(t("toast.adminAgency.update"));
    } catch (error: any) {
      handleError({ t, error });
    }
  };

  if (isLoadingAgency) {
    return <LoaderBlock />;
  }

  return (
    <Form defaultValues={formValues} validationSchema={validationSchema}>
      <FormLayout>
        <Flex mx={[0, 0, -1, -1]}>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter name="name" label={t("adminAgency.form.name.label")}>
              {props => (
                <FormClearableInput
                  {...props}
                  placeholder={t("adminAgency.form.name.placeholder")}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter name="email" label={t("adminAgency.form.email.label")}>
              {props => (
                <FormClearableInput
                  {...props}
                  placeholder={t("adminAgency.form.email.placeholder")}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter name="phone" label={t("adminAgency.form.phone.label")}>
              {props => (
                <FormClearableInput
                  {...props}
                  placeholder={t("adminAgency.form.phone.placeholder")}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="companyLicense"
              label={t("adminAgency.form.companyLicense.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  placeholder={t("adminAgency.form.companyLicense.placeholder")}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="uicNumber"
              label={t("adminAgency.form.uicNumber.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  placeholder={t("adminAgency.form.uicNumber.placeholder")}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="companyManager"
              label={t("adminAgency.form.companyManager.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  placeholder={t("adminAgency.form.companyManager.placeholder")}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="country"
              label={t("adminAgency.form.country.label")}
            >
              {props => (
                <FormSelect
                  {...props}
                  placeholder={t("adminAgency.form.country.placeholder")}
                  options={countryOptions}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="companyAddress"
              label={t("adminAgency.form.companyAddress.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  placeholder={t("adminAgency.form.companyAddress.placeholder")}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="status"
              label={t("adminAgency.form.status.label")}
            >
              {props => (
                <FormSelect
                  {...props}
                  placeholder={t("adminAgency.form.status.placeholder")}
                  options={statusOptions}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="suppliersHotels"
              label={t("adminAgency.form.suppliersHotels.label")}
            >
              {props => (
                <FormSelect
                  {...props}
                  placeholder={t(
                    "adminAgency.form.suppliersHotels.placeholder",
                  )}
                  options={hotelSupplierOptions}
                  isMulti
                  closeMenuOnSelect={false}
                  isClearable
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="suppliersTransfers"
              label={t("adminAgency.form.suppliersTransfers.label")}
            >
              {props => (
                <FormSelect
                  {...props}
                  placeholder={t(
                    "adminAgency.form.suppliersTransfers.placeholder",
                  )}
                  options={transferSupplierOptions}
                  isMulti
                  closeMenuOnSelect={false}
                  isClearable
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="suppliersActivities"
              label={t("adminAgency.form.suppliersActivities.label")}
            >
              {props => (
                <FormSelect
                  {...props}
                  placeholder={t(
                    "adminAgency.form.suppliersActivities.placeholder",
                  )}
                  options={activitySupplierOptions}
                  isMulti
                  closeMenuOnSelect={false}
                  isClearable
                />
              )}
            </FormAdapter>
          </Box>

          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="markupHotels"
              label={t("adminAgency.form.markupHotels.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  type="number"
                  endAdornment={<IconPercentage size={18} />}
                  adornmentStyle="filled"
                  placeholder={t("adminAgency.form.markupHotels.placeholder")}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="markupHotelsBase"
              label={t("adminAgency.form.markupHotelsBase.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  type="number"
                  endAdornment={<IconPercentage size={18} />}
                  adornmentStyle="filled"
                  placeholder={t(
                    "adminAgency.form.markupHotelsBase.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="markupHotelsOperator"
              label={t("adminAgency.form.markupHotelsOperator.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  type="number"
                  endAdornment={<IconPercentage size={18} />}
                  adornmentStyle="filled"
                  placeholder={t(
                    "adminAgency.form.markupHotelsOperator.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="markupTransfers"
              label={t("adminAgency.form.markupTransfers.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  type="number"
                  endAdornment={<IconPercentage size={18} />}
                  adornmentStyle="filled"
                  placeholder={t(
                    "adminAgency.form.markupTransfers.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="markupTransfersBase"
              label={t("adminAgency.form.markupTransfersBase.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  type="number"
                  endAdornment={<IconPercentage size={18} />}
                  adornmentStyle="filled"
                  placeholder={t(
                    "adminAgency.form.markupTransfersBase.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="markupTransfersOperator"
              label={t("adminAgency.form.markupTransfersOperator.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  type="number"
                  endAdornment={<IconPercentage size={18} />}
                  adornmentStyle="filled"
                  placeholder={t(
                    "adminAgency.form.markupTransfersOperator.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="markupActivities"
              label={t("adminAgency.form.markupActivities.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  type="number"
                  endAdornment={<IconPercentage size={18} />}
                  adornmentStyle="filled"
                  placeholder={t(
                    "adminAgency.form.markupActivities.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="markupActivitiesBase"
              label={t("adminAgency.form.markupActivitiesBase.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  type="number"
                  endAdornment={<IconPercentage size={18} />}
                  adornmentStyle="filled"
                  placeholder={t(
                    "adminAgency.form.markupActivitiesBase.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="markupActivitiesOperator"
              label={t("adminAgency.form.markupActivitiesOperator.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  type="number"
                  endAdornment={<IconPercentage size={18} />}
                  adornmentStyle="filled"
                  placeholder={t(
                    "adminAgency.form.markupActivitiesOperator.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="creditUsed"
              label={t("adminAgency.form.creditUsed.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  type="number"
                  readOnly
                  endAdornment={UserCurrency.EUR}
                  adornmentStyle="filled"
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="creditBalance"
              label={t("adminAgency.form.creditBalance.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  type="number"
                  readOnly
                  endAdornment={UserCurrency.EUR}
                  adornmentStyle="filled"
                />
              )}
            </FormAdapter>
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
            <FormAdapter
              name="creditLimit"
              label={t("adminAgency.form.creditLimit.label")}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  type="number"
                  endAdornment={UserCurrency.EUR}
                  adornmentStyle="filled"
                  placeholder={t("adminAgency.form.creditLimit.placeholder")}
                />
              )}
            </FormAdapter>
          </Box>
        </Flex>
        <FormContext<FormValues>
          render={({ handleSubmit }) => (
            <Button
              type="submit"
              variant="secondary"
              isLoading={isUpdatingAgency}
              onClick={handleSubmit(onSubmit)}
            >
              {t("adminAgency.form.submit")}
            </Button>
          )}
        />
      </FormLayout>
    </Form>
  );
};

export default AdminAgencyForm;
