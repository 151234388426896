import { pxToRem, spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";

export const Headline = styled.h6`
  margin-bottom: ${spacing(1)}px;
  font-weight: 600;
`;

export const Paragraph = styled.p`
  font-size: ${pxToRem(14)};
  margin-bottom: ${spacing(1)}px;
`;
