import { useReservations } from "@hotelspoint/api";
import {
  Breadcrumb,
  BreadcrumbContainer,
  Pagination,
  Panel,
  Table,
  TABLE_PAGE_SIZE_DEFAULT,
  TableFooter,
  TableLayout,
  TablePageSize,
  TableResultsCount,
} from "@hotelspoint/components";
import { pickFilterValues, useDocumentTitle } from "@hotelspoint/utils";
import { IconCalendarEvent, IconUser } from "@tabler/icons-react";
import omit from "lodash/omit";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import getColumns from "./ProfileReservations.columns";
import ProfileReservationsFilters from "./ProfileReservationsFilters";
import useProfileReservationsParams from "./useProfileReservationsParams";

const ProfileReservations = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("profileReservations.pageTitle"));

  const [pageSize, setPageSize] = useState(TABLE_PAGE_SIZE_DEFAULT);
  const [pageIndex, setPageIndex] = useState(1);

  const [query] = useProfileReservationsParams();
  const filters = useMemo(
    () => omit(pickFilterValues(query), ["agencyName", "userName"]),
    [query],
  );

  const breadcrumbs = useMemo(
    () => [
      {
        title: t("profile.pageTitle"),
        href: "/profile",
        icon: IconUser,
      },
      {
        title: t("profileReservations.pageTitle"),
        icon: IconCalendarEvent,
      },
    ],
    [t],
  );

  const [reservations, isLoading] = useReservations({
    pageIndex,
    pageSize,
    ...filters,
  });

  const data = useMemo(() => reservations?.results ?? [], [reservations]);

  const columns = useMemo(() => {
    return getColumns({ t });
  }, [t]);

  const stringifiedFilters = useMemo(() => JSON.stringify(filters), [filters]);

  useEffect(() => {
    setPageIndex(1);
  }, [pageSize, stringifiedFilters]);

  return (
    <>
      <BreadcrumbContainer>
        <Breadcrumb items={breadcrumbs} />
      </BreadcrumbContainer>
      <Panel title={t("profileReservations.pageTitle")}>
        <ProfileReservationsFilters isLoading={isLoading} />
        <TableLayout>
          <Table
            data={data}
            columns={columns}
            isLoading={isLoading}
            useReactTableProps={{
              state: {
                pagination: {
                  pageIndex,
                  pageSize: TABLE_PAGE_SIZE_DEFAULT,
                },
                columnPinning: {
                  right: ["actions"],
                },
              },
              manualPagination: true,
            }}
          />
          <TableFooter>
            <div>
              <TableResultsCount value={reservations?.count} />
            </div>
            <div>
              <Pagination
                pageIndex={pageIndex}
                pageSize={pageSize}
                setPageIndex={setPageIndex}
                totalCount={reservations?.count ?? 0}
              />
            </div>
            <TablePageSize value={pageSize} onChange={setPageSize} />
          </TableFooter>
        </TableLayout>
      </Panel>
    </>
  );
};

export default ProfileReservations;
