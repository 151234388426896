import {
  ApiResponseSuccessResult,
  ApiResponseSuccessResultList,
  PaginationOptions,
} from "@hotelspoint/types";

import FetchService from "./base/FetchService";
import { ReservationStatus } from "./ReservationService";

const MESSAGE_API_PATH = "messages";

export enum MessageType {
  Agency = "agency",
  Hotel = "hotel",
  Supplier = "supplier",
}

export enum MessagePriority {
  Low = 1,
  Medium = 2,
  High = 3,
  Critical = 4,
}

export enum MessageStatus {
  Pending = 1,
  InProgress = 2,
  Completed = 3,
}

export interface MessageSendPayload
  extends Pick<
    Message,
    "reservationId" | "type" | "subject" | "message" | "priority"
  > {
  email: string;
  alertDays: number;
}

export interface Message {
  id: number;
  type: MessageType;
  subject: string;
  message: string;
  senderEmail: string | null;
  senderAgencyId: number | null;
  senderAgencyName: string | null;
  assigneeId: number;
  confirmationId: string | null;
  reservationId: number;
  reservationStatus: ReservationStatus;
  reservationDateFrom: string;
  reservationAgencyId: number;
  reservationAgencyName: string;
  priority: MessagePriority;
  isNew: boolean;
  isAlert: boolean;
  isSystem: boolean;
  status: MessageStatus | null;
  dateSent: string | null;
  dateCreated: string;
}

export enum MessageOrderBy {
  Date = 1,
  Priority = 2,
}

export type MessageUpdatePayload = Partial<
  Pick<Message, "priority" | "status" | "isNew" | "isAlert">
>;

export interface MessageListParams extends PaginationOptions {
  id?: number;
  reservationId?: number;
  agencyId?: number;
  type?: MessageType;
  status?: MessageStatus;
  priority?: MessagePriority;
  assigneeId?: number;
  dateFrom?: string;
  dateTo?: string;
  checkInFrom?: string;
  checkInTo?: string;
  seen?: boolean;
  alert?: boolean;
  reservationStatus?: ReservationStatus;
  reservationConfirmed?: boolean;
  orderBy?: MessageOrderBy;
}

export type MessageListResponse = ApiResponseSuccessResultList<Message>;

export interface MessageConfigParams {
  reservationId: number;
}

export type MessageConfigEmails = {
  [key in MessageType]: string;
};

export interface MessageConfigTemplate {
  name: string;
  subject: string;
  message: string;
}

export type MessageConfigTemplates = {
  [key in MessageType]: MessageConfigTemplate[];
};

export interface MessageConfig {
  emails: Partial<MessageConfigEmails>;
  templates: Partial<MessageConfigTemplates>;
  signature: string;
}

const MessageService = {
  send: (payload: MessageSendPayload) => {
    return FetchService.post<Message>(MESSAGE_API_PATH, payload);
  },
  get: (id: number) => {
    return FetchService.get<Message>(`${MESSAGE_API_PATH}/${id}`);
  },
  update(id: number, payload: MessageUpdatePayload) {
    return FetchService.patch<ApiResponseSuccessResult>(
      `${MESSAGE_API_PATH}/${id}`,
      payload,
    );
  },
  getList: (params?: MessageListParams) => {
    return FetchService.get<MessageListResponse>(MESSAGE_API_PATH, params);
  },
  getConfig: (params: MessageConfigParams) => {
    return FetchService.get<MessageConfig>(
      `${MESSAGE_API_PATH}/config`,
      params,
    );
  },
};

export default MessageService;
