import PopoverContent from "./PopoverContent";
import PopoverContext from "./PopoverContext";
import PopoverTrigger from "./PopoverTrigger";
import usePopover, { PopoverOptions } from "./usePopover";

interface PopoverProps extends PopoverOptions {
  children: React.ReactNode;
}

const Popover = ({ children, modal = false, ...restOptions }: PopoverProps) => {
  // This can accept any props as options, e.g. `placement`,
  // or other positioning options.
  const popover = usePopover({ modal, ...restOptions });

  return (
    <PopoverContext.Provider value={popover}>
      {children}
    </PopoverContext.Provider>
  );
};

Popover.Trigger = PopoverTrigger;
Popover.Content = PopoverContent;

export default Popover;
