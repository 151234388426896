import { AdminMeal } from "@hotelspoint/api";
import {
  Button,
  TABLE_COLUMN_SIZE,
  TableCell,
  TableCellEmpty,
} from "@hotelspoint/components";
import { IconGitPullRequest } from "@tabler/icons-react";
import { createColumnHelper } from "@tanstack/react-table";
import { TFunction } from "i18next";

const columnHelper = createColumnHelper<AdminMeal>();

interface GetColumnsParams {
  t: TFunction;
  handleMap: (id: number) => void;
}

const getColumns = ({ t, handleMap }: GetColumnsParams) => [
  columnHelper.accessor("id", {
    header: t("adminMealConfiguration.columns.id"),
    size: TABLE_COLUMN_SIZE.ID,
  }),
  columnHelper.accessor("name", {
    header: t("adminMealConfiguration.columns.name"),
    size: TABLE_COLUMN_SIZE.NAME,
  }),
  columnHelper.accessor("mainName", {
    header: t("adminMealConfiguration.columns.mainName"),
    size: TABLE_COLUMN_SIZE.NAME,
    cell: props => props.getValue() || <TableCellEmpty />,
  }),
  columnHelper.display({
    id: "actions",
    size: TABLE_COLUMN_SIZE.ACTION,
    cell: props => {
      const { id, mainId } = props.row.original;

      if (mainId) {
        return (
          <TableCell>
            <TableCellEmpty />
          </TableCell>
        );
      }

      return (
        <TableCell>
          <Button small variant="secondary" onClick={() => handleMap(id)}>
            <IconGitPullRequest size={14} />
          </Button>
        </TableCell>
      );
    },
  }),
];

export default getColumns;
