import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

import { type HotelCountByPlaceParams, HotelService } from "../../services";

const useHotelCountByPlace = (params: HotelCountByPlaceParams) => {
  const { data, isLoading } = useQuery({
    queryKey: ["hotels_count", params],
    queryFn: () => HotelService.getCountByPlace(params),
    enabled: !!params?.placeId && !!params?.placeType,
  });

  const count = useMemo(() => data?.total, [data]);

  const ret: [typeof count, boolean] = [count, isLoading];

  return ret;
};

export default useHotelCountByPlace;
