import { mediaQuery } from "@hotelspoint/theme";
import styled from "styled-components";

export default styled.div`
  display: none;

  @media ${mediaQuery.tablet} {
    display: block;
  }
`;
