import { spacing } from "@hotelspoint/theme";
import { IconInfoCircleFilled } from "@tabler/icons-react";
import styled from "styled-components";

export const StatusWrapper = styled.div`
  margin-bottom: ${spacing(3)}px;
`;

export const Separator = styled.hr`
  margin: ${spacing(1)}px 0;
`;

export const LegalTerms = styled.div`
  display: flex;
  align-items: center;
`;

export const InfoIcon = styled(IconInfoCircleFilled)`
  flex: none;
  margin-right: ${spacing(1)}px;
`;
