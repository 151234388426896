import { spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";

import Card from "../../elements/Card";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: ${spacing(1)}px;
`;

export const Modal = styled(Card)`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing(1)}px;
  padding: ${spacing(1.5)}px ${spacing(2)}px;
`;
