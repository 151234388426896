import { AdminSearchListParams, AdminService } from "@hotelspoint/api";
import { useQuery } from "@tanstack/react-query";

const useAdminHotelSearches = (params?: AdminSearchListParams) => {
  const { data, isLoading } = useQuery({
    queryKey: ["admin", "searches_hotel", params],
    queryFn: () => AdminService.getHotelSearches(params),
  });

  const ret: [typeof data, boolean] = [data, isLoading];

  return ret;
};

export default useAdminHotelSearches;
