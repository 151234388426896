import { IconType } from "../../common";
import NavLink from "../../misc/NavLink";
import * as S from "./Breadcrumb.styled";

export interface BreadcrumbItem {
  title: string;
  href?: string;
  icon?: (() => React.ReactNode) | IconType;
}

interface BreadcrumbProps {
  items: BreadcrumbItem[];
}

const Breadcrumb = ({ items }: BreadcrumbProps) => {
  const crumbs = items.map(crumb => {
    return (
      <S.BreadcrumbItem key={crumb.title}>
        {crumb.icon && <crumb.icon size={16} />}
        {typeof crumb.href === "string" ? (
          <NavLink to={crumb.href}>{crumb.title}</NavLink>
        ) : (
          <span>{crumb.title}</span>
        )}
      </S.BreadcrumbItem>
    );
  });

  return (
    <nav aria-label="breadcrumb">
      <S.Breadcrumb>{crumbs}</S.Breadcrumb>
    </nav>
  );
};

export default Breadcrumb;
