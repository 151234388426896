import { useHotelDetails } from "@hotelspoint/api";
import { Button } from "@hotelspoint/components";
import { useTranslation } from "react-i18next";

import { HotelBaseInfo } from "../../../Search/SearchHotels/components";
import * as S from "./ProfileOfferHotelDetails.styled";

interface ProfileOfferHotelDetailsProps {
  id: number;
  setActiveHotel: (hotel: any) => void;
}

const ProfileOfferHotelDetails = ({
  id,
  setActiveHotel,
}: ProfileOfferHotelDetailsProps) => {
  const { t } = useTranslation();

  const [hotelDetails, isLoadingHotelDetails] = useHotelDetails(id);

  return (
    <S.DetailsWrapper>
      <div>
        <S.Heading>{t("profileOffer.hotel")}</S.Heading>
        <HotelBaseInfo
          isLoading={isLoadingHotelDetails}
          name={hotelDetails?.name}
          rating={hotelDetails?.rating}
          address={hotelDetails?.address}
          cityName={hotelDetails?.cityName}
        />
      </div>
      <Button variant="outlined" onClick={() => setActiveHotel(hotelDetails)}>
        {t("profileOffer.hotelDetails")}
      </Button>
    </S.DetailsWrapper>
  );
};

export default ProfileOfferHotelDetails;
