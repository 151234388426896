import { AdminSupplierStatementItem } from "@hotelspoint/api";
import {
  ButtonEdit,
  Chip,
  CopyToClipboard,
  TABLE_COLUMN_SIZE,
  TableCell,
} from "@hotelspoint/components";
import {
  formatDate,
  getPaymentStatusColor,
  getPaymentStatusName,
  getReservationStatusColor,
  getReservationStatusName,
  withCurrency,
} from "@hotelspoint/utils";
import { createColumnHelper } from "@tanstack/react-table";
import { TFunction } from "i18next";
import { Link } from "react-router-dom";

const columnHelper = createColumnHelper<AdminSupplierStatementItem>();

interface GetColumnsParams {
  t: TFunction;
}

const getColumns = ({ t }: GetColumnsParams) => [
  columnHelper.accessor("reservationId", {
    header: t("adminSupplierStatement.items.columns.reservationId"),
    size: TABLE_COLUMN_SIZE.ID,
  }),
  columnHelper.accessor("voucherCode", {
    header: t("adminSupplierStatement.items.columns.voucherCode"),
    size: TABLE_COLUMN_SIZE.AMOUNT,
    cell: props => {
      return <CopyToClipboard value={props.getValue()} />;
    },
  }),
  columnHelper.accessor("priceBase", {
    header: t("adminSupplierStatement.items.columns.priceBase"),
    size: TABLE_COLUMN_SIZE.AMOUNT,
    cell: props => withCurrency(props.getValue()),
  }),
  columnHelper.accessor("reservationStatus", {
    header: t("adminSupplierStatement.items.columns.reservationStatus"),
    size: TABLE_COLUMN_SIZE.STATUS,
    cell: props => {
      const reservationStatus = props.getValue();

      return (
        <Chip $color={getReservationStatusColor(reservationStatus)}>
          {t(getReservationStatusName(reservationStatus))}
        </Chip>
      );
    },
  }),
  columnHelper.accessor("paymentStatus", {
    header: t("adminSupplierStatement.items.columns.paymentStatus"),
    size: TABLE_COLUMN_SIZE.STATUS,
    cell: props => {
      const paymentStatus = props.getValue();

      return (
        <Chip $color={getPaymentStatusColor(paymentStatus)}>
          {t(getPaymentStatusName(paymentStatus))}
        </Chip>
      );
    },
  }),
  columnHelper.accessor("dateFrom", {
    header: t("adminSupplierStatement.items.columns.dateFrom"),
    size: TABLE_COLUMN_SIZE.DATE,
    cell: props => formatDate(props.getValue()),
  }),
  columnHelper.accessor("dateDeadline", {
    header: t("adminSupplierStatement.items.columns.dateDeadline"),
    size: TABLE_COLUMN_SIZE.DATE,
    cell: props => formatDate(props.getValue()),
  }),
  columnHelper.display({
    id: "actions",
    size: TABLE_COLUMN_SIZE.ACTION,
    cell: props => {
      const id = props.row.original.reservationId;

      return (
        <TableCell>
          <Link to={`/admin/bookings/${id}`}>
            <ButtonEdit />
          </Link>
        </TableCell>
      );
    },
  }),
];

export default getColumns;
