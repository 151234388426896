import { AdminCommissionListParams, AdminService } from "@hotelspoint/api";
import { useQuery } from "@tanstack/react-query";

const useAdminCommissions = (params?: AdminCommissionListParams) => {
  const { data, isLoading } = useQuery({
    queryKey: ["admin", "commissions", params],
    queryFn: () => AdminService.getCommissions(params),
  });

  const ret: [typeof data, boolean] = [data, isLoading];

  return ret;
};

export default useAdminCommissions;
