import { useTranslation } from "react-i18next";

import * as S from "./TableResultsCount.styled";

interface TableResultsCountProps {
  value?: number;
}

const TableResultsCount = ({ value }: TableResultsCountProps) => {
  const { t } = useTranslation();

  if (value === undefined) return null;

  return (
    <S.Wrapper>
      <span>{t("components.tableResultsCount.label")} </span>
      <span>{value}</span>
    </S.Wrapper>
  );
};

export default TableResultsCount;
