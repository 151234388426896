import { type FormControlInjectedProps } from "../FormControl";
import FormLabel from "../FormLabel";
import * as S from "./FormRadio.styled";

export interface FormRadioProps
  extends FormControlInjectedProps,
    Pick<React.InputHTMLAttributes<HTMLInputElement>, "disabled" | "checked"> {
  label: React.ReactNode;
}

const FormRadio = ({ label, id, ...inputProps }: FormRadioProps) => {
  return (
    <S.Wrapper>
      <S.RadioInput type="radio" id={id} {...inputProps} />
      <FormLabel htmlFor={id}>{label}</FormLabel>
    </S.Wrapper>
  );
};

export default FormRadio;
