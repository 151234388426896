import {
  AdminDestinationLocation,
  AdminDestinationLocationUpdatePayload,
} from "@hotelspoint/api";
import { InferType, number, object, string } from "yup";

export const validationSchema = object({
  id: number(),
  name: string().required(
    "adminDestinationLocation.form.name.validation.required",
  ),
  countryName: string(),
}).required();

export type FormValues = InferType<typeof validationSchema>;

export const defaultValues: FormValues = {
  id: undefined,
  name: "",
  countryName: "",
};

export const entity2Form = (entity: AdminDestinationLocation): FormValues => ({
  id: entity.id,
  name: entity.name,
  countryName: entity.countryName,
});

export const form2Entity = (
  formValues: FormValues,
): AdminDestinationLocationUpdatePayload => ({
  name: formValues.name,
});
